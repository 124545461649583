import img from "./Images/bot.png";
// import "./App.css";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

const steps = [
  {
    id: "0",
    message: "Hey Geek!",

    // This calls the next id
    // i.e. id 1 in this case
    trigger: "1",
  },
  {
    id: "1",

    // This message appears in
    // the bot chat bubble
    message: "Please write your username",
    trigger: "2",
  },
  {
    id: "2",

    // Here we want the user
    // to enter input
    user: true,
    trigger: "3",
  },
  {
    id: "3",
    message: " hi {previousValue}, how can I help you?",
    trigger: 4,
  },
  {
    id: "4",
    options: [
      // When we need to show a number of
      // options to choose we create alist
      // like this
      { value: 1, label: "View Courses" },
      { value: 2, label: "Read Articles" },
    ],
    end: true,
  },
];

// Creating our own theme
const theme = {
  background: "#fff",
  headerBgColor: "#47beb9 ",
  headerFontSize: "20px",
  botBubbleColor: "#47beb9",
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#FF5733",
  userFontColor: "white",
};

// Set some properties of the bot
const config = {
  botAvatar: img,
  floating: true,
};

// const Bot = () => {
//   var Tawk_API = Tawk_API || {},
//     Tawk_LoadStart = new Date();
//   (function() {
//     var s1 = document.createElement("script"),
//       s0 = document.getElementsByTagName("script")[0];
//     s1.async = true;
//     s1.src = "https://embed.tawk.to/650c2d110f2b18434fd9c60f/1harp03km";
//     s1.charset = "UTF-8";
//     s1.setAttribute("crossorigin", "*");
//     s0.parentNode.insertBefore(s1, s0);
//   })();
// };

function ChatBotUi() {
  return (
    <>
      {/* <ThemeProvider theme={theme}>
        <ChatBot
          // This appears as the header
          // text for the chat bot
          headerTitle="AdCreative"
          steps={steps}
          {...config}
        />
      </ThemeProvider> */}
    </>
  );
}

export default ChatBotUi;
