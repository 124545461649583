import React, { useEffect, useState } from 'react'
import "./imageGallery.css";
import ImageIcon from '../../../../../icons/imageIcon';
import LivePreview from '../../../../../icons/livePreview';
import SelectImg from "../../../../../../assets/images/select-img.png"
import SaveIcon from '../../../../../icons/saveIcon';
import { exoadsImages } from './adminImagesUrl';
import { addBgImage, addBrandData } from '../../../../../../Redux/brandSetupData';
import { useDispatch } from 'react-redux';
import axios, { ImageUrl, nodeUrl }  from '../../../../../axios/auth';
import Cookies from 'js-cookie';
export default function ImageGallery({setGenerationView,setImageGalleryVIew} ) {
  const userToken = Cookies.get("token");
const [exaodsImgUrl,setexaodsImgUrl]=useState()
const [loader, setLoader] = useState(false);
const dispatch=useDispatch()
const fetchBrand = async () => {
   
  try {
    const res = await axios.get("/get-image", {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });   
    // dispatch(addBrandData(res.data.data))
    setexaodsImgUrl(res.data.data)
    // Handle the response as needed
  } catch (e) {
    console.error("Error:", e);
    // Handle the error as needed
  }
};


useEffect(()=>{
  fetchBrand()
},[])

 // stock images states and  function end
 const uploadExoadsImages = async (picUrl) => {
    setLoader(true);
    let imgPath=`${nodeUrl}/${picUrl}`

    const base64Image = await urlToBase64(imgPath);
   
    dispatch(addBgImage(base64Image));
    dispatch(addBrandData({bgImage:base64Image,mainImage:picUrl}))
    setLoader(false);
    setImageGalleryVIew(false);
    setGenerationView("C")
  };
  const urlToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };

   
  // const uploadImageFun = async (e) => {
  //   // Check if files were selected
  //   if (e.target.files && e.target.files.length > 0) {
  //     const files = e.target.files;
  //     const formData = new FormData();
  
  //     // Append each selected file to FormData object
  //     for (let i = 0; i < files.length; i++) {
  //       formData.append('image', files[i]);
  //     }
  
  //     try {
  //       // Send POST request to upload endpoint
  //       const response = await axios.post(`/update-brand-image`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       });
  
  //       // Handle response
        
  //       // Assuming response.data.Data contains image path(s)
  //       if (response.data.Data) {
  //         const imagePath = response.data.Data.imagePath; // Adjust based on your API response structure
          
  //         // Dispatch actions to update Redux state or manage image paths
  //         dispatch(addBrandData({ mainImage: imagePath }));
  //         dispatch(addBrandData({ bgImage: `${nodeUrl}/${imagePath}` }));
          
  //         // Additional logic or state updates as needed
  //       }
  //     } catch (error) {
  //       console.error('Error uploading image:', error);
  //       // Handle error state or display error message to user
  //     }
  //   }
  
  //   // Reset input field after upload
  //   setTimeout(() => {
  //     e.target.value = null;
  //   }, 1000);
  // };
  






    return (
        <div className=
            "search-imges-section">
            <div className="search-imges-heading">
                <div className="search-img-box"  onClick={ ()=>{setImageGalleryVIew(false);setGenerationView("C")}}>
                    
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19 11.9993H5" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12 18.9995L5 11.9995L12 4.99951" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              
                </div>
                <h2>Background Image </h2>
            </div>

            <div className="search-images-main-details-alignment">
                <h3>Select image for your
                    Creative</h3>
            </div>

            <div className="stock-main-images-alignment">
                <div className="child-heading-alignment">
                    <LivePreview />
                    <h4>EXOAD Image Library </h4> 
                </div>

                <div className="all-images-alignment">
                    {exaodsImgUrl?.map((element,index) => {
                        return (

                            <div className="imagesBoxALignment">
                               
                                
                                <img src={`${nodeUrl}/${element?.path}`} alt="SelectImg"  onClick={()=>uploadExoadsImages(`${element?.path}`)}/>

                            </div>
                        )
                    })}
                </div>  
            </div>


            <div className="save-just-alignment">
                <SaveIcon />
                <p>Saved Just Now</p>
            </div>
        </div>
    )
}
