import React, { useContext, useEffect, useRef, useState } from "react";
import "./GoogleFunding.css";
import { Arrow, Caution, Excliamation, EyeSvg, Tick, googleSvg } from "./SVG";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import axios2, { nodeUrl } from "../../../../../axios/auth";
import { ToggleContext } from "../ContextFile";
// import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "../../../../../../assets/icons/close.png"
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from "js-cookie";
import { Modal, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API, { Customer_Id, GoogleAPI_BASEURL } from "../../../../../axios/auth";
import { Link } from "react-router-dom";
import { handleCoinChange } from "../../../../../../Redux/addCreateSlice";
// import nodeUrl from "../../../../../axios/auth"
import { format } from 'date-fns';

export const GoogleFunding = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const [toggle1, setToggle1] = useState("One-off");
  // const [toggle2, setToggle2] = useState("eur");
  const [rangeValue, setRangeValue] = useState(100); // Initialize the range value state with 0.
  const [fundinginfo, setFundingInfo] = useState({});
  const [modal, setModal] = useState(false);
  const [addBudgetView, setAddBudgetView] = useState(false);
  const { Cities, CountryCode, keywords } = useSelector(
    (state) => state.GoogleSearchDetails
  );
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const { HandleActiveStep } = useContext(ToggleContext);
  const [forecast, setForecast] = useState(null);
  const [isForecastLoading, setIsForecastLoading] = useState(false);
   const[id,setId] = useState("")
  const [customDateModal, setcustomDateModal] = useState(false)

  const { GoogleSearchHeadline, GoogleSearchDescription } = useSelector(
    (state) => state.CampaignDetail
  );

  const navigate = useNavigate();



  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate && date > endDate) {
      setEndDate(null); // Reset end date if the start date is after the end date
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  useEffect(() => {
    const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
    const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';

    setFundingInfo((prevInfo) => ({
      ...prevInfo,
      StartDate: formattedStartDate,
      endDate: formattedEndDate
    }));
  }, [startDate, endDate]);



  const { user } = useSelector((state) => state.loginuser);
const [pendingAdId,setPendingadId] = useState()
  
useEffect(()=>{
  setPendingadId((JSON.parse(sessionStorage.getItem("pendingAdData")))?._id)
},[])

  // update pending ad content
  async function updatePendingCampaign() {
    let data = {
  
      Amount: fundinginfo.amount,
      CountryCode: CountryCode,
      type: "search",
      Cities: Cities,
      startDate: FormatDate(fundinginfo?.StartDate),
      EndDate: FormatDate(fundinginfo?.endDate),
      GoogleSearchDescription: GoogleSearchDescription,
      GoogleSearchHeadline: GoogleSearchHeadline,
      keywords: keywords,
      WebsiteUrl: sessionStorage.getItem("websiteUrl"),
      websiteTitle: sessionStorage.getItem("websiteTitle"),
      noOfDays: fundinginfo?.noOfDays,
      clicks:Math.round(forecast?.clicks),
      impressions:forecast?.impressions.toFixed(2),
      status:"runing"
    };
      try {
        const res = await axios2.post(`/update-pending-ads/${id}`,data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error fetching customer:", error);
      }
    }
  // update pending ad content
  // token deduction for adFlow
  const deductToken = async() => {

    try {
      const res = await axios2.post("/deduct-token",{transactionType:"adFlow"},{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
      if (res?.data?.status==true) {
        dispatch(handleCoinChange(res?.data?.credits))
      } else {
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
      }
    } catch (error) {

    }
  };



 // token deduction for adFlow

 const deductTokenForAdPublish = async() => {

  try {
    const res = await axios2.post("/deduct-token",{transactionType:"publishAd"},{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
             
    if (res?.data?.status==true) {
      dispatch(handleCoinChange(res?.data?.credits))
    } else {
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 
    }
  } catch (error) {

  }
};



  const handleRangeChange = (event) => {
    // Update the range value state when the input value changes.
    setRangeValue(event.target.value);
  };

  const Monthly = [
    "Expert checks your ad",
    "Safety Net protects your budget",
    "Continuous optimization of your ad",
  ];

  const OnnOff = [
    "Expert checks your ad",
    "No long-term commitment",
    "Great for quick tests",
  ];

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();

    // Add leading zero if month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
 
    
   

    if (name === "amount") {
      let adjustedValue = parseInt(value); // Convert value to integer
      adjustedValue = Math.ceil(adjustedValue / 20) * 20; // Round up to the nearest multiple of 50
      setFundingInfo((prevState) => ({
        ...prevState,
        [name]: adjustedValue.toString(), // Convert back to string for state update
      }));
    } 
    if(value == "custom"){
      
      setcustomDateModal(true)
      setFundingInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      
    }
    else {
      setcustomDateModal(false)

      setFundingInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  //format date in this format: Output: 20240315
  const FormatDate = (Startdate) => {
    const date = new Date(Startdate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month index
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0");

    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
  };

  // let EndDate = FormatDate(fundinginfo.endDate);
  const SelectArray = [7, 14, 21];

  // date calculation according to the Select of days 7 days ,14 days and 21days
  function getNextDate(numberOfDays) {
    // Get the current date
    let currentDate = new Date();

    // Calculate the next date based on the provided number of days
    let nextDate = new Date(
      currentDate.getTime() + numberOfDays * 24 * 60 * 60 * 1000
    );

    // Format the next date as YYYY/MM/DD
    let StartDate = `${currentDate.getFullYear()}/${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    // Format the next date as YYYY/MM/DD
    let formattedNextDate = `${nextDate.getFullYear()}/${(
      nextDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${nextDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    setFundingInfo((prevState) => ({
      ...prevState,
      endDate: formattedNextDate,
      StartDate,
    }));
  }

  useEffect(() => {
    HandleActiveStep(3);
    if (toggle1 == "monthly") {
      setRangeValue(300);
      setFundingInfo((prevState) => ({
        ...prevState,
        amount: 300,
        noOfDays: "30",
      }));
    } else if (toggle1 == "One-off") {
      setRangeValue(20);
      setFundingInfo((prevState) => ({
        ...prevState,
        amount: 20,
        noOfDays: "7",
      }));
    }
  }, [toggle1]);

  useEffect(() => {
    if (fundinginfo.noOfDays) {
      getNextDate(fundinginfo.noOfDays);

      // range setting
      if (fundinginfo?.noOfDays == "7") {
        setRangeValue(20);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 20,
        }));
      } else if (fundinginfo?.noOfDays == "14") {
        setRangeValue(50);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 50,
        }));
      } else if (fundinginfo?.noOfDays == "21") {
        setRangeValue(100);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 100,
        }));
      }


      else if (fundinginfo?.noOfDays == "custom") {
        setRangeValue(500);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 500,
        }));
      }
    }
  }, [fundinginfo.noOfDays]);



  // check credits

  const checkcredits = async() => {
    try {
      const res = await axios2.post("/check-credits",{transactionType:"publishAd"},{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })

      if (res?.data?.status==true) {
         // dispatch(addWebsiteUrl(websitename));
     return true
 
      } else {
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
        return false

      }
    } catch (error) {

    }
  };




  //   useEffect(()=>{
  //       if (sessionStorage.getItem("googleSearchAdsCompleteData")){

  //       let data=JSON.parse(sessionStorage.getItem("googleSearchAdsCompleteData"))
  // // dateStrings End date and start Date,Calculate difference in days Start
  //  setFundingInfo((prevState) => ({
  //         ...prevState,
  //         amount: data?.Amount,
  //         noOfDays:data?.noOfDays ,
  //         EndDate:data?.EndDate,
  //         startDate:data?.startDate

  //       }));

  //     }
  //   },[])

  // api for payment from strip

  // let data={}

// save pending ad content
  async function createPendingCampaign() {
    let data = {

      Amount: parseInt(fundinginfo?.amount ) + parseInt(fundinginfo?.amount*10/100),
      CountryCode: CountryCode,
      type: "search",
      Cities: Cities,
      startDate: FormatDate(fundinginfo?.StartDate),
      EndDate: FormatDate(fundinginfo?.endDate),
      GoogleSearchDescription: GoogleSearchDescription,
      GoogleSearchHeadline: GoogleSearchHeadline,
      keywords: keywords,
      WebsiteUrl: sessionStorage.getItem("websiteUrl"),
      websiteTitle: sessionStorage.getItem("websiteTitle"),
      noOfDays: fundinginfo?.noOfDays,
      clicks:Math.round(forecast?.clicks),
      impressions:forecast?.impressions?.toFixed(2),
      status:"pending"
    };
      try {
        const res = await axios2.post("/create-pending-ads",data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setId(res?.data?.data?._id)
        sessionStorage.setItem("adId",res?.data?.data?._id)
      } catch (error) {
        // console.error("Error fetching customer:", error);
      }
    }
// save pending ad content


//update ad data
async function updatePendingAdData() {
  let data = {

    Amount: parseInt(fundinginfo?.amount ) + parseInt(fundinginfo?.amount*10/100),
    CountryCode: CountryCode,
    type: "search",
    Cities: Cities,
    startDate: FormatDate(fundinginfo?.StartDate),
    EndDate: FormatDate(fundinginfo?.endDate),
    GoogleSearchDescription: GoogleSearchDescription,
    GoogleSearchHeadline: GoogleSearchHeadline,
    keywords: keywords,
    WebsiteUrl: sessionStorage.getItem("websiteUrl"),
    websiteTitle: sessionStorage.getItem("websiteTitle"),
    noOfDays: fundinginfo?.noOfDays,
    clicks:Math.round(forecast?.clicks),
    impressions:forecast?.impressions?.toFixed(2),
    status:"pending",
    pendingAdId:pendingAdId
  };
    try {
      const res = await axios2.post("/update-pending-ads-data",data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     
   
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }


  const Checkout =async () => {
    setAddBudgetView(false)
    let data = {

      Amount: parseInt(fundinginfo?.amount ) + parseInt(fundinginfo?.amount*10/100),
      currency:user?.plan[0]?.currency ,
      CountryCode: CountryCode,
      type: "search",
      Cities: Cities,
      startDate: FormatDate(fundinginfo?.StartDate),
      EndDate: FormatDate(fundinginfo?.endDate),
      GoogleSearchDescription: GoogleSearchDescription,
      GoogleSearchHeadline: GoogleSearchHeadline,
      keywords: keywords,
      WebsiteUrl: sessionStorage.getItem("websiteUrl"),
      websiteTitle: sessionStorage.getItem("websiteTitle"),
      noOfDays: fundinginfo?.noOfDays,
      pendingAdId:id ? id : pendingAdId

    };



    //  if any of the data is not available then toast will pop and api will not be called
    if (
      data?.GoogleSearchDescription == "" ||
      data?.GoogleSearchHeadline == "" ||
      data?.GoogleSearchDescription[0] == "" ||
      data?.GoogleSearchDescription[1] == "" ||
      data?.GoogleSearchHeadline[0] == "" ||
      data?.GoogleSearchHeadline[1] == "" ||
      data?.GoogleSearchHeadline[2] == ""
    ) {
      // toast.error("Description and Headline is not Selected");
      toast.error("Description and Headline is not Selected"
        , {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 

      setTimeout(() => {
        navigate("/main/campaign/googlecompaign/google-add-1/content");
      }, 1000);
      return;
    } else if (
      data?.Cities?.length <= 0 ||
      data?.CountryCode?.length <= 0 ||
      keywords?.length <= 0
    ) {
      // toast.error("Country and Cities are not Selected");
      toast.error("Country and Cities are not Selected", {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 
      setTimeout(() => {
        navigate("/main/campaign/googlecompaign/google-add-1/targeting");
      }, 1000);
      return;
    }

    
    deductToken ()
if(pendingAdId){

  await  updatePendingAdData()
}
else{
  await createPendingCampaign()

}

   let checkres =  await checkcredits()

if(checkres==true){

  let SessionData = JSON.stringify(data);

  sessionStorage.setItem("googleSearchAdsCompleteData", SessionData);


    fetch(`${nodeUrl}/create-ads-checkout-session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
    body: JSON.stringify({
      data: data,
    }),
  })
    .then(async(res) => {
      if (res.ok) {
       await deductTokenForAdPublish()
        return res.json();}
      return res.json().then((json) => Promise.reject(json));
    })
    .then(({ url }) => {
        // updatePendingCampaign()
      window.location = url;

    })
    .catch((e) => {
      // console.log(e.error);
    });
}

   
  };

  // API for Google forcast details
  // useEffect(() => {
  //   ForcastForGoogle();
  // }, [fundinginfo.amount])

  useEffect(() => {
    setIsForecastLoading(true);

    const debounceTimer = setTimeout(() => {
      if (keywords?.length > 0 && fundinginfo.StartDate && fundinginfo.endDate) {
        ForcastForGoogle();
      }
    }, 3000);

    return () => {
      setIsForecastLoading(false);
      clearTimeout(debounceTimer);
    };
  }, [fundinginfo.amount, fundinginfo.StartDate, fundinginfo.endDate]);

  const ForcastForGoogle = async () => {
    let Keywords = keywords.map((item, i) => {
      let match_type;
      if (i === 0) {
          match_type = "EXACT";
      } else if (i === 1) {
          match_type = "PHRASE";
      } else {
          match_type = "BROAD";
      }
  
      return {
          text: item,
          max_cpc_bid_micros: fundinginfo.amount * 10000 + 50000 * i,
          match_type: match_type,
      };
  });
  

    // Assuming dateString is in the format 'YYYY-MM-DD'
    let dateString = FormatDate(fundinginfo?.StartDate);
    let formattedStartDate =
      dateString.slice(0, 4) +
      "-" +
      dateString.slice(4, 6) +
      "-" +
      dateString.slice(6);
    // Convert the formatted start date string to a Date object
    let startDate = new Date(formattedStartDate);
    // Add one day to the start date
    startDate.setDate(startDate.getDate() + 1);
    // Format the new date back into the desired format
    let nextDayDate = startDate?.toISOString()?.slice(0, 10);

    let dateString2 = FormatDate(fundinginfo?.endDate);
    let formattedEndDate =
      dateString2.slice(0, 4) +
      "-" +
      dateString2.slice(4, 6) +
      "-" +
      dateString2.slice(6);

    let data = {
      max_cpc_bid_micros: parseInt(fundinginfo.amount) * 10000,
      location_id: 2840,
      start_date: nextDayDate,
      end_date: formattedEndDate,

      keywords: Keywords,
    };



    // const url = `http://3.6.128.153:5000/v1/google/forecast?customer_id=${Customer_Id}`;
    const url = `${nodeUrl}/get-forecast`;


    try {
//node
      const ads = await axios.post(url, data);

      //python
      // const ads = await axios.post(url, data, {
      //   headers: {
      //     Authorization: "Basic ZXhvYWRzYWRtaW46b0tIS2ZxOVZIa0RBVXQ3",
      //     token: "f5d1da64-9bbd-4fe4-8ad5-bfb168c44447",
      //   },
      // });

      if (ads?.data) {
        setForecast(ads.data.data);
      }
      setIsForecastLoading(false);
    } catch (error) {
      // console.log(error);
      setIsForecastLoading(false);
    }

  
  };

  // ask an Expert from submission function-----> start

  const AskAnExpertForm = async (data) => {
    const token = Cookies.get("token");  
    try {
      const res = await API.post("/create-expert", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // toast.success("Form Submitted");
      toast.success("Form Submitted", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });

      return res;
    } catch (error) {
      // console.log(error);
    }
  };
  const currencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };


  function AddBudgetModal({ setAddBudgetView }) {

    const currencySymbol = (currency) => {
      switch (currency) {
        case 'USD':
          return '$';
        case 'INR':
          return '₹';
        case 'EUR':
          return '€';
        default:
          return '';
      }
    };


    return (


      <div className='add-budget-modal-section'>
        <div className='add-budget-modal-box'>
          <div className="add-budget-modal-heading">
            <div className="budget-details-flex">
              <h2>Ad Budget</h2>
              <p>
                {/* {user?.plan[0]?.currency} {fundinginfo?.amount} */}
              {` ${currencySymbol(user?.plan[0]?.currency)} ${fundinginfo.amount}`}
              
              </p>
            </div>
            <div className="budget-details-flex">
              <h2>Commission</h2>
              <p>
                {/* {user?.plan[0]?.currency} */}
                {currencySymbol(user?.plan[0]?.currency)} {fundinginfo?.amount*10/100} </p>
            </div>
            <div className="budget-details-flex">
              <h2>Total</h2>
              <p>{currencySymbol(user?.plan[0]?.currency)}  {parseInt(fundinginfo?.amount ) + parseInt(fundinginfo?.amount*10/100) }</p>
            </div>
          </div>
  
          <div className="close-icon-alignment" onClick={() => setAddBudgetView(false)}>
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
  
          <div className="launch-add-alignment" onClick={() => Checkout()}>
            <button>Launch Ad</button>
          </div>
         
        </div>
      </div>
    )
  }
  


  // ask an Expert from submission function-----> End
  return (
    <div className="google-funding-box">
      <div className="google-funding-main">
        <div className="google-funding-div1">
          <p className="google-funding-div1-text">Budget</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className=" google-funding-toggle-btn">
              <div
                className={`google-funding-toggle-btn-box ${toggle1 ===
                  "One-off" && "active"}`}
                onClick={() => setToggle1("One-off")}
              >
                <p
                  className={`google-funding-toggle-btn-text ${toggle1 ===
                    "One-off" && "active"}`}
                >
                  One-off
                </p>
              </div>

              <div
                className={`google-funding-toggle-btn-box ${toggle1 ===
                  "monthly" && "active"}`}
                onClick={() => setToggle1("monthly")}
              >
                <p
                  className={`google-funding-toggle-btn-text ${toggle1 ===
                    "monthly" && "active"}`}
                >
                  Monthly
                </p>
              </div>
            </div>
            {/* <ToggleBtn
              toggle={toggle2}
              setToggle={setToggle2}
              display={"block"}
            /> */}
          </div>
        </div>

        <div className="google-funding-div2">
          <div className="google-funding-div2-box">
            <div className="google-funding-div2-sub-box">
              <p className="google-funding-div2-p">
                 {`${currencySymbol(user?.plan[0]?.currency)} ${fundinginfo.amount}`} </p>
              {toggle1 === "One-off" ? (
                <Select
                  options={SelectArray}
                  handleChange={handleInputChange}
                />
              ) : (
                <p className="google-funding-div2-p">30 days</p>
              )}
            
            </div>
     
            {customDateModal && (

<div className="datepiker-box-alignment">

  <div className="datepicker-top-heading">
    <div>
      <label>Start Date</label>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        // minDate={new Date()}
        dateFormat="dd MMMM yyyy"
      />
    </div>
    <span> - </span>
    <div>
      <label>End Date</label>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd MMMM yyyy"
      />
    </div>
  </div>

  <div className="datepicker-bottom-alignment">
    <button onClick={() => setcustomDateModal(false)}>Cancel</button>
    {/* <button onClick={() =>setcustomDateModal(false), toggleDataSeries("cost")}>Apply</button> */}
    <button onClick={() =>  setcustomDateModal(false)} >Apply</button>

  </div>
</div>
)}
            <span className="google-funding-div2-span">Incl. 10% Commi.</span>
          </div>
          {/* <input
            style={{ width: "100%" }}
            type="range"
            value={fundinginfo.amount}
            min={rangeValue}
            max={1300}
            // onChange={handleRangeChange}
            name={"amount"}
            onChange={handleInputChange}
          /> */}

<input
  style={{
    width: '100%',
    appearance: 'none', // Removes default styles
    background: `linear-gradient(to right, #47beb9 0%, #47beb9 var(--value), #edf9f8 var(--value), #edf9f8 100%)`,
    '--value': `${((fundinginfo?.amount - rangeValue) / (1300 - rangeValue)) * 100}%`,
    borderRadius: '5px', // Adjust for rounded corners
    outline: 'none', // Removes outline
    border: 'none', // Removes default border
  }}
  type="range"
  value={fundinginfo.amount}
  min={rangeValue}
  max={1300}
  name="amount"
  onChange={handleInputChange}
/>
        </div>

        {/* <div className="google-pick-date-main">
          <div className="current-date-sub-main" style={{ gap: 30 }}>
            <span className="google-funding-div3-title">Start Date</span>
            <input
              type="date"
              min={getCurrentDate()}
              // {...register("SartingDateByTime", { required: true })}
              name={"startDate"}
              onChange={handleInputChange}
            />
          </div>
          <div className="current-date-sub-main" style={{ gap: 30 }}>
            <span className="google-funding-div3-title">End Date</span>
            <input
              type="date"
              min={getCurrentDate()}
              name={"endDate"}
              onChange={handleInputChange}
            />
          </div>
        </div> */}

        <div className="google-funding-div3">
          <span className="google-funding-div3-title">Forecast</span>

          <div
            className="google-funding-div3-box"
            style={
              toggle1 !== "monthly"
                ? { background: "#EDF9F8 ", width: "100%", height: "auto" }
                : { width: "auto" }
            }
          >
            {keywords?.length > 0 ? (
              <>
                <ForecastBox
                  title={"Impressions"}
                  views={forecast?.impressions?
                  forecast?.impressions.toFixed(2):0
                    
                  }
                  icon={EyeSvg}
                  isForecastLoading={isForecastLoading}
                />
                <ForecastBox
                  title={"Clicks"}
                  views={ forecast?.clicks?Math.round(forecast?.clicks):0 }
                  icon={Arrow}
                  isForecastLoading={isForecastLoading}
                />
              </>
            ) : (
              <NoForecast
                body={`   You have selected 0 keywords. Please select between 3 and 40 keywords
              in order to generate a forecast.`}
                title={"No forecast available"}
                logo={Caution}
              />
            )}
          </div>
        </div>

        <div className="google-funding-div4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <span className="google-funding-div4-text">Launch ON</span>
            <span style={{ marginBottom: "3px" }}>{googleSvg}</span>
          </div>

          <div className="google-funding-div4-main">
            {(toggle1 === "One-off" ? OnnOff : Monthly)?.map((item) => (
              <LaunchItems item={item} />
            ))}
          </div>
        </div>
        <div className="google-funding-div5" style={{}}>
          {/* <button
            className="google-funding-div5-btn1"
            style={{ background: "#160647" }}
          >
            <span className="google-funding-div5-btn1-text">Ask An Expert</span>
          </button> */}
          {/* <div
            style={{
              background: "#358F8B",
              width: "200px",
              borderRadius: "10px",
              alignSelf: "center",
            }}
          >
            <button
              className="google-funding-div5-btn1"
              // onClick={() => setModal(true)}
              style={{ background: "#358F8B", borderRadius: "10px" }}
            >
              <span className="google-funding-div5-btn1-text">
                Launch Google Ad.
              </span>
            </button>
          </div> */}
          <div className="google-funding-div5">
            <button
              style={{ background: "none" }}
              onClick={() => setModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="61"
                viewBox="0 0 273 61"
                fill="none"
              >
                <g filter="url(#filter0_i_370_10606)">
                  <path
                    d="M0 11.3884C0 5.09876 5.09876 0 11.3884 0H246.812C251.599 0 255.875 2.99341 257.513 7.49127L271.433 45.7145C274.138 53.1427 268.638 61 260.732 61H11.3884C5.09876 61 0 55.9012 0 49.6116V11.3884Z"
                    fill="url(#paint0_linear_370_10606)"
                  />
                </g>
                <text
                  x="24%"
                  y="64%"
                  font-family="Arial"
                  className="Ask-an-Expert"
                >
                  Ask an Expert
                </text>
                <defs>
                  <filter
                    id="filter0_i_370_10606"
                    x="0"
                    y="0"
                    width="285.797"
                    height="73.1476"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="13.6661" dy="12.1476" />
                    <feGaussianBlur stdDeviation="18.6011" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_370_10606"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_370_10606"
                    x1="0"
                    y1="0"
                    x2="265.5"
                    y2="61"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#01417E" />
                    <stop offset="1" stop-color="#072F56" />
                  </linearGradient>
                </defs>
              </svg>
            </button>


            {isForecastLoading ? 
          
        //   <button
        //   style={{ background: "none" }}
        
        // >
        //   <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="200"
        //     height="61"
        //     viewBox="0 0 273 61"
        //     fill="none"
        //   >
        //     {/* Your SVG path and filter definitions */}
        //     <g filter="url(#filter0_i_370_10611)">
        //       <path
        //         d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
        //         fill="url(#paint0_linear_370_10611)"
        //       />
        //     </g>
        //     {/* Spinner element */}
        //     <foreignObject x="0" y="0" width="100%" height="100%">
        //       <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        //         <Spinner color="white" size="sm" />
        //       </div>
        //     </foreignObject>
        //   </svg>
        // </button>
        
        <button style={{ background: "none", position: "relative", border: "none", padding: 0 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="61"
          viewBox="0 0 273 61"
          fill="none"
        >
          <g filter="url(#filter0_i_370_10611)">
            <path
              d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
              fill="url(#paint0_linear_370_10611)"
            />
          </g>
          <defs>
            <filter id="filter0_i_370_10611" x="0" y="0" width="273" height="61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.478431 0 0 0 0 0.478431 0 0 0 0 0.478431 0 0 0 0.3 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_370_10611" />
            </filter>
            <linearGradient
                  id="paint0_linear_370_10611"
                  x1="273"
                  y1="61"
                  x2="7.5"
                  y2="-1.26219e-06"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#47BEB9" />
                  <stop offset="1" stop-color="#358F8B" />
                </linearGradient>
          </defs>
          
            <foreignObject x="0" y="0" width="100%" height="100%">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                <Spinner color="white" size="sm" />
              </div>
            </foreignObject>
        </svg>
      </button>
:
          <button
            // onClick={() => setModal(true)}
            // onClick={Checkout}
            onClick={() => setAddBudgetView(true)}
            style={{ background: "none" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="61"
              viewBox="0 0 273 61"
              fill="none"
            >
              <g filter="url(#filter0_i_370_10611)">
                <path
                  d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
                  fill="url(#paint0_linear_370_10611)"
                />
              </g>
              <text
                x="21%"
                y="64%"
                font-family="Arial"
                className="Ask-an-Expert"
              >
                Proceed To Payment
              </text>
              <defs>
                <filter
                  id="filter0_i_370_10611"
                  x="0.869141"
                  y="0"
                  width="285.797"
                  height="73.1476"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="13.6661" dy="12.1476" />
                  <feGaussianBlur stdDeviation="18.6011" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_370_10611"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_370_10611"
                  x1="273"
                  y1="61"
                  x2="7.5"
                  y2="-1.26219e-06"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#47BEB9" />
                  <stop offset="1" stop-color="#358F8B" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        }
          </div>
        </div>
      </div>
      <CustomerIdModal
        show={modal}
        setModal={setModal}
        onHide={() => setModal(false)}
        AskAnExpertForm={AskAnExpertForm}
      />
 {
        addBudgetView && (

          <AddBudgetModal setAddBudgetView={setAddBudgetView} />
        )
      }
      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/googlecompaign/google-add-1/targeting"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Back
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const ToggleBtn = ({ toggle, setToggle, display }) => {
  return (
    <div className=" google-funding-toggle-btn">
      <div
        className={`google-funding-toggle-btn-box ${toggle === "eur" &&
          "active"}`}
        onClick={() => setToggle("eur")}
      >
        <p
          className={`google-funding-toggle-btn-text ${toggle === "eur" &&
            "active"}`}
        >
          EUR
        </p>
      </div>

      <div
        className={`google-funding-toggle-btn-box ${toggle === "usd" &&
          "active"}`}
        onClick={() => setToggle("usd")}
      >
        <p
          className={`google-funding-toggle-btn-text ${toggle === "usd" &&
            "active"}`}
        >
          USD
        </p>
      </div>

      <div
        className={`google-funding-toggle-btn-box ${toggle === "gdp" &&
          "active"}`}
        onClick={() => setToggle("gdp")}
        style={{ display: display }}
      >
        <p
          className={`google-funding-toggle-btn-text ${toggle === "gdp" &&
            "active"}`}
        >
          GDP
        </p>
      </div>
    </div>
  );
};

const ForecastBox = ({ views, title, icon, isForecastLoading }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "11px" }}>
      {icon}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <span className="google-funding-div3-impression-no ">
          {isForecastLoading ? <Spinner size="sm" /> : views}
        </span>
        <p className="google-funding-div3-impression ">{title}</p>
      </div>
    </div>
  );
};
export const NoForecast = ({ logo, title, body, color }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
      }}
    >
      <span>{logo}</span>
      <div>
        <p className="no-forecast-title">{title}</p>
        <span className="no-forecast-para" style={{ color: color }}>
          {body}
        </span>
      </div>
    </div>
  );
};

const LaunchItems = ({ item }) => {
  return (
    <div className="google-funding-div4-main">
      <div className="google-funding-div4-sub-main">
        {Tick}
        <span className="google-funding-div4-sub-main-text">{item}</span>
        {Excliamation}
      </div>
    </div>
  );
};

const Select = ({ selectHeading, options, handleChange }) => {
  const dropdownRef = useRef();

  const handleDropdownClick = () => {
    dropdownRef.current.classList.add("clicked");
  };
  return (
    <select
      name="noOfDays"
      className="hone-campaign-dropdown"
      ref={dropdownRef}
      onClick={(e) => handleChange(e)}
    >
      {/* <option
        value=""
        className="placeholder"
        style={"clicked" ? { display: "none" } : {}}
      >
        {selectHeading}
      </option> */}
      {options?.map((item, i) => (
        <>
          <option value={item}>{`${item} days`}</option>

        </>
      ))}
      <option value="custom">custom</option>

    </select>
  );
};

// MODAL FOR CUSTOMER_ID SELECTION
const CustomerIdModal = (props) => {
  const { AskAnExpertForm, onHide,setModal } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneLength, setPhoneLength] = useState(10);
  // const [value, setValue] = useState(null);
  // const options = countryList().getData();
  // const [error, setError] = useState('');
  


  const handleCountryCodeChange = (e) => {
    if(e.target.value.length ==1){
      setCountryCode("+");
    }
    else{
    

    const newCode = e.target.value.match(/\+\d+/) ? e.target.value.match(/\+\d+/)[0] : '';
    setCountryCode(newCode);

    const length = countryPhoneLengths[newCode] || 10; // Default to 10 if not found
    setPhoneLength(length);
    }
  };

  const countryPhoneLengths = {
    "+1": 10, // USA, Canada, several Caribbean nations
    "+7": 10, // Russia, Kazakhstan
    "+20": 10, // Egypt
    "+27": 9, // South Africa
    "+30": 10, // Greece
    "+31": 9, // Netherlands
    "+32": 9, // Belgium
    "+33": 9, // France
    "+34": 9, // Spain
    "+36": 9, // Hungary
    "+39": 10, // Italy
    "+40": 10, // Romania
    "+41": 9, // Switzerland
    "+43": 10, // Austria
    "+44": 10, // UK
    "+45": 8, // Denmark
    "+46": 10, // Sweden
    "+47": 8, // Norway
    "+48": 9, // Poland
    "+49": 11, // Germany
    "+51": 9, // Peru
    "+52": 10, // Mexico
    "+53": 8, // Cuba
    "+54": 10, // Argentina
    "+55": 10, // Brazil
    "+56": 9, // Chile
    "+57": 10, // Colombia
    "+58": 10, // Venezuela
    "+60": 10, // Malaysia
    "+61": 9, // Australia
    "+62": 10, // Indonesia
    "+63": 10, // Philippines
    "+64": 9, // New Zealand
    "+65": 8, // Singapore
    "+66": 9, // Thailand
    "+81": 10, // Japan
    "+82": 10, // South Korea
    "+84": 9, // Vietnam
    "+86": 11, // China
    "+90": 10, // Turkey
    "+91": 10, // India
    "+92": 10, // Pakistan
    "+93": 9, // Afghanistan
    "+94": 9, // Sri Lanka
    "+95": 9, // Myanmar
    "+98": 10, // Iran
    "+212": 9, // Morocco
    "+213": 9, // Algeria
    "+216": 8, // Tunisia
    "+218": 9, // Libya
    "+220": 8, // Gambia
    "+221": 9, // Senegal
    "+222": 8, // Mauritania
    "+223": 8, // Mali
    "+224": 9, // Guinea
    "+225": 8, // Côte d'Ivoire
    "+226": 8, // Burkina Faso
    "+227": 8, // Niger
    "+228": 8, // Togo
    "+229": 8, // Benin
    "+230": 7, // Mauritius
    "+231": 8, // Liberia
    "+232": 8, // Sierra Leone
    "+233": 9, // Ghana
    "+234": 10, // Nigeria
    "+235": 8, // Chad
    "+236": 8, // Central African Republic
    "+237": 9, // Cameroon
    "+238": 7, // Cape Verde
    "+239": 7, // São Tomé and Príncipe
    "+240": 9, // Equatorial Guinea
    "+241": 9, // Gabon
    "+242": 9, // Republic of the Congo
    "+243": 9, // Democratic Republic of the Congo
    "+244": 9, // Angola
    "+245": 7, // Guinea-Bissau
    "+248": 7, // Seychelles
    "+249": 9, // Sudan
    "+250": 9, // Rwanda
    "+251": 9, // Ethiopia
    "+252": 7, // Somalia
    "+253": 6, // Djibouti
    "+254": 9, // Kenya
    "+255": 9, // Tanzania
    "+256": 9, // Uganda
    "+257": 8, // Burundi
    "+258": 9, // Mozambique
    "+260": 9, // Zambia
    "+261": 9, // Madagascar
    "+262": 9, // Réunion
    "+263": 9, // Zimbabwe
    "+264": 9, // Namibia
    "+265": 8, // Malawi
    "+266": 8, // Lesotho
    "+267": 8, // Botswana
    "+268": 8, // Eswatini
    "+269": 7, // Comoros
    "+290": 7, // Saint Helena
    "+291": 7, // Eritrea
    "+297": 7, // Aruba
    "+298": 6, // Faroe Islands
    "+299": 6, // Greenland
    "+350": 8, // Gibraltar
    "+351": 9, // Portugal
    "+352": 9, // Luxembourg
    "+353": 9, // Ireland
    "+354": 7, // Iceland
    "+355": 9, // Albania
    "+356": 8, // Malta
    "+357": 8, // Cyprus
    "+358": 9, // Finland
    "+359": 9, // Bulgaria
    "+370": 8, // Lithuania
    "+371": 8, // Latvia
    "+372": 7, // Estonia
    "+373": 8, // Moldova
    "+374": 8, // Armenia
    "+375": 9, // Belarus
    "+376": 6, // Andorra
    "+377": 9, // Monaco
    "+378": 9, // San Marino
    "+380": 9, // Ukraine
    "+381": 9, // Serbia
    "+382": 9, // Montenegro
    "+383": 9, // Kosovo
    "+385": 9, // Croatia
    "+386": 9, // Slovenia
    "+387": 8, // Bosnia and Herzegovina
    "+389": 8, // North Macedonia
    "+420": 9, // Czech Republic
    "+421": 9, // Slovakia
    "+423": 9, // Liechtenstein
    "+500": 5, // Falkland Islands
    "+501": 7, // Belize
    "+502": 8, // Guatemala
    "+503": 8, // El Salvador
    "+504": 8, // Honduras
    "+505": 8, // Nicaragua
    "+506": 8, // Costa Rica
    "+507": 7, // Panama
    "+508": 6, // Saint Pierre and Miquelon
    "+509": 8, // Haiti
    "+590": 9, // Guadeloupe
    "+591": 8, // Bolivia
    "+592": 7, // Guyana
    "+593": 9, // Ecuador
    "+594": 9, // French Guiana
    "+595": 9, // Paraguay
    "+596": 9, // Martinique
    "+597": 7, // Suriname
    "+598": 9, // Uruguay
    "+599": 7, // Curaçao, Caribbean Netherlands
    "+670": 8, // East Timor
    "+672": 6, // Australian External Territories
    "+673": 7, // Brunei
    "+674": 7, // Nauru
    "+675": 8, // Papua New Guinea
    "+676": 7, // Tonga
    "+677": 7, // Solomon Islands
    "+678": 7, // Vanuatu
    "+679": 7, // Fiji
    "+680": 7, // Palau
    "+681": 6, // Wallis and Futuna
    "+682": 5, // Cook Islands
    "+683": 4, // Niue
    "+685": 7, // Samoa
    "+686": 8, // Kiribati
    "+687": 6, // New Caledonia
    "+688": 5, // Tuvalu
    "+689": 6, // French Polynesia
    "+690": 4, // Tokelau
    "+691": 7, // Micronesia
    "+692": 7, // Marshall Islands
    // Add more mappings as needed
};


const countryCodes = [
  { code: "+1", name: "USA" },
  { code: "+44", name: "UK" },
  { code: "+91", name: "India" },
  { code: "+61", name: "Australia" },
  { code: "+86", name: "China" },
  { code: "+81", name: "Japan" },
  { code: "+49", name: "Germany" },
  { code: "+33", name: "France" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+55", name: "Brazil" },
  { code: "+27", name: "South Africa" },
  { code: "+34", name: "Spain" },
  { code: "+52", name: "Mexico" },
  { code: "+82", name: "South Korea" },
  { code: "+60", name: "Malaysia" },
  { code: "+63", name: "Philippines" },
  { code: "+64", name: "New Zealand" },
  { code: "+65", name: "Singapore" },
  { code: "+66", name: "Thailand" },
  { code: "+90", name: "Turkey" },
  { code: "+31", name: "Netherlands" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+45", name: "Denmark" },
  { code: "+47", name: "Norway" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+358", name: "Finland" },
  { code: "+353", name: "Ireland" },
  { code: "+43", name: "Austria" },
  { code: "+32", name: "Belgium" },
  { code: "+420", name: "Czech Republic" },
  { code: "+30", name: "Greece" },
  { code: "+36", name: "Hungary" },
  { code: "+371", name: "Latvia" },
  { code: "+370", name: "Lithuania" },
  { code: "+356", name: "Malta" },
  { code: "+373", name: "Moldova" },
  { code: "+48", name: "Poland" },
  { code: "+40", name: "Romania" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+372", name: "Estonia" },
  { code: "+420", name: "Czech Republic" },
  { code: "+212", name: "Morocco" },
  { code: "+216", name: "Tunisia" },
  { code: "+213", name: "Algeria" },
  { code: "+234", name: "Nigeria" },
  { code: "+254", name: "Kenya" },
  { code: "+256", name: "Uganda" },
  { code: "+255", name: "Tanzania" },
  { code: "+258", name: "Mozambique" },
  { code: "+27", name: "South Africa" },
  { code: "+20", name: "Egypt" },
  { code: "+233", name: "Ghana" },
  { code: "+221", name: "Senegal" },
  { code: "+92", name: "Pakistan" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+962", name: "Jordan" },
  { code: "+965", name: "Kuwait" },
  { code: "+961", name: "Lebanon" },
  { code: "+968", name: "Oman" },
  { code: "+974", name: "Qatar" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+963", name: "Syria" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+972", name: "Israel" },
  { code: "+93", name: "Afghanistan" },
  { code: "+880", name: "Bangladesh" },
  { code: "+975", name: "Bhutan" },
  { code: "+673", name: "Brunei" },
  { code: "+855", name: "Cambodia" },
  { code: "+86", name: "China" },
  { code: "+61", name: "Australia" },
  { code: "+853", name: "Macau" },
  { code: "+852", name: "Hong Kong" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+972", name: "Israel" },
  { code: "+81", name: "Japan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+961", name: "Lebanon" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+976", name: "Mongolia" },
  { code: "+95", name: "Myanmar" },
  { code: "+977", name: "Nepal" },
  { code: "+64", name: "New Zealand" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+970", name: "Palestinian Territory" },
  { code: "+63", name: "Philippines" },
  { code: "+974", name: "Qatar" },
  { code: "+65", name: "Singapore" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+66", name: "Thailand" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+84", name: "Vietnam" },
  { code: "+967", name: "Yemen" },
];


  const handleChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only digits, spaces, parentheses, dashes, and dots
    const regex = /^[0-9\b\s().-]+$/;

    if (value === "" || regex.test(value)) {
      setPhoneNumber(value);
    }
  };

  const { register, handleSubmit, reset } = useForm();

  const Submit = async (data) => {
    data = { ...data, phoneNo: phoneNumber ,countryCode:countryCode};
    let res = await AskAnExpertForm(data);
    if (res?.data?.status) {
      reset();
      setPhoneNumber("");
      onHide();
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Row className="header-modal">
        <Row className="header-modal-heading">Ask an Expert</Row>

       
        <Row>
        <div className="close-icon-ask" onClick={() => setModal(false)}>
                        <img src={CloseIcon} alt="CloseIcon" />
                    </div>
          <form
            onSubmit={handleSubmit(Submit)}
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="ask-an-expert-text-field">
              <h6>Name*</h6>
              <input
                type="text"
                required
                maxLength={40}
                {...register("name")}
                placeholder="Enter Name"
              />
            </div>

            <div className="ask-an-expert-text-field">
              <h6>Email*</h6>
              <input
                type="email"
                required
                maxLength={40}
                {...register("email")}
                placeholder="Enter Email"
              />
            </div>
            <div className="ask-an-expert-text-field">
              <h6>Phone No</h6>

              <div
        className="phoneModal"
        style={{ width: "100%", display: "flex", alignItems: "center" }}
      >

          <input
          list="countryCodes"
          value={countryCode}
          onChange={handleCountryCodeChange}
          className="countryCodeInput"
          placeholder="Code"
          maxLength={5}
          // minLength={5}
          required
        />
        <datalist id="countryCodes">
        {countryCodes.map((country) => (
            <option key={country?.code} value={`${country?.code} ${country?.name}`}>
              {country?.code} {country?.name}
            </option>
          ))}
             
        </datalist>
        
        <input
          required
          className="phoneNumberInput"
          type="tel"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handleChange}
          pattern={`^\\d{${phoneLength}}$`}
          title={`Please enter a valid phone number with ${phoneLength} digits`}
          maxLength={phoneLength}
          minLength={phoneLength}
          
        />



      </div>
              {/* <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <p>+91</p>
                <input
                  style={{ width: "100%" }}
                  type="tel"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handleChange}
                  pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                  title="Please enter a valid phone number"
                  maxLength={10}
                  minLength={10}
                />
              </div> */}


            </div>
            <div className="ask-an-expert-text-field">
              <h6>Remark</h6>
              <textarea
                type="text"
                {...register("remark")}
                maxLength={100}
                placeholder="Enter Remark"
              />
            </div>
            <button type="submit" style={{ background: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="140"
                height="61"
                viewBox="0 0 273 61"
                fill="none"
              >
                <g filter="url(#filter0_i_370_10611)">
                  <path
                    d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
                    fill="url(#paint0_linear_370_10611)"
                  />
                </g>
                <text
                  x="35%"
                  y="68%"
                  font-family="Arial"
                  className="Ask-an-expert-modal-btn"
                >
                  Submit
                </text>
                <defs>
                  <filter
                    id="filter0_i_370_10611"
                    x="0.869141"
                    y="0"
                    width="285.797"
                    height="73.1476"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="13.6661" dy="12.1476" />
                    <feGaussianBlur stdDeviation="18.6011" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_370_10611"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_370_10611"
                    x1="273"
                    y1="61"
                    x2="7.5"
                    y2="-1.26219e-06"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#358F8B" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </form>
        </Row>
      </Row>
    </Modal>
  );
};

export default GoogleFunding;

