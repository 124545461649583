import React, { useState } from "react";
import style from "./brandsetup.module.css";

function ColorPicker() {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#555"); // Initialize with a default color

  const handleButtonClick = () => {
    setColorPickerVisible(!colorPickerVisible);
  };
  
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    setColorPickerVisible(false);
  };

  return (
    <div>
      <input
        className={style.ColorPickerBtn}
        type="button"
        id="button"
        onClick={handleButtonClick}
        value={selectedColor}
        style={{ background: selectedColor, color: "#fff" }}
      />
      {colorPickerVisible && (
        <input
          style={{ position: "absolute", margin: "5px 20px" }}
          type="color"
          id="colorPicker"
          onChange={handleColorChange}
        />
      )}
      {/* <div id="colorValue">Selected Color: {selectedColor}</div> */}
      {colorPickerVisible && (
        <div
          id="colorPreview"
          style={
            {
              // backgroundColor: selectedColor,
              // color: "white",
            }
          }
        ></div>
      )}
    </div>
  );
}

export default ColorPicker;
