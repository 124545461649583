import React, { useEffect, useRef, useState } from "react";
import "./PlatformSelection.css";
import { Button, Col, Container, Modal, Overlay, Row } from "react-bootstrap";
import {
  QuestionComponent,
  RightComponent,
} from "../NewCampaigns/NewCampaigns";
import { Facebooklogo, igLogo, googleLogo, BingLogo } from "./SVG";
import { Logo1, activeLogo3, logo2 } from "../NewCampaigns/SVG";
import Bing from "../../IntegrationPage/Images/Bing.png";
import ManImg from "./images/platform-man-img.png";
import { Link, useNavigate } from "react-router-dom";
import { NavItems } from "../../../../UI-Components/ReusableComponents";
import AiCampaignSuccess from "../../Plans/SuccessfullPurchase/AiCampaignSuccess";
import { successBtn } from "../../Plans/Svg";

import megaPhone from "../../Plans/SuccessfullPurchase/Image/MegaPhone.jpg";
import BotImage from "../../Plans/SuccessfullPurchase/Image/BotImage.jpg";

import style from "../../Plans/SuccessfullPurchase/AiCampaignSuccess.module.css";
import SearchAd from "../../../Main Pages/Images/SearchAdModal.png";
import DisplayAd from "../../../Main Pages/Images/DisplayAdModal.png";
import platformHeadline from "../../../Main Pages/Images/platformHeadline.png";
import faceBookLogo from "../../../Main Pages/Images/Facebook3D.png";
import Google3D from "../../../Main Pages/Images/Google3D.png";
import bingLogo from "../../../Main Pages/Images/Bing3D.png";

import IgLogo from "../../../Main Pages/Images/Ig3D.png";

import Cookies from "js-cookie";
// import axios from "axios";
import axios from "../../../../axios/auth"
import { toast } from "react-toastify";
import FacebookChooseAccountPopup from "../GoogleCampaign/SideNavContainer/FacebookChooseAccountPopup";
import InstragramChooseAccountPopup from "../GoogleCampaign/SideNavContainer/InstragramChooseAccountPopup";
import ChooseAccountPopup from "../GoogleCampaign/SideNavContainer/ChooseAccountPopup";
const PlatformSelection = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [facebookAccountModal, setFacebookAccountModal] = useState(false);
  const [instragramAccountModal, setInstragramAccountModal] = useState(false);
  const [googleAccountModal, setGoogleAccountModal] = useState(false);

 
  // to add border Radius in Modal
  useEffect(() => {
    // Get the modal content element
    const modalContent = document.querySelector(".modal-content");

    // Check if modal content exists
    if (modalContent) {
      // Add border radius
      modalContent.style.borderRadius = "40px";

      // You can adjust the value as needed
    }

    // Clean up function (optional)
    return () => {
      // Remove border radius when component unmounts (optional)
      if (modalContent) {
        modalContent.style.borderRadius = null;
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once after the initial render







  const navigate = useNavigate();
  const handleChooseBtn = (url) => {
    navigate(url);
  };
  return (
    <Container
      fluid
      style={{ margin: "0px 10px" }}
      className="platfrom-selection-scroller"
    >
      <Row className="platfromSelection-row-alignment ">
        <Col style={{ padding: "0px" }}>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "0px",
              justifyContent: "space-between",
            }}
          >
            <div className="choose-platform-headline">
              <div>
                {/* <img src={} alt="" /> */}
                <div className="left-navbar" style={{ width: "330px" }}>
                  <NavItems
                    logo={Logo1}
                    active={false}
                    text={"logo1"}
                    url={"/main/campaign/newcampaign"}
                  />
                  <NavItems
                    logo={logo2}
                    active={false}
                    text={"logo2"}
                    url={"/main/campaign/brandsummery"}
                  />
                  <NavItems
                    logo={activeLogo3}
                    active={true}
                    text={"logo3"}
                    label={"Platform Selection"}
                    url={"/main/campaign/platfromselection"}
                  />
                </div>
                <h5>
                  Please Choose Your Platform To Run
                  <br /> Your Ads
                </h5>
              </div>
              <img src={platformHeadline} alt="" />
            </div>
            {/* <Container fluid>
              <Row>
                <Col lg={5} mg={6} sm={12} style={{ padding: "0px" }}>
                  <Link to={"/main/campaign/facebookcampaign"}>
                    <AddPlatForm
                      title={"Facebook Ads"}
                      logo={Facebooklogo}
                      color={"#1877F2"}
                    />
                  </Link>
                </Col>
                <Col lg={5} mg={6} sm={12} style={{ padding: "0px" }}>
                  <Link to={"/main/campaign/instagramcampaign"}>
                    <AddPlatForm
                      title={"Instagram Ads"}
                      logo={igLogo}
                      color={" #FA8435"}
                    />
                  </Link>
                </Col>
                <Col lg={5} mg={6} sm={12} style={{ padding: "0px" }}>
                  <div ref={target} onClick={() => setShow(!show)}>
                    <AddPlatForm
                      title={"Google Ads"}
                      logo={googleLogo}
                      color={"#FABC04"}
                    />
                  </div>
                </Col>
                <Col lg={5} mg={6} sm={12} style={{ padding: "0px" }}>
                  <Link to={"/main/campaign/bingcampaign"}>
                    <AddPlatForm
                      title={"Bing Ads"}
                      logo={igLogo}
                      color={"#37BD"}
                    />
                  </Link>
                </Col>
              </Row>
            </Container> */}

            <div
              className="Platform-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              {/* faceBook */}
              <div className="platform-facebook-main">
                <img src={faceBookLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Facebook Ads</h5>
                  <p>
                    Leverage Facebook ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/facebookcampaign")
                    // }
                    onClick={() => setFacebookAccountModal(true)}
                    
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="49"
                      viewBox="0 0 141 49"
                      fill="none"
                    >
                      <path
                        d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
                        fill="white"
                      />
                      <text
                        x="25%"
                        y="63%"
                        font-family="Arial"
                        font-size="20"
                        fill="#000"
                        className="ads-svg-btn"
                      >
                        Choose
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
              {/* Ig */}
              <div className="platform-ig-main">
                <img src={IgLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Instagram Ads</h5>
                  <p>
                    Leverage Facebook ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/instagramcampaign")
                    // }
                    onClick={() => setInstragramAccountModal(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="49"
                      viewBox="0 0 141 49"
                      fill="none"
                    >
                      <path
                        d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
                        fill="white"
                      />
                      <text
                        x="28%"
                        y="63%"
                        font-family="Arial"
                        font-size="20"
                        fill="#000"
                        className="ads-svg-btn"
                      >
                        Choose
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
       
              {/* Google */}
              <div className="platform-google-main">
                <img src={Google3D} alt="" />
                <div className="platform-facebook">
                  <h5>Google Ads</h5>
                  <p>
                    Leverage Facebook ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    onClick={() => setShow(!show)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="49"
                      viewBox="0 0 141 49"
                      fill="none"
                    >
                      <path
                        d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
                        fill="white"
                      />
                      <text
                        x="28%"
                        y="63%"
                        font-family="Arial"
                        font-size="20"
                        fill="#000"
                        className="ads-svg-btn"
                      >
                        Choose
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
                     {/* Bing */}
                     <div className="platform-Bing-main">
                <img src={bingLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Bing Ads</h5>
                  <p>
                    Leverage Facebook ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/bingcampaign")
                    // }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="49"
                      viewBox="0 0 141 49"
                      fill="none"
                    >
                      <path
                        d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
                        fill="white"
                      />
                      <text
                        x="10%"
                        y="63%"
                        font-family="Arial"
                        font-size="20"
                        fill="#000"
                        className="ads-svg-btn"
                      >
                       Coming Soon
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <GooglePlatformSelectionModal
        show={show}
        setModal={setShow}
        onHide={() => setShow(false)}
      />
       {facebookAccountModal && (

<FacebookChooseAccountPopup setFacebookAccountModal={setFacebookAccountModal} />
)}

{instragramAccountModal && (
<InstragramChooseAccountPopup setInstragramAccountModal={setInstragramAccountModal} />
)}

{googleAccountModal && (
<ChooseAccountPopup />
)}
    </Container>
  );
};

const AddPlatForm = ({ title, logo, color }) => {
  return (
    <div className="single-platform">
      <div className="" style={{ margin: "4px 5px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{ visibility: "hidden" }}
        >
          <path
            d="M11.9999 21.9994C17.5227 21.9994 21.9998 17.5223 21.9998 11.9995C21.9998 6.47674 17.5227 1.99963 11.9999 1.99963C6.47711 1.99963 2 6.47674 2 11.9995C2 17.5223 6.47711 21.9994 11.9999 21.9994Z"
            stroke="#B2BBC6"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="main-platfrom-div " style={{ background: color }}>
        {logo}
        <div className="platform-inner-container">
          <span className="platform-inner-heading">{title}</span>
          <p className="platform-inner-para">
            With Google Search Ads you can reach a large audience that is
            specifically searching for a certain topic and might be in a buying
            mood already.
          </p>
        </div>
      </div>
    </div>
  );
};

const GooglePlatformSelectionModal = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container fluid className={`${style.AiSuccess}   ads-select-modal`}>
        <Row style={{ width: "100%", padding: "20px" }}>
          <h3 className="ad-select-modal-headline">Choose Your Ad Format</h3>
          <Row className="ad-select-modal-container">
            <BoxContainer
              image={SearchAd}
              title={"Search Ad"}
              link={"/main/campaign/googlecompaign"}
              para={
                "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
              }
            />
            <h6 className="ad-select-divider" />
            <BoxContainer
              image={DisplayAd}
              link={"/main/campaign/googlecompaign2"}
              title={"Display Ad"}
              para={
                "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
              }
            />
          </Row>
        </Row>
      </Container>
    </Modal>
  );
};

const BoxContainer = ({ image, title, para, link }) => {
  const token = Cookies.get("token");
  // check credits
  const navigate = useNavigate();

  const checkcredits = async() => {


    try {
      const res = await axios.post("/check-credits",{transactionType:"adFlow"},{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })

      if (res?.data?.status==true) {
         // dispatch(addWebsiteUrl(websitename));

  navigate(link);
  // sessionStorage.setItem("websiteUrl", websitename);
      } else {
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      }
    } catch (error) {

    }
  };

  return (
    <Col lg={5}>
      <Link  className="ad-select-solo" onClick={()=>checkcredits()}>
        <img src={image} alt="megaPhone" />
        <div>
          <h5>{title}</h5>
          <p>{para}</p>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
          >
            <path
              d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
              fill="white"
            />
            <text
              x="28%"
              y="63%"
              font-family="Arial"
              font-size="20"
              className="ad-select-btn"
            >
              Choose
            </text>
          </svg>
        </div>
      </Link>
    </Col>
  );
};

export default PlatformSelection;
