import "../home.css";
export const Banner = ({ images, speed = 20000 }) => {
  return (
    <div className="inner">
      <div className="wrapper">
        <section className="home-section" style={{ "--speed": `${20000}ms` }}>
          {images.map(({ id, image }) => (
            <div className="" key={id}>
              <img className="img-for-slider " src={image} alt={id} />
            </div>
          ))}
        </section>
        <section className="home-section" style={{ "--speed": `${20000}ms` }}>
          {images.map(({ id, image }) => (
            <div className="" key={id}>
              <img className="img-for-slider " src={image} alt={id} />
            </div>
          ))}
        </section>
        <section className="home-section" style={{ "--speed": `${20000}ms` }}>
          {images.map(({ id, image }) => (
            <div className="" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Banner;
