import React, { useEffect, useState } from "react";
import "./NewBrandSetup.css";
import BrandSetupBanner from "./brandSetupBanner";
import BrandSetupForm from "./brandSetupForm";
import BrandSetupColor from "./brandSetupColor";
import BrandCreationPopup from "./brandCreationPopup";
import { useDispatch, useSelector } from "react-redux";
import { addBrandData } from "../../../../../Redux/brandSetupData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addOnlyCreativeSize } from "../../../../../Redux/onlyCreativeData";
import { handleCoinChange, logoColorPikedFun } from "../../../../../Redux/addCreateSlice";
import { average, prominent } from "color.js";


import axios from "../../../../axios/auth";
import Cookies from "js-cookie";


export default function NewBrandSetup() {
  const userToken = Cookies.get("token");
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const [selectedColor1, setSelectedColor1] = useState(brandData?.mainBgColor??"white"); 
   const [selectedColor2, setSelectedColor2] = useState(brandData?.headingColor??"white");
   const [selectedColor3, setSelectedColor3] = useState(brandData?.descriptionColor??"white");
   const[ctaColor,setCtaColor]=useState(brandData?.ctaColor??"#ffffff")
   const[promotionTextColor,setPromotionText]=useState(brandData?.promotionTextColor?? "#ffffff")
  const [colors, setColors] = useState(); //color array from here
  const [averagecolor, setAverageColor] = useState();
  const [imageLogo, setImgLogo] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [brandName, setBrandName] = useState(brandData?.brandname);
  const[description,setDescription]=useState(brandData?.brandDescription)
  const[projectName,setProjectName]=useState(brandData?.projectName) 
  const [selectedFont,setSelectedFont]=useState("Poppins")
  const dispatch=useDispatch()
  const { onlyCreativeState } = useSelector((state) => state.creativeData);
  const [brandCreate, setBrandCreate] = useState(false);
  const checkStatus = brandData?.createProject ? "Project Name" : "Brand Name";
  const navigate = useNavigate();


  const deductToken = async() => {

    try {
      const res = await axios.post("/deduct-token",{transactionType:"projectCreation"},{
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                })
      if (res?.data?.status==true) {
        dispatch(handleCoinChange(res?.data?.credits))
      } else {
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      }
    } catch (error) {
  
    }
  };
  
  
    // check credits
    const checkcredits = async() => {
      try {
        const res = await axios.post("/check-credits",{transactionType:"projectCreation"},{
                    headers: {
                      Authorization: `Bearer ${userToken}`,
                    },
                  })
                  if(res?.data?.data>0){
                    return true
                  }else{
                  
                    // toast.error(res?.data?.message)
                    toast.error(res?.data?.message, {
                      className: 'custom-toast-error',
                      hideProgressBar: true
                    });
                    
                  }
  
  
      } catch (error) {
  
  
      }
    };
  
  
  

  const handleCreate = async() => {
    if (brandName?.length > 0) {
      if(brandData?.createProject){
        if(projectName?.length>0){
          let res= await checkcredits()
          if(res==true){
          await  deductToken()
            dispatch(addBrandData({  
              brandDescription:description,
              promotionTextColor:promotionTextColor,
              ctaColor:ctaColor,
              selectedFont:selectedFont,
              logo:brandData?.logo,
              mainBgColor:selectedColor1,
              headingColor:selectedColor2,
              descriptionColor:selectedColor3,
              projectName:projectName,
              
            }))
            navigate("/main/new-generate");
          }
          else{

          }
        
        }
        else{
          // toast.error(`Plese Enter ${checkStatus} `);
          toast.error(`Plese Enter ${checkStatus} `, {
            className: 'custom-toast-error',
            hideProgressBar: true
          });

        }
      }
      else {

        dispatch(addBrandData({  
          brandName:brandName,
          brandDescription:description,
          promotionTextColor:promotionTextColor,
          ctaColor:ctaColor,
          selectedFont:selectedFont,
          logo:brandData?.logo??"",
          mainBgColor:selectedColor1,
          headingColor:selectedColor2,
          descriptionColor:selectedColor3,
        }))
        setBrandCreate(true);
      }

    }
    else if(brandData?.createProject){
      if(projectName?.length>0){
        let res= await checkcredits()
        
    if(res==true){
      await  deductToken()
      dispatch(addBrandData({  
        brandDescription:description,
        promotionTextColor:promotionTextColor,
        ctaColor:ctaColor,
        selectedFont:selectedFont,
        logo:brandData?.logo??"",
        mainBgColor:selectedColor1,
        headingColor:selectedColor2,
        descriptionColor:selectedColor3,
        projectName:projectName,
      }))
      navigate("/main/new-generate");
    }
    else{

    }
        
      }
      else{
        // toast.error(`Plese Enter ${checkStatus} `);
        toast.error(`Plese Enter ${checkStatus} `, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });

        
      }
    }
    else {
      // toast.error(`Plese Enter ${checkStatus} `);
      toast.error(`Plese Enter ${checkStatus} `, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });

    }
  };

const HandelLogo = (e) => {
  const files = e.target.files;
  const paths = [];
  const compressedPaths = [];
  const fileImage = e.target.files[0];
  localStorage.setItem("myFile", JSON.stringify(fileImage));

  // Update brand logo and dispatch brand data
  setBrandLogo(fileImage);
  dispatch(addBrandData({...brandData, logo: fileImage}));

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 500;
        const MAX_HEIGHT = 500;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, width, height);

        const compressedDataUrl = canvas.toDataURL("image/png", 0.7);
        paths.push(event.target.result);
        compressedPaths.push(compressedDataUrl);

       
        if (paths.length === files.length && compressedPaths.length === files.length) {
          setImgLogo(compressedPaths);
          dispatch(addBrandData({ ...brandData, logo: compressedPaths }));
        
          prominent(compressedDataUrl, {
            format: "hex",
            amount: 3,
          }).then((colors) => {
            // Ensure colors is an array before applying filter
            if (Array.isArray(colors)) {
              // Filter out black color and select the first non-black color
              const filteredColors = colors.filter(color => color !== '#000000');
        
              setSelectedColor1(filteredColors[0] || colors[1]); // Use the first non-black color, or fallback to the second color
              setSelectedColor2(filteredColors[1] || colors[2]);
              setSelectedColor3(filteredColors[2] || colors[3]);
            } else {
              // Handle the case where colors is not an array
              
              setSelectedColor1('#ffffff'); // Fallback to white or any default color
              setSelectedColor2('#ffffff');
              setSelectedColor3('#ffffff');
            }
          });
        
          average(compressedDataUrl).then((color) =>
            setAverageColor(`rgba(${color.join(",")}, .3)`)
          );
        
          paths.forEach((originalPath) => {
            prominent(originalPath, {
              format: "hex",
              amount: 3,
            }).then((colors) => {
              if (Array.isArray(colors)) {

              } else {
              }
            });
        
            average(originalPath).then((color) =>
              console.log()
            );
          });
        }
        
      };
    };

    reader.readAsDataURL(file);
  }
};



useEffect(() => {
  dispatch(addBrandData({...brandData, colors:colors}));
  dispatch(logoColorPikedFun(colors));
}, [colors,colors])
const fontFamily=["Poppins","Arial","Time New Roman","Geogia","Comic Sans", "Fantasy","Monospace","Sans-Serif","cursive"]

  return (
    <div className="brand-setup-section" >
      <BrandSetupBanner   />
      <BrandSetupForm selectedFont={selectedFont} setSelectedFont={setSelectedFont} fontFamily={ fontFamily} description={description} setDescription={setDescription} HandelLogo={HandelLogo} brandName={brandName} setBrandName={setBrandName}
      setProjectName={setProjectName}
      projectName={projectName}
      />   
      <BrandSetupColor selectedColor1={selectedColor1} setSelectedColor1={setSelectedColor1} 
      selectedColor2={selectedColor2} setSelectedColor2={setSelectedColor2}
      selectedColor3={selectedColor3} setSelectedColor3={setSelectedColor3} ctaColor={ctaColor}setCtaColor={setCtaColor}
      setPromotionText={setPromotionText}
      promotionTextColor={promotionTextColor}
      
      />
      <div className="create-brand-button" >
        <button onClick={() => handleCreate()}>
          {brandData?.createProject ? "Continue" : "Create Brand"}{" "}
        </button>
      </div>
      {brandCreate && <BrandCreationPopup setBrandCreate={setBrandCreate} />}
    </div>
  );
}
