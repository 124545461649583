import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { ActiveSvg, CircleNav } from "../SVG";

const BingAdd1 = () => {
  // const [active, setActive] = useState("first");

  // const navigate = useNavigate();
  // useEffect(() => {
  //   let pageName = document.URL.split("/").at(-1);
  //   if (pageName == "content") {
  //     setActive("first");
  //   } else if (pageName == "targeting") {
  //     setActive("second");
  //   } else if (pageName == "funding") {
  //     setActive("third");
  //   }
  // }, []);

  // const HandleClick = (e, title) => {
  //   setActive(e);
  //   navigate(title);
  // };
  // scroller  for on scroll navigation.
  // const handleWheel = (e) => {
  //   const deltaY = e.deltaY;

  //   if (deltaY < 0) {
  //     if (active == "third") {
  //       setActive("second");
  //       navigate("targeting");
  //     } else if (active == "second") {
  //       setActive("first");
  //       navigate("content");
  //     }
  //   } else if (deltaY > 0) {
  //     if (active == "first") {
  //       setActive("second");
  //       navigate("targeting");
  //     } else if (active == "second") {
  //       setActive("third");
  //       navigate("funding");
  //     }
  //   }
  // };
  return (
    <>
      <Col lg={1} className="google-side-nav NavCircleDiv">
        {/* <div
          className="NavCircleDiv"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "15px 40px",
          }}
          onWheel={handleWheel}
        >
          <NavCricles
            title={"Content"}
            display={"none"}
            active={
              active === "first" || active === "second" || active === "third"
            }
            e={"first"}
            HandleClick={HandleClick}
          />
          <NavCricles
            title={"Targeting"}
            e={"second"}
            active={active === "second" || active === "third"}
            HandleClick={HandleClick}
          />
          <NavCricles
            title={"Funding"}
            e={"third"}
            active={active === "third"}
            HandleClick={HandleClick}
          />
          <div className="line" />
        </div> */}
      </Col>
      <Col>
        <Outlet />
      </Col>
    </>
  );
};

// const NavCricles = ({ title, display, active, HandleClick, e }) => {
//   const smalltitle = title.toLowerCase();
//   return (
//     <div className="google-side-nav-circles">
//       <div
//         style={{
//           display: "flex",
//           gap: "10px",
//           alignItems: "flex-end",
//         }}
//       >
//         <div>
//           <div
//             className={`line ${active && "active"}`}
//             style={{ display: display }}
//           />
//           <div onClick={() => HandleClick(e, smalltitle)}>
//             {active ? ActiveSvg : CircleNav}
//           </div>
//         </div>
//         <p className="google-side-nav-text">{title}</p>
//       </div>
//     </div>
//   );
// };

export default BingAdd1;
