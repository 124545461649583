import React, { useState } from 'react'
import "./settingsMainBanner.css";
import SettingIcon from "../../../../../../assets/images/setting-icon.png";
import UserIcon from '../../../../../icons/userIcon';
import BillingIcon from '../../../../../icons/billingIcon';
export default function SettingsMainBanner({ settinOption, setSettinOption }) {

    return (
        <div className='settings-main-banner-section'>
            <div className="settings-main-banner-box">
                <div className="setting-main-banner-to-details">
                    <div>
                        <img src={SettingIcon} alt="SettingIcon" />
                    </div>

                    <div className="details-alighnment">
                        <h1>Settings</h1>
                        <p>Manage your account details, billing information and package.</p>
                        <div className="details-bottom-alignment">
                            <div className={settinOption === "account" ? "option-button-alignment active-button" : "option-button-alignment"} onClick={() => setSettinOption("account")}>
                                <button>
                                    <UserIcon />
                                    Account
                                </button>
                            </div>

                            <div className={settinOption === "billing" ? "option-button-alignment active-button" : "option-button-alignment"} onClick={() => setSettinOption("billing")}>
                                <button>
                                    <BillingIcon />
                                    Billing
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {/* {settinOption === "account" && (

                    <div className="details-description-alignment">
                        <p>You have saved <span>$766.667, or 13 hours of work</span>, by automating your ad assets using Excode</p>
                        <p>In addition to improving your advertisement’s return on investment, Excode significantly saves your company’s time and money.</p>
                    </div>
                )}

                {settinOption === "billing" && (
                    <div className="promo-code-details-alignment">
                        <div className="promo-code-left">
                            <h4>Promo Code</h4>
                            <p>If you have a promo code, enter it here to apply your special discount.</p>
                        </div>
                        <div className="promo-code-right">
                            <input type="text" placeholder='Promo Code' />

                            <div className="apply-button">
                                <button>Apply</button>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}
