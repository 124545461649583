import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col } from "react-bootstrap";
import { addBgImage, addBrandData } from "../../../../../Redux/brandSetupData";
import { useDispatch, useSelector } from "react-redux";

const PixabayImageSearch = ({setSearchImgesVIew,setGenerationView}) => {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState([]);
  // ye updated File hai
  const [searchQuery, setSearchQuery] = useState( brandData?.searchQuery??"Marketing");
  const [previousQ, setPreviousQ] = useState("");
const [loader,setLoader]=useState()
  const apiKey = "41195581-a6a30be41ff9bef3f4ca1e8a0";
  const apiUrl = "https://pixabay.com/api/";
  const imageType = "photo";
const dispatch =useDispatch()

// stock images states and  function end
const uploadPixabaseImage = async (picUrl) => {
  setLoader(true);
  // const base64Image = await urlToBase64(picUrl);
  // dispatch(addBgImage(base64Image));
  dispatch(addBrandData({mainImage:picUrl}));
  setLoader(false);
  setSearchImgesVIew(false);
  setGenerationView("C")
};
const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return null;
  }
};

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (page) => {
    axios
      .get(apiUrl, {
        params: {
          key: apiKey,
          q: searchQuery,
          image_type: imageType,
          page: page,
        },
      })
      .then((response) => {
        if (response.config.params.q === previousQ) {
          setImages([...response.data.hits]);
        } else if (response.config.params.q !== previousQ) {
          setImages([...response.data.hits]);
        } else if (response.data.hits.length > 0) {
          setImages([...images, ...response.data.hits]);
          setPreviousQ(response.config.params.q);
          setCurrentPage(page);
        } else {
          setImages([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Pixabay API:", error);
      });
  };
  const LoadMoreData = (page) => {
    axios
      .get(apiUrl, {
        params: {
          key: apiKey,
          q: searchQuery,
          image_type: imageType,
          page: page,
        },
      })
      .then((response) => {
        if (response.data.hits.length > 0) {
          setImages((prevImages) => [...prevImages, ...response.data.hits]);
          setCurrentPage(page);
        } else {
          setImages([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Pixabay API:", error);
      });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setImages([]);
    fetchData(1);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    LoadMoreData(nextPage);
  };
 const  handleSearchQuery=(e)=>{
    setSearchQuery(e)
    dispatch(addBrandData({searchQuery:searchQuery}))
  }
  return (
    <div className="container">





      <div className="stockImageSearchMain">
        <h1
          className=""
          style={{
            fontSize: "24px",
            fontFamily: "Montserrat Alternates",
            color: "#546881",
          }}
        >
          Image Search
        </h1>

        <div className="form-group">
          <div className="stockImageSearchSub">
            <div>
              <input
                type="text"
                id="searchQuery"
                className="form-control"
                placeholder="Search Image"
                style={{}}
                value={searchQuery}
                onChange={(e) => handleSearchQuery(e.target.value)}
              />
            </div>
            <button
              onClick={handleSearch}
              className="btn btn-primary "
              style={{
                background: "#358f8b",
                border: "#358f8b",
                fontSize: "12px",
              }}
            >
              Search Images
            </button>
          </div>
        </div>
        <div className="">
          {images.length > 0 && (
            <button
              onClick={loadMore}
              id="loadMoreBtn"
              className="btn btn-secondary"
              style={{
                background: "#358f8b",
                border: "#358f8b",
                fontSize: "12px",
              }}
            >
              Load More
            </button>
          )}
        </div>
      </div>

      <Col style={{ padding: "0px" }}>
        <div className="background-images-carosole">
          {images.map((pic, i) => (
            <div
              className="background-img-div"
              // navigate("/Generate/inputpage")
              // onClick={HandelAccountImage }
            >
              <img
                className="background-Img"
                src={pic.webformatURL}
                alt=""
                // onClick={() => HandelAccountImage(pic)}
                onClick={() =>uploadPixabaseImage(pic.webformatURL)}
              />
            </div>
          ))}
        </div>
      </Col>



    </div>
  );
};

export default PixabayImageSearch;
