import React, { useEffect, useState } from 'react'
import "./settingOtherDetails.css";
import { MdModeEditOutline } from "react-icons/md";
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { handleFullName, handleprofileImage } from '../../../../../../Redux/addCreateSlice';
import UploadIcon2 from '../../../../../icons/uploadIcon2';
// import Avatar from "./Header Images/avatar.png";
import logo from "../../../../../../assets/logo/logo.png"
import Avatar from "../../../Header/Header Images/avatar.png";


import eyeClose from "../../../../Auth Pages/Images/password.png";
// import exoadLogo from "../../../../";


import eyeOpen from "../../../../Auth Pages/Images/openEye.png";
import Cookies from "js-cookie"
import { adduserdata } from '../../../../../../Redux/login/loginSlice';
import { nodeUrl } from '../../../../../axios/auth';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default function SettingOtherDetails({ settinOption, setSettinOption }) {
  const user = useSelector((state) => state.loginuser.user);
  let { profileImage } = useSelector((state) => state.addcreative);
  let { profileName } = useSelector((state) => state.addcreative);

  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [fullName, setFullName] = useState("");
  const [myName, setMyName] = useState("");

  const [apiBillName,setApiBillName] = useState("");
  const [apiBillEmail, setApiBillEmail] = useState("");



 
  const [toggle, setToggle] = useState(true);
  const [oldPasswordToggle, setOldPasswordToggle] = useState(false);
  const [newPasswordToggle, setNewPasswordToggle] = useState(false);
  const [currentPasswordToggle, setCurrentPasswordToggle] = useState(false);

  const [imagePath, setImagePath] = useState('');
  const [nameError, setNameError] = useState(false);

  const [billName, setBillName] = useState('');
  const [billComapny, setBillCompany] = useState('');
  const [billEmail, setBillEmail] = useState('');
  const [tax, setTax] = useState('');

  const [billnameError, setBillnameError] = useState(false);
  const [billEmailError, setBillEmailError] = useState(false);
  const [taxError, setTaxError] = useState(false);
  const [planStatus, setPlanStatus] = useState('');


  
  const {
    register,
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  const token = Cookies.get("token");
  const password = watch("newpassword", ""); // Watch the "password" field
  const navigate = useNavigate();
  const confirmPassword = watch("confirmPassword", ""); // Watch the "confirmPassword" field
  const name = watch("name", "");

  const handleImageChange = async (e) => {
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePath(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);

      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      try {
        const response = await axios.post(`${nodeUrl}/updateImage`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data?.Data) {
          // toast.success("profile image updated successfully!");
          toast.success("profile image updated successfully!", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });

          dispatch(handleprofileImage(response?.data?.Data?.imagePath))
          // setImagePath(profileImage)
          setImagePath(response?.data?.Data?.imagePath)

        } else {
          toast.error("something went wrong!", {
            className: 'custom-toast-error',
             hideProgressBar: true
           }); 
          

        }
      } catch (error) {
        // console.error('Error uploading image:', error);
      }

    }

  };


  const getImage = async () => {
    try {
      const response = await axios.get(`${nodeUrl}/getUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }

        });
      dispatch(handleprofileImage(response?.data?.data[0]?.imagePath))
      dispatch(handleFullName(response?.data?.data[0]?.fullName))
      setImagePath(response?.data?.data[0]?.imagePath);
      setFullName(response?.data?.data[0]?.fullName);

    } catch (error) {
      // console.error('Error uploading image:', error);
    }

  };


  useEffect(() => {
    getImage()
    fetchBillingDetails()
    setFullName(profileName)
  }, [])


  const updateName = async () => {
    try {
      if (myName?.length > 0) {

        if(myName == user?.fullName){

        }
        else{

        const response = await axios.post(`${nodeUrl}/Update-user-name`, { fullName: myName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }

          });
        if (response?.data?.Data) {
          // toast.success("Name updated successfully!");
          toast.success("Name updated successfully!", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
          setFullName(response?.data?.Data?.fullName);
          dispatch(handleFullName(response?.data?.Data?.fullName))

        } else {
          // toast.error("Enter your fullName First!");
          toast.error("Enter your fullName First!", {
            className: 'custom-toast-error',
             hideProgressBar: true
           }); 
        }
      }
      } else {

        if(user?.fullName){

        }else{
          setNameError(true);

        }
      }
    } catch (error) {
      // console.error('Error uploading image:', error);
      toast.error("Some thing went wrong!", {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 
    }

  };


  const fetchBillingDetails = async () => {
    try {
      const res = await axios.get(`${nodeUrl}/get-biling-deatils`, {
        headers: {
          Authorization: `Bearer ${token}`, // Correct spelling
        },
      });


      if (res?.data?.data?.length>0) {

        setBillName(res?.data?.data[0]?.fullName)
        setBillCompany(res?.data?.data[0]?.companyName)
        setBillEmail(res?.data?.data[0]?.email)
        setTax(res?.data?.data[0]?.tax)
        setPlanStatus(res?.data?.data[0]?.result)

        setApiBillName(res?.data?.data[0]?.fullName)
        setApiBillEmail(res?.data?.data[0]?.email)



      }
      else{
        setPlanStatus(user?.plan[0])
        setBillName(user?.fullName)
        setBillEmail(user?.email)
       

      }

    } catch (e) {
      // throw e;
    }
  };




  const Submit = async (data) => {
    if (
      data?.newpassword &&
      data?.currentpassword &&
      data?.confirmPassword &&
      data?.newpassword === data?.confirmPassword
    ) {
      try {

        const res = await axios.post(
          `${nodeUrl}/updateuser`,
          {
            password: data?.currentpassword,
            newPassword: data?.newpassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,

            },
          }
        );
        // fetchdata();
        resetField("currentpassword");
        if (res?.data?.status === true) {
          // toast.success("Password Updated Successfully.");
          toast.success("Password Updated Successfully.", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });


        }
      } catch (error) {
        toast.error(error.response?.data?.message, {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
      }
    }
    reset();
  };




  const updateBilling = async () => {

    if (billName?.length > 0) {

      setBillnameError(false)
      if (billEmail?.length > 0) {

        setBillEmailError(false)


        if (tax?.length > 0) {

          setTaxError(false)
          try {

            const res = await axios.post(
              `${nodeUrl}/create-biling-deatils`,
              {
                billingName: billName,
                billingComany: billComapny,
                billingEmail: billEmail,
                billingTax: tax
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (res?.data?.status === true) {
              fetchBillingDetails()
              // toast.success("Billing Deatils Updated Successfully.");
              toast.success("Billing Deatils Updated Successfully.", {
                className: 'custom-toast-success',
                hideProgressBar: true
              });
            }
            else {
              // toast.error("something went wrong!");
              toast.error("something went wrong!", {
                className: 'custom-toast-error',
                 hideProgressBar: true
               }); 
            }

          } catch (error) {
            toast.error(error?.response?.data?.message, {
              className: 'custom-toast-error',
               hideProgressBar: true
             }); 
          }
        }
        else {
          //tax
          setTaxError(true)
        }

      } else {
        //email
        setBillEmailError(true)
      }
    }
    else {
      //name
      setBillnameError(true)
    }


  }

  

  const currencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };

  function calculateDaysRemaining(expiryDate) {
    // Get the current date
    const currentDate = new Date();
    
    // Parse the subscription expiry date
    const subscriptionExpiryDate = new Date(expiryDate);
  
    // Calculate the difference in time (milliseconds)
    const differenceInTime = subscriptionExpiryDate - currentDate;
  
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 *60 * 60 * 24));
  
    return differenceInDays;
  }

  function generateInvoiceNumber() {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Get last two digits of the year
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    // Format: YYMMDDHHMMSS
    const invoiceNumber = `${year}${month}${day}${hours}${minutes}${seconds}`;
  
    return invoiceNumber;
  }
  
  // Example usage:
  const invoiceNumber = generateInvoiceNumber();
  
  const expiryDate = planStatus?.subscriptionExpiryDate;
  let daysRemaining;
  if(expiryDate){

     daysRemaining = calculateDaysRemaining(expiryDate);

  }

  function formatLocalDate(isoDateStr) {
    const date = new Date(isoDateStr);
  
    // Get date components
    const day = date.getDate();
    const monthIndex = date.getMonth(); // Zero-based
    const year = date.getFullYear();
  
    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get abbreviated month name
    const monthName = monthNames[monthIndex];
  
    // Format as "DD MMM YYYY"
    const formattedDate = `${day} ${monthName} ${year}`;
    
    return formattedDate;
  }
  
  // Function to get the current local date in "DD MMM YYYY" format
  function getCurrentLocalDate() {
    const now = new Date();
  
    // Get date components
    const day = now.getDate();
    const monthIndex = now.getMonth(); // Zero-based
    const year = now.getFullYear();
  
    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get abbreviated month name
    const monthName = monthNames[monthIndex];
  
    // Format as "DD MMM YYYY"
    const formattedCurrentDate = `${day} ${monthName} ${year}`;
  
    return formattedCurrentDate;
  }
  
  // Example usage
  // const isoDateStr = "2025-06-21T10:44:39.881Z";

  const  isoDateStr = planStatus?.subscriptionExpiryDate;
  let currentLocalDate ;
  let formattedDate;
  if( isoDateStr){

 formattedDate = formatLocalDate(isoDateStr);
  currentLocalDate = getCurrentLocalDate();
  
  
  }
  // Function to convert the date string to the desired format
function formatDate(dateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  // Parse the date string
  const date = new Date(dateString);
  
  // Get the day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  // Format the date as "DD MMM YYYY"
  return `${day} ${month} ${year}`;
}
 
  
  const createPdf = () => {

    const element = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Invoice Template</title>
    <style>
      / style.css /

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #000;
}

.invoice-container {
    width:80%
    margin: 20px auto;
    background: #fff;
    box-shadow: 0 0 8px #47beb9;
  }
    
.top-section {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    background-color: #47beb9;
    padding: 20px;
}
.middle-section{
    background-color: #fff !important;
    padding: 20px;
}
}

.header, .invoice-to, .details, .items, .totals, .footer {
    margin-bottom: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.header .left {
    width: 60%;
}

.header .right {
    width: 40%;
    text-align: right;
}

.header .company-logo img {
    max-width: 100px;
}

.invoice-to {
    padding-top: 20px;
}

.details .row, .items .row, .totals .row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.details .row .column, .items .row .column, .totals .row .column {
    width: 25%;
     text-align: center; / Center text horizontally /
}

.items .row.header {
    background: #e9f6f7;
    font-weight: bold;
}

.footer {
    text-align: center;
    font-size: 20px;
    color: #000;
    padding-top: 20px;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
    background-color: #47beb9;
    padding: 20px;
    margin-top: 140px;
}

p {
    margin: 5px 0;
}
.totals {
    width: 300px;
    float: right;
}
span.date-item {
    font-size: 14px;
}
    </style>
</head>
<body>
    <div class="invoice-container">
      <div class="top-section">
        <div class="header">
          <div class="left">
              <h1>INVOICE</h1>
              <p><strong>DATE</strong><br>${currentLocalDate}</p>
              <p><strong>INVOICE NO</strong><br>${invoiceNumber}</p>
          </div>
          <div class="right">
              <div class="company-logo">
                  <img src=${logo} alt="Logo">
              </div>
              <p><strong>Exoads.ai</strong><br>inc <mailto:br>city,xyz<br>phone:12345467890<br>email:support@exoads.ai</p>
          </div>
      </div>
      <div class="invoice-to">
    
          <p><strong>INVOICE TO</strong><br><br>${apiBillName ||  user?.fullName}<br>Email:${apiBillEmail || user?.email}</p>
      </div>
      </div>
        <div class="middle-section">
        <div class="items">
            <div class="row header">
                <div class="column">S NO.</div>
                <div class="column">Plan Name</div>
                <div class="column">Plan Date</div>
                <div class="column"> Plan Amount</div>
            </div>
            <div class="row">
                <div class="column">1</div>
                <div class="column">${planStatus?.name}</div>
                <div class="column">${formatDate(planStatus?.createdAt)}</div>
                <div class="column"> ${currencySymbol(user?.plan[0]?.currency)} ${planStatus?.amount}</div>
            </div>
        </div>
        <div class="column" style="padding-top: 30px;">
            <strong>DUE DATE</strong> : <span class="date-item">${formattedDate}</span>
        </div>
        <div class="totals">
            <div class="row">
                <div class="column">Subtotal</div>
                <div class="column"> ${currencySymbol(user?.plan[0]?.currency)} ${planStatus?.amount}</div>
            </div>
            <div class="row">
                <div class="column">Sales Tax</div>
                <div class="column">${currencySymbol(user?.plan[0]?.currency)}  0</div>
            </div>
            <div class="row">
                <div class="column">Total</div>
                <div class="column">${currencySymbol(user?.plan[0]?.currency)} ${planStatus?.amount}</div>
            </div>
        </div>
        </div>
        <div class="footer">
            <p>https://www.exoads.ai</p>
        </div>
    </div>
</body>
</html>
`;






// const element =`
// <!DOCTYPE html>
// <html lang="en">

// <head>
//   <meta charset="UTF-8" />
//   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//   <title>Invoice bill</title>
//   <style>
//     * {
//       margin: 0px;
//       padding: 0px;
//     }

//     .wrap{
//       // border: 2px solid black;
//       height: 700px;
//       width: 700px;
      
//     }
//     .mainContainer {
 
//       height: 600px;
//       width: 600px;
//       // margin: 50px;
//     }

//     .topSection {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//     }

//     .logo {
//       height: 100px;
//       width: 200px;
//     }

//     .priceDetail {
//       /* border-bottom: 5px solid black; */
//       margin-top: 20px;
//     }

//     .invoiceData {
//       display: flex;
//       justify-content: space-between;
//       width: 250px;
//       margin-top: -21px;
//     }

//     .baloonary {
//       display: flex;
//       width: 400px;
//       justify-content: space-between;
//       margin-top: 20px;
//     }



//     .desc {
//       display: flex;
//       justify-content: space-between;
//     }

//     .desc2 {
//       display: flex;
//       gap: 20px;
//       margin-right: 8px;
//       /* width: 100px; */
//       /* border: 1px solid red; */
//     }
//     .desc2 div{
//       width: 100px;
//       /* border: 1px solid black; */
//       text-align: right;
//     }

//     .line {
//       height: 3px;
//       width: 600px;
//       background-color: black;
//       margin-top: 3px;
//     }


//     .budget {
//       display: flex;
//       justify-content: space-between;
//       margin-top: 5px;
//     }

//     .budget1 {
//       display: flex;
//       gap: 20px;
//       margin-right: 8px;
//     }

//     .line1 {
//       height: 2px;
//       width: 600px;
//       background-color: grey;
//       margin-top: 15px;
//     }



//     .paid {
//       display: flex;
//       justify-content: space-between;
//       margin-top: 10px;
//     }

//     .paid1 {
//       display: flex;
//       gap: 20px;
//       margin-right: 8px;
//     }


//     .tbl {
//       margin-top: 15px;
//       margin-left: 300px;
//     }


//     .shape1 {
//       width: 300px;
//       height: 2px;
//       background-color: grey;
//     }

//     .col1 {
//       display: flex;
//       justify-content: space-between;
//       margin-right: 10px;
//       margin-top: 3px;
//     }


//     .shape2 {
//       width: 300px;
//       height: 2px;
//       background-color: grey;
//       margin-top: 2px;
//     }

//     .col2 {
//       display: flex;
//       justify-content: space-between;
//       margin-right: 10px;
//       margin-top: 4px;
//     }




//     .shape3 {
//       width: 300px;
//       height: 2px;
//       background-color: grey;
//       margin-top: 2px;
//     }

//     .col3 {
//       display: flex;
//       justify-content: space-between;
//       margin-right: 10px;
//       margin-top: 4px;
//     }

//     .amt {
//       color: black;
//       font-weight: 700;
//     }


//     .lastsection {
//       margin-top: 10px;
//     }


//     .Bussiness {
//       margin-top: 10px;
//     }
// .budget1 div{
// width: 100px;  
// text-align: end;
// /* border: 1px solid black; */
// }
// .paid1 div{
//   width: 100px;
//   /* border: 1px solid black; */
//   text-align: end;
// }
//     /* .lastline{
//       margin-top: 9px;
//       width:600px;
//       height:2px;
//       background-color: gray;
//     } */
//   </style>
// </head>

// <body>

//   <div class="wrap">
//     <div class="mainContainer">
//       <div class="topSection">
//         <div class="invoice">
//           <h2>Invoice</h2>
//         </div>
//         <div class="logoDiv">
//           <img class="logo" src=${UploadIcon2 } alt="" />
//         </div>
//       </div>
//       <div class="invoiceData">
//         <div>
//           <h5>Invoice Number</h5>
//           <h6>Date of issue</h6>
//           <h6>Date Due</h6>
//         </div>
//         <div>
//           <h5>1485f006-0011</h5>
//           <h6>20 May 2024</h6>
//           <h6>30 May 2024</h6>
//         </div>
//       </div>

//       <div class="baloonary">
//         <div>
//           <h5>Exoads</h5>
//           <h6>Support@exoads.com</h6>
//           <h6>LU VAT LU2305</h6>
//         </div>
//         <div>
//           <h5>Bill To</h5>
//           <h6>Pranav kumar<br>
//             silicon oasis<br>
//             Dubai<br>
//             United Arnaab Emirates<br>
//             support@bilnoory.com</h6>

//         </div>
//       </div>
//       <div>
//         <h3>€0.00 due May 30,2024</h3>
//       </div>
//       <!-- yaha shikha code likhegi -->
//       <div class="priceDetail">

//         <div class="desc">
//           <div>
//             <p>Description</p>
//           </div>

//           <div class="desc2">
//             <div>Qty</div>
//             <div>Unit Price</div>
//             <div>Amount</div>
//           </div>
//         </div>

//         <div class="line"></div>


//         <div class="budget">
//           <div>
//             <p>Ad budget (1-off)</p>
//           </div>

//           <div class="budget1">
//             <div>11dsfdsf2</div>
//             <div>€5844.00</div>
//             <div>€58.54545</div>
//           </div>
//         </div>

//         <div class="line1"></div>

//         <div class="paid">
//           <div>
//             <p>Paid from Exoads credit</p>
//           </div>

//           <div class="paid1">
//             <div>1</div>
//             <div>€58.00</div>
         
//               <div>
//                 €58.00</div>
//           </div>
//         </div>


//         <div class="tbl">

//           <div class="shape1"></div>
//           <div class="col1">
//             <div>subtotal</div>
//             <div>€0.00</div>

//           </div>

//           <div class="shape2"></div>
//           <div class="col2">
//             <div>total</div>
//             <div>€0.00</div>

//           </div>



//           <div class="shape3"></div>
//           <div class="col3">
//             <div class="amt">Amount</div>
//             <div>€0.00</div>

//           </div>
//         </div>

//         <div class="lastsection">
//           <div>
//             <p>Exoads is a produvt of Spoticle adfl</p>
//           </div>
//           <div>
//             <p>23, rue de rHippodrome</p>
//           </div>
//           <div>
//             <p>L-1730 Lumbboung</p>
//           </div>
//           <div>
//             <p>Lummboug</p>
//           </div>
//         </div>

//         <div class="Bussiness">
//           <div>
//             <p>Bussiness Registration Number:8228518</p>
//           </div>
//           <div>
//             <p>Vat Number:LU30483207</p>
//           </div>
//         </div>

//         <div class="lastline"></div>
//       </div>
//     </div>
// </div>

// </body>

// </html>
// `

html2pdf()
      .set({
        margin: 1,
        filename: 'invoice.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      })
      .from(element)
      .save();
  };
  

  return (

    <div className="setting_other-details-alignment">

      {settinOption === "account" && (
        <div className="setting-other-grid">
          <div className="setting-other-grid-item">
            <h4>Change Personal Information</h4>
            <p>If you change email address, make sure to confirm the one after.</p>
            <div className="change-personal-information-form">
              <div className="form-grid">
                <div>
                  <div className="form-grid-item">
                    <div className="middal-box">
                      <label htmlFor="fileInput" style={{ cursor: 'pointer', display: 'block' }}>
                        <input
                          id="fileInput"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: 'none', height: 200, width: 200 }}
                        />
                        <div className="upload-icon-container">
                          {imagePath ? (
                            <img className='myimage' src={`${nodeUrl}/${profileImage}`} 
                            onError={(e) => {
                              e.target.onerror = null; // Prevent infinite loop in case of repeated errors
                              e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
                              }}
                              alt="avatar"
                               />
                          ) : (
                            <p>Max 2 Mb</p>
                          )}
                          {imagePath ? (
                            <MdModeEditOutline className='pencilSvg' />
                          ) : (
                            <UploadIcon2 />
                          )}
                        </div>
                      </label>

                    </div>

                  </div>
                  <div className="update-button">
                    {/* <button>Update</button> */}
                  </div>
                </div>

                <div className="form-details-alignment">
                  <div className="form-input">
                    <label>Full Name</label>
                    <div className="input">

                      <input
                        type="text"
                        required
                        defaultValue={profileName || user?.fullName || ''}

                        placeholder='Enter your name'
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setNameError(false);
                          } else {
                            setNameError(true);
                          }
                          setMyName(e.target.value);
                        }}
                      />

                      <div className="update-button">
                        <button onClick={() => updateName()}>Update</button>
                      </div>
                    </div>
                  </div>
                  {nameError ? (
                    <p style={{ fontSize: 12, fontWeight: "600" }} className='text-danger'>Enter your fullName</p>
                  ) : (
                    <></>
                  )}
                  {/* <div className="form-input">
                                        <label>Email</label>
                                        <div className="input">
                                            <input type="text" placeholder='EXOAD@gmail.com' />
                                            <div className="update-button">
                                                <button>Update</button>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="setting-other-grid-item">
            <h4>Change Password</h4>
            <p>On your next login, you will be using this new password</p>
            <div className="change-password-alignment">
              <form onSubmit={handleSubmit(Submit)}>
                <div className="form-details-alignment">
                  <div className="form-input">
                    <label>Old Password</label>
                    <div className="input">
                      <input
                        type={oldPasswordToggle ? "password" : "text"}
                        placeholder="********"
                        {...register("currentpassword", {
                          required: "Current Password is required",
                        })}
                      />
                      {!oldPasswordToggle ? (
                        <img
                          className="header-password-icon"
                          src={eyeClose}
                          alt=""
                          onClick={() => setOldPasswordToggle(!oldPasswordToggle)}
                        />
                      ) : (
                        <img
                          className="header-password-icon"
                          src={eyeOpen}
                          alt=""
                          onClick={() => setOldPasswordToggle(!oldPasswordToggle)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-details-alignment">
                  <div className="form-input">
                    <label>New Password</label>
                    <div className="input">
                      <input
                        type={newPasswordToggle ? "password" : "text"}
                        placeholder="********"
                        {...register("newpassword", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "",
                          },
                        })}
                      />
                      {!newPasswordToggle ? (
                        <img
                          className="header-password-icon"
                          src={eyeClose}
                          alt=""
                          onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                        />
                      ) : (
                        <img
                          className="header-password-icon"
                          src={eyeOpen}
                          alt=""
                          onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                        />
                      )}
                    </div>
                    {password && password.length < 8 && (
                      <span
                        className="text-danger fw-bold"
                        style={{ fontSize: "12px" }}
                      >
                        password at least 8 char required
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-details-alignment">
                  <div className="form-input">
                    <label>Confirm New Password</label>
                    <div className="input">
                      <input
                        type={currentPasswordToggle ? "password" : "text"}
                        placeholder="********"
                        {...register("confirmPassword", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "",
                          },
                        })}
                      />

                      {!currentPasswordToggle ? (
                        <img
                          className="header-password-icon"
                          src={eyeClose}
                          alt=""
                          onClick={() =>
                            setCurrentPasswordToggle(!currentPasswordToggle)
                          }
                        />
                      ) : (
                        <img
                          className="header-password-icon"
                          src={eyeOpen}
                          alt=""
                          onClick={() =>
                            setCurrentPasswordToggle(!currentPasswordToggle)
                          }
                        />
                      )}



                      <div className="update-button">
                        <button type='submit'>Update</button>
                      </div>

                    </div>
                    {confirmPassword && password !== confirmPassword && (
                      <span
                        className="text-danger fw-bold"
                        style={{ fontSize: "12px", marginBottom: 12, position: "absolute" }}
                      >
                        Passwords do not match
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      )}



      {settinOption === "billing" && (

        <div className="billing-information-grid-alignment">
          <div className='billing-grid-item'>
            <h4>Billing Information</h4>
            <p>You can change your billing information in the field below</p>

            <div className="billing-grid">

              <div className="form-input">
                <label>Full Name</label>
                <div className="input">
                  <input type="text" placeholder='Enter your fullName'
                    defaultValue={billName}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setBillnameError(false)
                      }
                      else {
                      }

                      setBillName(e.target.value);
                    }} />
                </div>


                {billnameError ? (

                  <span
                    className="text-danger fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    Full name is required!
                  </span>
                ) : (
                  <></>
                )}

              </div>


              <div className="form-input">
                <label>Company Name (Optional)</label>
                <div className="input">
                  <input type="text" placeholder='Enter your company name'
                    defaultValue={billComapny}
                    onChange={(e) => {

                      setBillCompany(e.target.value);
                    }} />
                </div>

              </div>
              <div className="form-input">
                <label>Email</label>
                <div className="input">
                  <input type="text" placeholder='Enter Your Email'
                    defaultValue={billEmail}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setBillEmailError(false)
                      }
                      else {
                      }

                      setBillEmail(e.target.value);
                    }} />
                </div>

                {billEmailError ? (

                  <span
                    className="text-danger fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    Email is required!
                  </span>
                ) : (
                  <></>
                )}




              </div>
              <div className="form-input">
                <label>Tax (VAT)</label>
                <div className="input">
                  <input type="text" placeholder='Enter Your Tax'
                    defaultValue={tax}
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setTaxError(false)
                      }
                      else {
                      }

                      setTax(e.target.value);
                    }} />
                </div>

                {taxError ? (

                  <span
                    className="text-danger fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    Tax(VAT) is required!
                  </span>
                ) : (
                  <></>
                )}



              </div>
            </div>

            <div className="save-button-alignment">
              <button onClick={() => updateBilling()}>Save</button>
            </div>
          </div>


          {planStatus ? (

            <div className='upcoming-bill-icon-active'>

  
              <h4>Plan Expires In {daysRemaining} Days</h4>


              <p>This Is The Preview Of The Invoice</p>

              <div className="upcoming-fill-form">
                <div className="upcoming-bill-active">
                  <p>Plan Name</p>
                  <h6>{planStatus?.name}</h6>
                </div>
                <div className="upcoming-bill-active">
                  <p>Subcriptions Amount</p>
                  <h6>{currencySymbol(user?.plan[0]?.currency)} {planStatus?.amount}</h6>
                </div>
               
                <div className="upcoming-bill-active">
                  <p> Total Discount</p>
                  <h6>{currencySymbol(user?.plan[0]?.currency)} 00</h6>
                </div>
                <div className="upcoming-bill-active">
                  <p>Total</p>
                  <h6>{currencySymbol(user?.plan[0]?.currency)} {planStatus?.amount}</h6>
                </div>
              </div>

              <div className="bottom-details-active">
                <h6>Paid Invoice</h6>
                <p>You Can Download Paid Invoice Here</p>

                <OverlayTrigger
                   
                    overlay={
                        <Tooltip id={`tooltip`}>
                            
                            Click To  Download Invoice 
                        </Tooltip>
                    }
                >
                      
                

                <svg
      width="25"
      height="30"
      onClick={createPdf}
      viewBox="0 0 14 17"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }} // Optional: Add a pointer cursor to indicate clickability
    >
      <path d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z" />
    </svg>
          
    </OverlayTrigger>

              </div>
              <div className="blur-box">

              </div>
            </div>
          ) : (

            <div className='upcoming-bill-icon-alignment'>
              <h4>No Billing Info </h4>
              <p>This Is The Preview Of The Invoice</p>

              <div className="upcoming-fill-form">
                <div className="upcoming-bill-list">
                  <p>Subcriptions</p>
                  <h6>${currencySymbol(user?.plan[0]?.currency)} 00</h6>
                </div>
                <div className="upcoming-bill-list">
                  <p>Premium + Tax + 20%</p>
                  <h6>${currencySymbol(user?.plan[0]?.currency)} 00</h6>
                </div>
                <div className="upcoming-bill-list">
                  <p> Total Discount</p>
                  <h6>${currencySymbol(user?.plan[0]?.currency)} 00</h6>
                </div>
                <div className="upcoming-bill-list">
                  <p>Total</p>
                  <h6>${currencySymbol(user?.plan[0]?.currency)} 00</h6>
                </div>
              </div>

              <div className="bottom-details-alignment">
                <h6>Paid Invoice</h6>
                <p>You Can Download Paid Invoice Here</p>
                <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z" fill="black" />
                </svg>

              </div>
              <div className="blur-box">

              </div>
            </div>
          )}









        </div>




      )}
    </div>
  )
}
