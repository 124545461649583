import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../component/axios/auth";
import Cookies from "js-cookie";
import CryptoJS from 'crypto-js';
import { ToastContainer, toast } from "react-toastify";
let LoginToken;
export const userLogin = createAsyncThunk("userlogin", async (loginData) => {
  try {
    let { email, password } = loginData;

   
let secretKey ="exoads"
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();

    
    let data = {
        email,
        password: encryptedPassword
    };
    
    
    // Make the API call
    const res = await axios.post("/login", data);

    if (res?.data?.token) {
        const LoginToken = res?.data?.token;
      let sessionId= Math.random().toString(36).substring(2) + Date.now().toString(36);
        
        Cookies.set("session_id", sessionId); // Store token in a cookie
         sessionStorage.setItem("session_id",sessionId)
        Cookies.set("token", LoginToken); // Store token in a cookie
        return res?.data?.data[0];
    } else {
        toast.error(res?.data?.message, {
            className: 'custom-toast-error',
            hideProgressBar: true
        });
    }
} 
   catch (error) {
    console.log(error);
    
    // toast.error("Invalid Credential");
    toast.error("Invalid email. Please check and try again.", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
  }
});

const loginState = {
  token: "",
  user: null,
  status: "idle", // Change the initial status to 'idle'
};
const loginSlice = createSlice({
  name: "loginSlice",
  initialState: loginState,
  reducers: {
    adduserdata: (state, action) => {
      state.user = action.payload;
    },
    clearRedux: (state, action) => {
      state.user = state.loginState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.status = "loading"; // Updated the status to 'loading'
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "success"; // Updated the status to 'success'
        state.user = action.payload;
        state.token = LoginToken;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "rejected"; // Updated the status to 'rejected'
      });
  },
});

export const { adduserdata, signupGoogle, clearRedux } = loginSlice.actions;
export default loginSlice.reducer;
