import React, { useContext, useEffect, useRef, useState } from "react";
import "./HomeCampaigns.css";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  googleSvg,
  faceBook,
  eyeSvg,
  buttonSvg,
  decSvg,
  peopleSvg,
  inceasingSvg,
  PlusSvg,
  RenameSvg,
  Duplicate,
  Archive,
  GoogleAds,
  rightArrowGreen,
  BingAds,
  InstagramAds,
  noOfClicks,
} from "./SVG";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Cookies from "js-cookie";
import { InputField } from "../NewCampaigns/NewCampaigns";
import axios from "../../../../axios/auth";
import { TbHandClick } from "react-icons/tb";
import {
  PauseIcon,
  PlayIcon,
  RedDotIcon,
} from "../Performance Campaigns/Ad Traffice Only/SVg";
import { MailContext } from "../../../Auth Pages/mailContext";
import { useDispatch, useSelector } from "react-redux";
import {
  addGoogleAnalytics,
  addSingleGoogleAnalyticsDetail,
} from "../../../../../Redux/AdsAnalyticsDetailsSlice";
import ReactPaginate from 'react-paginate';
import GoogleAdsModal from "../../../Main Pages/Images/GoogleAdsModal.png";
import PDFIcon from "../../../../../assets/icons/pdf-icon.svg";
import StackedAreaChart from "./StackedAreaChart/StackedAreaChart";
import { toast } from "react-toastify";
import comingsoon from "./nodata.jpg"
import GoogleCampaignsLoader from "../../Dashboard/GoogleCampignLoader";

const DashboardPage = () => {
  const [analytic, setAnalytics] = useState([]);
  const [currentAnalytic, setCurrentAnalytics] = useState([]);
  const [adsModal, setAdsModal] = useState(false);
  const [adsModalForgoogle, setAdsModalForGoogle] = useState(false);

  const [totalReach, setTotalReach] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();

  const [reLoaddata, setReloadData] = useState();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [customDateModal, setcustomDateModal] = useState(false)
  const [activeTab, setActiveTab] = useState('tab1'); // Initial active tab state

 
  useEffect(() => {
    HandleSetToken();
  }, []);

  const HandleSetToken = () => {
    setToken(Cookies.get("token"));
  };

  const { HandlePerformanceCampaign, performanceCampaignData } = useContext(
    MailContext
  );
  const { GoogleAnalytics } = useSelector((state) => state.AdsAnalyticsDetail);
  

  const Select1 = ["Google", "Facebook", "Instagram", "Bing"];

  const data = [
    {
      name: "Page A",
      clicks: 4000,
      impressions: 2400,
      avgCPC: 2400,
      cost: 1200,
    },
    {
      name: "Page B",
      clicks: 3000,
      impressions: 1398,
      avgCPC: 2200,
      cost: 1500,
    },
    {
      name: "Page C",
      clicks: 2000,
      impressions: 9800,
      avgCPC: 2290,
      cost: 1600,
    },
    {
      name: "Page D",
      clicks: 2780,
      impressions: 3908,
      avgCPC: 2000,
      cost: 1900,
    },
    {
      name: "Page E",
      clicks: 1890,
      impressions: 4800,
      avgCPC: 2181,
      cost: 2000,
    },
    {
      name: "Page F",
      clicks: 2390,
      impressions: 3800,
      avgCPC: 2500,
      cost: 2100,
    },
    {
      name: "Page G",
      clicks: 3490,
      impressions: 4300,
      avgCPC: 2100,
      cost: 2400,
    },
  ];
  
  const fetchData = async () => {
    const url = `/get-all-ads`;
    setIsLoading(true);
    try {
      const ads = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (ads?.data) {
        setIsLoading(false);
        
        return ads.data;
      }
    } catch (error) {
      toast.error("Error in loading data", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      setIsLoading(false);
      // throw error; // rethrow the error to be caught by the caller
    }
  };

  useEffect(() => {
    if (GoogleAnalytics && GoogleAnalytics?.length > 0) {
      setAnalytics(GoogleAnalytics);
    } else if (token) {
      setIsLoading(true);
      fetchDataAndSetData();
    }
  }, [token, reLoaddata]);

  const fetchDataAndSetData = async () => {
    try {
      const fetchedData = await fetchData();

      setAnalytics(fetchedData?.data); // Set fetched data to state
      dispatch(addGoogleAnalytics(fetchedData?.data));
    } catch (error) {
      // Handle errors, maybe show a message to the user
    }
  };

  function formatNumber(num) {
    
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else if (num >= 10000) {
      return (num / 1000).toFixed(2) + "K";
    } else if (num == 0) {
      return 0;
    } else {
      return 0
    }
  }

  useEffect(() => {
    // Calculate totalReach when analytics data changes
    if (analytic && analytic.length > 0) {
      let totalData = analytic.reduce(
        (total, item) => total + item.metricData.interactions,
        0
      );
      
      setTotalReach(formatNumber(totalData));
    }
  }, [analytic]);

  

  // Render your component...

  const HandleSingleGoogleAnalytics = (data) => {
    
    // Assuming `data` is defined and contains the necessary structure
    if (data?.adData?.adMapData?.createdAt) {
        // Convert the createdAt string to a Date object
        const date = new Date(data.adData.adMapData.createdAt);

        const year = date.getFullYear();
        const month = date.getMonth(); // Months are zero-based (0 = January, 8 = September, etc.)
        const day = date.getDate();
        
        // Create a new Date object for the start date with time set to midnight UTC
        let startDate = new Date(Date.UTC(year, month, day));
        
        // Get the current date and time in UTC
        let currentDate = new Date();
        
        // Convert currentDate to UTC
        let currentDateUTC = new Date(Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes(),
            currentDate.getSeconds()
        ));
        
        // Get the current time in milliseconds
        let currentTime = currentDateUTC.getTime();
        
        // Get the start date time in milliseconds
        let startTime = startDate.getTime();
        
        // Calculate the difference in milliseconds
        let timeDifference =   currentTime-startTime;
        
        // Convert milliseconds to hours
        let hoursDifference = timeDifference / (1000 * 60 * 60);
        
        // Check if the difference is less than 24 hours and non-negative
        if (hoursDifference >= 0 && hoursDifference < 24) {

          setAdsModalForGoogle(true)

        } else {
          setAdsModal(true);
        }
    } else {
        // console.log('No start date is provided for the campaign.');
    }
    
    // Update state or dispatch actions as needed
    setCurrentAnalytics(data);
   
    dispatch(addSingleGoogleAnalyticsDetail(data));
};


  const [activeIndex, setActiveIndex] = useState({
    clicks: true,
    impressions: false,
    avgCPC: false,
    cost: false,
  });

  const toggleDataSeries = (key) => {
    
    setActiveIndex((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const tabActive= (key) => {
    setActiveTab(key);
  }
  const ITEMS_PER_PAGE = 10;
  
 // pagination code
 const [currentPage, setCurrentPage] = useState(0);

 // Calculate the total number of pages
 const pageCount = Math.ceil(analytic?.length / ITEMS_PER_PAGE);

 // Calculate the offset based on the current page
 const offset = currentPage * ITEMS_PER_PAGE;

 // Get the data for the current page
 const currentPageData = analytic?.slice(offset, offset + ITEMS_PER_PAGE);

 // Determine whether to show pagination
 const shouldShowPagination = pageCount > 1;


 // Handler for page change
 const handlePageChange = (event) => {
   setCurrentPage(event.selected);
 };

 // pagination code

  return (
    <Container className="main-container" style={{ padding: "0px 20px" }}>
      <Row>
        <Col lg={10} style={{}}>
         
          <div className="home-campaign-graph-body  home-campaign-graph-body-bg">
            <div className="home-campaing-child-box">
              <div className="home-heading-alignment">
                <div className="header-left-side-alignment">
                  <h6>Insight</h6>


                  <div className="chart-tab-alignment">

                    <div className={activeTab === "tab1" ? "tab-box-alignment active-tab" : "tab-box-alignment"} onClick={() => { setcustomDateModal(false); toggleDataSeries("cost"); tabActive("tab1") }}>
                      
                        <p>30 Days</p>
                    </div>
                  </div>
                  <div className="chart-tab-alignment">
                  <div className={activeTab === "tab2" ? "tab-box-alignment active-tab" : "tab-box-alignment"} onClick={() => { setcustomDateModal(false); toggleDataSeries("impressions"); tabActive("tab2") }}>
                  <p>7 Days</p>
                    </div>
                  </div>
                  <div className="chart-tab-alignment">
                  <div className={activeTab === "tab3" ? "tab-box-alignment active-tab" : "tab-box-alignment"} onClick={() => {setcustomDateModal(true);tabActive("tab3")} }>
                  <p>Custom</p>
                    </div>

                    {customDateModal && (

                      <div className="datepiker-box-alignment">

                        <div className="datepicker-top-heading">
                          <div>
                            <label>Start Date</label>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              // minDate={new Date()}
                              dateFormat="dd MMMM yyyy"
                            />
                          </div>
                          <span> - </span>
                          <div>
                            <label>End Date</label>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              selectsEnd
                              startDate={startDate}
                              endDate={endDate}
                              minDate={startDate}
                              dateFormat="dd MMMM yyyy"
                            />
                          </div>
                        </div>

                        <div className="datepicker-bottom-alignment">
                          <button onClick={() => setcustomDateModal(false)}>Cancel</button>
                          {/* <button onClick={() =>setcustomDateModal(false), toggleDataSeries("cost")}>Apply</button> */}
                          <button onClick={() => { setcustomDateModal(false); toggleDataSeries("cost"); }}>Apply</button>

                        </div>
                      </div>
                    )}

                  </div>
                </div>

                
              </div>

              <div className="home-heading-bottom-alignment">
              <div
        style={activeIndex.clicks ? { background: "#4285f4" } : {}}
        className="chart-details-box-alignment"
        onClick={() => toggleDataSeries("clicks")}
      >
                  <p >
                    Clicks <br /> {activeIndex.clicks ? "66.6k" : "0"}
                  </p>
                </div>


                <div
                 style={activeIndex.impressions ? { background: "#fbbc05" } : {}}
                  className="chart-details-box-alignment"
                  onClick={() => toggleDataSeries("impressions")}
                >
                  <p>
                    Impressions <br /> {activeIndex.impressions ? "66.6k" : "0"}
                  </p>
                </div>
                <div
                 style={activeIndex.avgCPC ? { background: "red" } : {}}
                  className="chart-details-box-alignment"
                  onClick={() => toggleDataSeries("avgCPC")}
                >
                  <p>
                    Avg CPC <br /> {activeIndex.avgCPC ? "66.6k" : "0"}
                  </p>
                </div>
                <div
                 style={activeIndex.cost ? { background: "#34a853" } : {}}
                  className="chart-details-box-alignment"
                  onClick={() => toggleDataSeries("cost")}
                >
                  <p>
                    Cost <br /> {activeIndex.cost ? "66.6k" : "0"}
                  </p>
                </div>


              </div>

              <div className="map-alignment">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    {activeIndex.clicks && (
                      <Area
                        type=""
                        dataKey="clicks"
                        stackId="1"
                        stroke="#4285f4"
                        fill="#4285f4"
                      />
                    )}
                    {activeIndex.impressions && (
                      <Area
                        type=""
                        dataKey="impressions"
                        stackId="1"
                        stroke="#fbbc05"
                        fill="#fbbc05"
                      />
                    )}
                    {activeIndex.avgCPC && (
                      <Area
                        type=""
                        dataKey="avgCPC"
                        stackId="1"
                        stroke="red"
                        fill="red"
                      />
                    )}
                    {activeIndex.cost && (
                      <Area
                        type=""
                        dataKey="cost"
                        stackId="1"
                        stroke="#34a853"
                        fill="#34a853"
                      />
                    )}
                  </AreaChart>
                </ResponsiveContainer>
             
                {/* <StackedAreaChart /> */}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={2} className="mt-3 mt-lg-0">
          {/* <div className="home-campaign-graph-header">
            <p>Insight</p>
            <Select selectHeading={"monthly"} options={Select1} />
          </div> */}
          <div
            className="home-campaign-graph-body"
            style={{ padding: "20px", maxHeight: "390px", borderRadius: "6px" }}
          >
            <div className="home-campaign-social-media">

              <SocialMedia
                SocialMediaLogo={GoogleAds}
                SocialMediaType={"Google Ads"}
                progress={`${totalReach}`}
                progressType={"Reach"}
                // progressPercentage={"23%"}
                // progressPercentageLogo={rightArrowGreen}
                loading={isLoading}
                GoogleAnalytics={GoogleAnalytics}
              />
              {/* <SocialMedia
                SocialMediaLogo={BingAds}
                SocialMediaType={"Bing Ads"}
                progress={"2,6m"}
                progressType={"Reach"}
              /> */}
              <SocialMedia
                SocialMediaLogo={faceBook}
                SocialMediaType={"Facebook Ads"}
                progress={"406K"}
                progressType={"Reach"}
              />
              <SocialMedia
                SocialMediaLogo={InstagramAds}
                SocialMediaType={"Instagram"}
                progress={"306K"}
                progressType={"Followers"}
                // border={"none"}
              />
                 <SocialMedia
  SocialMediaLogo={BingAds}
  SocialMediaType={"Bing Ads"}
  progress={<span style={{ fontSize: "15px",marginTop:"5px" }}>Coming soon...</span>}
  border={"none"}
/>

            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/* Header  */}
        <Header  currentPageData={currentPageData} analytic={analytic} HandleSingleGoogleAnalytics={HandleSingleGoogleAnalytics} setCurrentPage={setCurrentPage} isLoading={isLoading}currentPage={currentPage}/>

        {/* Main Body */}
        {isLoading || analytic == [] ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div
          style={{ position: "relative" }}
          className="home-campaign-main-campaign-details"
        >
      
           {currentPageData?.length<0&& <div className="add-run-box-alignment" style={{width:"100%",display:"flex",justifyContent:"center",marginLeft:"40%"}}>
               <img src={comingsoon}/>
              </div>}


          {shouldShowPagination  && (
          // {shouldShowPagination && (

            
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Math.ceil(analytic?.length / ITEMS_PER_PAGE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </div>
        


            
        )}
        <CustomModal
          currentAnalytic={currentAnalytic}
          show={adsModal}
          setModal={setAdsModal}
          onHide={() => setAdsModal(false)}
          token={token}
          fetchDataAndSetData={fetchDataAndSetData}
          HandleSetToken={HandleSetToken}
          setReloadData={setReloadData}
        />
             <CustomModalForLoader
          currentAnalytic={currentAnalytic}
          show={adsModalForgoogle}
          setModal={setAdsModalForGoogle}
          onHide={() => setAdsModalForGoogle(false)}
          token={token}
          fetchDataAndSetData={fetchDataAndSetData}
          HandleSetToken={HandleSetToken}
          setReloadData={setReloadData}
        />
      </Row>
    </Container>
  );
};

const SocialMedia = ({
  SocialMediaLogo,
  SocialMediaType,
  progress,
  progressType,
  progressPercentage,
  progressPercentageLogo,
  border,
  loading,
  GoogleAnalytics,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          justifyContent: "center",
        }}
      >
        {SocialMediaLogo} <p style={{ fontSize: "12px" }}>{SocialMediaType}</p>
      </div>
      <div
        className="home-compaign-impression-div"
        style={border ? { border: border } : {}}
      >
        {loading ? (
          <div style={{ padding: "0px" }}>
            <Spinner />
          </div>
        ) : (
          <p>{progress}</p>
        )}
        <div>
          <p>
            {progressType}
            <span>
              {progressPercentageLogo} {progressPercentage}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
const Header = ({ currentPageData,HandleSingleGoogleAnalytics,analytic,setCurrentPage,isLoading,currentPage}) => {
  return (
    // <div className="header" style={{ padding: "5px 0px 3px 0px" }}>
    //   <div className="header-campaings">
    //     <p className="header-text">Campaings</p>
    //     <div className="header-campaings-ads">


    //       <div  
    //         className="header-campaings-ad"
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           gap: "4px",
    //           background: "#47beb9",
    //         }}
    //       >
    //         {GoogleAds}
    //         <p>{analytics ? analytics?.length : 0}</p>
    //       </div>



    //       <div
    //         className="header-campaings-ad"
    //         style={{ display: "flex", alignItems: "center", gap: "10px" }}
    //       >
    //         {faceBook}
    //         <p style={{ color: "#B2BBC6" }}>0</p>
    //       </div>

    //       <div
    //         className="header-campaings-ad"
    //         style={{ display: "flex", alignItems: "center", gap: "10px" }}
    //       >
    //         {BingAds}
    //         <p style={{ color: "#B2BBC6" }}>0</p>
    //       </div>

    //       <div
    //         className="header-campaings-ad"
    //         style={{ display: "flex", alignItems: "center", gap: "10px" }}
    //       >
    //         {InstagramAds}
    //         <p style={{ color: "#B2BBC6" }}>0</p>
    //       </div>


    //     </div>
    //   </div>

    //   <div style={{ padding: "10px 0px" }}>
    //     <Link
    //       to={"/main/campaign/newcampaign"}
      
    //     >
       

    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="168"
    //         height="67"
    //         viewBox="0 0 218 67"
    //         fill="none"
    //       >
    //         <g filter="url(#filter0_i_9_793)">
    //           <path
    //             d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
    //             fill="#2E908F"
    //           />
    //           <text
    //             x="35%"
    //             y="60%"
    //             font-family="Arial"
    //             font-size="20"
    //             fill="white"
    //             className="newCampaignBtn"
    //           >
    //             New Campaign
    //           </text>
    //         </g>
    //         <svg
    //           width="34"
    //           height="34"
    //           viewBox="0 0 21 20"
    //           fill="none"
    //           x="15%"
    //           y="26%"
    //         >
    //           <path
    //             d="M10.9985 4.16602V15.8337"
    //             stroke="white"
    //             stroke-width="1.66678"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //           <path
    //             d="M5.16504 9.99988H16.8327"
    //             stroke="white"
    //             stroke-width="1.66678"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //         </svg>
    //         <defs>
    //           <filter
    //             id="filter0_i_9_793"
    //             x="0.516418"
    //             y="0"
    //             width="231.15"
    //             height="78.2004"
    //             filterUnits="userSpaceOnUse"
    //             color-interpolation-filters="sRGB"
    //           >
    //             <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //             <feBlend
    //               mode="normal"
    //               in="SourceGraphic"
    //               in2="BackgroundImageFix"
    //               result="shape"
    //             />
    //             <feColorMatrix
    //               in="SourceAlpha"
    //               type="matrix"
    //               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //               result="hardAlpha"
    //             />
    //             <feOffset dx="13.6661" dy="12.1476" />
    //             <feGaussianBlur stdDeviation="18.6011" />
    //             <feComposite
    //               in2="hardAlpha"
    //               operator="arithmetic"
    //               k2="-1"
    //               k3="1"
    //             />
    //             <feColorMatrix
    //               type="matrix"
    //               values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
    //             />
    //             <feBlend
    //               mode="normal"
    //               in2="shape"
    //               result="effect1_innerShadow_9_793"
    //             />
    //           </filter>
    //         </defs>
    //       </svg>
    //     </Link>
    //   </div>
    // </div>
    <Container>
    <Row className="mt-3">
      <Col lg={8}>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="social-tab-btn active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={()=>setCurrentPage(1)}>
            {GoogleAds}
            <p>{analytic?.length ||0}</p>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="social-tab-btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={()=>setCurrentPage(0)}>
            {faceBook}
              <p>0</p>
            </button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button className="social-tab-btn" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" onClick={()=>setCurrentPage(0)}>
            {BingAds}
              <p>0</p>
            </button>
          </li> */}
          <li className="nav-item" role="presentation">
            <button className="social-tab-btn" id="pills-instagram-tab" data-bs-toggle="pill" data-bs-target="#pills-instagram" type="button" role="tab" aria-controls="pills-instagram" aria-selected="false" onClick={()=>setCurrentPage(0)}>
            {InstagramAds}
              <p>0</p>
            </button>
          </li>
        </ul>
      </Col>
      <Col lg={4}></Col>
      <Col lg={12}>
      <div className="tab-content" id="pills-tabContent">
      <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
    
      {  isLoading
 ? (
  
        <div className="loader"></div> // Add your custom loader component or style here
 
        

      ) : (
        currentPageData?.length == 0 ||currentPageData == undefined ? (
          <div className="add-run-box-alignment" style={{ width: "80%", display: "flex", justifyContent: "center", marginLeft: "10%" }}>
            <img height={600} width={600} src={comingsoon} alt="Coming Soon" />
          </div>
        ) : (
         
          currentPageData?.map((item, i) => (
            
            <MainContent
              key={i}
              data={item}
              Svg={inceasingSvg}
              value={"Increasing"}
              color1={"#03BB63"}
              visited={1200}
              Impression={14000}
              index={i}
              length={analytic?.length}
              HandlePerformanceCampaign={HandleSingleGoogleAnalytics}
              currentPage={currentPage}
            />
          ))
        )
      )}
    </div>



          <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
          <div className="home-campaign-main-campaign-details" style={{position:"relative"}}>
              <div className="main-container-camp container-fluid" style={{marginBottom:"10px"}}>
                <div className="row-gap row">
                  <div className="main-container-camp-div1  col-lg-4">
                    <p className="camp-div1-p">Interplanetary AG b6...</p>
                    <div className="camp-div1-bottom">
                      <div className="camp-div1-bottom">
                        <div className="camp-div1-bottom-container">
                        {faceBook}
                        <p className="camp-div1-bottom-container-p">1/1</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-container-camp-div2 col-lg-6">
                    <div className="row">
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Last 2 Week activity</p>
                          <div className="progress-div">
                          {inceasingSvg}
                            <p className="progress-text">Increasing</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Start Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2024-04-22</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">End Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2037-12-30</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  {PlayIcon }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>
          <div className="home-campaign-main-campaign-details" style={{position:"relative"}}>
              <div className="main-container-camp container-fluid" style={{marginBottom:"10px"}}>
                <div className="row-gap row">
                  <div className="main-container-camp-div1  col-lg-4">
                    <p className="camp-div1-p">Interplanetary AG b6...</p>
                    <div className="camp-div1-bottom">
                      <div className="camp-div1-bottom">
                        <div className="camp-div1-bottom-container">
                        {BingAds}
                        <p className="camp-div1-bottom-container-p">1/1</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-container-camp-div2 col-lg-6">
                    <div className="row">
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Last 2 Week activity</p>
                          <div className="progress-div">
                          {inceasingSvg}
                            <p className="progress-text">Increasing</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Start Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2024-04-22</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">End Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2037-12-30</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  {PlayIcon }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="pills-instagram" role="tabpanel" aria-labelledby="pills-instagram-tab" tabIndex={0}>
          <div className="home-campaign-main-campaign-details" style={{position:"relative"}}>
              <div className="main-container-camp container-fluid" style={{marginBottom:"10px"}}>
                <div className="row-gap row">
                  <div className="main-container-camp-div1  col-lg-4">
                    <p className="camp-div1-p">Interplanetary AG b6...</p>
                    <div className="camp-div1-bottom">
                      <div className="camp-div1-bottom">
                        <div className="camp-div1-bottom-container">
                        {InstagramAds}
                        <p className="camp-div1-bottom-container-p">1/1</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-container-camp-div2 col-lg-6">
                    <div className="row">
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Last 2 Week activity</p>
                          <div className="progress-div">
                          {inceasingSvg}
                            <p className="progress-text">Increasing</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">Start Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2024-04-22</p>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{padding:"0px"}}>
                        <div className="center-container">
                          <p className="camp-div2-p">End Date</p>
                          <div className="progress-div">
                            <p className="progress-text" style={{color:"rgb(178, 187, 198)"}}>2037-12-30</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  {PlayIcon }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
  );
};



const MainContent = ({
  Svg,
  value,
  color1,
  visited,
  Impression,
  data,
  index,
  length,
  HandlePerformanceCampaign,currentPage
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowArchive, setModalShowArchive] = useState(false);

  const pageIndex = currentPage * 10 + index ;

  function truncateName(name, maxLength) {
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    } else {
      return name;
    }
  }

  // data?.adData?.adGroup?.name

  return (
    
    <Link
      // to={`performancecampaign`}
      onClick={() => HandlePerformanceCampaign(data)}
    >
      <Container
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1 " lg={4}>
            <p className="camp-div1-p">
              {truncateName(data?.adData?.adGroup?.name, 20)}
            </p>
            <div className="camp-div1-bottom">
              <Google googleSvg={GoogleAds}      index={pageIndex} 
 length={length} />
              {/* <Google googleSvg={faceBook} /> */}
            </div>
          </Col>

          <Col className="main-container-camp-div2" lg={6}>
            <Row>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"Last 2 Week activity"}
                  Svg={inceasingSvg}
                  label={"Increasing"}
                // color={color1}
                />
                {/*  */}
              </Col>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"Start Date"}
                  // Svg={eyeSvg}
                  label={data?.adData?.campaign?.startDate}
                  color={"#B2BBC6"}
                />
              </Col>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"End Date"}
                  // Svg={peopleSvg}
                  label={data?.adData?.campaign?.endDate}
                  color={"#B2BBC6"}
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <DropDown
              setModalShow={setModalShow}
              modalShow={modalShow}
              setModalShowArchive={setModalShowArchive}
              modalShowArchive={modalShowArchive}
            /> */}
            {data?.adData?.adGroupAd?.status == "ENABLED"
              ? PlayIcon
              : PauseIcon}
            {/* {data?.adData?.adGroupAd?.status == "PAUSED" && PauseIcon} */}
          </Col>
        </Row>
      </Container>
    </Link>
  );
};



const Google = ({ googleSvg, index, length }) => {
  return (
    <div className="camp-div1-bottom">
      <div className="camp-div1-bottom-container">
        {googleSvg}
        <p className="camp-div1-bottom-container-p">
          {index + 1}/{length}
        </p>
      </div>
    </div>
  );
};





const Center = ({ title, Svg, value, color, label }) => {
  return (
    <div className="center-container">
      <p className="camp-div2-p">{title}</p>
      <div className="progress-div">
        {Svg}
        <p className="progress-text" style={{ color: color }}>
          {value ? Math.round(value) : label}
        </p>
      </div>
    </div>
  );
};


const DropDown = ({
  setModalShow,
  modalShow,
  setModalShowArchive,
  modalShowArchive,
}) => {
  return (
    <Dropdown onClick={(e) => (e.stopPropagation(), e.preventDefault())}>
      <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-btn">
        <button className="end-button">{buttonSvg}</button>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setModalShow(true)}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {RenameSvg}
            <p className="drop-down-p">Rename</p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {Duplicate}
            <p className="drop-down-p">Duplicate</p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setModalShowArchive(true)}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {Archive}
            <p className="drop-down-p">Archive</p>
          </div>
        </Dropdown.Item>
        <CustomModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          title={"Edit Campaign Name"}
          buttonTitle={"Save"}
        />
        {/* <CustomModal
          show={modalShowArchive}
          onHide={() => setModalShowArchive(false)}
          title={"Archive Campaign"}
          buttonTitle={"STOP ADS AND ARCHIVE"}
        /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const CustomModal = (props) => {
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();

  const HandleAdStatus = async () => {
    setLoading(true);
    try {
      const adId = props?.currentAnalytic?.adData?.adGroupAd?.ad?.id;
      const currentStatus = props?.currentAnalytic?.adData?.adGroupAd?.status;

      let data = {
        ad_id: adId,
        status: currentStatus === "ENABLED" ? "PAUSED" : "ENABLED",
      };

      const res = await axios.post("/update-ad-status", data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      setLoading(false);
      dispatch(addGoogleAnalytics([]));

      props.setReloadData((prev) => !prev);

      props.onHide();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ads-modal-main1"
    >
      <div className="ads-modal-main">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={GoogleAdsModal} style={{ width: "110px" }} alt="" />
        </div>
        <div className="ads-modal-details-main" style={{ flex: 1 }}>
          <h4 className="ads-modal-Heading">Google Ads</h4>

          <div className="ads-modal-details">
            <div className="ads-modal-details-two">
              {/* 1 */}
              <div className="ads-modal-detail">
                <h3>{props?.currentAnalytic?.metricData?.impressions}</h3>
                <p>Impressions</p>
              </div>
              {/* 2 */}
              <div className="ads-modal-detail">
                <h3>{props?.currentAnalytic?.metricData?.conversions}</h3>
                <p>Converted</p>
              </div>
            </div>

            {/* 3 */}
            <div className="ads-modal-details-two">
              <div className="ads-modal-detail">
                <h3>{props?.currentAnalytic?.metricData?.clicks}</h3>
                <p>Clicks</p>
              </div>
              {/* 4 */}

              <div className="ads-modal-detail">
                <h3>{props?.currentAnalytic?.metricData?.interactions}</h3>
                <p>Visits</p>
              </div>
            </div>

            {/* 5 */}
            <div
              className="ads-modal-details-two"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div className="ads-modal-detail">
                <h3>${props?.currentAnalytic?.metricData?.costMicros}</h3>
                <p>Cost</p>
              </div>
            </div>
          </div>
          {/* button div */}
          <div className="ads-modal-btn-main">
            {["top"]?.map((placement) => (
              <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={
                  <Tooltip id={`tooltip-${placement}`}>
                    {props?.currentAnalytic?.adData?.adGroupAd?.status ==
                      "ENABLED"
                      ? "Click to Pause your Ad"
                      : "Click to Run your Ad"}
                  </Tooltip>
                }
              >
                <button style={{ background: "none" }} onClick={HandleAdStatus}>
                  <button style={{ background: "none" }}>
                    {loading ? (
                      <Spinner />
                    ) : props?.currentAnalytic?.adData?.adGroupAd?.status ==
                      "ENABLED" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="120"
                        height="53"
                        viewBox="0 0 122 43"
                        fill="none"
                      >
                        <path
                          d="M122 34.3788C122 38.7373 118.467 42.2705 114.108 42.2705H14.804C11.1383 42.2705 7.95525 39.7464 7.11995 36.1771L0.921242 9.69004C-0.237495 4.73877 3.52026 8.39233e-05 8.6053 8.39233e-05H114.108C118.467 8.39233e-05 122 3.53331 122 7.89177V34.3788Z"
                          fill="white"
                        />

                        <text
                          x="16%"
                          y="64%"
                          font-family="Arial"
                          className="ad-modal-stop-btn"
                        >
                          Pause Ad
                        </text>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          x="76%"
                          y="26%"
                        >
                          <path
                            d="M9.99803 18.3338C14.6008 18.3338 18.332 14.6026 18.332 9.99986C18.332 5.39714 14.6008 1.66589 9.99803 1.66589C5.39531 1.66589 1.66406 5.39714 1.66406 9.99986C1.66406 14.6026 5.39531 18.3338 9.99803 18.3338Z"
                            stroke="#D30303"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.666 12.5002V7.49915"
                            stroke="#D30303"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.33203 12.5002V7.49915"
                            stroke="#D30303"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="120"
                        height="43"
                        viewBox="0 0 122 43"
                        fill="none"
                      >
                        <path
                          d="M122 34.3788C122 38.7373 118.467 42.2705 114.108 42.2705H14.804C11.1383 42.2705 7.95525 39.7464 7.11995 36.1771L0.921242 9.69004C-0.237495 4.73877 3.52026 8.39233e-05 8.6053 8.39233e-05H114.108C118.467 8.39233e-05 122 3.53331 122 7.89177V34.3788Z"
                          fill="white"
                        />

                        <text
                          x="16%"
                          y="64%"
                          font-family="Arial"
                          className="ad-modal-stop-btn"
                        >
                          Run Ad
                        </text>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          x="64%"
                          y="25%"
                        >
                          <path
                            d="M9.99803 18.334C14.6008 18.334 18.332 14.6027 18.332 9.99998C18.332 5.39726 14.6008 1.66602 9.99803 1.66602C5.39531 1.66602 1.66406 5.39726 1.66406 9.99998C1.66406 14.6027 5.39531 18.334 9.99803 18.334Z"
                            stroke="#03BB63"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.33203 6.66638L13.333 10.0004L8.33203 13.3344V6.66638Z"
                            stroke="#03BB63"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </svg>
                    )}
                  </button>
                </button>
              </OverlayTrigger>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const CustomModalForLoader = (props) => {
  const [loading, setLoading] = useState(false);

  

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // className="ads-modal-main1"
    >
      <div className="ads-modal-main2">
       <GoogleCampaignsLoader/>
      </div>
    </Modal>
  );
};


const Select = ({ selectHeading, options }) => {
  const dropdownRef = useRef();

  const handleDropdownClick = () => {
    dropdownRef.current.classList.add("clicked");
  };
  return (
    <select
      className="hone-campaign-dropdown"
      ref={dropdownRef}
      onClick={handleDropdownClick}
    >
      {/* <option
        value=""
        className="placeholder"
        style={"clicked" ? { display: "none" } : {}}
      >
        {selectHeading}
      </option> */}
      {options?.map((item, i) => (
        <>
          <option value={i + 1}>{item} </option>
        </>
      ))}
    </select>
  );
};

export default DashboardPage;
