import React, { useEffect, useState } from 'react'
import "./CampaignsLoader.css";
// import SubmissionImg from "../../../../../assets/images/submission-img.png";
// import LiveImg from "../../../../../assets/images/live-img.png";
import SubmissionImg from "../../../../assets/images/submission-img.png";
// import dashboard from "../../Auth Pages/Images/dashboard.png";
import dashboard from "../../Auth Pages/Images/dashboard.png";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from "js-cookie";

// import axios from "../../../../axios/auth";
import axios from "../../../axios/auth";

import LiveImg from "../../../../assets/images/live-img.png";
import { useDispatch } from 'react-redux';
import { addGoogleAnalytics } from '../Redux/AdsAnalyticsDetailsSlice';
export default function CampaignsLoader() {

  const [adType, setAdType] = useState()


  let token = Cookies.get("token");
  const dispatch = useDispatch();
  const fetchData = async () => {
    const url = `/get-all-ads`;
    try {
      const ads = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (ads?.data) {
        return ads.data;
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    // Getting the value of the 'adType' parameter
    let adParams = urlParams.get("adType");
    setAdType(adParams)
  }, [])

  useEffect(() => {

    const fetchDataAndSetData = async () => {
      try {
        const fetchedData = await fetchData();
        dispatch(addGoogleAnalytics(fetchedData?.data));
      } catch (error) {
        // console.log(error);
        // Handle errors, maybe show a message to the user
      }
    };

    fetchDataAndSetData();
  }, [token]);



  return (
    <div className='container'>
      <div className='responsive'
      
    >
      <div className='campaignsLoaderWrapper'>
        <div className="campains-loader-alignment">
          <div className="campains-details-alignment">
            <div className='submission-details-details'>
              <img src={SubmissionImg} alt='SubmissionImg' />
              <h2>Submission</h2>
            </div>
            <div class="progress-container">
              <div class="progress-bar"></div>
            </div>
            <div>
              <div className='loader-height-alignment'>
                <div className="step-round-alignment">
                  <div className="second-round">

                  </div>
                </div>
              </div>
              {["top"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>

                      Approve in 24 Hours
                    </Tooltip>
                  }
                >
                  <h2 style={{ cursor: "pointer" }}>

                    <span>Exoads Approval</span>

                  </h2>
                </OverlayTrigger>
              ))}
            </div>
            <div>
              <div className='loader-height-alignment'>
                <div className="step-round-alignment deactive-alignment">
                  <div className="second-round">
                  </div>
                </div>
              </div>
              <h2 >{adType}<br />
                Approval</h2>
            </div>
            <div className='live-img'>
              <img src={LiveImg} alt="LiveImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
