import "./PlatformSelection.css";

export const Facebooklogo = (
  <svg
    className="platform-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
  >
    <g filter="url(#filter0_d_1326_19414)">
      <g clip-path="url(#clip0_1326_19414)">
        <path
          d="M87 43C87 64.5391 69.5391 82 48 82C26.4609 82 9 64.5391 9 43C9 21.4609 26.4609 4 48 4C69.5391 4 87 21.4609 87 43Z"
          fill="white"
          stroke="#1877F2"
          stroke-width="2"
        />
        <path
          d="M56.9538 25.3011H61.0439V17.6859C58.5885 17.3293 55.9312 17.0939 53.4489 17.1275C51.7402 17.1544 50.0516 17.5648 48.5313 18.3586C45.5242 19.926 43.3715 22.8793 42.8199 26.2227C42.719 26.8147 42.6719 27.4134 42.6719 28.0121V35.661H34.9087V44.5073H42.6719V69.472H52.1976V44.5073H59.685L60.9094 35.661H52.1976V30.0505C52.1976 27.4202 54.3302 25.2876 56.9605 25.2876L56.9538 25.3011Z"
          fill="#1877F2"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1326_19414"
        x="0"
        y="0"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1326_19414"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1326_19414"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1326_19414">
        <rect x="8" y="3" width="80" height="80" rx="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const igLogo = (
  <svg
    className="platform-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
  >
    <g filter="url(#filter0_d_1326_19444)">
      <g clip-path="url(#clip0_1326_19444)">
        <path
          d="M87 43C87 64.5391 69.5391 82 48 82C26.4609 82 9 64.5391 9 43C9 21.4609 26.4609 4 48 4C69.5391 4 87 21.4609 87 43Z"
          fill="white"
          stroke="url(#paint0_linear_1326_19444)"
          stroke-width="2"
        />
        <path
          d="M37.4853 20.8181H58.9855C65.0461 20.8181 69.9801 25.7521 69.9801 31.8127V53.3128C69.9801 59.3734 65.0461 64.3075 58.9855 64.3075H37.4853C31.4248 64.3075 26.4907 59.3734 26.4907 53.3128V31.8127C26.4907 25.7521 31.4248 20.8181 37.4853 20.8181ZM58.9855 62.1702C63.8701 62.1702 67.8429 58.1974 67.8429 53.3128V31.8127C67.8429 26.9281 63.8701 22.9553 58.9855 22.9553H37.4853C32.6007 22.9553 28.628 26.9281 28.628 31.8127V53.3128C28.628 58.1974 32.6007 62.1702 37.4853 62.1702H58.9855Z"
          stroke="url(#paint1_linear_1326_19444)"
          stroke-width="2"
        />
        <path
          d="M36.8306 42.5628C36.8306 36.2735 41.9463 31.1578 48.2355 31.1578C54.5248 31.1578 59.6405 36.2735 59.6405 42.5628C59.6405 48.852 54.5248 53.9677 48.2355 53.9677C41.9463 53.9677 36.8306 48.852 36.8306 42.5628ZM38.9678 42.5628C38.9678 47.6761 43.1222 51.8305 48.2355 51.8305C53.3489 51.8305 57.5033 47.6761 57.5033 42.5628C57.5033 37.4494 53.3489 33.295 48.2355 33.295C43.1222 33.295 38.9678 37.4494 38.9678 42.5628Z"
          stroke="url(#paint2_linear_1326_19444)"
          stroke-width="2"
        />
        <path
          d="M58.7544 30.4538C58.7544 29.5799 59.4638 28.8705 60.3376 28.8705C61.2115 28.8705 61.9209 29.5799 61.9209 30.4538C61.9209 31.3277 61.2115 32.037 60.3376 32.037C59.4638 32.037 58.7544 31.3277 58.7544 30.4538Z"
          stroke="url(#paint3_linear_1326_19444)"
          stroke-width="2"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1326_19444"
        x="0"
        y="0"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1326_19444"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1326_19444"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1326_19444"
        x1="19.7188"
        y1="71.2879"
        x2="76.2879"
        y2="14.7188"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#F58529" />
        <stop offset="0.25" stop-color="#F58529" />
        <stop offset="0.5" stop-color="#DD2A7B" />
        <stop offset="0.75" stop-color="#8134AF" />
        <stop offset="1" stop-color="#515BD4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1326_19444"
        x1="32.1542"
        y1="58.6478"
        x2="64.3204"
        y2="26.4816"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#F58529" />
        <stop offset="0.25" stop-color="#F58529" />
        <stop offset="0.5" stop-color="#DD2A7B" />
        <stop offset="0.75" stop-color="#8134AF" />
        <stop offset="1" stop-color="#515BD4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1326_19444"
        x1="39.4649"
        y1="51.3355"
        x2="57.0083"
        y2="33.7921"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#F58529" />
        <stop offset="0.25" stop-color="#F58529" />
        <stop offset="0.5" stop-color="#DD2A7B" />
        <stop offset="0.75" stop-color="#8134AF" />
        <stop offset="1" stop-color="#515BD4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1326_19444"
        x1="58.5112"
        y1="32.2807"
        x2="62.1645"
        y2="28.6274"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.015625" stop-color="#F58529" />
        <stop offset="0.25" stop-color="#F58529" />
        <stop offset="0.5" stop-color="#DD2A7B" />
        <stop offset="0.75" stop-color="#8134AF" />
        <stop offset="1" stop-color="#515BD4" />
      </linearGradient>
      <clipPath id="clip0_1326_19444">
        <rect x="8" y="3" width="80" height="80" rx="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const googleLogo = (
  <svg
    className="platform-svg"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
  >
    <g filter="url(#filter0_d_1326_19426)">
      <g clip-path="url(#clip0_1326_19426)">
        <g filter="url(#filter1_d_1326_19426)">
          <path
            d="M48 83C70.0914 83 88 65.0914 88 43C88 20.9086 70.0914 3 48 3C25.9086 3 8 20.9086 8 43C8 65.0914 25.9086 83 48 83Z"
            fill="white"
          />
          <path
            d="M87 43C87 64.5391 69.5391 82 48 82C26.4609 82 9 64.5391 9 43C9 21.4609 26.4609 4 48 4C69.5391 4 87 21.4609 87 43Z"
            stroke="white"
            stroke-width="2"
          />
        </g>
        <path
          d="M41.0202 25.2885C41.4645 24.1222 42.0754 23.0485 42.9825 22.1784C46.6109 18.6426 52.6089 19.5312 55.0711 23.9741C56.9223 27.3433 58.8846 30.6385 60.7914 33.9708C63.9755 39.5059 67.1966 45.0411 70.3437 50.5948C72.991 55.2414 70.1216 61.1098 64.8641 61.9058C61.6429 62.3871 58.6254 60.9062 56.9593 58.0182C54.164 53.1495 51.3501 48.2808 48.5547 43.4305C48.4992 43.3195 48.4251 43.2269 48.3511 43.1343C48.0549 42.8937 47.9253 42.5419 47.7402 42.2272C46.4999 40.0428 45.2225 37.8768 43.9822 35.7109C43.1862 34.304 42.3531 32.9156 41.5571 31.5086C40.8351 30.2498 40.5019 28.8799 40.5389 27.4359C40.5944 26.6954 40.687 25.9549 41.0202 25.2885Z"
          fill="#3C8BD9"
        />
        <path
          d="M41.0202 25.2886C40.8536 25.955 40.7055 26.6215 40.6684 27.325C40.6129 28.88 41.0017 30.324 41.7792 31.6753C43.8155 35.1742 45.8519 38.6915 47.8697 42.2088C48.0549 42.5236 48.203 42.8383 48.3881 43.1345C47.2773 45.0597 46.1666 46.9665 45.0373 48.8918C43.4823 51.5761 41.9273 54.2789 40.3537 56.9632C40.2797 56.9632 40.2612 56.9261 40.2427 56.8706C40.2242 56.7225 40.2797 56.5929 40.3167 56.4448C41.0757 53.668 40.4463 51.2058 38.5395 49.0954C37.3733 47.8181 35.8923 47.0961 34.1891 46.8554C31.9677 46.5407 30.0054 47.1146 28.2467 48.503C27.932 48.7437 27.7283 49.0954 27.3581 49.2805C27.2841 49.2805 27.247 49.2435 27.2285 49.188C28.1171 47.6515 28.9872 46.1149 29.8758 44.5784C33.5412 38.2102 37.2066 31.842 40.8906 25.4922C40.9276 25.4182 40.9832 25.3627 41.0202 25.2886Z"
          fill="#FABC04"
        />
        <path
          d="M27.3028 49.2434C27.6545 48.9287 27.9878 48.5954 28.358 48.2992C32.8565 44.7449 39.6135 47.3181 40.5946 52.9458C40.8353 54.2972 40.7057 55.5931 40.2984 56.8889C40.2799 57 40.2614 57.0926 40.2244 57.2037C40.0578 57.4998 39.9097 57.8146 39.7246 58.1108C38.077 60.8321 35.6518 62.1835 32.4677 61.9798C28.8208 61.7207 25.9514 58.9808 25.4516 55.3524C25.2109 53.5938 25.5626 51.9462 26.4697 50.4281C26.6549 50.0949 26.877 49.7987 27.0807 49.4655C27.1732 49.3915 27.1362 49.2434 27.3028 49.2434Z"
          fill="#34A852"
        />
        <path
          d="M27.3027 49.2435C27.2287 49.3176 27.2287 49.4471 27.0991 49.4657C27.0806 49.3361 27.1546 49.262 27.2287 49.1695L27.3027 49.2435Z"
          fill="#FABC04"
        />
        <path
          d="M40.2245 57.2033C40.1505 57.0737 40.2245 56.9811 40.2986 56.8886C40.3171 56.9071 40.3541 56.9441 40.3726 56.9626L40.2245 57.2033Z"
          fill="#E1C025"
        />
      </g>
      <rect
        x="9"
        y="4"
        width="78"
        height="78"
        rx="39"
        stroke="#FDD600"
        stroke-width="2"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1326_19426"
        x="0"
        y="0"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1326_19426"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1326_19426"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1326_19426"
        x="0"
        y="0"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1326_19426"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1326_19426"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1326_19426">
        <rect x="8" y="3" width="80" height="80" rx="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
