import axios from "axios";
const API = axios.create({
  // baseURL: "http://localhost:4000/api/v1",
  baseURL: "https://backend.exoads.ai/api/v1",
});

export const nodeUrl = "https://backend.exoads.ai/api/v1";
// export const nodeUrl = "http://localhost:4000/api/v1";


const GoogleAPI_BASEURL = axios.create({
  baseURL: "http://13.234.18.229:5000",
});

export const ImageUrl = "https://backend.exoads.ai/api/v1/public";
// export const ImageUrl = "http://localhost:4000/api/v1/public";


//  const addImageUrl = "http://localhost:4000/api/v1/";
 const addImageUrl = "https://backend.exoads.ai/api/v1/";   

export const myImageUrl = 'https://backend.exoads.ai/api/v1/'


export const Customer_Id = "3665690394";

export default API;
export { GoogleAPI_BASEURL,addImageUrl };
