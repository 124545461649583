import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import styles from "../sidebar/AdminSidebar.module.css";
// ImageUpload.js
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios, { nodeUrl } from "../../../axios/auth";
import { ToastContainer, toast } from "react-toastify";
import { Button, Col, Modal, Row } from "react-bootstrap";
import API from "../../../axios/auth";
import loader from "../images/ZZ5H.gif" 
function ImageRepository() {
  const token = Cookies.get("token");
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [myImg, setMyImg] = useState([]);
  const handleFileChange = (event) => {
    const files = Array?.from(event.target.files);
    setSelectedFile(files);
  };
  const [isLoading,setIsLoading] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      // toast.error("Please select a file.");
      toast.error("Please select a file.", {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 

      //   alert("Please select a file.");
      return;
    }
    const formData = new FormData();
    selectedFile.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    try {
      const response = await axios.post("/imageupload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.message) {
        // alert("Image uploaded successfully!");
        // toast.success("Image uploaded successfully!");
        toast.success("Image uploaded successfully!", {
          className: 'custom-toast-success',
          hideProgressBar: true
        });
        getImage();
        // setSelectedFile([]);
      } else {
        // toast.error("Image upload failed.");
        toast.error("Image upload failed.", {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
        // alert("Image upload failed.");
      }
    } catch (error) {
      // console.error("Error uploading image:", error);
    }
  };
  async function getImage() {
    setIsLoading(true)
    try {
      const response = await axios.get("/get-image", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMyImg(response?.data?.data);
      setIsLoading(false)
    } catch (error) {
      // console.log(console.error);
    }
  }


  useEffect(() => {
    getImage();
  }, [token]);
  // Function to open the delete confirmation modal
  const openDeleteModal = (customerId) => {
    setDeleteImageId(customerId);
    setShowDeleteModal(true);
  };
  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteImageId(null);
    setShowDeleteModal(false);
  };
  // Function to handle delete customer action
  const handleDeleteCustomer = async () => {
    try {
      const response = await axios.post(`/imagedelete/${deleteImageId}`, "", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getImage();
      // toast.info("Image deleted successfully");
      toast.success("Image deleted successfully", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
      setMyImg(response?.data?.data);
    } catch (error) {
      // console.log(console.error);
    }
    closeDeleteModal();
  };
  return (
    <>
      <div className=" container-fluid">
        <div className="row ">
          <div className="col-sm-2 p-0">
            <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>
          </div>
          <div className="col-sm-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container">
              <div className="container">
                <div className="row">
                  <h4 className=" my-4 ps-0">
                    <b> Manage Image Repository </b>
                  </h4>
                  <div className="col-sm-10 bg-white">
                    <h2 className="my-2">Image Upload</h2>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label className="fw-bold me-2"> Select an image:</label>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>
                      <button type="submit" className="btn btn-primary mb-1" style={{ backgroundColor: "#47beb9", border: "none" }}>Upload</button>
                    </form>
                  </div>
                  <hr />
                  {isLoading?<div style={{width:"100%",height:"50vh", position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img src={loader} style={{width:"100px",height:"100px"}} alt="" /></div>: 
                  <div className="mt-2 p-0">
                    <Row>
                      {myImg?.map((data) => (
                        <Col sm={3} className="col-sm-3" key={data.id}>
                          <img
                            src={`${nodeUrl}/${data?.path}`}
                            className="img-fluid"
                            alt="Responsive image"
                            style={{ height: "200px", width: "200px" }}
                          />
                          <button className="btn btn-primary mt-2" style={{ backgroundColor: "#47beb9", border: "none" }}
                            onClick={() => openDeleteModal(data._id)}>
                            Delete
                          </button>
                        </Col>
                      ))}
                    </Row>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {/ Delete Confirmation Modal /} */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this customer?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteCustomer}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
}
export default ImageRepository;
