import React, { useEffect,  useState } from "react";
import "./Integration.css";
import {  Col, Container, Modal,  Row } from "react-bootstrap";

// import { Facebooklogo, igLogo, googleLogo, BingLogo } from "./SVG";
// import { Logo1, activeLogo3, logo2 } from "../Campaigns/NewCampaigns/SVG";

import { Link, useLocation, useNavigate } from "react-router-dom";

// import { NavItems } from "../../../UI-Components/ReusableComponents";
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import style from "../Plans/SuccessfullPurchase/AiCampaignSuccess.module.css";

import SearchAd from "../../Main Pages/Images/SearchAdModal.png";

import DisplayAd from "../../Main Pages/Images/DisplayAdModal.png";
import platformHeadline from "../../Main Pages/Images/platformHeadline.png";
import faceBookLogo from "../../Main Pages/Images/Facebook3D.png";
import Google3D from "../../Main Pages/Images/Google3D.png";
import bingLogo from "../../Main Pages/Images/Bing3D.png";

import IgLogo from "../../Main Pages/Images/Ig3D.png";

import Cookies from "js-cookie";
// import axios4 from "axios";
// import axios from "../../../../axios/auth"
import axios, { nodeUrl } from "../../../axios/auth"

import { toast } from "react-toastify";

// import FacebookChooseAccountPopup from "../GoogleCampaign/SideNavContainer/FacebookChooseAccountPopup";
import FacebookChooseAccountPopup from "../Campaigns/GoogleCampaign/SideNavContainer/FacebookChooseAccountPopup";

// import InstragramChooseAccountPopup from "../GoogleCampaign/SideNavContainer/InstragramChooseAccountPopup";
import InstragramChooseAccountPopup from "../Campaigns/GoogleCampaign/SideNavContainer/InstragramChooseAccountPopup";



// import ChooseAccountPopup from "../GoogleCampaign/SideNavContainer/ChooseAccountPopup";
import ChooseAccountPopup from "../Campaigns/GoogleCampaign/SideNavContainer/ChooseAccountPopup";
// import { LoginSocialGoogle } from "reactjs-social-login";
// import { Axios } from "axios";

const Integration = () => {
  const [show, setShow] = useState(false);
  // const target = useRef(null);
  const [facebookAccountModal, setFacebookAccountModal] = useState(false);
  const [instragramAccountModal, setInstragramAccountModal] = useState(false);
  const [googleAccountModal, setGoogleAccountModal] = useState(false);
  const [googleData, setGoogleData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [fbconnected, setFbConnected] = useState(false);
  // const [instaconnected, setInstaConnected] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [disconnect, setDisconnect] = useState(false);


  const token = Cookies.get("token");

  // to add border Radius in Modal
  useEffect(() => {
  
    getConnectdDetail()
    fetchConnectData()

    // Get the modal content element
    const modalContent = document.querySelector(".modal-content");

    // Check if modal content exists
    if (modalContent) {
      // Add border radius
      modalContent.style.borderRadius = "40px";

      // You can adjust the value as needed
    }

    // Clean up function (optional)
    return () => {
      // Remove border radius when component unmounts (optional)
      if (modalContent) {
        modalContent.style.borderRadius = null;
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once after the initial render


  async function fetchConnectData() {

    try {
      setIsLoading(true)
      const res = await axios.get(`${nodeUrl}/get-connect`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if(res?.data?.data){
        setIsLoading(false)

         if(res?.data?.data?.fbConnect){
          setFbConnected(true)

         }
         if(res.data?.data?.googleConnect){
          setConnected(true)

         }

      }
    } catch (error) {

      console.error("Error fetching customer:", error);
    }
  }

  async function updateConnectStatus(status) {
    try {
  const tkn= Cookies.get("token");
      
      const res = await axios.post(`${nodeUrl}/updateConnect`,{status:status}, {
        headers: {
          Authorization: `Bearer ${token ||tkn}`,
        },
      });
      
       
      if(res?.data?.Data){
        if(res?.data?.Data?.fbConnect){
         setFbConnected(true)

        }
        else{
          setFbConnected(false)
        }
        if(res.data?.Data?.googleConnect){
         setConnected(true)

        }
        else{
          setConnected(false)


        }
      }
    } catch (error) {
    setFbConnected(false)
  setConnected(false)


      console.error("Error fetching customer:", error);
    }
  }

  
  useEffect(() => {
    if (googleData?.email || googleData?.name) {
      
      googleSignin();
    }
  }, [googleData]); 

// useEffect(()=>{
//  let p = sessionStorage.getItem("connected")
//  if(p=="true"){
//   setConnected(true)
//  }
//  else{
//   setConnected(false)
//  }
 
// },[])


// useEffect(()=>{
//   let p = sessionStorage.getItem("fbconnected")
//   if(p=="true"){
//    setFbConnected(true)
//   }
//   else{
//    setFbConnected(false)
//   }
  
//  },[])

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const connected = queryParams.get('status');
    if(connected==='connected'){
      // sessionStorage.setItem("connected",true)
      setConnected(true)

      updateConnectStatus("googleConnect")
      toast.success('Account Connected Successfully!', {
        className: 'custom-toast-success',
        hideProgressBar: true,
        autoClose: 3000,
      });
      const newUrl = 'Integrations';
      window.history.replaceState(null, '', newUrl);
    
    }

    
  }, [location]);



  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const connected = queryParams.get('status');
    if(connected==='fbconnected'){
      // sessionStorage.setItem("fbconnected",true)
      setFbConnected(true)
      
      updateConnectStatus("fbConnect")

      toast.success('Account Connected Successfully!', {
        className: 'custom-toast-success',
        hideProgressBar: true,
        autoClose: 3000,
      });
      const newUrl = 'Integrations';
      window.history.replaceState(null, '', newUrl);
    }

    
  }, [location]);

  const googleSignin = async () => {

    const res = await axios.post("/connect-google", {
      email: googleData?.email,
      name: googleData?.name,
      token:googleData?.access_token

    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }} );

if(res?.data?.status === true){

  toast.success('Account Connected Successfully!', {
    className: 'custom-toast-success',
    hideProgressBar: true,
    autoClose: 3000,
  });



  getConnectdDetail()
}
  };


  const handleGoogleConnect = () => {
    // window.location.href = 'http://3.6.128.153:5000/v1/google/authorize?token=f5d1da64-9bbd-4fe4-8ad5-bfb168c44388&username=exoadsadmin';
    
    // window.location.href = 'http://localhost:5000/v1/google/authorize?token=f5d1da64-9bbd-4fe4-8ad5-bfb168c44388&username=exoadsadmin';
    window.location.href = 'https://google.exoads.ai/v1/google/authorize?token=f5d1da64-9bbd-4fe4-8ad5-bfb168c44388&username=exoadsadmin';
  }
 
  
  const handleFacebookConnect  = () => {
    // window.location.href = 'http://127.0.0.1:8000//v1/facebook/authorize?token=tgfhfh&username=hari';
    window.location.href = 'https://facebook.exoads.ai/v1/facebook/authorize?token=facebook&username=hari';

    // window.location.href = 'http://3.6.128.153:8000/v1/facebook/authorize?token=tgfhfh&username=hari';

  }

  const getConnectdDetail = async () => {
    try{

    const res = await axios.get("/get-connect-google",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }} );

if(res?.data?.data !=null){

      setConnected(true)
  }
  else{
    // setConnected(false)
  }
}catch{

}
}


  const disConnectgoogleSignin = async () => {

    setIsClicked(true);
      setDisconnect(true);
      updateConnectStatus("disconnectGoogle")

      // sessionStorage.removeItem("connected")
    setTimeout(() => {
      setConnected(false)
      setIsClicked(false);
    setDisconnect(false);
  
    toast.error('Account Disconnected Successfully!', {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    }, 2000);


  
  };

  const disConnectFacebookSignin = async () => {

    setIsClicked(true);

      updateConnectStatus("disconnectFb")
      // sessionStorage.removeItem("fbconnected")
    setTimeout(() => {
      setFbConnected(false)
      setIsClicked(false);
  
    toast.error('Account Disconnected Successfully!', {
      className: 'custom-toast-error',
        hideProgressBar: true
    });
    }, 2000);
  }

  // const navigate = useNavigate();

  // const handleChooseBtn = (url) => {
  //   navigate(url);
  // };

  return (
    <Container
      fluid
      style={{ margin: "0px 10px" }}
      className="platfrom-selection-scroller"
    >
      <Row className="platfromSelection-row-alignment ">
        <Col style={{ padding: "0px" }}>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              padding: "0px",
              justifyContent: "space-between",
            }}
          >
            <div className="choose-platform-headline">
              <div>
               
                <h5 style={{fontWeight:"bolder"}}>
           <strong> Connect your own AD Account to
           <br /> EXOADS</strong>
          </h5>
              </div>
              <img src={platformHeadline} alt="" />
            </div>
           
            <div
              className="Platform-div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              {/* faceBook */}
              <div className="platform-facebook-main">
                <img src={faceBookLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Facebook Ads</h5>
                  <p>
                    Leverage Facebook ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/facebookcampaign")
                    // }

                    // onClick={() => setFacebookAccountModal(true)}
                  >
               {
  isLoading ? (
    <div className="d-flex justify-content-center align-items-center">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  ) : (
    <>
      {fbconnected ? (
        <>
          {/* Disconnect Button */}
          <svg
            className="conSvg"
            onClick={disConnectFacebookSignin}
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
          >
            <path
              d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
              fill="white"
            />
            <text
              x="15%"
              y="63%"
              fontFamily="Arial"
              fontSize="20"
              fill="#000"
              className="ads-svg-btn"
            >
              Disconnect
            </text>
          </svg>

          {/* Connected Button */}
          <svg
            className="disSvg"
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
            style={{ cursor: "default" }} 
          >
            <path
              d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
              fill="white"
            />
            <text
              x="16%"
              y="63%"
              fontFamily="Arial"
              fontSize="22"
              fill="#000"
              className="ads-svg-btn"
            >
              Connected
            </text>
          </svg>
        </>
      ) : isClicked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
          style={{ cursor: "not-allowed" }}
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="6%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Disconnecting...
          </text>
        </svg>
      ) : (
        <svg
          onClick={handleFacebookConnect}
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="25%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Connect
          </text>
        </svg>
      )}
    </>
  )
}



                  </div>
                </div>
              </div>
              {/* Ig */}
              <div className="platform-ig-main">
                <img src={IgLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Instagram Ads</h5>
                  <p>
                    Leverage Instagram ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/instagramcampaign")
                    // }
                    // onClick={() => setInstragramAccountModal(true)}
                  >
{
  isLoading ? (
    <div className="d-flex justify-content-center align-items-center">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  ) : (
    <>
      {fbconnected ? (
        <>
          {/* Disconnect Button */}
          <svg
            className="conSvg"
            onClick={disConnectFacebookSignin}
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
          >
            <path
              d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
              fill="white"
            />
            <text
              x="15%"
              y="63%"
              fontFamily="Arial"
              fontSize="20"
              fill="#000"
              className="ads-svg-btn"
            >
              Disconnect
            </text>
          </svg>

          {/* Connected Button */}
          <svg
            className="disSvg"
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
            style={{ cursor: "default" }} 
          >
            <path
              d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
              fill="white"
            />
            <text
              x="16%"
              y="63%"
              fontFamily="Arial"
              fontSize="22"
              fill="#000"
              className="ads-svg-btn"
            >
              Connected
            </text>
          </svg>
        </>
      ) : isClicked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
          style={{ cursor: "not-allowed" }}
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="6%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Disconnecting...
          </text>
        </svg>
      ) : (
        <svg
          onClick={handleFacebookConnect}
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="25%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Connect
          </text>
        </svg>
      )}
    </>
  )
}




                  </div>
                </div>
              </div>
              {/* Google */}
              <div className="platform-google-main">
                <img src={Google3D} alt="" />
                <div className="platform-facebook">
                  <h5>Google Ads</h5>
                  <p>
                    Leverage Google ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>

                  
                  <Link  style={{ alignSelf: "center", cursor: "pointer" }}>




                  {
  isLoading ? (
    <div className="d-flex justify-content-center align-items-center">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  ) : (
    <>
      {connected ? (
        <>
          {/* Disconnect Button */}
          <svg
            className="conSvg"
            onClick={disConnectgoogleSignin}
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
          >
            <path
              d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
              fill="white"
            />
            <text
              x="15%"
              y="63%"
              fontFamily="Arial"
              fontSize="20"
              fill="#000"
              className="ads-svg-btn"
            >
              Disconnect
            </text>
          </svg>

          {/* Connected Button */}
          <svg
            className="disSvg"
            xmlns="http://www.w3.org/2000/svg"
            width="101"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
            style={{ cursor: "default" }} 
          >
            <path
              d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
              fill="white"
            />
            <text
              x="16%"
              y="63%"
              fontFamily="Arial"
              fontSize="22"
              fill="#000"
              className="ads-svg-btn"
            >
              Connected
            </text>
          </svg>
        </>
      ) : disconnect ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
          style={{ cursor: "not-allowed" }}
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="6%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Disconnecting...
          </text>
        </svg>
      ) : (
        <svg
          onClick={handleGoogleConnect}
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="49"
          viewBox="0 0 141 49"
          fill="none"
        >
          <path
            d="M0 5C0 2.23858 2.23858 0 5 0H121.976C124.089 0 125.974 1.32835 126.685 3.31832L140.614 42.3183C141.777 45.5745 139.363 49 135.905 49H5C2.23858 49 0 46.7614 0 44V5Z"
            fill="white"
          />
          <text
            x="25%"
            y="63%"
            fontFamily="Arial"
            fontSize="20"
            fill="#000"
            className="ads-svg-btn"
          >
            Connect
          </text>
        </svg>
      )}
    </>
  )
}







                  </Link>
                  
                </div>
              </div>

              {/* Bing */}
              <div className="platform-Bing-main">
                <img src={bingLogo} alt="" />
                <div className="platform-facebook">
                  <h5>Bing Ads</h5>
                  <p>
                    Leverage Bing ads for targeted reach, precise analytics,
                    and scalable campaigns to boost brand visibility and drive
                    conversions effectively.
                  </p>
                  <div
                    style={{ alignSelf: "center"}}
                    // onClick={() =>
                    //   handleChooseBtn("/main/campaign/bingcampaign")
                    // }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="49"
                      viewBox="0 0 141 49"
                      fill="none"
                    >
                      <path
                        d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
                        fill="white"
                      />
                      <text
                        x="14%"
                        y="63%"
                        font-family="Arial"
                        font-size="20"
                        fill="#000"
                        className="ads-svg-btn"
                      >
                        Coming Soon
                      </text>
                    </svg>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </Col>
      </Row>
      <GooglePlatformSelectionModal
        show={show}
        setModal={setShow}
        onHide={() => setShow(false)}
      />
      {facebookAccountModal && (

        <FacebookChooseAccountPopup setFacebookAccountModal={setFacebookAccountModal} />
      )}

      {instragramAccountModal && (
        <InstragramChooseAccountPopup setInstragramAccountModal={setInstragramAccountModal} />
      )}

      {googleAccountModal && (
        <ChooseAccountPopup />
      )}
    </Container>
  );
};

const AddPlatForm = ({ title, logo, color }) => {
  return (
    <div className="single-platform">
      <div className="" style={{ margin: "4px 5px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          style={{ visibility: "hidden" }}
        >
          <path
            d="M11.9999 21.9994C17.5227 21.9994 21.9998 17.5223 21.9998 11.9995C21.9998 6.47674 17.5227 1.99963 11.9999 1.99963C6.47711 1.99963 2 6.47674 2 11.9995C2 17.5223 6.47711 21.9994 11.9999 21.9994Z"
            stroke="#B2BBC6"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="main-platfrom-div " style={{ background: color }}>
        {logo}
        <div className="platform-inner-container">
          <span className="platform-inner-heading">{title}</span>
          <p className="platform-inner-para">
            With Google Search Ads you can reach a large audience that is
            specifically searching for a certain topic and might be in a buying
            mood already.
          </p>
        </div>
      </div>
    </div>
  );
};

const GooglePlatformSelectionModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Container fluid className={`${style.AiSuccess}   ads-select-modal`}>
          <Row style={{ width: "100%", padding: "20px" }}>
            <h3 className="ad-select-modal-headline">Choose Your Ad Format</h3>
            <Row className="ad-select-modal-container">
              <BoxContainer
                image={SearchAd}
                title={"Search Ad"}
                link={"/main/campaign/googlecompaign"}
                para={
                  "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
                }
              />
              <h6 className="ad-select-divider" />
              <BoxContainer
                image={DisplayAd}
                link={"/main/campaign/googlecompaign2"}
                title={"Display Ad"}
                para={
                  "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
                }
              />
            </Row>
          </Row>
        </Container>
      </Modal>

      {/* <GoogleAccountSelect /> */}
      {/* <FacebookAccountSelect /> */}
      {/* <InstragramAccountSelect /> */}
    </>
  );
};

const BoxContainer = ({ image, title, para, link }) => {
  const token = Cookies.get("token");
  // check credits
  const navigate = useNavigate();

  const checkcredits = async () => {


    try {
      const res = await axios.post("/check-credits", { transactionType: "adFlow" }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (res?.data?.status === true) {
        // dispatch(addWebsiteUrl(websitename));

        navigate(link);
        // sessionStorage.setItem("websiteUrl", websitename);
      } else {
        toast.error(res.data.message)
      }
    } catch (error) {

    }
  };

  return (
    <Col lg={5}>
      <Link className="ad-select-solo" onClick={() => checkcredits()}>
        <img src={image} alt="megaPhone" />
        <div>
          <h5>{title}</h5>
          <p>{para}</p>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="49"
            viewBox="0 0 141 49"
            fill="none"
          >
            <path
              d="M141 5C141 2.23858 138.761 0 136 0H19.0236C16.9105 0 15.0256 1.32835 14.3149 3.31832L0.386322 42.3183C-0.776627 45.5745 1.63736 49 5.09503 49H136C138.761 49 141 46.7614 141 44V5Z"
              fill="white"
            />
            <text
              x="28%"
              y="63%"
              font-family="Arial"
              font-size="20"
              className="ad-select-btn"
            >
              Choose
            </text>
          </svg>
        </div>
      </Link>
    </Col>
  );
};

export default Integration;

