import React, { useEffect, useState } from "react";
import style from "./AiCampaignSuccess.module.css";
import { Col, Container, Row } from "react-bootstrap";
// import Click from "./Image/cursorClick.png";
import megaPhone from "./Image/MegaPhone.jpg";
import BotImage from "./Image/BotImage.jpg";
import ClickArrow from "./Image/ClickArrow.jpg";

import axios from "../../../../axios/auth";

import { successBtn } from "../Svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { adduserdata } from "../../../../../Redux/login/loginSlice";

const AiCampaignSuccess = () => {
  const [mode, setMode] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userToken = Cookies.get("token");

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const res = await axios.get("/getUser", {
        headers: {
          Authorization: `Bearer ${userToken}`, // Correct spelling
        },
      });

      dispatch(adduserdata(res.data.data[0]));

      if (res.data.data[0]?.plan) {
        const length = res.data.data[0].plan.length;
        setMode(res.data.data[0].plan[length - 1]?.subscriptionMode);
      }
      if (res.data.data.role == "admin") {
        navigate("/admin");
      }
    } catch (e) {
      if (e.response.data.message === "User Unauthorised") {
        navigate("/login");
        // return <Navigate to="/login" />;
      } else if (e.response.statusText === "Unauthorized") {
        navigate("/login");
      }
    }
  };
  return (
    <>
      {mode === "aiCreative" && (
        <Container fluid className={style.AiSuccess}>
          <Row style={{ width: "100%" }}>
            <Row>
              <div className={style.aiRow}>
                <img src={ClickArrow} alt="arrow" />
                <label>Select the Process </label>
              </div>
            </Row>
            <Row className={style.aiRow2}>
              <BoxContainer
                image={BotImage}
                link={"/main/brand"}
                title={"Creative"}
                para={
                  "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
                }
              />
            </Row>
          </Row>
        </Container>
      )}

      {mode === "aiCampaign" && (
        <Container fluid className={style.AiSuccess}>
          <Row style={{ width: "100%" }}>
            <Row>
              <div className={style.aiRow}>
                <img src={ClickArrow} alt="arrow" />
                <label>Start Creating </label>
              </div>
            </Row>
            <Row className={style.aiRow2}>
              <BoxContainer
                image={megaPhone}
                title={"Campaign"}
                link={"/main/campaign"}
                para={
                  "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
                }
              />
              <BoxContainer
                image={BotImage}
                link={"/main/brand"}
                title={"Creative"}
                para={
                  "Omnis a ullam. Commodi placeat vero deserunt voluptates unde aut. Sint illum rerum recusandae est sed est. Tempora vel assumenda eveniet."
                }
              />
            </Row>
          </Row>
        </Container>
      )}
    </>
  );
};

const BoxContainer = ({ image, title, para, link }) => {
  return (
    <Col lg={3}>
      <Link to={link} className={style.aiCol}>
        <img src={image} alt="megaPhone" />
        <div>
          <h5>{title}</h5>
          <p>{para}</p>
        </div>
        <button>{successBtn}</button>
      </Link>
    </Col>
  );
};
export default AiCampaignSuccess;
