import React, { useEffect, useState } from "react";
import "./Ad.css";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import {
  AdGraph,
  AdGreenDot,
  AdRedDot,
  NewGraph,
  TableArrow,
} from "../.PagesSVG";
import { AdRunItems } from "../Campaigns/Performance Campaigns/Ad Traffice Only/AdTrafficeOnly";
import {
  GreenDotIcon,
  PauseIcon,
  PlayIcon,
  RedDotIcon,
} from "../Campaigns/Performance Campaigns/Ad Traffice Only/SVg";
import adsBg from "../../Main Pages/Images/adsBg.png";
import adsBg2 from "../../Main Pages/Images/adsBg2.png";

import adsMainBg from "../../Main Pages/Images/adsMainBg.png";
import Google3D from "../../Main Pages/Images/Google3D.png";
import Bing3D from "../../Main Pages/Images/Bing3D.png";
import Facebook3D from "../../Main Pages/Images/Facebook3D.png";
import Ig3D from "../../Main Pages/Images/Ig3D.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios, { addImageUrl } from "../../../axios/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CampaignDetailSlice, { addFaceBookDeatils } from "../../../../Redux/CampaignDetailSlice";
import { addGoogleDisplayDeatils, addGoogleSearchDeatils, addInstagramDeatils } from "../Redux/CampaignDetailSlice";
import { addWebsiteDetailForGoogle, addWebsiteDetailForGoogle2 } from "../Redux/AiSuggestion";
import { AddTargetingDetails } from "../../../../Redux/GoogleSearchSlice";
import { AddTargetingDetailsFacebook} from "../../../../Redux/FacebbokAdsSlice";
import { addFaceBookSuggestion } from "../../../../Redux/AiSuggestion";
import comingsoon from "./comingsoon.jpg"
import noData from "./nodata.jpg"
import { AddTargetingDetailsInstagram } from "../../../../Redux/InstagramAdSlice";


const TableData = [
  {
    id: "01",

    platform: "Google",
    CampaignName: "Campaign 1",
    StartDate: "01-01-2023 | 17:16",
    EndDate: "01-01-2023 | 17:16",
    increasing: true,
  },
  {
    id: "02",
    platform: "Facebook",
    CampaignName: "Campaign 1",
    StartDate: "01-01-2023 | 17:16",
    EndDate: "01-01-2023 | 17:16",
    increasing: false,
  },
  {
    id: "03",
    platform: "Google",
    CampaignName: "Campaign 1",
    StartDate: "01-01-2023 | 17:16",
    EndDate: "01-01-2023 | 17:16",
    increasing: true,
  },
  {
    id: "04",
    platform: "Facebook",
    CampaignName: "Campaign 1",
    StartDate: "01-01-2023 | 17:16",
    EndDate: "01-01-2023 | 17:16",
    increasing: false,
  },
  {
    id: "05",
    platform: "Google",
    CampaignName: "Campaign 1",
    StartDate: "01-01-2023 | 17:16",
    EndDate: "01-01-2023 | 17:16",
    increasing: true,
  },
];

const Add = () => {
  const [optionIconState, setOptionIconState] = useState("A");
 const [data,setData] = useState([])
 const [showDeleteModal, setShowDeleteModal] = useState(false);
 const [showDuplicateModal, setShowDuplicateModal] = useState(false);
 const [duplicateData,setdupliacteData] = useState()
const [deleteId,setdeleteId] = useState()
const [runningAd, setRunningAd] = useState([]);
const [ fbData,setFbData] = useState([])
const[fbDuplicateData,setFbDuplicateData] = useState([])
const [showFbDuplicateModel,setshowFBDuplicateModel] = useState(false)
const[instaDuplicateData,setInstaDuplicateData] = useState([])
const [showInstaDuplicateModel,setshowInstaDuplicateModel] = useState(false)
const { GoogleAnalytics } = useSelector((state) => state.AdsAnalyticsDetail);
const [showFbDeleteModel,setShowfbDeleteModal] = useState("");
const [instaData,setInstaData] = useState("");
const [instaDeleteModal,setinstaDeleteModal] = useState(false)
const [isLoading,setIsLoading] = useState(true)

const dispatch = useDispatch()
const navigate = useNavigate()
  const token = Cookies.get("token");
  async function fetchGoogleData() {
      try {
        const res = await axios.get("/get-pending-ads", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(res?.data){
          setIsLoading(false)
          setData(res?.data?.data);

        }
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching customer:", error);
      }
    }

    async function fetchFacebookData() {
      try {
        const res = await axios.get("/get-fb-pending-ads", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(res?.data){
        setIsLoading(false)
        setFbData(res?.data?.data);
        }
      } catch (error) {
        setIsLoading(false)

        console.error("Error fetching customer:", error);
      }
    }


async function fetchInstagramData() {
  try {
    const res = await axios.get("/get-insta-pending-ads", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(res?.data){
   
    setIsLoading(false)
    setInstaData(res?.data?.data);
       
  }
  } catch (error) {
    setIsLoading(false)

    console.error("Error fetching customer:", error);

  }
}
  
    useEffect(()=>{
      fetchGoogleData()
       fetchFacebookData()
       fetchInstagramData()
    },[])
    


    const handleData = async() =>{
      setOptionIconState("A")
     await fetchGoogleData()
    }
  
    const handleFbData = async() =>{
      setOptionIconState("B")
     await fetchFacebookData()
    }


  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setdeleteId(null)
    setShowDeleteModal(false);
    setShowfbDeleteModal(false)
    setinstaDeleteModal(false)
  };

    // Function to close the delete confirmation modal
    const closeDupliacteModal = () => {
      setdeleteId(null)
      setshowFBDuplicateModel(false)
      setShowDuplicateModal(false);
      setshowInstaDuplicateModel(false)
    };

  // Function to handle delete customer action
  const handleDeleteCustomer = async () => {
    
    const res = await axios.get(
      `/delete-pending-ads/${deleteId}`,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // toast.error("Data deleted succefully");
      
    toast.error("Data deleted succefully", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    fetchGoogleData();
    closeDeleteModal();
  };





  // Function to handle delete customer action
  const handleFacebookDeleteCustomer = async () => {
    const res = await axios.get(
      `/fb-ads-delete/${deleteId}`,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    // toast.error("Data deleted succefully");
      
    toast.error("Data deleted succefully", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    fetchFacebookData();
    closeDeleteModal();
  };


  const handleInstaDeleteCustomer = async () => {
    const res = await axios.get(
      `/insta-ads-delete/${deleteId}`,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    // toast.error("Data deleted succefully");
      
    toast.error("Data deleted succefully", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    fetchInstagramData();
    closeDeleteModal();
  };


// Function to handle delete customer action
const handleDuplicateCustomer = async () => {
  const res = await axios.post(
    `/create-pending-ads`,
    duplicateData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // toast.success("Data duplicated succefully");
  toast.success("Data duplicated succefully", {
    className: 'custom-toast-success',
    hideProgressBar: true
  });

  fetchGoogleData();
  closeDupliacteModal();
};



const handleDuplicateFacebookCustomer = async () => {
  const res = await axios.post(
    `/create-fb-pending-ads`,
    fbDuplicateData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // toast.success("Data duplicated succefully");
  toast.success("Data duplicated succefully", {
    className: 'custom-toast-success',
    hideProgressBar: true
  });

  fetchFacebookData();
  closeDupliacteModal();
};


const handleDuplicateInstaCustomer = async () => {
  const res = await axios.post(
    `/create-insta-pending-ads`,
    instaDuplicateData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  // toast.success("Data duplicated succefully");
  toast.success("Data duplicated succefully", {
    className: 'custom-toast-success',
    hideProgressBar: true
  });

  fetchInstagramData();
  closeDupliacteModal();
};


const handleNavigate = (data) =>{
if(data.type=='search'){

  sessionStorage.setItem("pendingAdData",JSON.stringify(data))
sessionStorage.removeItem("websiteUrl")
localStorage.removeItem("persist:root")
dispatch(addWebsiteDetailForGoogle(""))
dispatch(addWebsiteDetailForGoogle2(""))
  // dispatch(addGoogleSearchDeatils(""))
  dispatch(
    addGoogleSearchDeatils({
      headline: data?.GoogleSearchHeadline,
      descriptions: data?.GoogleSearchDescription,

    })
  );
  dispatch(AddTargetingDetails({
    keywords:data?.keywords
  }))

 
  
  navigate("/main/campaign/googlecompaign/google-add-1/content")
}
else if(data.type == 'Display'){
  sessionStorage.setItem("pendingAdData",JSON.stringify(data))
  dispatch(addGoogleDisplayDeatils(""))
sessionStorage.removeItem("websiteUrl")
  navigate("/main/campaign/googlecompaign2/google-add-1/content")

}
}

const handlFacebookNavigate = (data)=>{
  
  sessionStorage.setItem("websiteUrl",data.WebsiteUrl)
  sessionStorage.setItem("websiteTitle",data.websiteTitle)
  sessionStorage.setItem("websiteDetail",data.websiteDetail)
  sessionStorage.setItem("account_id",data.account_id)
  sessionStorage.setItem("adId",data?._id)
  sessionStorage.removeItem("facebookAdsComplateData")
  dispatch(addFaceBookDeatils({ headline: '',
    descriptions: '',
    image: '',}))

    dispatch(
      addFaceBookSuggestion({
        descriptions: '',
        headlines: '',
        keyword: '',
      })
    );

    dispatch(AddTargetingDetailsFacebook({

    }))

    let transformeddata = [
      {headline: data?.headline[0]},
      {description: data?.description[0]},
      {hash:data?.hash,
        imagePath:data.image
      },
      {cities:data.Cities},
      {countryCode:data.CountryCode
      },
      {country:data.country},
      {keywords:data.keywords},
      {minAge:data.minAge},
      {maxAge:data.maxAge},
      {gender:data.gender},
      {Amount:data.Amount}
    ]
sessionStorage.setItem("fbData",JSON.stringify(transformeddata))
navigate("/main/campaign/facebookcampaign/facebook-add-1/content")

}


const handlInstaNavigate = (data)=>{
  
  sessionStorage.setItem("websiteUrl",data.WebsiteUrl)
  sessionStorage.setItem("websiteTitle",data.websiteTitle)
  sessionStorage.setItem("websiteDetail",data.websiteDetail)
  sessionStorage.setItem("account_id",data.account_id)
  sessionStorage.setItem("adId",data?._id)
  sessionStorage.removeItem("instagramAdsComplateData")
  dispatch(addInstagramDeatils({ headline: '',
    descriptions: '',
    image: '',}))

    dispatch(
      addInstagramDeatils({
        descriptions: '',
        headlines: '',
        keyword: '',
      })
    );

    dispatch(AddTargetingDetailsInstagram({

    }))

    let transformeddata = [
      {headline: data?.headline[0]},
      {description: data?.description[0]},
      {hash:data?.hash,
        imagePath:data.image
      },
      {cities:data.Cities},
      {countryCode:data.CountryCode
      },
      {country:data.country},
      {keywords:data.keywords},
      {minAge:data.minAge},
      {maxAge:data.maxAge},
      {gender:data.gender},
      {Amount:data.Amount}
    ]
sessionStorage.setItem("instaData",JSON.stringify(transformeddata))
navigate("/main/campaign/instagramcampaign/instagram-add-1/content")

}



const handleNavigateToAd = (data) =>{
  if(data.type=='search'){
    sessionStorage.setItem("pendingAdData",JSON.stringify(data))
  sessionStorage.removeItem("websiteUrl")
  localStorage.removeItem("persist:root")
  dispatch(addWebsiteDetailForGoogle(""))
  dispatch(addWebsiteDetailForGoogle2(""))
    // dispatch(addGoogleSearchDeatils(""))
    dispatch(
      addGoogleSearchDeatils({
        headline: data?.GoogleSearchHeadline,
        descriptions: data?.GoogleSearchDescription,
  
      })
    );
    dispatch(AddTargetingDetails({
      keywords:data?.keywords
    }))
    
    navigate("/main/campaign/googlecompaign/google-add-1/funding")
  }
  else if(data.type == 'Display'){
    sessionStorage.setItem("pendingAdData",JSON.stringify(data))
    dispatch(addGoogleDisplayDeatils(""))
  sessionStorage.removeItem("websiteUrl")
    navigate("/main/campaign/googlecompaign2/google-add-1/funding")
  
  }
  }

sessionStorage.removeItem("GoogleDisplayImages")
  const openDeleteModal = (id) => {
    setdeleteId(id)
    setShowDeleteModal(true);
  };


  const openFbDeleteModal = (id) => {
    setdeleteId(id)
    setShowfbDeleteModal(true);
  };


  const openInstaDeleteModal = (id) => {
    setdeleteId(id)
    setinstaDeleteModal(true);
  };

  const openDuplicateModal = (data) => {
    setdupliacteData(data)
    setShowDuplicateModal(true);
  };


  const openDuplicateModalFacebook = (data) => {
    setFbDuplicateData(data)
    setshowFBDuplicateModel(true)
  };

  const openDuplicateModalInsta = (data) => {
    setInstaDuplicateData(data)
    setshowInstaDuplicateModel(true)
  };

  useEffect(()=>{
    let enableAd =[]
    GoogleAnalytics?.map((item, i) => {
    if(item?.adData?.adGroup?.status == "ENABLED"){
    
      enableAd.push(item?.adData)
    }
    
    setRunningAd(enableAd)
    }
    
    )
    
    
  },[])


  return (
    <Container
      fluid
      style={{ background: "" }}
      className="adContainer1 Adrun-main-container "
    >
      <Row
        style={{
          gap: "20px",
          background: `url(${adsBg2})`,
          backgroundSize: "100% 106%",
          objectFit: "contain",
          backgroundRepeat: "no-repeat",
          padding: "24px 20px",
        }}
      >
        <Row style={{ gap: "2 0px" }}>
          <p className="ad-heading">Ad Run</p>
          <div
            className="ad-mainConatiner"
            style={{
              background: `url(${adsMainBg})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div className="ad-ActiveAds">
              <div className="ad-ActiveAds-div">
                <h4>Active Ads</h4>
                <p>{runningAd?.length} <div className="roundBox   ">
                </div></p>
              </div>

              <div className="ad-ActiveAds-div">
                <h4>InActive Ads</h4>
                <p style={{ color: "#C2C8CE" }}>{data?.length} <div className="roundBox second  ">
                </div></p>
              </div>
            </div>
            <div className="divBorder"></div>

            <div className="ad-ActiveAds-Platform" onClick={() => handleData()}>
              <h4>Google Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <span>{data?.length}</span>
                <img style={{ width: "60px" }} src={Google3D} alt="" />
              </div>
            </div>

            <div className="ad-ActiveAds-Platform" onClick={() => handleFbData()}>
              <h4>Facebook Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <span>{fbData.length}</span>
                <img style={{ width: "50px" }} src={Facebook3D} alt="" />
              </div>
            </div>

            <div className="ad-ActiveAds-Platform" onClick={() => setOptionIconState("C")}>
              <h4>Insta Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <span>{instaData?.length}</span>
                <img style={{ width: "50px" }} src={Ig3D} alt="" />
              </div>
            </div>

            <div className="ad-ActiveAds-Platform" onClick={() => setOptionIconState("D")}>
              <h4>Bing Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <span>0</span>

                <img style={{ width: "45px" }} src={Bing3D} alt="" />
              </div>
            </div>


          </div>
        </Row>





      </Row>

{/* Delete Confirmation facebook Modal */}
<Modal  show={showFbDeleteModel} onHide={closeDeleteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleFacebookDeleteCustomer}>
                Delete
              </button>
            </Modal.Footer>
            </div>
          </Modal>


{/* Delete Confirmation insta Modal */}
<Modal  show={instaDeleteModal} onHide={closeDeleteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleInstaDeleteCustomer}>
                Delete
              </button>
            </Modal.Footer>
            </div>
          </Modal>


 {/* Delete Confirmation Modal */}
          <Modal  show={showDeleteModal} onHide={closeDeleteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleDeleteCustomer}>
                Delete
              </button>
            </Modal.Footer>
            </div>
          </Modal>


 {/* Duplicate Confirmation facebook Modal */}
 <Modal  show={showFbDuplicateModel} onHide={closeDupliacteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Duplication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to duplicate this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDupliacteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleDuplicateFacebookCustomer}>
                Confirm
              </button>
            </Modal.Footer>
            </div>
          </Modal>


 {/* Duplicate Confirmation insta Modal */}
 <Modal  show={showInstaDuplicateModel} onHide={closeDupliacteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Duplication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to duplicate this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDupliacteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleDuplicateInstaCustomer}>
                Confirm
              </button>
            </Modal.Footer>
            </div>
          </Modal>


           {/* Duplicate Confirmation Modal */}
           <Modal  show={showDuplicateModal} onHide={closeDupliacteModal}>
            <div className="ads-delete-modal">
            <Modal.Header closeButton>
              <Modal.Title>Confirm Duplication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to duplicate this Ad?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDupliacteModal}>
                Cancel
              </Button>
              <button style={{ width:80, height:40, borderRadius:10,color:"white",   background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)"}} onClick={handleDuplicateCustomer}>
                Confirm
              </button>
            </Modal.Footer>
            </div>
          </Modal>
      {/* <Row>
        <Table responsive striped>
          <thead style={{ paddingBottom: "10px" }}>
            <tr style={{}}>
              <TableHeading
                title={"Status"}
                borderRadius={"10px 0px 0px 10px"}
              />

              <TableHeading title={"ID"} />
              <TableHeading title={"Campaign Name"} />

              <TableHeading title={"Platform"} />
              <TableHeading title={"Campaign Name"} />
              <TableHeading title={"Start Date"} />
              <TableHeading title={"End Date"} />
              <TableHeading
                title={"Control"}
                Display={false}
                borderRadius={"0px 10px 10px 0px"}
              />
            </tr>
          </thead>
          <tbody>
            {TableData.map((item) => (
              <tr
                style={{
                  padding: "20px",
                  border: "1px solid  #E4E4E7",
                  background: " #FFF",
                }}
              >
                <TableBody item={item} />
              </tr>
            ))}
          </tbody>
        </Table>
      </Row> */}

      <div className="add-run-details-section">
        <div className="add-run-grid-alignment">
          
        {optionIconState === "A" && (
<>
  {isLoading ? (
     <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
     <div className="spinner-border text-primary" role="status">
       <span className="visually-hidden">Loading...</span>
     </div>
   </div>
  ) : (
    data == 0 || data == undefined ? (
      <div className="add-run-box-alignment addRun" 
      // style={{ width: "100%", display: "flex", justifyContent: "center", marginLeft: "40%" }}
      >
        <img src={noData} alt="Coming Soon" />
      </div>
    ) : (
      data?.map((result, index) => (
        <div className="add-run-box-alignment" key={index}>
          <div className="add-run-heading">
            <img src={Google3D} alt="Google3D" />
            <h4>{`Google-Campaign-${index + 1}`}</h4>
          </div>

          <div className="add-run-subheading">
            <div className="add-run-subheading-box">
              <div className="subheading-details-box">
                <h4>{result?.impressions}</h4>
                <p>Impressions</p>
              </div>
              <div className="subheading-details-box">
                <h4>{result?.clicks}</h4>
                <p>Clicks</p>
              </div>
            </div>
          </div>

          <div className="add-run-description-details-alignment"
          //  onClick={() => handleNavigateToAd(result)}
           >
            <h4>{result?.GoogleSearchHeadline[0]} | {result?.GoogleSearchHeadline[1]} | {result?.GoogleSearchHeadline[2]}</h4>
            {result?.image && (
              <img
                src={`${addImageUrl}${JSON.parse(result?.image).imageUrl}`}
                style={{ width: "100px", height: "100px", marginLeft: "150px" }}
                alt="Ad Visual"
              />
            )}
            <p>{result?.GoogleSearchDescription[0]}</p>
            <p>{result?.GoogleSearchDescription[1]}</p>
          </div>

          <div className="add-run-description-bottom-alignment">
            <div className="bottom-box" onClick={() => openDeleteModal(result?._id)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z"
                  fill="white"
                />
              </svg>
              <p>Delete AD</p>
            </div>
            <div className="bottom-box" onClick={() => handleNavigate(result)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z"
                  fill="white"
                />
              </svg>
              <p>Edit AD</p>
            </div>
            <div className="bottom-box" onClick={() => openDuplicateModal(result)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z"
                  fill="white"
                />
                <path
                  d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z"
                  fill="white"
                />
              </svg>
              <p>Duplicate</p>
            </div>
          </div>
        </div>
      ))
    )
  )}
</>

)}

         {optionIconState === "B" && (
                    <>
                     {fbData == 0  || fbData == undefined ? (
      <div className="add-run-box-alignment addRun"
      //  style={{ width: "100%", display: "flex", justifyContent: "center", marginLeft: "40%" }}
       >
        <img src={noData} alt="Coming Soon" />
      </div>
    ) : (
        fbData.map((data,index) => {
            return (

              <div className="add-run-box-alignment">
                <div className="add-run-heading">
              
                <img src={Facebook3D} alt="Facebook3D" />
                      <h4>{`Facebook-Campign-${index+1}`}</h4>
                </div>

                <div className="add-run-subheading">
                  <div className="add-run-subheading-box">
                    <div className="subheading-details-box">
                      <h4>0</h4>
                      <p>Impression</p>
                    </div>
                    <div className="subheading-details-box">

                      <h4>0</h4>
                      <p>Conversion</p>
                    </div>
                  </div>
                </div>

                <div className="add-run-description-details-alignment">
                  <h4>{data.headline}</h4>
                  {data?.image?<img src={`${data?.image}`} style={{width:"100px" ,height:"100px",marginLeft:"150px"}}/>:""}
                  <p>{data.description}</p>
                </div>

                <div className="add-run-description-bottom-alignment">
                  <div className="bottom-box"  onClick={() =>
                                            openFbDeleteModal(data?._id)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z" fill="white" />
                    </svg>
                    <p>Delete AD</p>
                  </div>
                  <div className="bottom-box" onClick={()=>handlFacebookNavigate(data)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z" fill="white" />
                    </svg>

                    <p>Edit AD</p>
                  </div>
                  <div className="bottom-box" onClick={() =>
                                            openDuplicateModalFacebook(data)}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z" fill="white" />
                      <path d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z" fill="white" />
                    </svg>

                    <p>Duplicate</p>
                  </div>
                </div>
              </div>
            )
          }
        )
      )}
        
                    </>
                  )}

   
{optionIconState === "C" && (
                    <>
             {instaData== 0  || fbData == undefined ? (
      <div className="add-run-box-alignment  addRun"
      //  style={{ width: "100%", display: "flex", justifyContent: "center", marginLeft: "40%" }}
       >
        <img src={noData} alt="Coming Soon" />
      </div>
    ) : (

  instaData?.map((data,index) => {
            return (

              <div className="add-run-box-alignment">
                <div className="add-run-heading">
                <img src={Ig3D} alt="Ig3D" />
                      <h4>{`Instagram-Campign-${index+1}`}</h4>
                </div>

                <div className="add-run-subheading">
                  <div className="add-run-subheading-box">
                    <div className="subheading-details-box">
                      <h4>0</h4>
                      <p>Impression</p>
                    </div>
                    <div className="subheading-details-box">

                      <h4>0</h4>
                      <p>Conversion</p>
                    </div>
                  </div>
                </div>

                <div className="add-run-description-details-alignment">
                <div className="add-run-description-details-alignment">
                  {data?.image?<img src={`${data?.image}`} style={{width:"100px" ,height:"100px",marginLeft:"150px"}}/>:""}
                  <p>{data.description}</p>
                </div>
                </div>

                <div className="add-run-description-bottom-alignment">
                  <div className="bottom-box"  onClick={() =>
                                            openInstaDeleteModal(data?._id)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z" fill="white" />
                    </svg>
                    <p>Delete AD</p>
                  </div>
                  <div className="bottom-box" onClick={()=>handlInstaNavigate(data)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z" fill="white" />
                    </svg>

                    <p>Edit AD</p>
                  </div>
                  <div className="bottom-box" onClick={() =>
                                            openDuplicateModalInsta(data)}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z" fill="white" />
                      <path d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z" fill="white" />
                    </svg>

                    <p>Duplicate</p>
                  </div>
                </div>
              </div>
               )
              }
            )
          )}
                    </>
                  )}
                    {optionIconState === "D" && (
                    <>
                       {[0].map(() => {
            return (

              <div className="add-run-box-alignment addRun"
               >
               <img src={comingsoon} className="comingImg"/>
              </div>
            )
          })}
                    </>
                  )}

        </div>
      </div>
    </Container>
  );
};

const AdBox = ({ ActiveAds, ActiveAdsNo, background, pathSvg, NewGraph }) => {
  return (
    <Col
      style={{ padding: "0px", background: background, borderRadius: "10px" }}
    >
      <div className="Active-ad-box">
        <div className="Active-ad-box-div">
          <span>{ActiveAds}</span>
          <p>{ActiveAdsNo}</p>
        </div>
        <span>
          <svg
            height="80px"
            width="80px"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 512 512"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0" />

            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            />

            <g id="SVGRepo_iconCarrier">
              <path
                style={{ fill: "#fff808080" }}
                d="M509.561,314.624L487.29,292.07c-3.284-3.324-8.639-3.359-11.96-0.076 c-3.323,3.282-3.358,8.637-0.076,11.961l8.052,8.153H8.458c-4.671,0-8.458,3.786-8.458,8.458s3.787,8.458,8.458,8.458h474.847 l-8.052,8.153c-3.282,3.323-3.248,8.679,0.076,11.96c1.649,1.627,3.795,2.439,5.943,2.439c2.182,0,4.363-0.839,6.019-2.515 l22.271-22.554C512.813,323.215,512.813,317.918,509.561,314.624z"
              />{" "}
              <path
                style={{ fill: "#fff" }}
                d="M365.915,456.183c-3.287,0-6.278-1.905-7.667-4.887L242.684,203.134l-25.161,35.541 c-1.748,2.469-4.682,3.82-7.695,3.533c-3.013-0.283-5.644-2.155-6.902-4.909l-41.388-90.661L74.879,320.662 c-2.083,4.183-7.16,5.884-11.341,3.801c-4.18-2.083-5.883-7.161-3.801-11.341l94.561-189.894c1.454-2.918,4.478-4.768,7.713-4.687 c3.26,0.055,6.198,1.979,7.551,4.945l42.646,93.417l25.088-35.436c1.741-2.461,4.664-3.814,7.664-3.536 c3.003,0.272,5.634,2.12,6.906,4.854l113.903,244.592l48.604-108.4c1.911-4.263,6.921-6.165,11.178-4.257 c4.262,1.91,6.169,6.916,4.257,11.178l-56.175,125.288c-1.354,3.021-4.347,4.973-7.657,4.998 C365.955,456.183,365.934,456.183,365.915,456.183z"
              />
              <path
                style={{ fill: "#fff808080" }}
                d="M94.4,24.711L71.846,2.44c-3.294-3.253-8.591-3.253-11.885,0L37.407,24.711 c-3.324,3.282-3.358,8.637-0.076,11.961c3.282,3.323,8.637,3.359,11.96,0.076l8.153-8.052v474.846c0,4.672,3.787,8.458,8.458,8.458 s8.458-3.786,8.458-8.458V28.696l8.153,8.052c1.648,1.627,3.796,2.439,5.942,2.439c2.183,0,4.364-0.839,6.019-2.515 C97.757,33.348,97.724,27.993,94.4,24.711z"
              />{" "}
            </g>
          </svg>
        </span>
      </div>
    </Col>
  );
};

const TableHeading = ({ title, Display, borderRadius }) => {
  return (
    <>
      <th
        style={{
          padding: "0px",

          // border: "2px solid #B2BBC6",
          // borderRadius: "50px",
          // textAlign: "center",
        }}
      >
        <div
          style={{
            gap: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #B2BBC6",
            padding: "10px",
            borderRadius: borderRadius ? borderRadius : "0px",
          }}
        >
          <p className="table-heading-p">{title}</p>
          <p
            style={{
              visibility: Display !== false ? "visible" : "hidden",
              paddingBottom: "4px",
              cursor: "pointer",
            }}
          >
            {TableArrow}
          </p>
        </div>
      </th>
    </>
  );
};

const TableBody = ({ item }) => {
  return (
    <>
      <td className="table-heading-p" style={{ padding: "30px" }}>
        {
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              opacity="0.4"
              d="M12.7333 13.12C12.3667 14.06 11.4733 14.6667 10.4667 14.6667H6.53332C5.51999 14.6667 4.63332 14.06 4.26665 13.12C3.89999 12.1733 4.15332 11.1267 4.89999 10.4467L7.59999 8H9.40665L12.1 10.4467C12.8467 11.1267 13.0933 12.1733 12.7333 13.12Z"
              fill="#D30303"
            />
            <path
              d="M9.71334 12.0934H7.28668C7.03334 12.0934 6.83334 11.8868 6.83334 11.6401C6.83334 11.3868 7.04001 11.1868 7.28668 11.1868H9.71334C9.96668 11.1868 10.1667 11.3934 10.1667 11.6401C10.1667 11.8868 9.96001 12.0934 9.71334 12.0934Z"
              fill="#D30303"
            />
            <path
              d="M12.7333 2.87992C12.3667 1.93992 11.4733 1.33325 10.4667 1.33325H6.53333C5.52666 1.33325 4.63333 1.93992 4.26666 2.87992C3.90666 3.82659 4.15333 4.87325 4.90666 5.55325L7.59999 7.99992H9.40666L12.1 5.55325C12.8467 4.87325 13.0933 3.82659 12.7333 2.87992ZM9.71333 4.81992H7.28666C7.03333 4.81992 6.83333 4.61325 6.83333 4.36659C6.83333 4.11992 7.04 3.91325 7.28666 3.91325H9.71333C9.96666 3.91325 10.1667 4.11992 10.1667 4.36659C10.1667 4.61325 9.95999 4.81992 9.71333 4.81992Z"
              fill="#D30303"
            />
          </svg>
        }
      </td>
      <td className="table-heading-p">{item.id}</td>
      <td className="table-heading-p">{item.CampaignName}</td>

      <td className="table-heading-p">{item.platform}</td>
      <td className="table-heading-p">Campaign 1</td>
      <td className="table-heading-p">01-01-2023 | 17:16</td>
      <td className="table-heading-p">01-01-2023 | 17:16</td>
      <td className="table-heading-p">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M10.5 16.6668C10.9603 16.6668 11.3335 16.2936 11.3335 15.8333C11.3335 15.3729 10.9603 14.9998 10.5 14.9998C10.0397 14.9998 9.6665 15.3729 9.6665 15.8333C9.6665 16.2936 10.0397 16.6668 10.5 16.6668Z"
            stroke="#1D242D"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5001 10.8333C10.9605 10.8333 11.3336 10.4601 11.3336 9.99976C11.3336 9.53943 10.9605 9.16626 10.5001 9.16626C10.0398 9.16626 9.66663 9.53943 9.66663 9.99976C9.66663 10.4601 10.0398 10.8333 10.5001 10.8333Z"
            stroke="#1D242D"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.5001 4.99952C10.9605 4.99952 11.3336 4.62635 11.3336 4.16602C11.3336 3.70569 10.9605 3.33252 10.5001 3.33252C10.0398 3.33252 9.66663 3.70569 9.66663 4.16602C9.66663 4.62635 10.0398 4.99952 10.5001 4.99952Z"
            stroke="#1D242D"
            stroke-width="1.66678"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </td>
    </>
  );
};
export default Add;
  