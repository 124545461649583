import React, { useEffect, useState } from "react";
import "./Ad.css";
import { Button, Container, Modal, Row } from "react-bootstrap";

import adsBg2 from "../../Main Pages/Images/adsBg2.png";
import adsMainBg from "../../Main Pages/Images/adsMainBg.png";
import Google3D from "../../Main Pages/Images/Google3D.png";
import Bing3D from "../../Main Pages/Images/Bing3D.png";
import Facebook3D from "../../Main Pages/Images/Facebook3D.png";
import Ig3D from "../../Main Pages/Images/Ig3D.png";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios, { addImageUrl } from "../../../axios/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFaceBookDeatils } from "../../../../Redux/CampaignDetailSlice";
import {
  addGoogleDisplayDeatils,
  addGoogleSearchDeatils,
  addInstagramDeatils,
} from "../Redux/CampaignDetailSlice";
import {
  addWebsiteDetailForGoogle,
  addWebsiteDetailForGoogle2,
} from "../Redux/AiSuggestion";
import { AddTargetingDetails } from "../../../../Redux/GoogleSearchSlice";
import { AddTargetingDetailsFacebook } from "../../../../Redux/FacebbokAdsSlice";
import { addFaceBookSuggestion } from "../../../../Redux/AiSuggestion";
import comingsoon from "./comingsoon.jpg";
import noData from "./nodata.jpg";
import { AddTargetingDetailsInstagram } from "../../../../Redux/InstagramAdSlice";

const Add = () => {
  const [optionIconState, setOptionIconState] = useState("A");
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateData, setdupliacteData] = useState();
  const [deleteId, setdeleteId] = useState();
  const [runningAd, setRunningAd] = useState([]);
  const [fbData, setFbData] = useState([]);
  const [fbDuplicateData, setFbDuplicateData] = useState([]);
  const [showFbDuplicateModel, setshowFBDuplicateModel] = useState(false);
  const [instaDuplicateData, setInstaDuplicateData] = useState([]);
  const [showInstaDuplicateModel, setshowInstaDuplicateModel] = useState(false);
  const { GoogleAnalytics } = useSelector((state) => state.AdsAnalyticsDetail);
  const [showFbDeleteModel, setShowfbDeleteModal] = useState("");
  const [instaData, setInstaData] = useState("");
  const [instaDeleteModal, setinstaDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("token");

  async function fetchGoogleData() {
    try {
      const res = await axios.get("/get-pending-ads", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res?.data) {
        setIsLoading(false);
        setData(res?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching customer:", error);
    }
  }

  async function fetchFacebookData() {
    try {
      const res = await axios.get("/get-fb-pending-ads", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res?.data) {
        setIsLoading(false);
        setFbData(res?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching customer:", error);
    }
  }

  async function fetchInstagramData() {
    try {
      const res = await axios.get("/get-insta-pending-ads", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res?.data) {
        setIsLoading(false);
        setInstaData(res?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching customer:", error);
    }
  }

  useEffect(() => {
    fetchGoogleData();
    fetchFacebookData();
    fetchInstagramData();
  }, []);

  const handleData = async () => {
    setOptionIconState("A");
    await fetchGoogleData();
  };

  const handleFbData = async () => {
    setOptionIconState("B");
    await fetchFacebookData();
  };

  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setdeleteId(null);
    setShowDeleteModal(false);
    setShowfbDeleteModal(false);
    setinstaDeleteModal(false);
  };

  // Function to close the delete confirmation modal
  const closeDupliacteModal = () => {
    setdeleteId(null);
    setshowFBDuplicateModel(false);
    setShowDuplicateModal(false);
    setshowInstaDuplicateModel(false);
  };

  // Function to handle delete customer action
  const handleDeleteCustomer = async () => {
    await axios.get(
      `/delete-pending-ads/${deleteId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    toast.error("Data deleted succefully", {
      className: "custom-toast-error",
      hideProgressBar: true,
    });
    fetchGoogleData();
    closeDeleteModal();
  };

  // Function to handle delete customer action
  const handleFacebookDeleteCustomer = async () => {
    await axios.get(
      `/fb-ads-delete/${deleteId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    toast.error("Data deleted succefully", {
      className: "custom-toast-error",
      hideProgressBar: true,
    });
    fetchFacebookData();
    closeDeleteModal();
  };

  const handleInstaDeleteCustomer = async () => {
    await axios.get(
      `/insta-ads-delete/${deleteId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    toast.error("Data deleted succefully", {
      className: "custom-toast-error",
      hideProgressBar: true,
    });
    fetchInstagramData();
    closeDeleteModal();
  };

  // Function to handle delete customer action
  const handleDuplicateCustomer = async () => {
    await axios.post(`/create-pending-ads`, duplicateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // toast.success("Data duplicated succefully");
    toast.success("Data duplicated succefully", {
      className: "custom-toast-success",
      hideProgressBar: true,
    });

    fetchGoogleData();
    closeDupliacteModal();
  };

  const handleDuplicateFacebookCustomer = async () => {
    await axios.post(`/create-fb-pending-ads`, fbDuplicateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // toast.success("Data duplicated succefully");
    toast.success("Data duplicated succefully", {
      className: "custom-toast-success",
      hideProgressBar: true,
    });

    fetchFacebookData();
    closeDupliacteModal();
  };

  const handleDuplicateInstaCustomer = async () => {
    await axios.post(`/create-insta-pending-ads`, instaDuplicateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // toast.success("Data duplicated succefully");
    toast.success("Data duplicated succefully", {
      className: "custom-toast-success",
      hideProgressBar: true,
    });

    fetchInstagramData();
    closeDupliacteModal();
  };

  const handleNavigate = (data) => {
    if (data.type === "search") {
      sessionStorage.setItem("pendingAdData", JSON.stringify(data));
      sessionStorage.removeItem("websiteUrl");
      localStorage.removeItem("persist:root");
      dispatch(addWebsiteDetailForGoogle(""));
      dispatch(addWebsiteDetailForGoogle2(""));
      // dispatch(addGoogleSearchDeatils(""))
      dispatch(
        addGoogleSearchDeatils({
          headline: data?.GoogleSearchHeadline,
          descriptions: data?.GoogleSearchDescription,
        })
      );
      dispatch(
        AddTargetingDetails({
          keywords: data?.keywords,
        })
      );

      navigate("/main/campaign/googlecompaign/google-add-1/content");
    } else if (data.type === "Display") {
      sessionStorage.setItem("pendingAdData", JSON.stringify(data));
      dispatch(addGoogleDisplayDeatils(""));
      sessionStorage.removeItem("websiteUrl");
      navigate("/main/campaign/googlecompaign2/google-add-1/content");
    }
  };

  const handlFacebookNavigate = (data) => {
    sessionStorage.setItem("websiteUrl", data.WebsiteUrl);
    sessionStorage.setItem("websiteTitle", data.websiteTitle);
    sessionStorage.setItem("websiteDetail", data.websiteDetail);
    sessionStorage.setItem("account_id", data.account_id);
    sessionStorage.setItem("adId", data?._id);
    sessionStorage.removeItem("facebookAdsComplateData");
    dispatch(addFaceBookDeatils({ headline: "", descriptions: "", image: "" }));

    dispatch(
      addFaceBookSuggestion({
        descriptions: "",
        headlines: "",
        keyword: "",
      })
    );

    dispatch(AddTargetingDetailsFacebook({}));

    let transformeddata = [
      { headline: data?.headline[0] },
      { description: data?.description[0] },
      { hash: data?.hash, imagePath: data.image },
      { cities: data.Cities },
      { countryCode: data.CountryCode },
      { country: data.country },
      { keywords: data.keywords },
      { minAge: data.minAge },
      { maxAge: data.maxAge },
      { gender: data.gender },
      { Amount: data.Amount },
    ];
    sessionStorage.setItem("fbData", JSON.stringify(transformeddata));
    navigate("/main/campaign/facebookcampaign/facebook-add-1/content");
  };

  const handlInstaNavigate = (data) => {
    sessionStorage.setItem("websiteUrl", data.WebsiteUrl);
    sessionStorage.setItem("websiteTitle", data.websiteTitle);
    sessionStorage.setItem("websiteDetail", data.websiteDetail);
    sessionStorage.setItem("account_id", data.account_id);
    sessionStorage.setItem("adId", data?._id);
    sessionStorage.removeItem("instagramAdsComplateData");
    dispatch(
      addInstagramDeatils({ headline: "", descriptions: "", image: "" })
    );

    dispatch(
      addInstagramDeatils({
        descriptions: "",
        headlines: "",
        keyword: "",
      })
    );

    dispatch(AddTargetingDetailsInstagram({}));

    let transformeddata = [
      { headline: data?.headline[0] },
      { description: data?.description[0] },
      { hash: data?.hash, imagePath: data.image },
      { cities: data.Cities },
      { countryCode: data.CountryCode },
      { country: data.country },
      { keywords: data.keywords },
      { minAge: data.minAge },
      { maxAge: data.maxAge },
      { gender: data.gender },
      { Amount: data.Amount },
    ];
    sessionStorage.setItem("instaData", JSON.stringify(transformeddata));
    navigate("/main/campaign/instagramcampaign/instagram-add-1/content");
  };

  const handleNavigateToAd = (data) => {
    if (data.type === "search") {
      sessionStorage.setItem("pendingAdData", JSON.stringify(data));
      sessionStorage.removeItem("websiteUrl");
      localStorage.removeItem("persist:root");
      dispatch(addWebsiteDetailForGoogle(""));
      dispatch(addWebsiteDetailForGoogle2(""));
      // dispatch(addGoogleSearchDeatils(""))
      dispatch(
        addGoogleSearchDeatils({
          headline: data?.GoogleSearchHeadline,
          descriptions: data?.GoogleSearchDescription,
        })
      );
      dispatch(
        AddTargetingDetails({
          keywords: data?.keywords,
        })
      );

      navigate("/main/campaign/googlecompaign/google-add-1/funding");
    } else if (data.type === "Display") {
      sessionStorage.setItem("pendingAdData", JSON.stringify(data));
      dispatch(addGoogleDisplayDeatils(""));
      sessionStorage.removeItem("websiteUrl");
      navigate("/main/campaign/googlecompaign2/google-add-1/funding");
    }
  };

  sessionStorage.removeItem("GoogleDisplayImages");
  const openDeleteModal = (id) => {
    setdeleteId(id);
    setShowDeleteModal(true);
  };

  const openFbDeleteModal = (id) => {
    setdeleteId(id);
    setShowfbDeleteModal(true);
  };

  const openInstaDeleteModal = (id) => {
    setdeleteId(id);
    setinstaDeleteModal(true);
  };

  const openDuplicateModal = (data) => {
    setdupliacteData(data);
    setShowDuplicateModal(true);
  };

  const openDuplicateModalFacebook = (data) => {
    setFbDuplicateData(data);
    setshowFBDuplicateModel(true);
  };

  const openDuplicateModalInsta = (data) => {
    setInstaDuplicateData(data);
    setshowInstaDuplicateModel(true);
  };

  useEffect(() => {
    let enableAd = [];
    GoogleAnalytics?.map((item) => {
      if (item?.adData?.adGroup?.status === "ENABLED") {
        enableAd.push(item?.adData);
      }

      setRunningAd(enableAd);
    });
  }, []);

  return (
    <Container
      fluid
      style={{ background: "" }}
      className="adContainer1 Adrun-main-container "
    >
      <Row
        style={{
          gap: "20px",
          background: `url(${adsBg2})`,
          backgroundSize: "100% 106%",
          objectFit: "contain",
          backgroundRepeat: "no-repeat",
          padding: "24px 20px",
        }}
      >
        <Row style={{ gap: "2 0px" }}>
          <p className="ad-heading">Ad Run</p>
          <div
            className="ad-mainConatiner"
            style={{
              background: `url(${adsMainBg})`,
              backgroundSize: "100% 100%",
            }}
          >
            <div className="ad-ActiveAds">
              <div className="ad-ActiveAds-div">
                <h4>Active Ads</h4>
                <p>
                  {runningAd?.length} <div className="roundBox   "></div>
                </p>
              </div>

              <div className="ad-ActiveAds-div">
                <h4>InActive Ads</h4>
                <p style={{ color: "#C2C8CE" }}>
                  {data?.length} <div className="roundBox second  "></div>
                </p>
              </div>
            </div>
            <div className="divBorder"></div>

            <div className="ad-ActiveAds-Platform" onClick={() => handleData()}>
              <h4>Google Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <span>{data?.length}</span>
                <img style={{ width: "60px" }} src={Google3D} alt="" />
              </div>
            </div>

            <div
              className="ad-ActiveAds-Platform"
              onClick={() => handleFbData()}
            >
              <h4>Facebook Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <span>{fbData.length}</span>
                <img style={{ width: "50px" }} src={Facebook3D} alt="" />
              </div>
            </div>

            <div
              className="ad-ActiveAds-Platform"
              onClick={() => setOptionIconState("C")}
            >
              <h4>Insta Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <span>{instaData?.length}</span>
                <img style={{ width: "50px" }} src={Ig3D} alt="" />
              </div>
            </div>

            <div
              className="ad-ActiveAds-Platform"
              onClick={() => setOptionIconState("D")}
            >
              <h4>Bing Ads</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <span>0</span>

                <img style={{ width: "45px" }} src={Bing3D} alt="" />
              </div>
            </div>
          </div>
        </Row>
      </Row>

      {/* Delete Confirmation facebook Modal */}
      <Modal show={showFbDeleteModel} onHide={closeDeleteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleFacebookDeleteCustomer}
            >
              Delete
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Delete Confirmation insta Modal */}
      <Modal show={instaDeleteModal} onHide={closeDeleteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleInstaDeleteCustomer}
            >
              Delete
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleDeleteCustomer}
            >
              Delete
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Duplicate Confirmation facebook Modal */}
      <Modal show={showFbDuplicateModel} onHide={closeDupliacteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Duplication</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to duplicate this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDupliacteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleDuplicateFacebookCustomer}
            >
              Confirm
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Duplicate Confirmation insta Modal */}
      <Modal show={showInstaDuplicateModel} onHide={closeDupliacteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Duplication</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to duplicate this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDupliacteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleDuplicateInstaCustomer}
            >
              Confirm
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Duplicate Confirmation Modal */}
      <Modal show={showDuplicateModal} onHide={closeDupliacteModal}>
        <div className="ads-delete-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Duplication</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to duplicate this Ad?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDupliacteModal}>
              Cancel
            </Button>
            <button
              style={{
                width: 80,
                height: 40,
                borderRadius: 10,
                color: "white",
                background: "linear-gradient(90deg, #47beb9 0%, #215856 100%)",
              }}
              onClick={handleDuplicateCustomer}
            >
              Confirm
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      <div className="add-run-details-section">
        <div className="add-run-grid-alignment">
          {optionIconState === "A" && (
            <>
              {isLoading ? (
                <div
                  className="spinner-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : data === 0 || data === undefined ? (
                <div className="add-run-box-alignment addRun">
                  <img src={noData} alt="Coming Soon" />
                </div>
              ) : (
                data?.map((result, index) => (
                  <div className="add-run-box-alignment" key={index}>
                    <div className="add-run-heading">
                      <img src={Google3D} alt="Google3D" />
                      <h4>{`Google-Campaign-${index + 1}`}</h4>
                    </div>

                    <div className="add-run-subheading">
                      <div className="add-run-subheading-box">
                        <div className="subheading-details-box">
                          <h4>{result?.impressions}</h4>
                          <p>Impressions</p>
                        </div>
                        <div className="subheading-details-box">
                          <h4>{result?.clicks}</h4>
                          <p>Clicks</p>
                        </div>
                      </div>
                    </div>

                    <div className="add-run-description-details-alignment">
                      <h4>
                        {result?.GoogleSearchHeadline[0]} |{" "}
                        {result?.GoogleSearchHeadline[1]} |{" "}
                        {result?.GoogleSearchHeadline[2]}
                      </h4>
                      {result?.image && (
                        <img
                          src={`${addImageUrl}${
                            JSON.parse(result?.image).imageUrl
                          }`}
                          style={{
                            width: "100px",
                            height: "100px",
                            marginLeft: "150px",
                          }}
                          alt="Ad Visual"
                        />
                      )}
                      <p>{result?.GoogleSearchDescription[0]}</p>
                      <p>{result?.GoogleSearchDescription[1]}</p>
                    </div>

                    <div className="add-run-description-bottom-alignment">
                      <div
                        className="bottom-box"
                        onClick={() => openDeleteModal(result?._id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z"
                            fill="white"
                          />
                        </svg>
                        <p>Delete AD</p>
                      </div>
                      <div
                        className="bottom-box"
                        onClick={() => handleNavigate(result)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z"
                            fill="white"
                          />
                        </svg>
                        <p>Edit AD</p>
                      </div>
                      <div
                        className="bottom-box"
                        onClick={() => openDuplicateModal(result)}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z"
                            fill="white"
                          />
                          <path
                            d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z"
                            fill="white"
                          />
                        </svg>
                        <p>Duplicate</p>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </>
          )}

          {optionIconState === "B" && (
            <>
            {console.log(fbData,"fff")
            }
              {fbData === 0 || fbData === undefined || fbData?.length ===0 ? (
                <div
                  className="add-run-box-alignment addRun"
                  //  style={{ width: "100%", display: "flex", justifyContent: "center", marginLeft: "40%" }}
                >
                  <img src={noData} alt="Coming Soon" />
                </div>
              ) : (
                fbData.map((data, index) => {
                  return (
                    <div className="add-run-box-alignment">
                      <div className="add-run-heading">
                        <img src={Facebook3D} alt="Facebook3D" />
                        <h4>{`Facebook-Campign-${index + 1}`}</h4>
                      </div>

                      <div className="add-run-subheading">
                        <div className="add-run-subheading-box">
                          <div className="subheading-details-box">
                            <h4>0</h4>
                            <p>Impression</p>
                          </div>
                          <div className="subheading-details-box">
                            <h4>0</h4>
                            <p>Conversion</p>
                          </div>
                        </div>
                      </div>

                      <div className="add-run-description-details-alignment">
                        <h4>{data.headline}</h4>
                        {data?.image ? (
                          <img
                            src={`${data?.image}`}
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              marginLeft: "150px",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        <p>{data.description}</p>
                      </div>

                      <div className="add-run-description-bottom-alignment">
                        <div
                          className="bottom-box"
                          onClick={() => openFbDeleteModal(data?._id)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z"
                              fill="white"
                            />
                          </svg>
                          <p>Delete AD</p>
                        </div>
                        <div
                          className="bottom-box"
                          onClick={() => handlFacebookNavigate(data)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z"
                              fill="white"
                            />
                          </svg>

                          <p>Edit AD</p>
                        </div>
                        <div
                          className="bottom-box"
                          onClick={() => openDuplicateModalFacebook(data)}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z"
                              fill="white"
                            />
                            <path
                              d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z"
                              fill="white"
                            />
                          </svg>

                          <p>Duplicate</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          )}

          {optionIconState === "C" && (
            <>
              {instaData === 0 || fbData === undefined ||instaData?.length === 0  ? (
                <div className="add-run-box-alignment  addRun">
                  <img src={noData} alt="Coming Soon" />
               
                  {/* <div className="add-run-description-bottom-alignment">
                     
                      <div
                        className="bottom-box"
                        // onClick={() => openDuplicateModal(result)}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z"
                            fill="white"
                          />
                          <path
                            d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z"
                            fill="white"
                          />
                        </svg>
                        <p>Create New Ad</p>
                      </div>
                    </div> */}
               
                </div>
                  
              ) : (
                instaData?.map((data, index) => {
                  return (
                    <div className="add-run-box-alignment">
                      <div className="add-run-heading">
                        <img src={Ig3D} alt="Ig3D" />
                        <h4>{`Instagram-Campign-${index + 1}`}</h4>
                      </div>

                      <div className="add-run-subheading">
                        <div className="add-run-subheading-box">
                          <div className="subheading-details-box">
                            <h4>0</h4>
                            <p>Impression</p>
                          </div>
                          <div className="subheading-details-box">
                            <h4>0</h4>
                            <p>Conversion</p>
                          </div>
                        </div>
                      </div>

                      <div className="add-run-description-details-alignment">
                        <div className="add-run-description-details-alignment">
                          {data?.image ? (
                            <img
                              src={`${data?.image}`}
                              alt=""
                              style={{
                                width: "100px",
                                height: "100px",
                                marginLeft: "150px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <p>{data.description}</p>
                        </div>
                      </div>

                      <div className="add-run-description-bottom-alignment">
                        <div
                          className="bottom-box"
                          onClick={() => openInstaDeleteModal(data?._id)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 5C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19L18.997 7.071L18.064 20.142C18.0281 20.6466 17.8023 21.1188 17.4321 21.4636C17.0619 21.8083 16.5749 22 16.069 22H7.93C7.42414 22 6.93707 21.8083 6.56688 21.4636C6.1967 21.1188 5.97092 20.6466 5.935 20.142L5.002 7.072C5.00048 7.04803 4.99982 7.02402 5 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20ZM14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3C15 3.26522 14.8946 3.51957 14.7071 3.70711C14.5196 3.89464 14.2652 4 14 4H10C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14Z"
                              fill="white"
                            />
                          </svg>
                          <p>Delete AD</p>
                        </div>
                        <div
                          className="bottom-box"
                          onClick={() => handlInstaNavigate(data)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.71 7.0425C21.1 6.6525 21.1 6.0025 20.71 5.6325L18.37 3.2925C18 2.9025 17.35 2.9025 16.96 3.2925L15.12 5.1225L18.87 8.8725M3 17.2525V21.0025H6.75L17.81 9.9325L14.06 6.1825L3 17.2525Z"
                              fill="white"
                            />
                          </svg>

                          <p>Edit AD</p>
                        </div>
                        <div
                          className="bottom-box"
                          onClick={() => openDuplicateModalInsta(data)}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.6875 17.4999H5.9375C5.19158 17.4999 4.47621 17.2036 3.94876 16.6762C3.42132 16.1487 3.125 15.4334 3.125 14.6874V5.93744C3.125 5.19152 3.42132 4.47615 3.94876 3.9487C4.47621 3.42126 5.19158 3.12494 5.9375 3.12494H14.6875C15.4334 3.12494 16.1488 3.42126 16.6762 3.9487C17.2037 4.47615 17.5 5.19152 17.5 5.93744V14.6874C17.5 15.4334 17.2037 16.1487 16.6762 16.6762C16.1488 17.2036 15.4334 17.4999 14.6875 17.4999Z"
                              fill="white"
                            />
                            <path
                              d="M5 1.875H14.2141C14.0196 1.32705 13.6604 0.852685 13.1856 0.516987C12.7109 0.181289 12.1439 0.000701956 11.5625 0H2.8125C2.06658 0 1.35121 0.296316 0.823762 0.823762C0.296316 1.35121 0 2.06658 0 2.8125V11.5625C0.000701956 12.1439 0.181289 12.7109 0.516987 13.1856C0.852685 13.6604 1.32705 14.0196 1.875 14.2141V5C1.875 4.1712 2.20424 3.37634 2.79029 2.79029C3.37634 2.20424 4.1712 1.875 5 1.875Z"
                              fill="white"
                            />
                          </svg>

                          <p>Duplicate</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          )}
          {optionIconState === "D" && (
            <>
              {[0].map(() => {
                return (
                  <div className="add-run-box-alignment addRun">
                    <img src={comingsoon} alt="" className="comingImg" />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Add;
