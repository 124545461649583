import React, { useEffect, useState } from "react";
import "./projectCreationPopup.css";
import "../../NewBrand/brandlist/brandlist.css";
import BonToImg from "../../../../../../assets/images/bonton.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBrandData, replaceAllBrandData } from "../../../../../../Redux/brandSetupData";
import axios from "../../../../../axios/auth"
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export default function ProjectCreationPopup({ setProjectPopup,project }) {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const imageLogo = brandData?.logo;
  const { brand } = useSelector((state) => state.brandList);
  const userToken = Cookies.get("token");
  const [projectList,setProjectList]=useState([])
const [createNewProject,setCreateNewProject]=useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEdit = (projectId) => {
    dispatch(addBrandData({ createProject: "createProject",projectId:projectId }));
    fetchProjecDetails(projectId)
    // navigate("/main/brand-setup");
  };
  const handleContinue = () => {
    
    if(project?.length>=4){
// toast.error("You have exceeded your project limit")
toast.error("You have exceeded your project limit", {
  className: 'custom-toast-error',
  hideProgressBar: true
});


    }
    else{
      dispatch(addBrandData({ createProject: "createProject" }));
      fetchBrandInitialInfo(brand?.brandId)
      navigate("/main/brand-setup");
    }
  
  };


  // fetchProjectDeatail

  const fetchProjecDetails = async (projectId) => {
    try {
      const res = await axios.get(`/fetch-project/${projectId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      dispatch(replaceAllBrandData(...res.data?.data))
      dispatch(addBrandData({ createProject: "createProject",projectId:projectId}));
      
    setTimeout(()=>{
       navigate("/main/brand-setup");
    },500)

    } catch (e) {
      console.error("Error fetchProjecDetails:", e);
      // Handle the error as needed
    }
  };







  const fetchBrandInitialInfo = async (brandId) => {
    try {
      const res = await axios.post("/fetch-brand", {brandId:brandId},{
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      // Handle the response as needed
    } catch (e) {
      // console.error("Error:", e);
      // Handle the error as needed
    }
  };

 

  
const getProjectArray=[0]

useEffect(()=>{
if(getProjectArray.length>=4){
  setCreateNewProject(false)
}
else{
  setCreateNewProject(true)
}
},[])
const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
  return (
    <div className="modalWrapper">
      <div className="modalBox">
        <div className="row gy-2">
          {project.map((element,index) => {
            return (
              <div className="col-sm-3 generated-selection-details">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{element?.projectName}</h5>
                    <p className="card-text">
                      <h6>{formatDate(element.createdAt)}</h6>
                      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <p>Yaay! You Have Successfully Created Your Project</p>
                      </div>
                    </p>

                    <div className="continueBtn" onClick={()=>handleEdit(element?.id)}>
                      <div> Edit</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="modal-bottom-alignment">
          <div className="edit-button" onClick={() => setProjectPopup(false)}>
            <button>Back</button>
          </div>
{createNewProject&&
          <div className="continue-button" onClick={handleContinue}>
            <button>Create New Project</button>
          </div>}
        </div>
      </div>
    </div>
  );
}
