import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./NewCampaigns.css";
import NewCampaignsimg from "./Image/New campaigns.png";
import { activeLogo1, logo2, logo3 } from "./SVG";
import { Link, useNavigate } from "react-router-dom";
import { NavItems } from "../../../../UI-Components/ReusableComponents";
import { useDispatch } from "react-redux";
import { addWebsiteUrl } from "../../../../../Redux/AiSuggestion";
import Cookies from "js-cookie";
// import axios from "axios";
import axios from "../../../../axios/auth"

import { toast } from "react-toastify";
import { Axios } from "axios";
import { addGoogleSearchDeatils } from "../../Redux/CampaignDetailSlice";
import { AddTargetingDetails } from "../../../../../Redux/GoogleSearchSlice";
import { LoginSocialFacebook } from "reactjs-social-login";

const NewCampaigns = () => {
  let websiteUrl = sessionStorage.getItem("websiteUrl");
  const [websitename, setWebsiteName] = useState(websiteUrl || "");
  const [websiteFieldError, setWebsiteFieldError] = useState(false);
  const [myWebsite, setMyWebsite] = useState(true);


  const dispatch = useDispatch();

  const token = Cookies.get("token");
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleGlobalKeyPress = (e) => {
      if (e.key === "Enter" && websitename) {
        checkcredits();
      }
    };

    window.addEventListener("keypress", handleGlobalKeyPress);
    return () => {
      window.removeEventListener("keypress", handleGlobalKeyPress);
    };
  }, [websitename]);
  
  const handleWebsiteChange = (e) => {
    const value = e.target.value;

    if (value?.length > 0) {

      setWebsiteFieldError(false);
    } else {
      setWebsiteFieldError(true);
    }

    setMyWebsite(value);
  };

  // check credits

  const checkcredits = async() => {

    if(myWebsite?.length>0 || websitename){
      setWebsiteFieldError(false);
    
    try {
      const res = await axios.post("/check-credits",{transactionType:"scanWebsite"},{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })

      if (res?.data?.status==true) {
         // dispatch(addWebsiteUrl(websitename));
         sessionStorage.setItem("websiteUrl", websitename);
         sessionStorage.removeItem("pendingAdData")
  navigate("/main/campaign/brandsummery");
  
      } else {
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        
      }
    
    } catch (error) {

    }
  }
    else{
      setWebsiteFieldError(true);


    }
  };





  return (

    <Container
      fluid
      style={{ margin: "10px" }}
      className="newcampaign-container"
    >
      <Row>
        <Col>
          <Row>
            <div className="left-navbar">
              <NavItems
                logo={activeLogo1}
                active={true}
                text={"logo1"}
                url={"/main/campaign/newcampaign"}
              />
              <NavItems
                logo={logo2}
                active={false}
                text={"logo2"}
                // url={"/main/campaign/brandsummery"}
              />
              <NavItems
                logo={logo3}
                active={false}
                text={"logo3"}
                // url={"/main/campaign/platfromselection"}
              />
            </div>
          </Row>
          <Row>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <QuestionComponent
                question={"Which website would you like to promote?"}
                text={`Based on your website, we will generate a briefing for your brand which
                will be used to guide our AI. This is also the website that will open
                when users click your ad.`}
                gap={20}
              />

              <InputField
                label={"Enter your website"}
                placeholder={"e.g. https://your-page.com"}
                setState={setWebsiteName}
                state={websitename ? websitename : ""}
                onChange={handleWebsiteChange}
                onEnter={checkcredits}
                errorMessage={websiteFieldError ? "Please enter your website here." : ""}
             
              />

           
              <button
                style={{ background: "none", display: "contents" }}
                onClick={checkcredits}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="61"
                  viewBox="0 0 273 61"
                  fill="none"
                >
                  <g filter="url(#filter0_i_370_10606)">
                    <path
                      d="M0 11.3884C0 5.09876 5.09876 0 11.3884 0H246.812C251.599 0 255.875 2.99341 257.513 7.49127L271.433 45.7145C274.138 53.1427 268.638 61 260.732 61H11.3884C5.09876 61 0 55.9012 0 49.6116V11.3884Z"
                      fill="url(#paint0_linear_370_10606)"
                    />
                  </g>
                  <text
                    x="24%"
                    y="64%"
                    font-family="Arial"
                    className="Ask-an-Expert"
                  >
                    Scan Website
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_370_10606"
                      x="0"
                      y="0"
                      width="285.797"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_370_10606"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_370_10606"
                      x1="0"
                      y1="0"
                      x2="265.5"
                      y2="61"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2E908F" />
                      <stop offset="1" stop-color="#2E908F" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            </div>
          </Row>
        </Col>

        <RightComponent
          text=" Empowering Your Digital Presence through Comprehensive Scans!"
          image={NewCampaignsimg}
          style={"newCampaignRightImg"}
        />
      </Row>
    </Container>
  );
};

export const QuestionComponent = ({ question, text, gap }) => {
  return (
    <div className="question-container" style={{ gap: `${gap}px` }}>
      <h1 className="question">{question}</h1>
      <p className="question-p">{text}</p>
    </div>
  );
};
export const RightComponent = ({ text, image, width, height, lg, style }) => {
  return (
    <Col lg={lg ? lg : ""}>
      <div className="right-container">
        <h3 className="right-heading">{text}</h3>

        <img className={`right-img ${style}`} src={image} alt="" />
      </div>
    </Col>
  );
};



export const InputField = ({
  label,
  placeholder,
  labelSize,
  gap,
  fontWeight,
  inputHeight,
  setState,
  state,
  Preplaceholder,
  onChange,
  errorMessage,
  // onEnter
}) => {
  const [error, setError] = useState(false);

  const handleBrandChange = (e) => {
    const newTitle = e.target.value;
  
    // Retrieve the current value of websiteTitle from sessionStorage
    let websiteTitle = sessionStorage.getItem("websiteTitle");
  
    // Update the value of websiteTitle with the new value
    websiteTitle = newTitle;
  
    // Save the updated value back to sessionStorage
    sessionStorage.setItem("websiteTitle", websiteTitle);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // Update the state
    setState(value);

    // Handle brand change logic
    handleBrandChange(e);

    // Custom onChange handler
    if (onChange) {
      onChange(e);
    }

    // Set error if input is empty
    if (value?.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e?.key === "Enter") {
      // onEnter();
    }
  };

  // Use effect to clear the error message when the state changes
  useEffect(() => {
    if (state?.trim() !== "") {
      setError(false);
    }
  }, [state]);

  return (
    <div
      style={{
        ...(labelSize ? { fontSize: labelSize } : {}),
        ...(gap ? { gap: gap } : {}),
      }}
      className="website-input-main"
    >
      <p
        className="website-input-text"
        style={fontWeight ? { fontWeight: fontWeight } : {}}
      >
        {label}
      </p>
      <input
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        className="website-input"
        value={state}
        required
        style={inputHeight ? { height: inputHeight } : {}}
        placeholder={placeholder}
      />

      {error && (
        <p style={{ color: 'red', marginTop: '0px' }}>
          {errorMessage || "This field is required"}
        </p>
      )}
    </div>
  );
};

// export const InputField = ({
//   label,
//   placeholder,
//   labelSize,
//   gap,
//   fontWeight,
//   inputHeight,
//   setState,
//   state,
//   Preplaceholder,
//   onChange,
//   errorMessage,
//   // onEnter

// }) => {

//   const handleBrandChange = (e) => {
//     const newTitle = e.target.value;
  
//     // Retrieve the current value of websiteTitle from sessionStorage
//     let websiteTitle = sessionStorage.getItem("websiteTitle");
  
//     // Update the value of websiteTitle with the new value
//     websiteTitle = newTitle;
  
//     // Save the updated value back to sessionStorage
//     sessionStorage.setItem("websiteTitle", websiteTitle);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       // onEnter();
//     }
//   };
//   // Add debug logs

//   return (
//     <div
//       style={
//         (labelSize ? { fontSize: labelSize } : {}, gap ? { gap: gap } : {})
//       }
//       className="website-input-main"
//     >
//       <p
//         className="website-input-text"
//         style={fontWeight ? { fontWeight: fontWeight } : {}}
//       >
//         {label}
//       </p>
//       <input
//         onChange={(e) => {

//           setState(e.target.value);
//         handleBrandChange(e)
//           if (onChange) {
//             onChange(e);
//           }
//         }}
//         onKeyPress={handleKeyPress}
//         className="website-input"
//         value={state}
//         required
//         style={inputHeight ? { height: inputHeight } : {}}
//         placeholder={placeholder}
//       />
//       {errorMessage && (
//         <p style={{ color: 'red', marginTop: '5px' }}></p>
//       )}
//     </div>
//   );
// };

export const InputField2 = ({
  label,
  placeholder,
  labelSize,
  gap,
  fontWeight,
  inputHeight,
  setState,
  state,
  Preplaceholder,
  onChange,
  errorMessage,
  onEnter

}) => {

  const handleBrandDescriptionChange = (e) => {
    const newTitle = e.target.value;
  
    // Retrieve the current value of websiteTitle from sessionStorage
    let websiteTitle = sessionStorage.getItem("websiteDetail");
  
    // Update the value of websiteTitle with the new value
    websiteTitle = newTitle;
  
    // Save the updated value back to sessionStorage
    sessionStorage.setItem("websiteDetail", websiteTitle);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onEnter();
    }
  };
  // Add debug logs

  return (
    <div
      style={
        (labelSize ? { fontSize: labelSize } : {}, gap ? { gap: gap } : {})
      }
      className="website-input-main"
    >
      <p
        className="website-input-text"
        style={fontWeight ? { fontWeight: fontWeight } : {}}
      >
        {label}
      </p>
      <input
        onChange={(e) => {

          setState(e.target.value);
          handleBrandDescriptionChange(e)
          if (onChange) {
            onChange(e);
          }
        }}
        onKeyPress={handleKeyPress}
        className="website-input"
        value={state}
        required
        style={inputHeight ? { height: inputHeight } : {}}
        placeholder={placeholder}
      />
      {errorMessage && (
        <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
      )}
    </div>
  );
};

export const Button = ({ label, width, linkto, onClick }) => {
  return (
    <Link
      style={{}}
      to={`/main/campaign/${linkto}`}
      className="website-scan-btn-main"
      onClick={onClick}
    >
      <span className="website-scan-btn">{label}</span>
    </Link>
  );
};
export default NewCampaigns;
