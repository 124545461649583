import React, { useState } from "react";
import "./BrandSummery.css";
import NewCampaigns, {
  QuestionComponent,
  RightComponent,
  Button,
  InputField,
  InputField2,
} from "../NewCampaigns/NewCampaigns";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Logo1, PlusIcon, activeLogo2, logo3 } from "../NewCampaigns/SVG";
import BrandImage from "./images/BrandImage.png";
import { NavItems } from "../../../../UI-Components/ReusableComponents";
import { useDispatch, useSelector } from "react-redux";
import { addbrandDetails } from "../../../../../Redux/newCampaignSlice";
import {
  addCompanyName,
  addWebsiteDeatil,
  addWebsiteUrl,
} from "../../../../../Redux/AiSuggestion";
import axios from "../../../../axios/auth";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { MailContext } from "../../../Auth Pages/mailContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleCoinChange } from "../../../../../Redux/addCreateSlice";
import { addGoogleAnalytics } from "../../Redux/AdsAnalyticsDetailsSlice";
import CampaignDetailSlice, { addBingDeatils, addFaceBookDeatils, addGoogleDisplayDeatils, addGoogleSearchDeatils, addIgDeatils } from "../../../../../Redux/CampaignDetailSlice";
import { AddTargetingDetails } from "../../../../../Redux/GoogleSearchSlice";
import { AddTargetingDetailsDisplay } from "../../../../../Redux/GoogleDisplaySlice";
import { AddTargetingDetailsFacebook} from "../../../../../Redux/FacebbokAdsSlice";
import { AddTargetingDetailsInstagram } from "../../../../../Redux/InstagramAdSlice";

const BrandSummery = () => {
  let websiteTitle = sessionStorage.getItem("websiteTitle");
  let LocalwebsiteDetail = sessionStorage.getItem("websiteDetail");

  const [isLoading, setIsLoading] = useState(true);
  const [brandname, setBrandname] = useState(websiteTitle || null);
  const [Erorr, setErorr] = useState(false);

  
  const [brandDescription, setBrandDescription] = useState(
    LocalwebsiteDetail || null
  );
  const { aiSuggestionLoader, HandleAiSuggestionLoader } = useContext(
    MailContext
  );

  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let websiteUrlFromLocal = sessionStorage.getItem("websiteUrl");
  let websiteDetail = sessionStorage.getItem("websiteDetail");
  let CompanyName = sessionStorage.getItem("websiteTitle");
  const { websiteUrl } = useSelector((state) => state.AiSuggestion);
  // websiteUrlFromLocal is the url of website stored in session-storage
  useEffect(() => {
    if (
      websiteUrlFromLocal &&
      websiteUrl?.length > 0 &&
      websiteUrlFromLocal === websiteUrl
    ) {

      
            
      sessionStorage.setItem("websiteDetail", websiteDetail);
      sessionStorage.setItem("websiteTitle", CompanyName);
      dispatch(addCompanyName(CompanyName));
      dispatch(addWebsiteDeatil(websiteDetail));
      setIsLoading(false);
      HandleAiSuggestionLoader(false);
      
    } else if (
      websiteUrlFromLocal &&
      websiteUrl?.length > 0 &&
      websiteUrlFromLocal !== websiteUrl
    ) {

      dispatch(addFaceBookDeatils("")) 
      dispatch(addGoogleSearchDeatils("")) 
      dispatch(addGoogleDisplayDeatils("")) 
      dispatch(addIgDeatils("")) 
      dispatch(addBingDeatils("")) 
      dispatch(AddTargetingDetails("")) 
      dispatch(AddTargetingDetailsDisplay(""))
      dispatch(AddTargetingDetails(""))
      
      // sessionStorage.clear()

      setBrandname("");
      setBrandDescription("");
      AiSuggestionData(websiteUrlFromLocal);
    } else if (websiteUrlFromLocal) {
      setBrandname("");
      setBrandDescription("");
      AiSuggestionData(websiteUrlFromLocal);
    }
    return () => {
      HandleAiSuggestionLoader(false); // Set IsLoader to false when the component unmounts
    };
  }, []);



  // token deduction

  const deductToken = async() => {

    try {
      const res = await axios.post("/deduct-token",{transactionType:"scanWebsite"},{
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
      if (res?.data?.status==true) {
        dispatch(handleCoinChange(res?.data?.credits))
      } else {
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
  

        navigate("/main/campaign/newcampaign");

      }
    } catch (error) {

    }
  };


  

  const AiSuggestionData = async (UrlOfWebsite) => {
    HandleAiSuggestionLoader(true);

    try {
      const url = "/fetch-ai/website";

     let response= await axios
        .post(
          url,
          { websiteUrl: UrlOfWebsite },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if(response?.data?.status ==true){
          sessionStorage.setItem("websiteDetail", response?.data?.data);
          sessionStorage.setItem("websiteTitle", response?.data?.title);
          setBrandname(response?.data?.title);
          setBrandDescription(response?.data?.data);
          dispatch(addWebsiteDeatil(response?.data?.data));
          dispatch(addWebsiteUrl(UrlOfWebsite));
          dispatch(addCompanyName(response?.data?.title));
          deductToken()
        sessionStorage.removeItem("googleSearchAdsCompleteData")
        sessionStorage.removeItem("googleDisplayAdsCompleteData")
          dispatch(
            addGoogleSearchDeatils({
              headline: "",
              descriptions: ""
        
            })
          );
          sessionStorage.removeItem("adId")
          dispatch(AddTargetingDetails({
            keywords:""
          }))
  sessionStorage.removeItem("facebookAdsComplateData")

          dispatch(
            addGoogleDisplayDeatils({
                 headline: "",
              descriptions: "",
              image:""
            })
          )
          dispatch(AddTargetingDetailsFacebook({
            
          }))
          dispatch(AddTargetingDetailsInstagram({
            
          }))
          sessionStorage.removeItem("instaData")
          sessionStorage.removeItem("account_id")
          sessionStorage.removeItem("instagramAdsComplateData")
sessionStorage.removeItem("GoogleDisplayImages")
sessionStorage.removeItem("fbData")
          setIsLoading(false);

        }else{
          toast.error("Error in loading data", {
            className: 'custom-toast-error',
            hideProgressBar: true
          });
          navigate("/main/campaign/newcampaign");
          // console.error(error);
        }
    } catch (error) {
      toast.error("Error in loading data", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      navigate("/main/campaign/newcampaign");
      // console.error(error);
    }
    HandleAiSuggestionLoader(false);
  };

useEffect(()=>{
  sessionStorage.removeItem("pendingAdData")
},[])

  const scanWebsiteButton = () => {

    if (brandname && brandDescription) {


    sessionStorage.setItem("websiteTitle", brandname);
    sessionStorage.setItem("websiteDetail", brandDescription);


    // dispatch(
    //   addbrandDetails({
    //     brandname: brandname,
    //     brandDescription: brandDescription,
    //   })
    // );
    dispatch(addWebsiteUrl(websiteUrlFromLocal));
    navigate("/main/campaign/platfromselection");
    }
    else{
      
    }
  };

  return (
    <div>
      <Container
        fluid
        style={{ margin: "10px " }}
        className="newcampaign-container "
      >
        {isLoading ? (
          <Row>
            <Col>
              <Row>
                <div className="left-navbar">
                  <NavItems
                    logo={Logo1}
                    active={false}
                    text={"logo1"}
                    url={"/main/campaign/newcampaign"}
                  />
                  <NavItems
                    logo={activeLogo2}
                    active={true}
                    text={"logo2"}
                    url={"/main/campaign/brandsummery"}
                  />
                  <NavItems
                    logo={logo3}
                    active={false}
                    text={"logo3"}
                    url={"/main/campaign/platfromselection"}
                  />
                </div>
              </Row>
              <Row>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <QuestionComponent
                    question={`Here's what we learned
                about your brand`}
                    text={`Please review and confirm the following answers.`}
                    gap={10}
                  />

                 

                  <InputField
                    label={"What’s the name of your brand?"}
                    placeholder={"Exoads"}
                    setState={setBrandname}
                    state={brandname}
                    // Preplaceholder={
                    //   sessionStorage.getItem("brandname")
                    //     ? sessionStorage.getItem("brandname")
                    //     : ""
                    // }
                   
                  />
                  <InputField2
                    label={"How will you describe your Brands?"}
                    placeholder={"Exoads"}
                    setState={setBrandDescription}
                    state={brandDescription}
                    // Preplaceholder={
                    //   sessionStorage.getItem("branddescription")
                    //     ? sessionStorage.getItem("branddescription")
                    //     : ""
                    // }
                  />

                  {/* <div className="advance-options">
                    <p className="advance-p">Advance Option</p>
                    {PlusIcon}
                  </div> */}

                  {/* <button
                    className="website-scan-btn-main"
                    onClick={scanWebsiteButton}
                  >
                    <span className="website-scan-btn">Confirm</span>
                  </button> */}
                  <button
                    style={{ background: "none", display: "contents" }}
                    onClick={scanWebsiteButton}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="200"
                      height="61"
                      viewBox="0 0 273 61"
                      fill="none"
                    >
                      <g filter="url(#filter0_i_370_10606)">
                        <path
                          d="M0 11.3884C0 5.09876 5.09876 0 11.3884 0H246.812C251.599 0 255.875 2.99341 257.513 7.49127L271.433 45.7145C274.138 53.1427 268.638 61 260.732 61H11.3884C5.09876 61 0 55.9012 0 49.6116V11.3884Z"
                          fill="url(#paint0_linear_370_10606)"
                        />
                      </g>
                      <text
                        x="34%"
                        y="64%"
                        font-family="Arial"
                        className="Ask-an-Expert"
                      >
                        Confirm
                      </text>
                      <defs>
                        <filter
                          id="filter0_i_370_10606"
                          x="0"
                          y="0"
                          width="285.797"
                          height="73.1476"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="13.6661" dy="12.1476" />
                          <feGaussianBlur stdDeviation="18.6011" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_370_10606"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_370_10606"
                          x1="0"
                          y1="0"
                          x2="265.5"
                          y2="61"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2E908F" />
                          <stop offset="1" stop-color="#2E908F" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </div>
              </Row>
            </Col>
            <RightComponent
              text="Discover the perfect brand name and description! "
              image={BrandImage}
              style={"BrandSummartyRightImg"}
            />
          </Row>
        ) : (
          <Row>
            <Col>
              <Row>
                <div className="left-navbar">
                  <NavItems
                    logo={Logo1}
                    active={false}
                    text={"logo1"}
                    url={"/main/campaign/newcampaign"}
                  />
                  <NavItems
                    logo={activeLogo2}
                    active={true}
                    text={"logo2"}
                    url={"/main/campaign/brandsummery"}
                  />
                  <NavItems
                    logo={logo3}
                    active={false}
                    text={"logo3"}
                    url={"/main/campaign/platfromselection"}
                  />
                </div>
              </Row>
              <Row>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <QuestionComponent
                    question={`Here's what we learned
                  about your brand`}
                    text={`Please review and confirm the following answers.`}
                    gap={10}
                  />
                  <InputField
                    label={"What’s the name of your brand?"}
                    placeholder={"EXOADS"}
                    setState={setBrandname}
                    state={brandname}
                  />
                 

{/* {(brandname?.length === 0 ) && (
  <p style={{ color: 'red', marginTop: '0px' }}>
    {"This field is required"}
  </p>
)} */}

 


                  <p className="website-input-text">
                    How will you describe your Brand?
                  </p>
                  <textarea
                    style={{ height: "20vh", maxHeight: "300px" }}
                    onChange={(e) => {
                      setBrandDescription(e.target.value);
                    }}
                    className="website-input"
                    value={brandDescription}
                    placeholder={"EXOADS"}
                  ></textarea>

              {brandDescription?.length == 0&&
                     <p style={{ color: 'red' }}>
          { "This field is required"}
        </p>
}

                  {/* <Button
                    label="Confirm"
                    width={24}
                    linkto={"platfromselection"}
                    onClick={scanWebsiteButton}
                  /> */}
                  {/* 
                  <button
                    className="website-scan-btn-main"
                    onClick={scanWebsiteButton}
                  >
                    <span className="website-scan-btn">Confirm</span>
                  </button> */}

                  <button
                    style={{ background: "none", display: "contents" }}
                    onClick={scanWebsiteButton}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="200"
                      height="61"
                      viewBox="0 0 273 61"
                      fill="none"
                    >
                      <g filter="url(#filter0_i_370_10606)">
                        <path
                          d="M0 11.3884C0 5.09876 5.09876 0 11.3884 0H246.812C251.599 0 255.875 2.99341 257.513 7.49127L271.433 45.7145C274.138 53.1427 268.638 61 260.732 61H11.3884C5.09876 61 0 55.9012 0 49.6116V11.3884Z"
                          fill="url(#paint0_linear_370_10606)"
                        />
                      </g>
                      <text
                        x="34%"
                        y="64%"
                        font-family="Arial"
                        className="Ask-an-Expert"
                      >
                        Confirm
                      </text>
                      <defs>
                        <filter
                          id="filter0_i_370_10606"
                          x="0"
                          y="0"
                          width="285.797"
                          height="73.1476"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="13.6661" dy="12.1476" />
                          <feGaussianBlur stdDeviation="18.6011" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_370_10606"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_370_10606"
                          x1="0"
                          y1="0"
                          x2="265.5"
                          y2="61"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2E908F" />
                          <stop offset="1" stop-color="#2E908F" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </div>
              </Row>
            </Col>
            <RightComponent
              text="Discover the perfect brand name and description! "
              image={BrandImage}
              style={"BrandSummartyRightImg"}
            />
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BrandSummery;
