import React from 'react'
import "./brandCreationPopup.css"
import BonToImg from "../../../../../../assets/images/bonton.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BrandList from '../../NewBrand/brandlist';
import { addBrandArray } from '../../../../../../Redux/generatedBrandList';
import { addBrandData } from '../../../../../../Redux/brandSetupData';
import axios from "../../../../../axios/auth";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { handleCoinChange } from '../../../../../../Redux/addCreateSlice';

export default function BrandCreationPopup({ setBrandCreate }) {
    const userToken = Cookies.get("token");
    const {brandData} =useSelector((state)=>state?.brandSetupData)
 const imageLogo=brandData?.logo;
const navigate=useNavigate()
const dispatch=useDispatch()

const createBrand = async () => {
    const data = {
      brandname: brandData?.brandName,
      brandDescription: brandData?.brandDescription,
      promotionTextColor: brandData?.promotionTextColor,
      ctaColor: brandData?.ctaColor,
      selectedFont: brandData?.selectedFont,
      logo: brandData?.logo[0],
      mainBgColor: brandData?.mainBgColor,
      headingColor: brandData?.headingColor,
      descriptionColor: brandData?.descriptionColor
    };

  
    try {
   
      const res = await axios.post("/create-brand", data, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      

      if(res?.data?.status ==true){
        deductToken()
        fetchBrand()
        // toast.success(res?.data?.message)
        
  toast.success(res?.data?.message, {
    className: 'custom-toast-success',
    hideProgressBar: true
  });
       
        // Handle the response as needed
      }
      else{
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });

      
      }
     
    } catch (e) {
      // console.error("Error:", e);
      // Handle the error as needed
    }
  };



const handleContinue=async()=>{
// dispatch((addBrandArray(brandData)))
let res= await checkcredits()
if(res==true){
  createBrand()
}

// navigate("/main/brand-list")
}

// deduct Credits
const deductToken = async() => {

  try {
    const res = await axios.post("/deduct-token",{transactionType:"createBrand"},{
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              })
    if (res?.data?.status==true) {
      dispatch(handleCoinChange(res?.data?.credits))
    } else {
      // toast.error(res?.data?.message)
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      
    }
  } catch (error) {

  }
};


  // check credits
  const checkcredits = async() => {
    try {
      const res = await axios.post("/check-credits",{transactionType:"createBrand"},{
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                })
                if(res?.data?.data>0){
                  return true
                }else{
                
                  // toast.error(res?.data?.message)
                  toast.error(res?.data?.message, {
                    className: 'custom-toast-error',
                    hideProgressBar: true
                  });
                }

    } catch (error) {


    }
  };






const fetchBrand = async () => {
  try {
    const res = await axios.get("/fetch-brand", {
      headers: {
        Authorization: `Bearer ${userToken}` 
      }
    });
    dispatch(addBrandArray(res?.data?.data))
    navigate("/main/brand-list")
    // Handle the response as needed
  } catch (e) {
    // console.error("Error fetchBrand brandCreation popUp:", e);
    // Handle the error as needed
  }
};

    return (  
        <div className="modalWrapper" >
            <div className="modalBox">
                <div className="modal-grid-alignment">
                    <div className="modal-grid-item">
                        <div className="brand-img-aliignment">

                            <div className="second-ring-alignment">
                                <div className="logo-alignment ">
                               
                                     <img   src={imageLogo??""}   className='rounded-pill'    style={{ height: "114px", width: "150px" }}  alt="BonToImg" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-grid-item">
                        <div className="brand-right-side-alignment">
                            <h4>{brandData?.brandName}</h4>
                            <p>{brandData?.brandDescription??"No Description Provided"}  </p>
                        </div>
                    </div>
                </div>
                <div className="modal-bottom-alignment">
                    <div className="edit-button" onClick={() => setBrandCreate(false)}>
                        <button>Edit</button>
                    </div>
                
                        <div className="continue-button" onClick={handleContinue}>
                            <button>Continue</button>
                        </div>
                
                </div>
            </div>
        </div>
    )
}
