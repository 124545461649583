import React from 'react'
import "./cancleModal.css";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import axios from 'axios';
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { handleCoinChange } from '../../../../../../Redux/addCreateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { nodeUrl } from '../../../../../axios/auth';
import CloseIcon from "../../../../../../assets/icons/close.png"

export default function CancleModal({ setCancleModal }) {

  const { user } = useSelector((state) => state.loginuser);

  let expireDate= user?.plan[0]?.subscriptionExpiryDate
  // Assuming the date string is in ISO format
  let formattedDate
  if(expireDate){

// Convert the date string to a Date object
let date = new Date(expireDate);
// Format the date to the desired format: Wed Jun 19 2024
 formattedDate = date.toDateString();
}



    const [buttonVisible, setButtonVisible] = useState(false);
    
    const navigate = useNavigate();
    const dispatch = useDispatch  ();
  
      const cancelPlan = async () => {
          const token = Cookies.get("token");
          setButtonVisible(true)
          try {
            const res = await axios.get(
              `${nodeUrl}/cancel-Subscription`,
             
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
  
          
            let userActivePlan = res?.data?.status;
             if(userActivePlan == true){
            dispatch(handleCoinChange(0))
  
              setCancleModal(false)
              //  toast.success("subscription Cancel Successfully");
               toast.success("subscription Cancel Successfully", {
                className: 'custom-toast-success',
                hideProgressBar: true
              });
             }
             else{
              // toast.error("some thing went wrong");
              toast.error("some thing went wrong", {
                className: 'custom-toast-error',
                 hideProgressBar: true
               }); 
             }
      
          } catch (error) { }
        };
    
    return (
        <div className="cancle-modal-section">
            <div className="cancle-modal-box">
                <div className='cancle-modal-heaading'>
                    <h4>Cancel Your Plan ?</h4>
                    <p>You are currently on the MONTHLY PLAN are you sure you want to cancel it?</p>
                   
                </div>
                <div className="close-icon-dele" 
                onClick={() => setCancleModal(false)}>
                <img src={CloseIcon} alt="CloseIcon" />
                
                
                  </div>
               
                <div className="cancle-modal-body-alignment">
                <p>
  If you proceed, your plan will cancel at the end of its current billing period on 
  <span style={{ fontWeight: 'bold' }}>
    {' '}{formattedDate}{' '}
  </span> 
  and the following will happen:
</p>


                    {/* <p>If you proceed, your plan will cancel at the end of its current billing period on {formattedDate} and the following will happen:</p> */}
                    <div className="cancle-modal-list-alignment">
                        <ul>
                            <li>You will not be charged for another period</li>
                            <li>All running ads will stop</li>
                            <li>You won’t be able to launch new ads</li>
                        </ul>
                    </div>


                </div>

                <div className='cancle-modal-bottom-alignment'>
                    <p>Are you sure you want to cancel your plan?</p>
                    <div className="cancle-modal-bottom-grid">

                    {
                  buttonVisible ?
                    <button style={{cursor:"not-allowed"}}
                 disabled 
                    >
                     <Spinner />
                    </button>
                    :
                    <button
                    onClick={() => cancelPlan()}
                    >
                     Cancel Plan
                    </button>
                }



                        <button onClick={() => setCancleModal(false)}>Don’t Cancel Plan</button>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}
