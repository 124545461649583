import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(
  "pk_test_51NrbkFSE27yXthrGuyY8qu2pdUrgbPhjDaujItTAlSgF8nlJDKQF739mrHdwoRT47r9hfqKcPGshgYvzbctuNzsv001QdNT7oT"
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
      <ToastContainer style={{ fontSize: "12px" }} />
    </PersistGate>
  </Provider>
);


reportWebVitals();
