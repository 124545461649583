import React, { useContext, useEffect, useRef, useState } from "react";
import "./HomeCampaigns.css";
import { Link } from "react-router-dom";
import {
  googleSvg,
  faceBook,
  eyeSvg,
  buttonSvg,
  decSvg,
  peopleSvg,
  inceasingSvg,
  PlusSvg,
  RenameSvg,
  Duplicate,
  Archive,
  GoogleAds,
  rightArrowGreen,
  BingAds,
  InstagramAds,
  noOfClicks,
} from "./SVG";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  Tooltip,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Cookies from "js-cookie";
import { InputField } from "../NewCampaigns/NewCampaigns";
import axios from "../../../../axios/auth";
import { TbHandClick } from "react-icons/tb";
import {
  PauseIcon,
  PlayIcon,
  RedDotIcon,
} from "../Performance Campaigns/Ad Traffice Only/SVg";
import { MailContext } from "../../../Auth Pages/mailContext";
import { useDispatch, useSelector } from "react-redux";
import {
  addGoogleAnalytics,
  addSingleGoogleAnalyticsDetail,
} from "../../../../../Redux/AdsAnalyticsDetailsSlice";

import GoogleAdsModal from "../../../Main Pages/Images/GoogleAdsModal.png";

const Campaigns = () => {
  const [analytic, setAnalytics] = useState([]);
  const [currentAnalytic, setCurrentAnalytics] = useState([]);
  const [adsModal, setAdsModal] = useState(false);

  const [totalReach, setTotalReach] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  let token = Cookies.get("token");

  const { HandlePerformanceCampaign, performanceCampaignData } = useContext(
    MailContext
  );
  const { GoogleAnalytics } = useSelector((state) => state.AdsAnalyticsDetail);

  const Select1 = ["Google", "Facebook", "Instagram", "Bing"];
  const data = [
    {
      name: "Jan",
      Traffic: 4000,
      Leads: 2400,
      amt: 2400,
    },
    {
      name: "Feb",
      Traffic: 3000,
      Leads: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      Traffic: 2000,
      LeadsLeads: 2800,
      amt: 2290,
    },
    {
      name: "Apr",
      Traffic: 2780,
      Leads: 3908,
      amt: 2000,
    },
    {
      name: "May",
      Traffic: 1890,
      Leads: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      Traffic: 2390,
      Leads: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      Traffic: 3490,
      Leads: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      Traffic: 1890,
      Leads: 4800,
      amt: 2181,
    },
    {
      name: "Sep",
      Traffic: 2390,
      Leads: 3800,
      amt: 2500,
    },
    {
      name: "Oct",
      Traffic: 3490,
      Leads: 4300,
      amt: 2100,
    },
    {
      name: "Nov",
      Traffic: 2390,
      Leads: 3800,
      amt: 2500,
    },
    {
      name: "Dec",
      Traffic: 3490,
      Leads: 4300,
      amt: 2100,
    },
  ];

  const fetchData = async () => {
    const url = `/get-all-ads`;
    setIsLoading(true);
    try {
      const ads = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (ads?.data) {
        setIsLoading(false);
        return ads.data;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // throw error; // rethrow the error to be caught by the caller
    }
  };

  useEffect(() => {
    if (GoogleAnalytics && GoogleAnalytics.length > 0) {
      setAnalytics(GoogleAnalytics);
    }

    const fetchDataAndSetData = async () => {
      try {
        const fetchedData = await fetchData();
        setAnalytics(fetchedData.data); // Set fetched data to state
        dispatch(addGoogleAnalytics(fetchedData.data));
      } catch (error) {
        console.log(error);
        // Handle errors, maybe show a message to the user
      }
    };

    fetchDataAndSetData();
  }, [token]);

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + "M";
    } else if (num >= 10000) {
      return (num / 1000).toFixed(2) + "K";
    } else if (num == 0) {
      return 0;
    } else {
      return num.toString();
    }
  }

  useEffect(() => {
    // Calculate totalReach when analytics data changes
    if (analytic && analytic.length > 0) {
      let totalData = analytic.reduce(
        (total, item) => total + item.metricData.interactions,
        0
      );
      setTotalReach(formatNumber(totalData));
    }
  }, [analytic]);

  // Render your component...

  const HandleSingleGoogleAnalytics = (data) => {
    setCurrentAnalytics(data);
    setAdsModal(true);
    dispatch(addSingleGoogleAnalyticsDetail(data));
  };

  return (
    <Container
      className="main-container"
      style={{ padding: "0px 20px", margin: "10px" }}
    >
      <Row>
        <Col lg={10} style={{}}>
          {/* <div className="home-campaign-graph-header">
            <p>Insight</p>
            <Select selectHeading={"monthly"} options={Select1} />
          </div> */}
          <div className="home-campaign-graph-body  home-campaign-graph-body-bg">
            <div
              style={{
                width: "95%",
                height: "80%",
                background: "#fff",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <ResponsiveContainer>
                <BarChart
                  width={200}
                  height={100}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    style={{ fontSize: "16px" }}
                    textAnchor="end"
                  />
                  <YAxis style={{ fontSize: "16px" }} />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="Traffic"
                    fill="#160647"
                    background={{ fill: "#eee" }}
                  />
                  <Bar dataKey="Leads" fill="#47BEB9" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* Todays Lead section */}
              <div className="TodaysLead-body">
                <div className="TodaysLead-item">
                  <h5 style={{ color: "#000" }}>Today’s Leads</h5>
                  <p style={{ color: "#000" }}>12,426</p>
                </div>
                {/* 03BB63 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width={40}
                    height={40}
                    fill="#03BB63"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M17 1h-2a1 1 0 0 0-1 1v16.992h4V2a1 1 0 0 0-1-1zm-6 6H9a1 1 0 0 0-1 1v10.992h4V8a1 1 0 0 0-1-1zm-6 6H3a1 1 0 0 0-1 1v4.992h4V14a1 1 0 0 0-1-1z"></path>
                    </g>
                  </svg>
                  <div className="TodaysLeadSvg" style={{}}>
                    <p style={{}}>+ 36%</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 10 11"
                      fill="none"
                    >
                      <path
                        d="M1.65906 4.2424C1.42475 4.47672 1.42475 4.85662 1.65906 5.09093C1.89338 5.32525 2.27328 5.32525 2.50759 5.09093L1.65906 4.2424ZM4.99999 1.75L5.42426 1.32574C5.18994 1.09142 4.81005 1.09142 4.57573 1.32574L4.99999 1.75ZM7.4924 5.09093C7.72671 5.32525 8.10661 5.32525 8.34093 5.09093C8.57524 4.85662 8.57524 4.47672 8.34093 4.2424L7.4924 5.09093ZM4.39999 9.25C4.39999 9.58137 4.66862 9.85 4.99999 9.85C5.33137 9.85 5.59999 9.58137 5.59999 9.25H4.39999ZM2.50759 5.09093L5.42426 2.17426L4.57573 1.32574L1.65906 4.2424L2.50759 5.09093ZM4.57573 2.17426L7.4924 5.09093L8.34093 4.2424L5.42426 1.32574L4.57573 2.17426ZM4.39999 1.75V9.25H5.59999V1.75H4.39999Z"
                        fill="#22C55E"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/*Todays Traffic section  */}
              <div className="TodaysLead-body">
                <div className="TodaysLead-item">
                  <h5 style={{ color: "#000" }}>Total Traffic</h5>
                  <p style={{ color: "#000" }}>$2,38,485</p>
                </div>
                {/* 03BB63 */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <svg
                    width={40}
                    height={40}
                    fill="#f64"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    transform="matrix(-1, 0, 0, 1, 0, 0)"
                    stroke="#f64"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M17 1h-2a1 1 0 0 0-1 1v16.992h4V2a1 1 0 0 0-1-1zm-6 6H9a1 1 0 0 0-1 1v10.992h4V8a1 1 0 0 0-1-1zm-6 6H3a1 1 0 0 0-1 1v4.992h4V14a1 1 0 0 0-1-1z"></path>
                    </g>
                  </svg>
                  <div className="TodaysLeadSvg" style={{}}>
                    <p style={{ color: "#EF4444" }}>+ 14%</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 10 11"
                      fill="none"
                    >
                      <path
                        d="M8.34093 6.7576C8.57524 6.52328 8.57524 6.14338 8.34093 5.90907C8.10662 5.67475 7.72672 5.67475 7.4924 5.90907L8.34093 6.7576ZM5 9.25L4.57573 9.67426C4.81005 9.90858 5.18995 9.90858 5.42426 9.67426L5 9.25ZM2.5076 5.90907C2.27328 5.67475 1.89338 5.67475 1.65907 5.90907C1.42475 6.14338 1.42475 6.52328 1.65907 6.7576L2.5076 5.90907ZM5.6 1.75C5.6 1.41863 5.33137 1.15 5 1.15C4.66863 1.15 4.4 1.41863 4.4 1.75L5.6 1.75ZM7.4924 5.90907L4.57573 8.82574L5.42426 9.67426L8.34093 6.7576L7.4924 5.90907ZM5.42426 8.82574L2.5076 5.90907L1.65907 6.7576L4.57573 9.67426L5.42426 8.82574ZM5.6 9.25L5.6 1.75L4.4 1.75L4.4 9.25L5.6 9.25Z"
                        fill="#EF4444"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="home-campaign-graph-header">
            <p>Insight</p>
            <Select selectHeading={"monthly"} options={Select1} />
          </div>
          <div
            className="home-campaign-graph-body"
            style={{ padding: "20px", maxHeight: "300px", borderRadius: "6px" }}
          >
            <div className="home-campaign-social-media">
              <SocialMedia
                SocialMediaLogo={GoogleAds}
                SocialMediaType={"Google Ads"}
                progress={`${totalReach}`}
                progressType={"reach"}
                // progressPercentage={"23%"}
                // progressPercentageLogo={rightArrowGreen}
                loading={isLoading}
                GoogleAnalytics={GoogleAnalytics}
              />
              <SocialMedia
                SocialMediaLogo={BingAds}
                SocialMediaType={"Bing Ads"}
                progress={"2,6m"}
                progressType={"reach"}
              />
              <SocialMedia
                SocialMediaLogo={faceBook}
                SocialMediaType={"Facebook Ads"}
                progress={"406K"}
                progressType={"reach"}
              />
              <SocialMedia
                SocialMediaLogo={InstagramAds}
                SocialMediaType={"Instagram"}
                progress={"306K"}
                progressType={"Followers"}
                border={"none"}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        {/* Header  */}
        <Header analytics={analytic} />

        {/* Main Body */}
        {isLoading && GoogleAnalytics == null ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div
            style={{ position: "relative" }}
            className="home-campaign-main-campaign-details"
          >
            {analytic?.map((item, i) => (
              <MainContent
                data={item}
                Svg={inceasingSvg}
                value={"Increasing"}
                color1={"#03BB63"}
                visited={1200}
                Impression={14000}
                index={i}
                length={analytic.length}
                HandlePerformanceCampaign={HandleSingleGoogleAnalytics}
              />
            ))}
          </div>
        )}
        <CustomModal
          currentAnalytic={currentAnalytic}
          show={adsModal}
          setModal={setAdsModal}
          onHide={() => setAdsModal(false)}
        />
      </Row>
    </Container>
  );
};

const SocialMedia = ({
  SocialMediaLogo,
  SocialMediaType,
  progress,
  progressType,
  progressPercentage,
  progressPercentageLogo,
  border,
  loading,
  GoogleAnalytics,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          justifyContent: "center",
        }}
      >
        {SocialMediaLogo} <p style={{ fontSize: "12px" }}>{SocialMediaType}</p>
      </div>
      <div
        className="home-compaign-impression-div"
        style={border ? { border: border } : {}}
      >
        {loading && GoogleAnalytics == null ? (
          <div style={{ padding: "0px" }}>
            <Spinner />
          </div>
        ) : (
          <p>{progress}</p>
        )}
        <div>
          <p>
            {progressType}
            <span>
              {progressPercentageLogo} {progressPercentage}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
const Header = ({ analytics }) => {
  return (
    <div className="header" style={{ padding: "5px 0px 3px 0px" }}>
      <div className="header-campaings">
        <p className="header-text">Campaings</p>
        <div className="header-campaings-ads">
          <div
            className="header-campaings-ad"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              background: "#47beb9",
            }}
          >
            {GoogleAds}
            <p>{analytics ? analytics?.length : 0}</p>
          </div>
          <div
            className="header-campaings-ad"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {faceBook}
            <p style={{ color: "#B2BBC6" }}>0</p>
          </div>
          <div
            className="header-campaings-ad"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {BingAds}
            <p style={{ color: "#B2BBC6" }}>0</p>
          </div>
          <div
            className="header-campaings-ad"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {InstagramAds}
            <p style={{ color: "#B2BBC6" }}>0</p>
          </div>
        </div>
      </div>

      <div style={{ padding: "10px 0px" }}>
        <Link
          to={"/main/campaign/newcampaign"}
          // onClick={(e) => {
          //   e.stopPropagation();
          //   e.preventDefault();
          // }}
          // className="header-button"
        >
          {/* {PlusSvg}
          <span className="header-btn-text">New Campaign</span> */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="168"
            height="67"
            viewBox="0 0 218 67"
            fill="none"
          >
            <g filter="url(#filter0_i_9_793)">
              <path
                d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
                fill="#2E908F"
              />
              <text
                x="35%"
                y="60%"
                font-family="Arial"
                font-size="20"
                fill="white"
                className="newCampaignBtn"
              >
                New Campaign
              </text>
            </g>
            <svg
              width="34"
              height="34"
              viewBox="0 0 21 20"
              fill="none"
              x="15%"
              y="26%"
            >
              <path
                d="M10.9985 4.16602V15.8337"
                stroke="white"
                stroke-width="1.66678"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.16504 9.99988H16.8327"
                stroke="white"
                stroke-width="1.66678"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <defs>
              <filter
                id="filter0_i_9_793"
                x="0.516418"
                y="0"
                width="231.15"
                height="78.2004"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_9_793"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const MainContent = ({
  Svg,
  value,
  color1,
  visited,
  Impression,
  data,
  index,
  length,
  HandlePerformanceCampaign,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowArchive, setModalShowArchive] = useState(false);

  function truncateName(name, maxLength) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    } else {
      return name;
    }
  }

  // data?.adData?.adGroup?.name

  return (
    <Link
      // to={`performancecampaign`}
      onClick={() => HandlePerformanceCampaign(data)}
    >
      <Container
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1 " lg={4}>
            <p className="camp-div1-p">
              {truncateName(data?.adData?.adGroup?.name, 20)}
            </p>
            <div className="camp-div1-bottom">
              <Google googleSvg={GoogleAds} index={index} length={length} />
              {/* <Google googleSvg={faceBook} /> */}
            </div>
          </Col>

          <Col className="main-container-camp-div2" lg={6}>
            <Row>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"Last 2 Week activity"}
                  Svg={inceasingSvg}
                  label={"Increasing"}
                  // color={color1}
                />
                {/*  */}
              </Col>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"Start Date"}
                  // Svg={eyeSvg}
                  label={data?.adData?.campaign?.startDate}
                  color={"#B2BBC6"}
                />
              </Col>
              <Col style={{ padding: "0px" }}>
                <Center
                  title={"End Date"}
                  // Svg={peopleSvg}
                  label={data?.adData?.campaign?.endDate}
                  color={"#B2BBC6"}
                />
              </Col>
            </Row>
          </Col>
          <Col
            lg={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <DropDown
              setModalShow={setModalShow}
              modalShow={modalShow}
              setModalShowArchive={setModalShowArchive}
              modalShowArchive={modalShowArchive}
            /> */}
            {data?.adData?.campaign?.status == "ENABLED" && PlayIcon}{" "}
            {data?.adData?.campaign?.status == "PAUSED" && PauseIcon}
          </Col>
        </Row>
      </Container>
    </Link>
  );
};

const Google = ({ googleSvg, index, length }) => {
  return (
    <div className="camp-div1-bottom">
      <div className="camp-div1-bottom-container">
        {googleSvg}
        <p className="camp-div1-bottom-container-p">
          {index + 1}/{length}
        </p>
      </div>
    </div>
  );
};

const Center = ({ title, Svg, value, color, label }) => {
  return (
    <div className="center-container">
      <p className="camp-div2-p">{title}</p>
      <div className="progress-div">
        {Svg}
        <p className="progress-text" style={{ color: color }}>
          {value ? Math.round(value) : label}
        </p>
      </div>
    </div>
  );
};

const DropDown = ({
  setModalShow,
  modalShow,
  setModalShowArchive,
  modalShowArchive,
}) => {
  return (
    <Dropdown onClick={(e) => (e.stopPropagation(), e.preventDefault())}>
      <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-btn">
        <button className="end-button">{buttonSvg}</button>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setModalShow(true)}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {RenameSvg}
            <p className="drop-down-p">Rename</p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {Duplicate}
            <p className="drop-down-p">Duplicate</p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setModalShowArchive(true)}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {Archive}
            <p className="drop-down-p">Archive</p>
          </div>
        </Dropdown.Item>
        <CustomModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          title={"Edit Campaign Name"}
          buttonTitle={"Save"}
        />
        {/* <CustomModal
          show={modalShowArchive}
          onHide={() => setModalShowArchive(false)}
          title={"Archive Campaign"}
          buttonTitle={"STOP ADS AND ARCHIVE"}
        /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export const CustomModal = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ads-modal-main1"
    >
      <div className="ads-modal-main">
        <div>
          <img src={GoogleAdsModal} style={{ width: "110px" }} alt="" />
        </div>
        <div className="ads-modal-details-main" style={{ flex: 1 }}>
          <h4 className="ads-modal-Heading">Google Ads</h4>

          <div className="ads-modal-details">
            {/* 1 */}
            <div className="ads-modal-detail">
              <h3>{props?.currentAnalytic?.metricData?.impressions}</h3>
              <p>Impressions</p>
            </div>
            {/* 2 */}
            <div className="ads-modal-detail">
              <h3>{props?.currentAnalytic?.metricData?.clicks}%</h3>
              <p>Clicks</p>
            </div>
            {/* 3 */}
            <div className="ads-modal-detail">
              <h3>{props?.currentAnalytic?.metricData?.interactions}</h3>
              <p>Visits</p>
            </div>
            {/* 4 */}
            <div className="ads-modal-detail">
              <h3>${props?.currentAnalytic?.metricData?.costMicros}</h3>
              <p>Cost</p>
            </div>

            {/* 5 */}
            <div className="ads-modal-detail">
              <h3>{props?.currentAnalytic?.metricData?.conversions}</h3>
              <p>Converted</p>
            </div>
          </div>
          {/* button div */}
          <div className="ads-modal-btn-main">
            <div>
              <button style={{ background: "none" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="43"
                  viewBox="0 0 122 43"
                  fill="none"
                >
                  <path
                    d="M0 7.89169C0 3.53323 3.53323 0 7.89168 0H107.196C110.862 0 114.045 2.52415 114.88 6.0934L121.079 32.5805C122.237 37.5317 118.48 42.2704 113.395 42.2704H7.89168C3.53323 42.2704 0 38.7372 0 34.3787V7.89169Z"
                    fill="white"
                  />
                  <text
                    x="24%"
                    y="64%"
                    font-family="Arial"
                    className="ad-modal-stop-btn"
                  >
                    Stop
                  </text>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    x="60%"
                    y="33%"
                  >
                    <circle
                      cx="6.53194"
                      cy="6.48165"
                      r="5.54366"
                      fill="#358F8B"
                    />
                  </svg>
                </svg>
              </button>
              <button style={{ background: "none" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="43"
                  viewBox="0 0 122 43"
                  fill="none"
                >
                  <path
                    d="M122 34.3788C122 38.7373 118.467 42.2705 114.108 42.2705H14.804C11.1383 42.2705 7.95525 39.7464 7.11995 36.1771L0.921242 9.69004C-0.237495 4.73877 3.52026 8.39233e-05 8.6053 8.39233e-05H114.108C118.467 8.39233e-05 122 3.53331 122 7.89177V34.3788Z"
                    fill="white"
                  />

                  <text
                    x="24%"
                    y="64%"
                    font-family="Arial"
                    className="ad-modal-stop-btn"
                  >
                    Pause
                  </text>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="16"
                    viewBox="0 0 9 16"
                    fill="none"
                    x="73%"
                    y="30%"
                  >
                    <rect
                      x="0.430664"
                      y="0.858887"
                      width="3.46479"
                      height="14.5521"
                      fill="#358F8B"
                    />
                    <rect
                      x="5.28125"
                      y="0.858887"
                      width="3.46479"
                      height="14.5521"
                      fill="#358F8B"
                    />
                  </svg>
                </svg>
              </button>
            </div>

            <button style={{ background: "none" }} onClick={props.onHide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="61"
                viewBox="0 0 169 61"
                fill="none"
              >
                <g filter="url(#filter0_i_370_11053)">
                  <path
                    d="M169 49.6116C169 55.9012 163.901 61 157.612 61H20.8266C15.4994 61 10.884 57.3071 9.71552 52.1096L1.12201 13.8864C-0.478653 6.76686 4.93578 0 12.2331 0H157.612C163.901 0 169 5.09876 169 11.3884V49.6116Z"
                    fill="#5BBCBB"
                  />
                </g>
                <text
                  x="35%"
                  y="64%"
                  font-family="Arial"
                  className="ad-modal-save-btn"
                >
                  Save
                </text>
                <defs>
                  <filter
                    id="filter0_i_370_11053"
                    x="0.839844"
                    y="0"
                    width="181.826"
                    height="73.1476"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="13.6661" dy="12.1476" />
                    <feGaussianBlur stdDeviation="18.6011" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_370_11053"
                    />
                  </filter>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Select = ({ selectHeading, options }) => {
  const dropdownRef = useRef();

  const handleDropdownClick = () => {
    dropdownRef.current.classList.add("clicked");
  };
  return (
    <select
      className="hone-campaign-dropdown"
      ref={dropdownRef}
      onClick={handleDropdownClick}
    >
      {/* <option
        value=""
        className="placeholder"
        style={"clicked" ? { display: "none" } : {}}
      >
        {selectHeading}
      </option> */}
      {options.map((item, i) => (
        <>
          <option value={i + 1}>{item} </option>
        </>
      ))}
    </select>
  );
};

export default Campaigns;
