import React, { useContext, useState } from "react";
import "./GoogleContent.css";
import Laptop from "./Images/Laptop.png";
import Mobile from "./Images/Phone.png";
import {
  FaceBookHahaSvg,
  FaceBookHeartSvg,
  FacebookLikeSvg,
  FullLoader,
  MobileSvg,
  TransparentComment,
  TransparentLike,
  TransparentShare,
  desktopIcon,
  dotIconReverse,
  dotsIcon1,
  halfLoader,
} from "../SVG";
import { AiIcon, BlackIcon, BlueDot, facebookBlackIcon } from "./SVG";
import { CommonBtn } from "../../../../../UI-Components/ReusableComponents";
import { ToggleContext } from "../ContextFile";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { Link, NavLink, Outlet } from "react-router-dom";
import InputMain from "./AiGenerationModal/InputMain";
import AiSuggestionMain from "./AiGenerationModal/AiSuggestionMain";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios, { nodeUrl } from "../../../../../axios/auth";
import Cookies from "js-cookie";
import {
  addIgSuggestion,
  addWebsiteDeatil,
  addWebsiteUrl,
  addWebsiteDetailForIg,
} from "../../../../../../Redux/AiSuggestion";
import { wondSvg } from "../../FacebookCampaign/SideNavContainer/SVG";
import { addIgDeatils } from "../../../../../../Redux/CampaignDetailSlice";
import { toast } from "react-toastify";

// const data = [
//   "1 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "2 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "3 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "4 Ipsum is simply dummy text of the printing and typesetting industry.",
// ];

// const MessageData = [
//   "Create Your Own Website",
//   "Create Your Own Website",
//   "Create Your Own Website",
//   "Create Your Own Website",
// ];

const InstagramContent = () => {
  const { tempData } = useSelector((state) => state.template);

  const [aiText, setAiText] = useState([]);
  const [aiText2, setAiText2] = useState([]);
  const [Image, setImage] = useState("");

  const { toggle } = useContext(ToggleContext);
  const { handleToggleVisibleBtn, HandleActiveStep } = useContext(
    ToggleContext
  );

  // const websiteDetail = sessionStorage.getItem("websiteDetail");

  const LocalwebsiteDetail = sessionStorage.getItem("websiteDetail");
  const LocalwebsiteUrl = sessionStorage.getItem("websiteUrl");
  const LocalCompanyName = sessionStorage.getItem("websiteTitle");

  const token = Cookies.get("token");

  const [aiApiDescription, setAiApiDescription] = useState();
  const [aiApiHeadline, setAiApiHeadline] = useState();
  const { websiteDetails, Instagram, websiteDetailsForIg } = useSelector(
    (state) => state.AiSuggestion
  );

  const { IgDescription, IgImage } = useSelector(
    (state) => state.CampaignDetail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    HandleActiveStep(1);
    handleToggleVisibleBtn(true);
    // AiSuggestionData();

    if (IgDescription?.length > 0 || IgImage?.length > 0) {
      setAiText(IgDescription);
      setImage(IgImage);
    }



    if (
      websiteDetailsForIg &&
      LocalwebsiteDetail &&
      websiteDetailsForIg == LocalwebsiteDetail
    ) {
      if (Instagram?.descriptions?.length > 0) {
        setAiApiDescription(Instagram?.descriptions);
        // setAiApiHeadline(Facebook[0]?.headlines?.Headlines);
      } else {
        if (LocalwebsiteDetail) {
          AiSuggestionData();
        }
      }
    } else if (
      websiteDetailsForIg &&
      LocalwebsiteDetail &&
      websiteDetailsForIg !== LocalwebsiteDetail
    ) {
      if (LocalwebsiteDetail) {
        AiSuggestionData();
      }
    } else if (LocalwebsiteDetail) {
      AiSuggestionData();
    }
    return () => {
      handleToggleVisibleBtn(false);
    };
  }, []);

  useEffect(() => {
    if (aiText?.length > 0 || Image?.length > 0) {
      dispatch(
        addIgDeatils({
          // headline: aiText2,
          descriptions: aiText,
          image: Image,
        })
      );
    }
  }, [aiText, Image]);



  useEffect(() => {
    let data1 = sessionStorage.getItem("instaData");

    if (!data1) {
    }
    else {
      let parsedData = JSON.parse(data1);
      const minAgeObj = parsedData.find(item => item.hasOwnProperty('minAge'));
      const maxAgeObj = parsedData.find(item => item.hasOwnProperty('maxAge'));
      const genderObj = parsedData.find(item => item.hasOwnProperty('gender'));

      const descriptionObj = parsedData.find(item => item.hasOwnProperty('description'));
      const hashObj = parsedData.find(item => item.hasOwnProperty('hash'));
      const keywordsObj = parsedData.find(item => item.hasOwnProperty('keywords'));
      const cityObj = parsedData.find(item => item.hasOwnProperty('cities'));
      const countryCodeObj = parsedData.find(item => item.hasOwnProperty('countryCode'));
      const imagePathObj = parsedData.find(item => item.hasOwnProperty('imagePath'));

      setAiText(descriptionObj?.description)
      setImage(imagePathObj?.imagePath)
    }

  }, []);


  const AiSuggestionData = () => {
    setAiText2([]);
    setAiText([]);
    setImage("");
    dispatch(addIgDeatils({ descriptions: "", image: "" }));
    try {
      const url = "/fetch-ai/data";

      axios
        .post(
          url,
          { platform: "instagram", websiteDetail: LocalwebsiteDetail },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.Description) {
            dispatch(
              addIgSuggestion({
                descriptions: response?.data?.Description,
                keyword: response?.data?.keyword,
              })
            );
            setAiApiDescription(response?.data?.Description);

            dispatch(addWebsiteDetailForIg(LocalwebsiteDetail));
            dispatch(addWebsiteUrl(LocalwebsiteUrl));
          } else {
            setAiApiDescription(["no data Found"]);
            setAiApiHeadline(["no data Found"]);
          }
        });
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <div className="FacebookAiSuggestionScroller">
      {toggle ? (
        <MobileView
          tempData={tempData}
          aiText={aiText}
          aiText2={aiText2}
          setAiText={setAiText}
          setAiText2={setAiText2}
          Image={Image}
          setImage={setImage}
          aiApiDescription={aiApiDescription}
          aiApiHeadline={aiApiHeadline}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      ) : (
        <LaptopView
          tempData={tempData}
          aiText={aiText}
          aiText2={aiText2}
          setAiText={setAiText}
          setAiText2={setAiText2}
          Image={Image}
          setImage={setImage}
          aiApiDescription={aiApiDescription}
          aiApiHeadline={aiApiHeadline}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      )}

      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/platfromselection"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        <Link to={"/main/campaign/instagramcampaign/instagram-add-1/targeting"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

// laptop compponent
const LaptopView = ({
  tempData,
  aiText,
  setAiText,
  aiText2,
  setAiText2,
  Image,
  setImage,
  aiApiDescription,
  aiApiHeadline,
  LocalwebsiteUrl,
  LocalCompanyName,
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [aimodalToggle3, setAiModalToggle3] = useState(false);
  const [accountId, setAccountId] = useState();

  const [messageEdit, setMessageEdit] = useState(false);
  const [messageEdit2, setMessageEdit2] = useState(false);

  const handleDescriptionChange= (e)=>{
    if(e.target.value==""){
      let fbDataArray = sessionStorage.getItem('instaData');

      // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
      fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
  
      // Find the item with the "description" key in the array
  
      fbDataArray = fbDataArray.filter(obj => !obj.description);
  
      // Convert the updated array back into a JSON string
      sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));
    }
   
    
      }


  useEffect(() => {
    if (tempData && tempData?.image?.length > 0) {
      setImage(tempData?.image);
    }
  }, []);

  const token = Cookies.get("token");


  useEffect(() => {

    setAccountId(sessionStorage.getItem("account_id"))
  }, [])

  const HandelAiItems = (item) => {

    // Retrieve the current array from sessionStorage
    let fbDataArray = sessionStorage.getItem('instaData');

    // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
    fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];

    // Find the item with the "description" key in the array
    let itemToUpdate = fbDataArray?.find(obj => obj.description);

    if (itemToUpdate) {
      // Update the description if it's different
      if (itemToUpdate.description !== item) {
        itemToUpdate.description = item;
      }
    } else {
      // If the item does not exist, add the new item directly
      fbDataArray.push({ description: item });
    }

    // Convert the updated array back into a JSON string
    sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));


    setAiText(item);
    setAiModalToggle(!aimodalToggle);
  };
  const HandleGreenDotForImage = (e) => {
    e.stopPropagation();
    setAiModalToggle3(!aimodalToggle3);
  };

  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
  };
  // below funtion is for Ai suggestion message

  const HandelAiItems2 = (item) => {
    setAiText2(item);
    setAiModalToggle2(!aimodalToggle2);
  };

  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
  };

  const handleImageChange = (e) => {
    setIsImageLoading(true)
    const file = e.target.files[0];
    uploadImage(file)
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const src = event.target.result;
        setImage(src);
      };

      reader.readAsDataURL(file);
    }

    setAiModalToggle3(false);
  };
  const uploadImage = (e) => {

    const formData = new FormData();
    formData.append('image', e);
    formData.append('account_id', accountId)
    // Send the file to the backend using axios
    axios.post(`${nodeUrl}//upload-fb-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {

        setIsImageLoading(false);

        // Retrieve the current array from sessionStorage
        let fbDataArray = sessionStorage.getItem('instaData');

        // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
        fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];

        // Find the item with the "hash" key in the array
        let itemToUpdate = fbDataArray?.find(obj => obj.hash === response?.data?.hash);

        if (itemToUpdate) {
          // Update the imagePath if it's different
          if (itemToUpdate.imagePath !== response?.data?.image_path) {
            itemToUpdate.imagePath = response?.data?.image_path;
          }
        } else {
          // If the item does not exist, add the new item directly
          fbDataArray.push({
            hash: response?.data?.hash,
            imagePath: response?.data?.image_path
          });
        }

        // Convert the updated array back into a JSON string
        sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));
      })
      .catch(error => {
        setIsImageLoading(false)
        // toast.error("Error in uploading image")
        toast.error("Error in uploading image", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      });
  }


  return (
    <div
      className="google-content-main"
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        setMessageEdit(false);
        // setAiModalToggle2(false);
        // setMessageEdit2(false);
        setAiModalToggle3(false);
      }}
    >
      <div className="facebook-laptopimg-main-box">
        <div
          className="google-content-mainbox"
          style={{ flexWrap: "wrap", gap: "3px" }}
        >
          <div className="google-content-mainbox-header">
            <div className="google-content-mainbox-header-div">
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{facebookBlackIcon}</span>
                <div>
                  <h6>
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6>
                  <p>Sponsored</p>
                </div>
              </div>
              <div>{dotIconReverse}</div>
            </div>
          </div>

          {/* <div
            className="google-content-mainbox-div2"
            onClick={() => setMessageEdit2(!messageEdit2)}
          >
            {!aiText2 ? (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  flexDirection: "column",
                  width: "90%",
                }}
              >
                {halfLoader}
                {FullLoader}
              </div>
            ) : (
              <div
                style={{ color: "#546881", fontSize: "12px", width: "80%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMessageEdit2(!messageEdit2);
                }}
              >
                {!messageEdit2 ? (
                  aiText2
                ) : (
                  <input
                    style={{ width: "100%", outline: "none" }}
                    value={aiText2}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setAiText2(e.target.value);
                    }}
                  />
                )}
              </div>
            )}
            <div
              className="google-content-greendot"
              onClick={HandleGreenDot2}
            ></div>
          </div> */}

          <div
            className="google-content-mainbox-div2"
            style={{ width: "100%" }}
          >
            <div
              className="google-content-mainbox-div3 "
              style={{
                width: "90%",
                // height: "140px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
             <div>
                {isImageLoading ?
                  <Spinner style={{ marginTop: "20px" }} /> : Image ? (
                    <img
                      style={{
                        width: "100%",
                        height: "96px",
                        background: "white",
                        overflow: "scroll",
                        alignItems: "center",
                        marginTop: "20px"
                      }}
                      src={Image}
                      alt=""
                    />
                  ) : (
                    <div className="facebook-laptop-modal-img-box">&nbsp;</div>
                  )
                }
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  onClick={() => setMessageEdit(!messageEdit)}
                >
                  <p className="google-content-yourwebsite">{`${
                    LocalwebsiteUrl ? LocalwebsiteUrl : "Your Website"
                  }`}</p>

                  {!aiText ? (
                    FullLoader
                  ) : (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMessageEdit(!messageEdit);
                      }}
                      style={{ fontSize: "10px" }}
                    >
                      {!messageEdit ? (
                        aiText
                      ) : (
                        <textarea
                          style={{ width: "95%", outline: "none" }}
                          value={aiText}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setAiText(e.target.value);
                            handleDescriptionChange(e)
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "50px",
                marginTop: "24px",
              }}
            >
              <div
                className="google-content-greendot"
                style={{
                  position: "relative",
                  marginTop: "-30px",
                  cursor: "pointer",
                  display: "inline-block",
                }}
              >
                <input
                  id="file"
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "11px",
                    left: "0",
                    width: "131px",
                    height: "38px",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={(e) => handleImageChange(e)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="153"
                  height="50"
                  viewBox="0 0 203 60"
                  fill="none"
                >
                  <g filter="url(#filter0_d_392_5164)">
                    <path
                      d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                      fill="url(#paint0_linear_392_5164)"
                    />

                    <text x="24%" y="64%" className="pickHeadlineSvgText" style={{ fontSize: "16px", fill: "white" }}>
                      Pick Image
                      <input
                        id="file"
                        type="file"
                        accept="image/*"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleImageChange(e)}
                      />
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_392_5164"
                      x="0"
                      y="0"
                      width="203"
                      height="90"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="11" dy="11" />
                      <feGaussianBlur stdDeviation="11.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_392_5164"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_392_5164"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_392_5164"
                      x1="98.868"
                      y1="12"
                      x2="98.868"
                      y2="70.5283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#47BEB9" />
                      <stop offset="1" stopColor="#215856" />
                    </linearGradient>
                  </defs>
                </svg>


              </div>
              <div
                className="google-content-greendot"
                onClick={(e) => HandleGreenDot(e)}
                style={{ marginTop: "-10px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="153"
                  height="50"
                  viewBox="0 0 203 60"
                  fill="none"
                >
                  <g filter="url(#filter0_d_392_5164)">
                    <path
                      d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                      fill="url(#paint0_linear_392_5164)"
                    />
                    <text x="24%" y="64%" className="pickHeadlineSvgText">
                      Pick Description
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_392_5164"
                      x="0"
                      y="0"
                      width="203"
                      height="90"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="11" dy="11" />
                      <feGaussianBlur stdDeviation="11.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_392_5164"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_392_5164"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_392_5164"
                      x1="98.868"
                      y1="12"
                      x2="98.868"
                      y2="70.5283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#47BEB9" />
                      <stop offset="1" stop-color="#215856" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="facebook-content-socialmedia-box">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <div>
                <span style={{ marginRight: "-4px" }}>{FacebookLikeSvg}</span>
                <span style={{ marginRight: "-4px" }}>{FaceBookHeartSvg}</span>
                <span>{FaceBookHahaSvg}</span>
              </div>
              <p style={{ color: "#000", fontSize: "14px", fontWeight: "500" }}>
                77
              </p>
            </div>

            <div
              style={{
                color: "#000",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "12px",
                fontFamily: "Poppins",
              }}
            >
              <p> 5 Comments</p>
              <p> 1 Share</p>
            </div>
          </div>
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              color: "#000",
              justifyContent: "space-between",
              width: "100%",
              padding: "2px 10px",
              borderTop: "0.758px solid var(--text-lighter, #B2BBC6)",
            }}
          >
            <p>{TransparentLike} Like</p>
            <p>{TransparentComment} Comment</p>
            <p>{TransparentShare} Share</p>
          </div>
        </div>

        {aimodalToggle2 && (
          <AiGenerationPopUpLaptop
            data={aiApiHeadline}
            // data={MessageData}
            title={"Message"}
            onClick={HandelAiItems2}
            extrastyle={"laptopModalMarginAiMessage"}
          />
        )}

        {aimodalToggle && (
          <AiGenerationPopUpLaptop
            data={aiApiDescription}
            // data={data}
            title={"Descriptions"}
            extrastyle={"laptopModalMarginAiHeading"}
            onClick={HandelAiItems}
          />
        )}

        {aimodalToggle3 && (
          <AiGenerationPopUpLaptopImage
            data={aiApiDescription}
            image={Image}
            styleprop={"laptopImageAiModal"}
            title={"Headlines"}
            onClick={handleImageChange}
          />
        )}
      </div>
    </div>
  );
};

const AiGenerationPopUpLaptopImage = ({
  onClick,
  title,
  data,
  Image,
  styleprop,
}) => {
  return (
    <div
      className={`google-content-modal ${styleprop}`}
      onClick={(e) => e.stopPropagation()}
      // style={{ top: "100px", width: "23%", padding: "40px 16px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <input
          id="file"
          type="file"
          accept="image/*"
          style={{ width: "100%", textAlign: "center" }}
          onChange={(e) => onClick(e)}
        />
        <label
          style={{
            color: "#f2f2f2",
            background: "transparent",
            width: "160px",
            textAlign: "center",
            padding: "8px 16px",
            borderRadius: "16px",
          }}
          for="file"
          class="btn-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_6231)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="30%"
                y="60%"
                font-family="Arial"
                font-size="40"
                fill="white"
                className="uploadSvgBtn"
              >
                UPLOAD
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_6231"
                x="0.967773"
                y="0"
                width="188.698"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_6231"
                />
              </filter>
            </defs>
          </svg>
        </label>
        {/* </CommonBtn> */}
        {/* <CommonBtn padding={"5px 10px"} gap={"5px"} left={false} icon={AiIcon}>
          <Link to={"/main/Generate/background"}>Generate Image</Link>
        </CommonBtn> */}
      </div>
    </div>
  );
};

const MobileView = ({
  tempData,
  aiText,
  setAiText,
  aiText2,
  setAiText2,
  Image,
  setImage,
  aiApiDescription,
  aiApiHeadline,
  LocalwebsiteUrl,
  LocalCompanyName,
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [aimodalToggle3, setAiModalToggle3] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [messageEdit, setMessageEdit] = useState(false);
  const [messageEdit2, setMessageEdit2] = useState(false);

  const [lgShow, setLgShow] = useState(false);
  const [accountId, setAccountId] = useState();
  const token = Cookies.get("token");

  const handleDescriptionChange= (e)=>{
    if(e.target.value==""){
      let fbDataArray = sessionStorage.getItem('instaData');

      // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
      fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
  
      // Find the item with the "description" key in the array
  
      fbDataArray = fbDataArray.filter(obj => !obj.description);
  
      // Convert the updated array back into a JSON string
      sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));
    }
   
    
      }
  useEffect(() => {

    setAccountId(sessionStorage.getItem("account_id"))
  }, [])

  useEffect(() => {
    if (tempData && tempData?.image?.length > 0) {
      setImage(tempData?.image);
    }
  }, []);

  const HandelAiItems = (item) => {

    // Retrieve the current array from sessionStorage
    let fbDataArray = sessionStorage.getItem('instaData');

    // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
    fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];

    // Find the item with the "description" key in the array
    let itemToUpdate = fbDataArray?.find(obj => obj.description);

    if (itemToUpdate) {
      // Update the description if it's different
      if (itemToUpdate.description !== item) {
        itemToUpdate.description = item;
      }
    } else {
      // If the item does not exist, add the new item directly
      fbDataArray.push({ description: item });
    }

    // Convert the updated array back into a JSON string
    sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));


    setAiText(item);
    setAiModalToggle(!aimodalToggle);
  };
  const HandleGreenDotForImage = (e) => {
    e.stopPropagation();
    setAiModalToggle3(!aimodalToggle3);
  };

  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
  };
  // below funtion is for Ai suggestion message

  const HandelAiItems2 = (item) => {
    setAiText2(item);
    setAiModalToggle2(!aimodalToggle2);
  };

  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
  };

  const handleImageChange = (e) => {
    setIsImageLoading(true)
    const file = e.target.files[0];
    uploadImage(file)
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const src = event.target.result;
        setImage(src);
      };

      reader.readAsDataURL(file);
    }

    setAiModalToggle3(false);
  };
  const uploadImage = (e) => {

    const formData = new FormData();
    formData.append('image', e);
    formData.append('account_id', accountId)
    // Send the file to the backend using axios
    axios.post(`${nodeUrl}/upload-insta-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
    })
      .then(response => {

        setIsImageLoading(false);

        // Retrieve the current array from sessionStorage
        let fbDataArray = sessionStorage.getItem('instaData');

        // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
        fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];

        // Find the item with the "hash" key in the array
        let itemToUpdate = fbDataArray?.find(obj => obj.hash === response?.data?.hash);

        if (itemToUpdate) {
          // Update the imagePath if it's different
          if (itemToUpdate.imagePath !== response?.data?.image_path) {
            itemToUpdate.imagePath = response?.data?.image_path;
          }
        } else {
          // If the item does not exist, add the new item directly
          fbDataArray.push({
            hash: response?.data?.hash,
            imagePath: response?.data?.image_path
          });
        }

        // Convert the updated array back into a JSON string
        sessionStorage.setItem('instaData', JSON.stringify(fbDataArray));
      })
      .catch(error => {
        setIsImageLoading(false)
        // toast.error("Error in uploading image")
        toast.error("Error in uploading image", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      });
  }

  const GetImage = ()=>{}

  return (
    <div
      className="google-content-main facebook-mobileview-box"
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        // setAiModalToggle2(false);
        setMessageEdit(false);
        // setMessageEdit2(false);
        setAiModalToggle3(false);
      }}
    >
      <div className="facebook-mobileview-box-subdiv" style={{}}>
        <div
          className="google-content-mainbox"
          style={{ flexWrap: "wrap", gap: "4px" }}
        >
          <div className="google-content-mainbox-header">
            <div className="google-content-mainbox-header-div">
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{facebookBlackIcon}</span>
                <div>
                  <h6>
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6>
                  <p>Sponsored</p>
                </div>
              </div>
              <div>{dotIconReverse}</div>
            </div>
          </div>

          <div
            className="google-content-mainbox-div2"
            style={{ width: "100%" }}
          >
            <div
              className="google-content-mainbox-div3"
              style={{
                width: "90%",
                // height: "160px",
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
            <div>
                {isImageLoading ?
                  <Spinner style={{ marginTop: "20px" }} /> : Image ? (
                    <img
                      style={{
                        width: "100%",
                        height: "96px",
                        background: "white",
                        overflow: "scroll",
                        alignItems: "center",
                        marginTop: "20px"
                      }}
                      src={Image}
                      alt=""
                    />
                  ) : (
                    <div className="facebook-mobile-modal-img-box">&nbsp;</div>
                  )
                }
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "95%",
                  }}
                  onClick={() => setMessageEdit(!messageEdit)}
                >
                  <p className="google-content-yourwebsite">{`${
                    LocalwebsiteUrl ? LocalwebsiteUrl : "Your Website"
                  }`}</p>

                  {!aiText ? (
                    halfLoader
                  ) : (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMessageEdit(!messageEdit);
                      }}
                    >
                      {!messageEdit ? (
                        aiText
                      ) : (
                        <textarea
                          style={{ width: "95%", outline: "none" }}
                          value={aiText}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            setAiText(e.target.value);
                            handleDescriptionChange(e)
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* green buttons for actions  */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: "50px",
                marginTop: "24px",
              }}
            >
            <div
                className="google-content-greendot"
                style={{
                  position: "relative",
                  marginTop: "-30px",
                  cursor: "pointer",
                  display: "inline-block",
                }}
              >
                <input
                  id="file"
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: "11px",
                    left: "0",
                    width: "131px",
                    height: "38px",
                    opacity: "0",
                    cursor: "pointer",
                  }}
                  onChange={(e) => handleImageChange(e)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="153"
                  height="50"
                  viewBox="0 0 203 60"
                  fill="none"
                >
                  <g filter="url(#filter0_d_392_5164)">
                    <path
                      d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                      fill="url(#paint0_linear_392_5164)"
                    />

                    <text x="24%" y="64%" className="pickHeadlineSvgText" style={{ fontSize: "16px", fill: "white" }}>
                      Pick Image
                      <input
                        id="file"
                        type="file"
                        accept="image/*"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleImageChange(e)}
                      />
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_392_5164"
                      x="0"
                      y="0"
                      width="203"
                      height="90"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="11" dy="11" />
                      <feGaussianBlur stdDeviation="11.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_392_5164"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_392_5164"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_392_5164"
                      x1="98.868"
                      y1="12"
                      x2="98.868"
                      y2="70.5283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#47BEB9" />
                      <stop offset="1" stopColor="#215856" />
                    </linearGradient>
                  </defs>
                </svg>


              </div>
              <div
                className="google-content-greendot"
                onClick={(e) => HandleGreenDot(e)}
                style={{ marginTop: "-20px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="153"
                  height="90"
                  viewBox="0 0 203 90"
                  fill="none"
                >
                  <g filter="url(#filter0_d_392_5164)">
                    <path
                      d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                      fill="url(#paint0_linear_392_5164)"
                    />
                    <text x="24%" y="44%" className="pickHeadlineSvgText">
                      Pick Discription
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_392_5164"
                      x="0"
                      y="0"
                      width="203"
                      height="90"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="11" dy="11" />
                      <feGaussianBlur stdDeviation="11.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_392_5164"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_392_5164"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_392_5164"
                      x1="98.868"
                      y1="12"
                      x2="98.868"
                      y2="70.5283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#47BEB9" />
                      <stop offset="1" stop-color="#215856" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          {/* social media */}
          <div className="facebook-content-socialmedia-box">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <div>
                <span style={{ marginRight: "-4px" }}>{FacebookLikeSvg}</span>
                <span style={{ marginRight: "-4px" }}>{FaceBookHeartSvg}</span>
                <span>{FaceBookHahaSvg}</span>
              </div>
              <p style={{ color: "#000", fontSize: "14px", fontWeight: "500" }}>
                77
              </p>
            </div>

            <div
              style={{
                color: "#000",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "12px",
                fontFamily: "Poppins",
              }}
            >
              <p> 5 Comments</p>
              <p> 1 Share</p>
            </div>
          </div>
          {/* social media 2 */}
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              color: "#000",
              justifyContent: "space-between",
              width: "100%",
              padding: "2px 10px",
              borderTop: "0.758px solid var(--text-lighter, #B2BBC6)",
            }}
          >
            <p>{TransparentLike} Like</p>
            <p>{TransparentComment} Comment</p>
            <p>{TransparentShare} Share</p>
          </div>
        </div>

        {aimodalToggle && (
          <AiGenerationModal
            data={aiApiDescription}
            // data={data}
            title={"Descriptions"}
            onClick={HandelAiItems}
            styleprop={"mobileHeadlinesWidthProp"}
          />
        )}

        {aimodalToggle2 && (
          <AiGenerationModal
            // width={"26%"}
            styleprop={"mobileMessageWidthProp"}
            data={aiApiHeadline}
            title={"Message"}
            onClick={HandelAiItems2}
          />
        )}

        {aimodalToggle3 && (
          <AiGenerationModalForImage
            width={"26%"}
            // data={MessageData}
            title={"Message"}
            onClick={handleImageChange}
            Image={GetImage}
          />
        )}

        {/* {aimodalToggle && (
          <AiGenerationPopUpLaptop
            data={data}
            title={"Headlines"}
            onClick={HandelAiItems}
          />
        )} */}
      </div>
    </div>
  );
};

export const ToggleBtn = ({ toggle, handleToggleBtn }) => {
  return (
    <div>
      <button
        className={`desktop-toggle-btn-main ${toggle && "active"}`}
        onClick={handleToggleBtn}
      >
        <p
          className="desktop-toggle-text"
          style={toggle ? { display: "none" } : {}}
        >
          Desktop
        </p>
        <div className="desktop-icon-main">
          {toggle ? MobileSvg : desktopIcon}
        </div>
        <p
          className="desktop-toggle-text"
          style={!toggle ? { display: "none" } : {}}
        >
          Mobile
        </p>
      </button>
    </div>
  );
};

const AiGenerationModal = ({ onClick, title, data, width, styleprop }) => {
  return (
    <div
      className={`google-content-modal mobile-headline-suggestion ${styleprop}`}
      onClick={(e) => e.stopPropagation()}
      style={{}}
    >
      <div className="google-content-modal-div1">
        <h1>AI Suggestions</h1>
        <p>{`Instagram Ads > ${title}`}</p>
      </div>
      <div
        style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
      ></div>
      <div className="google-content-modal-div2">
        {/* {data.map((item, i) => (
          <ul>
            <li key={i} onClick={() => onClick(item)}>
              {item}
            </li>
          </ul>
        ))} */}
        {data ? (
          data.map((item, i) => (
            <ul>
              <li key={i} onClick={() => onClick(item)}>
                {item}
              </li>
            </ul>
          ))
        ) : (
          <Spinner style={{ alignSelf: "center" }} />
        )}
      </div>
      {/* <div>
        <CommonBtn padding={"5px 10px"} gap={"5px"} left={false} icon={AiIcon}>
          Generate More
        </CommonBtn>
      </div> */}
    </div>
  );
};

const AiGenerationModalForImage = ({ onClick, title, data, width, Image }) => {
  return (
    <div
      className={`google-content-modal image-modal-for-mobile  `}
      onClick={(e) => e.stopPropagation()}
      // style={{ marginLeft: "37%", width: width ? width : "30%", top: "153px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {/* <CommonBtn padding={"5px 10px"} gap={"5px"} left={false} icon={AiIcon}> */}
        <input
          id="file"
          type="file"
          accept="image/*"
          style={{ width: "100%", textAlign: "center" }}
          onChange={(e) => onClick(e)}
        />
        <label
          style={{
            color: "#f2f2f2",
            background: "transparent",
            width: "160px",
            textAlign: "center",
            padding: "8px 16px",
            borderRadius: "16px",
          }}
          for="file"
          class="btn-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_6231)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="30%"
                y="60%"
                font-family="Arial"
                font-size="40"
                fill="white"
                className="uploadSvgBtn"
              >
                UPLOAD
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_6231"
                x="0.967773"
                y="0"
                width="188.698"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_6231"
                />
              </filter>
            </defs>
          </svg>
        </label>
        {/* </CommonBtn> */}
        {/* <CommonBtn padding={"5px 10px"} gap={"5px"} left={false} icon={AiIcon}>
          <Link to={"/Generate/background"}>Generate Image</Link>
        </CommonBtn> */}
      </div>
    </div>
  );
};

const AiGenerationPopUpLaptop = ({ onClick, title, data, extrastyle }) => {
  return (
    <div
      className={`google-content-modal ${extrastyle}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="google-content-modal-div1">
        <h1>AI Suggestions</h1>
        <p>{`Instagram Ads > ${title}`}</p>
      </div>
      <div
        style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
      ></div>
      <div className="google-content-modal-div2">
        {data ? (
          data.map((item, i) => (
            <ul>
              <li key={i} onClick={() => onClick(item)}>
                {item}
              </li>
            </ul>
          ))
        ) : (
          <Spinner style={{ alignSelf: "center" }} />
        )}
      </div>
      {/* <div>
        <CommonBtn padding={"5px 10px"} gap={"5px"} left={false} icon={AiIcon}>
          Generate More
        </CommonBtn>
      </div> */}
    </div>
  );
};

export default InstagramContent;
