import React, { useEffect, useState } from "react";
// import "./GoogleAdd-3.css";
import "../../GoogleCampaign/Google-Add-3/GoogleAdd-3.css";

import { Col, Container, Row } from "react-bootstrap";
import { NoForecast } from "../../GoogleCampaign/SideNavContainer/GoogleFunding";
import { Excliamation } from "../../GoogleCampaign/SideNavContainer/SVG";
import {
  EditIcon,
  GreenTick,
  saveIcon,
} from "../../GoogleCampaign/Google-Add-3/SVG";
import { useSelector } from "react-redux";
// import { EditIcon, GreenTick, cancelIcon, saveIcon } from "./SVG";
// import { NoForecast } from "../SideNavContainer/GoogleFunding";
// import { Excliamation } from "../SideNavContainer/SVG";

const YourWebsite = () => {

  const [AdsDetail, setAdsDetail] = useState(null);

  const {SingleGoogleAdDetail}=useSelector(state=>state.AdsAnalyticsDetail)


useEffect(()=>{
  if(SingleGoogleAdDetail && SingleGoogleAdDetail?.adData?.adGroupAd?.ad?.finalUrls[0]){
   
    setAdsDetail(SingleGoogleAdDetail?.adData?.adGroupAd?.ad?.finalUrls[0])
  }
},[SingleGoogleAdDetail])

  return (
    <Container className="adTrffice-main" style={{ margin: "10px" }}>
      <Row style={{ gap: "10px" }} lg={12}>
        <Col lg={6}>
          <LeftBox url={AdsDetail} />
        </Col>
        <Col lg={4}>
          <div className="google-add3-right-div">
            <div className="google-add3-right-div-main ">
              <div className="google-add3-right-div-main-header">
                <p>Analytics</p>
                <button>
                  <span>Connect</span>
                </button>
              </div>
              <div>
                <NoForecast
                  logo={Excliamation}
                  title={`Not connected`}
                  body={`EXOAD is not receiving any events from this page yet. We recommend you connect your page to Balloonary by installing the snippet.`}
                  color="#B2BBC6"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const Header = ({ handleToggle, toggle, handlesave, handleCancel }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <p>Page</p>
      {/* {!toggle ? (
        <span onClick={handleToggle}>{EditIcon}</span>
      ) : (
        <div className="d-flex gap-1 align-items-center justify-content-center">
          <span onClick={handleCancel}>{saveIcon}</span>
        </div>
      )} */}
    </div>
  );
};

const LeftBox = ({url}) => {
  const [toggle, setToggle] = useState(false);
  // const [url, setUrl] = useState("");


  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleCancel = () => {
    setToggle(false);
  };

  const handlesave = (e) => {
    setToggle(e.target.value);
  };
  return (
    <div className="google-add3-div1">
      <div className=" google-add3-div1-main">
        <Header
          handleToggle={handleToggle}
          toggle={toggle}
          handlesave={handlesave}
          handleCancel={handleCancel}
        />
      </div>
      {false ? (
        <div className="d-flex  flex-column gap-1 " style={{}}>
          <label>enter website url</label>
          <input
            className="p-1 rounded webiste-placeholder"
            type="text"
            placeholder="website-Url"
            style={{ background: "#edf9f8", outline: "none" }}
            // onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      ) : (
        <>
        <div className="google-add3-div2">
          <span>{GreenTick}</span>
          <div className="google-add3-div2-main">
            <p>Page connected</p>
            <span>You have connected a valid URL.</span>
          </div>
        </div>

        <div style={{}}>
          {url ? (
            <embed
              type="text/html"
              src={url}
              width="100%"
              height="200"
              style={{ overflow: "hidden" }}
            />
          ) : (
            <div
              style={{
                width: "450px",
                height: "40vh",
                background: "#edf9f8",
              }}
              className="rounded-1"
            ></div>
          )}
        </div>

        <div className="google-add3-div4">
          {url ? url : "https://your-website.com"}
        </div>
      </>
      )}
    </div>
  );
};

export default YourWebsite;
