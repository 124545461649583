import "./Login.css";
import LoginImg from "./Images/Login.png";
import Exoad from "./Images/EXOAD.png";
import vector from "./Images/Vector 3.png";
import google from "./Images/google.png";
import faceBook from "./Images/Facebook.png";
import apple from "./Images/apple.png";
import closeeye from "./Images/password.png";
import eyeOpen from "./Images/openEye.png";
import React, { useRef } from 'react';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../axios/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { userSignUp } from "../../../Redux/login/signupSlice";
import { MailContext } from "./mailContext";
import OTPInput from "react-otp-input";
import { LoginSocialGoogle } from "reactjs-social-login";
import CryptoJS from 'crypto-js';

const SignUp = () => {
  const [modal, setModal] = useState(false);
  const [toggleOtp, setToggleOtp] = useState(false);

  const { siginupModal, handleSiginupModal } = useContext(MailContext);
  const [otp, setOtp] = useState();
  const [user, setUser] = useState();
  const [toggleEye, setToggleEye] = useState(false);
  const [toggleEye2, setToggleEye2] = useState(false);
  const [paswordlength,setPasswordLength] =useState(1)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },trigger, clearErrors
  } = useForm();

  // const { register, handleSubmit, formState: { errors }, trigger } = useForm();
  const password = watch("password", ""); // Watch the "password" field

  const confirmPassword = watch("confirmPassword", ""); // Watch the "confirmPassword" field
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (signUpdata) => {
    if (password === confirmPassword) {
      SignUpOtp(signUpdata);
      setUser(signUpdata);
    }
  };

  const SignUpOtp = async (userData) => {
    try {

      let secretKey ="exoads"
    const confirmPassword = CryptoJS.AES.encrypt(userData.confirmPassword, secretKey).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(userData.password, secretKey).toString();

      const userDto ={
         confirmPassword:confirmPassword,
         email:userData.email,
         fullName:userData.fullName,
           password:encryptedPassword

      }
      
      const res = await axios.post("/signup/otp", userDto);
      if (res?.data?.data) {
        setToggleOtp(true);

        toast.success(res?.data?.message, {
          className: 'custom-toast-success',
          hideProgressBar: true
        });
      } else {
        toast.error(res?.data?.message, {
         className: 'custom-toast-error',
          hideProgressBar: true
        }); 
      }


      return res?.data;
    } catch (error) {
    }
  };

  const handleResendOtp = () => {
    setOtp("");
    SignUpOtp(user);
  };

  const OtpVerification = async (data) => {
let secretKey ="exoads"
const encpassword = CryptoJS.AES.encrypt(data?.password, secretKey).toString();
    let para = {
      fullName: data?.fullName,
      email: data?.email,
      password: encpassword,
      otp: otp,
      role: "user",
    };

    try {
      const res = await axios.post(
        "/signup",
        para // Assuming 'data' is a variable with the OTP value
      );
      if (res?.data?.status == true) {
         toast.success(res?.data?.message, {
          className: 'custom-toast-success',
          hideProgressBar: true
        });
        setModal(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000); // 2000 milliseconds = 2 seconds
      } else {
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      }
    } catch (error) {
      throw error; // Re-throw the error to handle it in the onSubmit catch block
    }
  };

  const handleClick = () => {
    navigate('/landingpage/home');
  };

  const handleCloseModal = () => {
    setModal(false);

    navigate("/login");
  };
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const handlePasswordChange = async (event) => {
    const passwordValue = event.target.value;
    setPasswordLength(passwordValue.length)
    
    // Check the length of the password
    if (passwordValue.length < 8) {
      // Trigger validation if the length is less than 8
      await trigger('password');
    } else {
      clearErrors('password');
    }
  };
  

  return (
    <Container fluid className="layout">
      <>
        <Row className="align-items-center">
          <Col
            className=" liginImg   d-flex justify-content-end align-items-center "
            lg={6}
            md={12}
            sm={12}
          >
          
          </Col>
          <Col style={{ textAlign: "center" }} lg={6} md={12} sm={12}>
            <div
              className="right d-flex justify-content-center align-items-center"
              style={{ padding: "3rem 2rem 1rem 2rem",border:"3px solid white",maxHeight:"690px"}}
            >
              <div className=" ">
                <img src={Exoad} alt="Exoad"  onClick={handleClick} style={{ cursor: "pointer" }} />

                {!toggleOtp ? (
                  <>
                    <div style={{ width: "100%" }}>
                      <h1 className="welcome">Welcome Back !</h1>
                      <p className="p" style={{ width: "100%" }}>
                        Sign In To engage your audience with your <br />{" "}
                        <span>best creatives</span>
                      </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <div>
                          <div
                            className="input-div"
                            style={{ marginBottom: 10 }}
                          >
                            <label
                              style={{ fontSize: 13 }}
                              className="signup-label"
                            >
                              Name<span style={{color:"red"}}>*</span>
                            
                            </label>
                            <input
                              className="input"
                              type="text"
                              maxLength={50}
                              placeholder="Enter your name"
                              {...register("fullName", { required: true })}
                            />
                              {errors.fullName && (
                                <span className="text-danger fw-bold"style={{fontSize:"12px"}}>Name field is required</span>
                              )}
                          </div>
                          <div className="input-div mb">
                            <label
                              style={{ fontSize: 12 }}
                              className="signup-label"
                            >
                              Email Address<span style={{color:"red"}}>*</span>
                             
                            </label>

                            <div className="password-container">
                              <div className="password-sub-container">
                                <input
                                  className="password-container password-input "
                                  type="email"
                                  maxLength={50}
                                  placeholder="Enter your email"
                                  {...register("email", { required: true })}
                                />
                                

                              </div>
                             
                            </div>
                            {errors.email && (
                                <span className="text-danger fw-bold"  style={{ fontSize: 12 }}>
                                  Email field is required
                                </span>
                              )}
                          </div>

                          <div className="input-div mb">
                            <label
                              style={{ fontSize: 12 }}
                              className="signup-label"
                            >
                              Password<span style={{color:"red"}}>*</span>
                              
                            </label>

                            <div className="password-container">
                              <div className="password-sub-container">
                                <input
                                  className="password-container password-input"
                                  type={!toggleEye2 ? "password" : "text"}
                                  maxLength={50}
                                  placeholder="*****"
                                  {...register("password", {
                                    required:true,
                                    minLength: {
                                      value: 8,
                                      message: "Password must be at least 8 characters long",
                                    },
                                  })}
                                  onChange={handlePasswordChange} 
                                />

                                {!toggleEye2 ? (
                                  <img
                                    className="password-icon"
                                    src={closeeye}
                                    alt="password"
                                    onClick={() => setToggleEye2(!toggleEye2)}
                                  />
                                ) : (
                                  <img
                                    className="password-icon"
                                    src={eyeOpen}
                                    alt="password"
                                    onClick={() => setToggleEye2(!toggleEye2)}
                                  />
                                )}
                              </div>
                             
                            </div>
                            {paswordlength > 0 ? (
  errors.password && (
    <span className="text-danger fw-bold" style={{ fontSize: 12 }}>
      {errors.password.message || "Password must be at least 8 characters long"}
    </span>
  )
) : (
  <span className="text-danger fw-bold" style={{ fontSize: 12 }}>
    Password field is required
  </span>
)}

                          </div>
                          <div
                            className="input-div "
                            style={{ marginBottom: 14 }}
                          >
                            <label
                              style={{ fontSize: 12 }}
                              className="signup-label"
                            >
                              Confirm Password<span style={{color:"red"}}>*</span>
                            </label>

                            <div className="password-container">
                              <div className="password-sub-container">
                                <input
                                  className="password-container password-input"
                                  type={!toggleEye ? "password" : "text"}
                                  placeholder="*****"
                                  maxLength={50}
                                  {...register("confirmPassword", {
                                    required: true,
                                  })}
                                />
                                {!toggleEye ? (
                                  <img
                                    className="password-icon"
                                    src={closeeye}
                                    alt="password"
                                    onClick={() => setToggleEye(!toggleEye)}
                                  />
                                ) : (
                                  <img
                                    className="password-icon"
                                    src={eyeOpen}
                                    alt="password"
                                    onClick={() => setToggleEye(!toggleEye)}
                                  />
                                )}
                              </div>
                            </div>

                            {password.length >= 8 &&
                              confirmPassword &&
                              password !== confirmPassword && (
                                <span
                                  className="text-danger fw-bold"
                                  style={{ fontSize: "12px" }}
                                >
                                  Passwords do not match
                                </span>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="button-container">
                        <button type="submit" className="login-button" style={{fontSize:"17px"}}>
                          {"Sign Up"}
                        </button>
                      </div>
                    </form>
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p className="belowbutton">
                        
                        &nbsp; Or log in with &nbsp;{" "}
                      </p>
                    </div>
                    <div className="footer-images" style={{ paddingTop: "6px" }}>
                  <LoginSocialGoogle
                    client_id="222990346622-vjf4q7poq52ca1ksl05njpdpi7okp11h.apps.googleusercontent.com"
                    scope="email profile"
                    // discoveryDocs="claims_supported"
                    // access_type="offline"
                    onResolve={({ provider, data }) => {
                      // setGoogleData(data);
                    }}
                    onReject={({ error }) => {
                      // setIsLoading(false);
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={google}
                      alt="google"
                      // onClick={() => setIsLoading(true)}
                    />
                  </LoginSocialGoogle>

                </div>

                    <div className="footer-container">
                      

                      <div>
                        <p
                          className="footer-bottom"
                          style={{ cursor: "pointer" }}
                        >
                          Already have an Account?&nbsp;
                          <Link to={"/login"} className="footer-span-login">
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <form
                    style={{ paddingTop: "20px" }}
                    className="otp-screen-form"
                    onSubmit={handleSubmit(OtpVerification)}
                  >
                   
   <CustomOTPInput
      value={otp}
      required
      onChange={setOtp}
    />
      


                    <p>
                      Didn’t receive code?
                      <span
                        onClick={handleResendOtp}
                        style={{ cursor: "pointer" }}
                      >
                        Resend
                      </span>
                    </p>
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        width: "80%",
                        justifyContent: "center",
                      }}
                      type="submit"
                      className="login-button button-container"
                    >
                      {"Confrim"}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
        <CustomModal
          // show={true}
          show={modal}

          buttonTitle={"Login"}
          handleCloseModal={handleCloseModal}
        />
      </>
    </Container>
  );
};

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="confirmpass-body-modal">
        <h5 className="confirmpass-label-modal ">
          Your Account has been Successfully Created
        </h5>

        <Button
          className="confirmpass-btn-modal "
          onClick={props.handleCloseModal}style={{width:"200px"}}
        >
          {props.buttonTitle}
        </Button>
      </div>
    </Modal>
  );
};


const CustomOTPInput = ({ numInputs = 6,  value = '', onChange }) => {
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const newOtp = [...value];
    newOtp[index] = e.target.value;
    onChange(newOtp.join(''));

    // Move focus to the next input if available and a digit is entered
    if (e.target.value && index < numInputs - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      // Move focus to the previous input on backspace
      inputsRef.current[index - 1].focus();
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="otp-input-container">
      {Array.from({ length: numInputs }, (_, index) => (
        <input
          key={index}
          className="otp-input"
          type="text"
          maxLength="1"
          required
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onKeyPress={handleKeyPress}
          ref={(el) => (inputsRef.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default SignUp;
