import React, { useEffect, useState } from "react";
import "./brandlist.css";
import Slider from "react-slick";
import NextArrow from "../../../../../../assets/icons/next-icon.svg";
import PrevArrow from "../../../../../../assets/icons/prev-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import ProjectCreationPopup from "../../NewBrandSetup/projectCreationPopup";
import axios from "../../../../../axios/auth"
import Cookies from "js-cookie";
import { addBrandData } from "../../../../../../Redux/brandSetupData";
import { addBrandArray } from "../../../../../../Redux/generatedBrandList";
import { useNavigate } from "react-router-dom";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-box next-box" onClick={onClick}>
      <img src={NextArrow} alt="NextArrow" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-box prev-box" onClick={onClick}>
      <img src={PrevArrow} alt="PrevArrow" />
    </div>
  );
}
export default function BrandList() {
  const userToken = Cookies.get("token");
  const [project,setProjectList]=useState([])

  const { brand } = useSelector((state) => state.brandList);

  const dispatch=useDispatch()
  const [projectPoup, setProjectPopup] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 973,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

const navigate=useNavigate()

  const openPopup = (element) => {
  
          dispatch(addBrandData({ brandname: element?.brandname,brandId:element?._id,logo:element?.logo,  mainBgColor:element?.mainBgColor,  headingColor:element?.headingColor,descriptionColor:element?.descriptionColor,promotionTextColor:element?.promotionTextColor,ctaColor:element?.ctaColor,
            selectedFont:element?.selectedFont,createProject: "createProject"
          }))
          fetchProject(element?._id)
  };
  
  const fetchBrand = async () => {
    try {
      const res = await axios.get("/fetch-brand", {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      
      dispatch(addBrandArray(res?.data?.data))
      // Handle the response as needed
    } catch (e) {
      // console.error("Error:", e);
      // Handle the error as needed
    }
  };


  useEffect(()=>{
    fetchBrand()
  },[])

// fetchProject
const fetchProject = async (brandId) => {
  try {
    const res = await axios.get(`/fetch-brand-project/${brandId}`, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
    setProjectList(res?.data?.data)
    if(res?.data?.data.length>0){
      setProjectPopup(true);
    }
    else{
      navigate("/main/brand-setup")
    }

  } catch (e) {
    // Handle the error as needed
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

  return (
    <div className="brandList-section">
      <div className="brandList-grid">
        {brand.map((element, index) => {
          return (
            <div
              className="silder-box-alignment"
              
            >
              <div className="brandList-details-box" onClick={() => openPopup(element)}>
                <div className="brandList-name-alignment">
                  <div className="brandList-profile">
                    {/* <span>R</span> */}
                    <span><img style={{width:"30px",height:"30px"}} className="rounded-circle" src={element?.logo}/></span>
                  </div>
                  <div className="brandList-profile-name">
                    <h2>{element?.brandname}</h2>
                    <p>{element?.noOfProject
                    } Project Created</p>
                  </div>
                </div>

                <div className="brandList-notifocation">
                  <div className="brandList-notifocation-box">
                    <div>
                      <h4>Brand</h4>
                      <p>Yaay! You Have Successfully Created Your Brand</p>
                    </div>

                    <div className="celebtationIcon">
                      <p>🎉</p>
                    </div>
                  </div>
                </div>

                <div className="brandList-description-details">
                  <div className="sidebg"></div>
                  <div className="brandList-description-white-box">
                    <div className="brandList-description-details-heading ">
                      <h5>Discription</h5>
                      <span>{formatDate(element.createdAt)}</span>
                    </div>
                    <div className="all-description">
                      <p>{element?.brandDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {projectPoup && ( 
        <ProjectCreationPopup setProjectPopup={setProjectPopup} project={project}/>
      )}
    </div>
  );
}
