import React, { useEffect, useState } from "react";
import "./MainPage.css";
import Chatbot from "./Images/Chatbot.png";
import "../Pages/Header/Header.css";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "../Pages/SideBar";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Header from "../Pages/Header/Header";
import {
  ToggleContext,
  ToggleProvider,
} from "../Pages/Campaigns/GoogleCampaign/ContextFile";
import Cookies from "js-cookie";
import axios from "../../axios/auth";
import { useDispatch, useSelector } from "react-redux";
import { adduserdata } from "../../../Redux/login/loginSlice";
import ChatBotUi from "./ChatBotUi";
import TestingPage from "../../../Testing/TestingPage";
import { useContext } from "react";
import { MailContext } from "../Auth Pages/mailContext";
import GoogleFontLoader from "react-google-font-loader";
// import { ToastContainer, toast } from "react-toastify";

const MainPage = () => {
  const userToken = Cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false); //used in header component
  const [UserFonts, setUserFonts] = useState();
  const [notification, setNotification] = useState([]);

  const { aiSuggestionLoader, HandleAiSuggestionLoader, fonts } = useContext(
    MailContext
  );

  useEffect(() => {
    fetchdata();
    fetchNotification();
  }, []);

  const fetchdata = async () => {

    if(userToken){
    try {
      const res = await axios.get("/getUser", {
        headers: {
          Authorization: `Bearer ${userToken}`, // Correct spelling
        },
      });

      dispatch(adduserdata(res.data?.data[0]));
      if (res.data?.data[0].role == "admin") {
        navigate("/admin");
      }
      if (res.data?.data[0]?.fonts[0]?.fontsFamily) {
        setUserFonts(res.data?.data[0].fonts[0].fontsFamily);
      }
    } catch (e) {
  
      if (e?.response?.data?.message === "User Unauthorised") {
        navigate("/login");
        // return <Navigate to="/login" />;
      } else if (e?.response?.statusText === "Unauthorized") {
        navigate("/login");
      }
    }}
    else{
      navigate("/login");
    }
  };

  const fetchNotification = async () => {

    if(userToken){
    try {


      const res = await axios.get("/getNotification", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setNotification(res.data.data.slice(-10));
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  }
  };

  const Bot = () => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/650c2d110f2b18434fd9c60f/1harp03km";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  };

  if (true) {
    {
      // Bot();
    }

    return (
      <ToggleProvider>
        {aiSuggestionLoader && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: "999",
            }}
          >
            <TestingPage />
          </div>
        )}
        <Container
          fluid
          style={{ zIndex: -1 }}
          className="main-container-main campaign-main-scroller"
        >
          {/* <ChatBotUi /> */}
          <Row style={{}}>
            <GoogleFontLoader
              fonts={[
                { font: fonts, weights: [300, 400, 500, 600, 700, 800, 900] },
              ]}
            />
            {UserFonts?.map((item, index) => (
              <GoogleFontLoader
                key={index} // Use a unique key for each font loader
                fonts={[
                  {
                    font: item,
                    weights: [300, 400, 500, 600, 700, 800, 900],
                  },
                ]}
              />
            ))}
            <Col className="p-0" lg={2} md={3}>
              <div className="mainpage-show-sidebar">
                <SideBar />
              </div>
            </Col>
            <Col className="d-flex flex-column" lg={10} md={9}>
              <Row
                className="bg-success"
                style={
                  {
                    // position: "sticky",
                  }
                }
              >
                <Header
                  modalShow={modalShow}
                  setModalShow={setModalShow}
                  notification={notification ? notification : []}
                />
              </Row>
              <Row
                style={{ height: "100%" }}
                className="main-content-main-page"
              >
                <Outlet />
              </Row>
            </Col>
          </Row>
        </Container>
        {/* <ToastContainer  style={{fontSize:"12px"}}/> */}
      </ToggleProvider>
    );
  } else {
    // Redirect to the login page if userToken is not present
    return <Navigate to="/login" />;
  }
};

export default MainPage;
