import React, { useEffect, useState } from "react";
import style from "./Setting.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SingleModal, plan1 } from "../Plans/Plans";
import { GreenDiamond } from "../Plans/Svg";
import { InputField } from "../Generate Page/TextAndBtnPage";
import { CommonBtn } from "../../../UI-Components/ReusableComponents";
import { useForm } from "react-hook-form";
import axiosUrl from "../../../axios/auth";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const Settings = () => {
  const token = Cookies.get("token");
  const { register, handleSubmit } = useForm();
  const [plandata, setPlandata] = useState([]);

  const { user } = useSelector((state) => state.loginuser);

  const [data, setData] = useState({
    email: user?.email ? user.email : "",
    name: user?.fullName ? user.fullName : "",
    companyname: user?.companyName ? user?.companyName : "",
  });

  const submit = (data) => {};
  useEffect(() => {
    activplan();
    AIModalApi();
  }, []);

  const activplan = async () => {
    try {
      const res = await axiosUrl.get("/userActivePlans", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const letestplan = res?.data?.data.length - 1;
      setPlandata(res?.data?.data[letestplan]);
    } catch (error) {
      // Handle the 401 error here
    }
  };

  const AIModalApi = async () => {
    try {
      // const res = await axios.get(
      //   `https://4pbg7k6zm5paluzcystubioo7u0qjjvl.lambda-url.ap-south-1.on.aws/?query=Write 10 facebook ad Discription for the provided text in between 90 to 120 words return the output in jason with key value description1: text, description2: text, and so on. for text: Welcome To IT Spark Technology. Let us be your next Preferred IT Partner. With more than 7 years of experience, IT Spark provides the solutions any company needs to continuously expand while building a strong online presence. We are armed with a large team of experts in AI development, application development, Quality Assurance, web development and Digital Marketing`,
      //   {
      //     headers: {
      //       //   Authorization: `Bearer ${token}`,
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      const url =
        "https://4pbg7k6zm5paluzcystubioo7u0qjjvl.lambda-url.ap-south-1.on.aws/?query=Write 10 facebook ad Description for the provided text in between 90 to 120 words return the output in json with key value description1: text, description2: text, and so on. for text: Welcome To IT Spark Technology. Let us be your next Preferred IT Partner. With more than 7 years of experience, IT Spark provides the solutions any company needs to continuously expand while building a strong online presence. We are armed with a large team of experts in AI development, application development, Quality Assurance, web development and Digital Marketing";

      const response = await axios.get(url, {
        headers: {
          //"Content-Type": "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Handle the response data here

    } catch (error) {
      // Handle the 401 error here
    }
  };
  const navigate = useNavigate();

  const navigateFunction = () => {
    navigate("/plans");
  };

  const handleEdit = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid className={style.settingsMainContainer}>
      <Row>
        <Col lg={6}>
          <Row className={style.col2Box} style={{ width: "100%" }}>
            <h1 className={style.plansHeading}>User</h1>
            <div style={{ width: "100%" }}>
              <Col lg={9}>
                <InputField
                  label={"Name"}
                  placeholder={"User Name"}
                  register={register}
                  registerName={"name"}
                  valueName={data?.name}
                  previewText={handleEdit}
                  textColor={"#000"}
                />
                {/* <InputField
                  label={"Company Name"}
                  placeholder={"eg. Superpower Inc."}
                  register={register}
                  valueName={data?.companyname}
                  registerName={"companyName"}
                /> */}
                <InputField
                  label={"Email"}
                  placeholder={"user@gmail.com"}
                  register={register}
                  registerName={"email"}
                  valueName={data?.email}
                  previewText={handleEdit}
                  textColor={"#000"}
                />
                <InputField
                  label={"Password"}
                  placeholder={"********"}
                  text={"password"}
                  register={register}
                  registerName={"password"}
                  textColor={"#000"}
                />
              </Col>
            </div>
            <div
              style={{ width: "100%", height: "0.5px", background: " #B2BBC6" }}
            ></div>

            {/* billing info */}
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className={style.billing}>Billing info</h5>
                <Link
                  className={style.ChangePlanBtn}
                  style={{
                    display: "flex",

                    paddingRight: "20px",
                  }}
                >
                  CHANGE
                </Link>
              </div>
              <p className={style.billingMain}>
                418, Shekhar Central New Palasia,
                <br /> Indore ,Madhya Pradesh, India.
              </p>
            </div> */}
            {/* <div
              style={{ width: "100%", height: "0.5px", background: " #B2BBC6" }}
            ></div> */}

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className={style.dangerZoneHeading}>Danger Zone</p>
              <div style={{ display: "flex", gap: "4px" }}>
                <CommonBtn color={"#D30303"}>Delete Account</CommonBtn>
              </div>
            </div> */}
          </Row>
        </Col>
        <Col style={{ display: "flex", gap: "20px" }} lg={6}>
          <div
            className={style.HideDividerSetting}
            style={{ width: "0.5px", height: "100%", background: " #B2BBC6" }}
          ></div>
          <div className={style.col2Box} style={{ width: "100%" }}>
            <h1 className={style.plansHeading}>Active plan</h1>
            <div style={{ width: "100%" }}>
              {plandata ? (
                <SingleModal
                  lg={7}
                  plans={plandata}
                  md={10}
                  PlanType={"Starter"}
                  amount={24}
                  data={plan1}
                  Checkout={navigateFunction}
                  // toggle={toggle}
                  yearlyAmount={244}
                  // handleModalClick={handleModalClick}
                  Diamond={GreenDiamond}
                  selectPlanBtnTitle={"Select New Plan"}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "700",
                    gap: "12px",
                  }}
                >
                  No Active Plan
                  <Link
                    style={{
                      color: "#f2f2f2",
                      fontWeight: "500",
                      fontSize: "14px",
                      // border: "2px solid ",
                      padding: "10px 20px",
                      background: "#546881",
                      borderRadius: "10px",
                    }}
                    to={"/plans"}
                  >
                    Renew Your plan
                  </Link>
                </div>
              )}
            </div>
            {/* <Link
              className={style.ChangePlanBtn}
              style={{ textAlign: "end", width: "100%", paddingRight: "20px" }}
              to={"/plans"}
            >
              Change Plan
            </Link> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
