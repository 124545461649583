import { Link, NavLink, Outlet } from 'react-router-dom';
import './AdminSidebar.css'
import Styles from "./AdminSidebar.module.css"

import sidebarbg from "../../../assets/images/backgroundImg/sidebar.png"

function AdminSidebar() {
    return (
        <>
            <div className={`${Styles.sidebarBgColor} `} style={{ overflow:"scroll", scrollbarWidth:"0px", scrollbarWidth: "none" }}>
                <ul className=" pt-4" style={{ listStyle: "none"}} >
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h3 className=" text-white fw-bold" ><NavLink to="/admin/dashboard" className={({isActive})=> isActive ?`${Styles.active}`:``}>  Dashboard  </NavLink></h3></div>
                    </li>

                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/managecustomer" className={({isActive})=> isActive ?`${Styles.active}`:``}>  Manage Customer </NavLink> </h6> </div>
                    </li>

                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/planmanagement"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Plan  </NavLink>   </h6></div>
                    </li>

                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageCredits"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Credits  </NavLink>   </h6></div>
                    </li>

                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageEnquiry"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Enquiries  </NavLink>   </h6></div>
                    </li>
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageJobs"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Jobs  </NavLink>   </h6></div>
                    </li>
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageFacebook"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Facebook Ads  </NavLink>   </h6></div>
                    </li>
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageInstagram"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage InstagramAds </NavLink>   </h6></div>
                    </li>
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageGoogle"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage GoogleAds </NavLink>   </h6></div>
                    </li>
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title  "> <NavLink to="/admin/manageExpert"
                        className={({isActive})=> isActive ? `${Styles.active}`:``}
                        > Manage Expert  </NavLink>   </h6></div>
                    </li>
                    
                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/imagerepository"
                        
                        className={({isActive})=> isActive ?`${Styles.active}`:``}>Stock Image</NavLink> </h6></div>
                    </li>

                    {/* <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/manageaccess" className={({isActive})=> isActive ?`${Styles.active}`:``}> 
                        
                        Manage Access </NavLink></h6></div>
                    </li> */}

                    <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/managediscount"  className={({isActive})=> isActive ?`${Styles.active}`:``} >  Manage Blogs  </NavLink> </h6></div>
                    </li>

                    {/* <li className="my-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "><NavLink to="/admin/managecampaign" 
                        className={({isActive})=> isActive ?`${Styles.active}`:``}> Manage Campaign </NavLink>  </h6></div>
                    </li> */}


                    <li className="mt-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/managetransaction" className={({isActive})=> isActive ?`${Styles.active}`:``}> Manage Transaction </NavLink> </h6></div>
                    </li>

                    <li className="mt-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/manageTermsAndConditions" className={({isActive})=> isActive ?`${Styles.active}`:``}> Terms and Conditions </NavLink> </h6></div>
                    </li>

                    <li className="mt-4  align-item-center">
                        <div className="pt-3"><h6 className="title text-white "> <NavLink to="/admin/managePrivacyPolicies" className={({isActive})=> isActive ?`${Styles.active}`:``}> Privacy policies </NavLink> </h6></div>
                    </li>
                    {/* <li className="float-right d-flex justify-content-end me-1">
                    <img src={sidebarbg} style={{ height: "150px" }} />
                </li> */}
                </ul>
            </div>
        </>

    );
}

export default AdminSidebar