import React, { useEffect, useState } from 'react'
import "./GoogleCampignLoder.css";
// import SubmissionImg from "../../../../../assets/images/submission-img.png";
// import LiveImg from "../../../../../assets/images/live-img.png";
import SubmissionImg from "../../../../assets/images/submission-img.png";
// import dashboard from "../../Auth Pages/Images/dashboard.png";
import dashboard from "../../Auth Pages/Images/dashboard.png";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from "js-cookie";

// import axios from "../../../../axios/auth";
import axios from "../../../axios/auth";

import LiveImg from "../../../../assets/images/live-img.png";
import { useDispatch } from 'react-redux';
import { addGoogleAnalytics } from '../Redux/AdsAnalyticsDetailsSlice';
export default function GoogleCampaignsLoader() {



   
    
   


    return (

        <div className='mainLoader1'
        // style={{
        //     backgroundImage: `url(${dashboard})`,
        //     backgroundSize: "contain",
        //     backgroundPosition: "center",
        //     backgroundRepeat: "no-repeat"

        // }}
    >
        <div className='campaignsLoaderWrapper1'>
            <div className="campains-loader-alignment1">


                <div className="campains-details-alignment1">
                    <div className='submission-details-details1'>
                        <img src={SubmissionImg} class='SubmissionImg1'   />
                        <h2>Submission</h2>
                    </div>
                    <div class="progress-container1">
        <div  class="progress-bar1"></div>
    </div>
                    <div>
                        <div className='loader-height-alignment1'>
                            <div className="step-round-alignment1">
                                <div className="second-round1">

                                </div>
                            </div>
                        </div>
                        {["top"].map((placement) => (
                <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                        <Tooltip id={`tooltip-${placement}`}>
                            
                                Approve in 24 Hours
                        </Tooltip>
                    }
                >
                    <h2 style={{cursor:"pointer"}}>
                      
                            <span>Exoads Approval</span>
                      
                    </h2>
                </OverlayTrigger>
            ))}
                    </div>
                    <div>

                        <div className='loader-height-alignment1'>
                            <div className="step-round-alignment1 deactive-alignment1">
                                <div className="second-round1">

                                </div>
                            </div>
                        </div>
                        <h2 >Google<br/>
                        Approval</h2>
                    </div>

                    <div className='live-img1'>
                        <img src={LiveImg} alt="LiveImg" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
