import React from 'react'

export default function DownloadIcon2() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5298 7.22C11.3892 7.07955 11.1986 7.00066 10.9998 7.00066C10.8011 7.00066 10.6105 7.07955 10.4698 7.22L8.74985 8.94V0.75C8.74985 0.551088 8.67083 0.360322 8.53018 0.21967C8.38952 0.0790175 8.19876 0 7.99985 0C7.80093 0 7.61017 0.0790175 7.46952 0.21967C7.32887 0.360322 7.24985 0.551088 7.24985 0.75V8.94L5.52985 7.22C5.38767 7.08752 5.19963 7.0154 5.00532 7.01882C4.81102 7.02225 4.62564 7.10097 4.48823 7.23838C4.35081 7.37579 4.2721 7.56118 4.26867 7.75548C4.26524 7.94978 4.33737 8.13783 4.46985 8.28L7.46985 11.28C7.61047 11.4205 7.8011 11.4993 7.99985 11.4993C8.1986 11.4993 8.38922 11.4205 8.52985 11.28L11.5298 8.28C11.6703 8.13937 11.7492 7.94875 11.7492 7.75C11.7492 7.55125 11.6703 7.36063 11.5298 7.22Z" fill="white" />
            <path d="M13.748 8.75C13.3 8.75 12.908 9.024 12.591 9.341L9.591 12.341C9.38207 12.5499 9.13403 12.7157 8.86104 12.8287C8.58806 12.9418 8.29548 13 8 13C7.70452 13 7.41194 12.9418 7.13896 12.8287C6.86597 12.7157 6.61793 12.5499 6.409 12.341L3.409 9.341C3.092 9.024 2.7 8.75 2.252 8.75H0C0 10.8717 0.842855 12.9066 2.34315 14.4069C3.84344 15.9071 5.87827 16.75 8 16.75C10.1217 16.75 12.1566 15.9071 13.6569 14.4069C15.1571 12.9066 16 10.8717 16 8.75H13.748Z" fill="white" />
        </svg>

    )
}
