import React, { useEffect, useState } from "react";
import "./generatedSelection.css";
import EditIcon from "../../../../../icons/editIcon";
import ConvertIcon from "../../../../../icons/convertIcon";
import PlusIcon from "../../../../../icons/plusIcon";
import DownloadIcon from "../../../../../icons/downloadIcon";
import RoundedIcon from "../../../../../icons/roundedIcon";
import BGStar from "../../../../../icons/bgStar";
import ProfileImg from "../../../../../../assets/images/profile-img.png";
import EditIcon2 from "../../../../../icons/editIcon2";
import DownloadModal from "../downloadModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCreativeEditAll,
  handleCoinChange,
  logoColorPikedFun,
} from "../../../../../../Redux/addCreateSlice";
import {
  addBrandData,
  addConvertAll,
  addEditOne,
  addEditOneCreative,
  removeBrandData,
  replaceAllBrandData,
} from "../../../../../../Redux/brandSetupData";
import {
  addOnlyCreativeSize,
  addOnlyCreativeState,
} from "../../../../../../Redux/onlyCreativeData";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import axios, { nodeUrl } from "../../../../../axios/auth";
import JSZip from "jszip";
import GenerateLoader from "../generateLoader";
import { toast } from "react-toastify";
import DownloadModalLoader from "../downloadModal/indexLoader";
// import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';
export default function GeneratedSelection() {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const [downloadButtonDropdown, setDownloadButtonDropdown] = useState(
    new Array(60).fill(false)
  );
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadModalLoader, setDownloadModalLoader] = useState(false);
  const [imageData, setImageData] = useState(
    brandData?.singleEdit?.length > 0 ? brandData?.singleEdit : []
  );
  const [editImage, seteditImage] = useState(imageData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageLoader] = useState(false);
  const userToken = Cookies.get("token");

  const [difSizeImageLIst, setDifSizeImageLIst] = useState([]);
  const [selectedDownload, setSelectedDownload] = useState([]);
  const [previousIndex, setpreviousIndex] = useState(null);
  const [editpreviousIndex, seteditpreviousIndex] = useState(brandData?.editIndex);
  const [loading, setLoading] = useState(Array(imageData?.length).fill(false));
  const [singleEditArray, setSingleEditArray] = [brandData?.singleEditArray??[]];
  useEffect(() => {
    if (!brandData?.onlyCreativeSelected) {
      projectPayload();
    }
  }, []);

  // create or update Project
  const projectPayload = async () => {
    const normalizeLogo = (logo) => {
      // Check if the logo is an array
      if (Array.isArray(logo)) {
        return logo;
      }

      // Check if the logo is an object
      if (typeof logo === "object" && logo !== null) {
        return [logo];
      }

      // Check if the logo is a string
      if (typeof logo === "string" && logo.trim() !== "") {
        return [logo];
      }

      // Check if the logo is blank or any other type
      if (!logo) {
        return [];
      }
    };

    const normalizeCtaIcon = (icon) => {
      // Check if the logo is an array
      if (Array.isArray(icon)) {
        return icon;
      }

      // Check if the logo is an object
      if (typeof icon === "object" && icon !== null) {
        return [icon];
      }

      // Check if the logo is a string
      if (typeof icon === "string" && icon.trim() !== "") {
        return [icon];
      }

      // Check if the logo is blank or any other type
      if (!icon) {
        return [];
      }
    };

    const normalizedLogo = await normalizeLogo(brandData.logo);
    const normalizedCta = await normalizeCtaIcon(brandData.ctaIcon);

    const data = {
      size: brandData?.size,
      brandname: brandData?.brandname,
      projectName: brandData?.projectName,
      brandId: brandData?.brandId,
      projectId: brandData?.projectId ?? "",
      heading: brandData?.heading,
      description: brandData?.description,
      promotionText: brandData?.promotionText,
      ctaText: brandData?.ctaText,
      ctaIcon: normalizedCta[0],
      mainImage: brandData?.mainImage,
      bgImage: "return base 64",
      selectedFont: brandData?.selectedFont,
      logo: normalizedLogo[0],
      ctaColor: brandData?.ctaColor,
      promotionTextColor: brandData?.promotionTextColor,
      mainBgColor: brandData?.mainBgColor,
      headingColor: brandData?.headingColor,
      descriptionColor: brandData?.descriptionColor,
      opacity: brandData?.opacity ?? "",
    };


    try {
      const res = await axios.post("/create-project", data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // setImageData(res?.data)
      // dispatch(addBrandData({template:res.data}))
      // console.log("create-project generated page", res.data,)
    } catch (error) {
      console.log(error, "create-project generated page", error);
    }
  };

  // create or update Project
  //  const differentSizePayload= async(index)=>{
  //   console.log("differentSizePayload index",index)
  //   const normalizeLogo = (logo) => {
  //     // Check if the logo is an array
  //     if (Array.isArray(logo)) {
  //       return logo;
  //     }

  //     // Check if the logo is an object
  //     if (typeof logo === 'object' && logo !== null) {
  //       return [logo];
  //     }

  //     // Check if the logo is a string
  //     if (typeof logo === 'string' && logo.trim() !== '') {
  //       return [logo];
  //     }

  //     // Check if the logo is blank or any other type
  //     if (!logo) {
  //       return [];
  //     }

  //   }

  //   const normalizeCtaIcon = (icon) => {
  //     // Check if the logo is an array
  //     if (Array.isArray(icon)) {
  //       return icon;
  //     }

  //     // Check if the logo is an object
  //     if (typeof icon === 'object' && icon !== null) {
  //       return [icon];
  //     }

  //     // Check if the logo is a string
  //     if (typeof icon === 'string' && icon.trim() !== '') {
  //       return [icon];
  //     }

  //     // Check if the logo is blank or any other type
  //     if (!icon) {
  //       return [];
  //     }

  //   }

  //   const normalizedLogo =await normalizeLogo(brandData.logo);
  //   const normalizedCta =await normalizeCtaIcon(brandData.ctaIcon);

  //   const data={
  //     size:brandData?.size,
  //     brandname:brandData?.brandname,
  //     projectName:brandData?.projectName,
  //     brandId:brandData?.brandId,
  //     projectId:brandData?.projectId ?? "",
  //     heading:brandData?.heading,
  //     description:brandData?.description,
  //     promotionText:brandData?.promotionText,
  //     ctaText:brandData?.ctaText,
  //     ctaIcon:normalizedCta[0],
  //     mainImage:brandData?.mainImage,
  //     bgImage:"return base 64",
  //     selectedFont:brandData?.selectedFont,
  //     logo:normalizedLogo[0],
  //     ctaColor:brandData?.ctaColor,
  //     promotionTextColor:brandData?.promotionTextColor,
  //     mainBgColor:brandData?.mainBgColor,
  //     headingColor:brandData?.headingColor,
  //     descriptionColor:brandData?.descriptionColor,
  //     opacity:brandData?.opacity ?? "",
  //     templateNo:index
  //   }

  //   try {

  // // const url=brandData?.projectId?editProject:newProject

  //     const res = await axios.post("/convert-all-size", data,{
  //       headers: {
  //         Authorization: `Bearer ${userToken}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     // setImageData(res?.data)
  //   // dispatch(addBrandData({template:res.data}))

  //     setDifSizeImageLIst(res.data.data)
  //     setDownloadModal(true)
  //   } catch ( error) {
  //     console.log(error,"convert-all-size generated page",error)
  //   }

  // }

  const differentSizePayload = async (index) => {
    setDownloadModalLoader(true);
    setDownloadModal(true);
    // Assuming `brandData` is defined somewhere accessible in your function
    const {
      logo: brandLogo,
      ctaIcon: brandCtaIcon,
      size,
      brandname,
      projectName,
      brandId,
      projectId,
      heading,
      description,
      promotionText,
      ctaText,
      mainImage,
      selectedFont,
      ctaColor,
      promotionTextColor,
      mainBgColor,
      headingColor,
      descriptionColor,
      opacity,
    } = brandData;

    const normalizeLogo = (logo) => {
      if (Array.isArray(logo)) {
        return logo;
      }
      if (typeof logo === "object" && logo !== null) {
        return [logo];
      }
      if (typeof logo === "string" && logo.trim() !== "") {
        return [logo];
      }
      return [];
    };

    const normalizeCtaIcon = (icon) => {
      if (Array.isArray(icon)) {
        return icon;
      }
      if (typeof icon === "object" && icon !== null) {
        return [icon];
      }
      if (typeof icon === "string" && icon.trim() !== "") {
        return [icon];
      }
      return [];
    };

    const normalizedLogo = await normalizeLogo(brandLogo);
    const normalizedCta = await normalizeCtaIcon(brandCtaIcon);

    const data = {
      size,
      brandname,
      projectName,
      brandId,
      projectId: projectId ?? "",
      heading,
      description,
      promotionText,
      ctaText,
      ctaIcon: normalizedCta[0],
      mainImage,
      bgImage: "return base 64",
      selectedFont,
      logo: normalizedLogo[0],
      ctaColor,
      promotionTextColor,
      mainBgColor,
      headingColor,
      descriptionColor,
      opacity: opacity ?? "",
      templateNo: index,
    };

    try {
      // Check if the index has changed since the last API call
      if (index !== previousIndex) {
        const res = await axios.post("/convert-all-size", data, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setDifSizeImageLIst(res.data.data);
        // setDifSizeImageList(res.data.data);
        setDownloadModal(true);
        setDownloadModalLoader(false);
        // Update previousIndex to current index
        previousIndex = index;
      } else {
        // Index hasn't changed, do nothing or handle as needed
        setDownloadModal(true);
        setDownloadModalLoader(false);
      }
    } catch (error) {
      console.error("Error in differentSizePayload:", error);
    }
    setpreviousIndex(index);
  };

  // Define previousIndex variable outside the function scope

  useEffect(() => {
    const number = localStorage.getItem("editIndexNumber");
    if (brandData?.singleEdit?.length > 0) {
      handleEditIndex(number);

    } else {
      getDetail();
      
    }
  }, []);

  // get Template Images
  const getdata = async (startIndex) => {
    const normalizeLogo = (logo) => {
      // Check if the logo is an array
      if (Array.isArray(logo)) {
        return logo;
      }

      // Check if the logo is an object
      if (typeof logo === "object" && logo !== null) {
        return [logo];
      }

      // Check if the logo is a string
      if (typeof logo === "string" && logo.trim() !== "") {
        return [logo];
      }

      // Check if the logo is blank or any other type
      if (!logo) {
        return [];
      }
    };

    const normalizeCtaIcon = (icon) => {
      // Check if the logo is an array
      if (Array.isArray(icon)) {
        return icon;
      }

      // Check if the logo is an object
      if (typeof icon === "object" && icon !== null) {
        return [icon];
      }

      // Check if the logo is a string
      if (typeof icon === "string" && icon.trim() !== "") {
        return [icon];
      }

      // Check if the logo is blank or any other type
      if (!icon) {
        return [];
      }
    };

    const normalizedLogo = await normalizeLogo(brandData.logo);
    const normalizedCta = await normalizeCtaIcon(brandData.ctaIcon);

    const data = {
      size: brandData?.size,
      brandname: brandData?.brandname,
      projectName: brandData?.projectName,
      heading: brandData?.heading,
      description: brandData?.description,
      promotionText: brandData?.promotionText,
      ctaText: brandData?.ctaText,
      ctaIcon: normalizedCta[0],
      mainImage: brandData?.mainImage,
      bgImage: "return base 64",
      selectedFont: brandData?.selectedFont,
      logo: normalizedLogo[0],
      ctaColor: brandData?.ctaColor,
      promotionTextColor: brandData?.promotionTextColor,
      mainBgColor: brandData?.mainBgColor,
      headingColor: brandData?.headingColor,
      descriptionColor: brandData?.descriptionColor,
      brandId: brandData?.brandId,
      opacity: brandData?.opacity ?? "",
      startIndex: startIndex,
    };

    try {
      const res = await axios.post(
        `${nodeUrl}/createtemplate`,
        data,

        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return res;
    } catch (e) {
      // toast.error("An error occurred");
      toast.error("An error occurred", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });

      console.log(e);
      return e;
    }
  };

  const getDetail = async () => {
    setLoader(true);
    setImageLoader(true);

    try {
      let startIndex = 0;
      const step = 4; // Number of items to fetch per request
      const maxRetries = 56; // Number of retries or batches
      let isSuccess = true; // Flag to check if all requests are successful

      while (isSuccess && startIndex <= maxRetries) {
        const res = await getdata(startIndex);

        if (res?.data?.status === true) {
          const imageData = res.data?.data;

          setImageData((prevImageData) => [...prevImageData, ...imageData]);
          setLoader(false);
        } else {
          setLoader(false);
          // toast.error(res?.data?.error || "Error fetching data");
          toast.error(res?.data?.error || "Error fetching data", {
            className: 'custom-toast-error',
            hideProgressBar: true
          });
          isSuccess = false;
        }
        if(startIndex <3){
          deductTokenG()
        }else{

        }

        startIndex += step;
      }

      if (isSuccess) {
    

        setLoader(false);
        setImageLoader(false);
      }
    } catch (e) {
      setLoader(false);
      // toast.error("An error occurred");
      toast.error("An error occurred", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      console.log(e);
    }

  
  };


  const handleEditAll = () => {
    navigate("/main/brand-setup");
    dispatch(addCreativeEditAll("EditAll"));
    if(brandData?.editAllBrandData?.userId){
      dispatch(replaceAllBrandData({...brandData?.editAllBrandData}))
      addBrandData({ convertAll: "", editAll: "editAll", singleEdit: [] })
    }else{
      dispatch(
        addBrandData({ convertAll: "", editAll: "editAll", singleEdit: [] })
      );
    }
    
  };

  const handleNewProject = () => {
    dispatch(addCreativeEditAll(""));
    dispatch(addBrandData({ convertAll: "", editAll: "", singleEdit: [] }));
    dispatch(addOnlyCreativeState("onlyCreativeSelected"));
    dispatch(removeBrandData({}));
    dispatch(logoColorPikedFun([]));
    dispatch(addOnlyCreativeSize(""));
    dispatch(addBrandData({ createProject: "CreateProject" }));
    if (brandData?.onlyCreativeSelected) {
      navigate("/main/brand-setup");
    } else {
      navigate("/main/brand-list");
    }
  };
  const ConvertAll = () => {
    dispatch(addCreativeEditAll(""));
    dispatch(
      addBrandData({ convertAll: "ConverAll", editAll: "", singleEdit: [] })
    );
    dispatch(addConvertAll("ConverAll"));
    navigate("/main/new-generate");
    dispatch(addOnlyCreativeSize(""));
  };

  // const handleEditOneCreative=()=>{
  //   // seteditImage(imageData)
  //     dispatch(addEditOneCreative("EditOne"))
  //     // navigate("/main/brand-setup")
  // }

useEffect(()=>{

},[brandData])

  // TestingCode
  const handleEditOneCreative = async (index) => {
 localStorage.setItem("editIndexNumber", index);


if(brandData?.editpreviousIndex===index){
}
else if(brandData?.singleEdit?.length > 0){
dispatch(addBrandData({
  projectName:brandData?.editAllBrandData?.projectName,
      heading:brandData?.editAllBrandData?.heading,
      description:brandData?.editAllBrandData?.description,
      promotionText:brandData?.editAllBrandData?.promotionText,
      ctaText:brandData?.editAllBrandData?.ctaText,
      ctaIcon: brandData?.editAllBrandData?.ctaIcon,
      mainImage:brandData?.editAllBrandData?.mainImage,
      bgImage: "return base 64",
      selectedFont:brandData?.editAllBrandData?.selectedFont,
      logo: brandData?.editAllBrandData?.logo,
      ctaColor:brandData?.editAllBrandData?.ctaColor,
      promotionTextColor:brandData?.editAllBrandData?.promotionTextColor,
      mainBgColor:brandData?.editAllBrandData?.mainBgColor,
      headingColor:brandData?.editAllBrandData?.headingColor,
      descriptionColor:brandData?.editAllBrandData?.descriptionColor,
      opacity:brandData?.editAllBrandData?.opacity,
}))
}

   dispatch((addBrandData({editpreviousIndex:index})))

    dispatch(addEditOneCreative("EditOne"));

    if (brandData?.singleEdit?.length > 0) {
      // handleEditIndex(number);
    }
    else{
     dispatch( addBrandData({
     
        editAllBrandData: brandData,
    
      }))
      dispatch(
        addBrandData({
          convertAll: "",
          editAll: "",
          singleEdit: imageData,
      editIndex:index
        })
      );
    }

    navigate("/main/brand-setup");
  };
  const newLoadingStatus = [...loading];
  const handleEditIndex = (index) => {
    // const newLoadingStatus = [...loading];
    newLoadingStatus[index] = true;
    setLoading(newLoadingStatus);
  
    handleEditIndexPayload(index)
   
  };



  const handleEditIndexPayload = async (index) => {
    const normalizeLogo = (logo) => {
      // Check if the logo is an array
      if (Array.isArray(logo)) {
        return logo;
      }

      // Check if the logo is an object
      if (typeof logo === "object" && logo !== null) {
        return [logo];
      }

      // Check if the logo is a string
      if (typeof logo === "string" && logo.trim() !== "") {
        return [logo];
      }

      // Check if the logo is blank or any other type
      if (!logo) {
        return [];
      }
    };

    const normalizeCtaIcon = (icon) => {
      // Check if the logo is an array
      if (Array.isArray(icon)) {
        return icon;
      }

      // Check if the logo is an object
      if (typeof icon === "object" && icon !== null) {
        return [icon];
      }

      // Check if the logo is a string
      if (typeof icon === "string" && icon.trim() !== "") {
        return [icon];
      }

      // Check if the logo is blank or any other type
      if (!icon) {
        return [];
      }
    };

    const normalizedLogo = await normalizeLogo(brandData.logo);
    const normalizedCta = await normalizeCtaIcon(brandData.ctaIcon);

    const data = {
      size: brandData?.size,
      brandname: brandData?.brandname,
      projectName: brandData?.projectName,
      brandId: brandData?.brandId,
      projectId: brandData?.projectId ?? "",
      heading: brandData?.heading,
      description: brandData?.description,
      promotionText: brandData?.promotionText,
      ctaText: brandData?.ctaText,
      ctaIcon: normalizedCta[0],
      mainImage: brandData?.mainImage,
      bgImage: "return base 64",
      selectedFont: brandData?.selectedFont,
      logo: normalizedLogo[0],
      ctaColor: brandData?.ctaColor,
      promotionTextColor: brandData?.promotionTextColor,
      mainBgColor: brandData?.mainBgColor,
      headingColor: brandData?.headingColor,
      descriptionColor: brandData?.descriptionColor,
      opacity: brandData?.opacity ?? "",
      templateNo:index
    };

    try {
      const res = await axios.post("/edit-template", data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      // setImageData(res?.data)
      // dispatch(addBrandData({template:res.data}))

      const newImage = res.data.data;

      // Update the image data and loading status
      const newImageData = [...imageData];
      newImageData[index] = newImage[0];
  
      const updatedLoadingStatus = [...loading];
      updatedLoadingStatus[index] = false;
  
      setImageData(newImageData);
      setLoading(updatedLoadingStatus);
      deductTokenG()


    } catch (error) {
      console.log(error, "edit-template generated page", error);
    }
  };

  const handleDownloadClick = (index) => {
    const updatedDropdown = downloadButtonDropdown.map((item, i) =>
      i === index ? !item : false
    );

    setDownloadButtonDropdown(updatedDropdown);
  };

  // <button onClick={() => downloadImage(image.imagePath, `creative${image?.originalWidth}X${image?.originalHeight}`, image?.originalWidth, image?.originalHeight)}>Download</button>

  // handle current post
  const downloadImage = async(base64Data, fileName, width, height) => {

    const res=await checkcredits()
    if(res==true){
     
     const res= await deductToken()

      try {
        // Create an image element
        const img = new Image();
        img.src = `data:image/png;base64,${base64Data}`;
  
        img.onload = () => {
          // Create a high-resolution canvas element
          const highResCanvas = document.createElement("canvas");
          const scale = 2; // Higher resolution for better quality
          highResCanvas.width = width * scale;
          highResCanvas.height = height * scale;
  
          // Draw the image on the high-resolution canvas
          const highResCtx = highResCanvas.getContext("2d");
          highResCtx.drawImage(
            img,
            0,
            0,
            highResCanvas.width,
            highResCanvas.height
          );
  
          // Create a canvas with the specified dimensions
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
  
          // Draw the high-resolution image onto the smaller canvas with bicubic interpolation
          const ctx = canvas.getContext("2d");
          ctx.drawImage(
            highResCanvas,
            0,
            0,
            highResCanvas.width,
            highResCanvas.height,
            0,
            0,
            canvas.width,
            canvas.height
          );
  
          // Convert the canvas to a blob
          canvas.toBlob(
            (blob) => {
              const url = window.URL.createObjectURL(blob);
  
              // Create a download link
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
  
              // Free up the memory
              window.URL.revokeObjectURL(url);
            },
            "image/png",
            1
          ); // Set image quality to highest
        };
  
        img.onerror = (error) => {
          console.error("Error loading image:", error);
        };
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    }

  
  };

  const downloadImageAsBlob = (base64Data, width, height) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = `data:image/png;base64,${base64Data}`;

        img.onload = () => {
          const highResCanvas = document.createElement("canvas");
          const scale = 2;
          highResCanvas.width = width * scale;
          highResCanvas.height = height * scale;

          const highResCtx = highResCanvas.getContext("2d");
          highResCtx.drawImage(
            img,
            0,
            0,
            highResCanvas.width,
            highResCanvas.height
          );

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(
            highResCanvas,
            0,
            0,
            highResCanvas.width,
            highResCanvas.height,
            0,
            0,
            canvas.width,
            canvas.height
          );

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/png",
            1
          );
        };

        img.onerror = (error) => {
          reject(new Error("Error loading image: " + error));
        };
      } catch (error) {
        reject(new Error("Error processing image: " + error));
      }
    });
  };

  async function downloadAll() {
    if (imgLoader) {
      // toast.error("Wait For All Creative Render");
      toast.error("Wait For All Creative Render", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });

    } else {

     const res=await checkcredits()
     if(res==true){
      await deductToken()
      const zip = new JSZip();
      await Promise.all(
        imageData.map(async (ele, index) => {
          const base64Data = ele?.imagePath;
          const width = ele?.originalWidth; // Assuming you have width in ele
          const height = ele?.originalHeight; // Assuming you have height in ele

          try {
            const blob = await downloadImageAsBlob(base64Data, width, height);
            zip.file(`creative_${index}.jpg`, blob);
          } catch (error) {
            console.error(`Error processing image ${index}:`, error);
          }
        })
      );

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "creatives.zip");
      });
    }
    }
  }

  // async function downloadAll() {
  //   // setIsLoader(true);
  //   const zip = new JSZip();
  //   await Promise.all(
  //     imageData.map(async (ele,index) => {
  //       const response = await fetch(`data:image/png;base64,${ele?.imagePath}`)
  //       // src={`data:image/png;base64,${image?.imagePath}`};
  //       const data = await response.blob();
  //       zip.file(`creative_${index}.jpg`, data);
  //     })
  //   );

  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "creatives.zip");
  //   });
  // }

  // async function downloadAll() {
  //   const zip = new JSZip();
  //   await Promise.all(
  //     imageData.map(async (ele, index) => {
  //       // Assuming ele.imagePath is a base64 encoded string without the data:image/png;base64, prefix
  //       const base64Data = ele?.imagePath.split(',')[1];
  //       const byteCharacters = atob(base64Data);
  //       const byteNumbers = new Array(byteCharacters.length);
  //       for (let i = 0; i < byteCharacters.length; i++) {
  //         byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }
  //       const byteArray = new Uint8Array(byteNumbers);
  //       zip.file(`creative_${index}.jpg`, byteArray, { binary: true });
  //     })
  //   );

  //   zip.generateAsync({ type: "blob" }).then((content) => {
  //     saveAs(content, "creatives.zip");
  //   });
  // }

  // handleSelected downloadImages  function



// deduct Credits
const deductToken = async() => {

  try {
    const res = await axios.post("/deduct-token",{transactionType:"creativeDownload"},{
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              })
    if (res.data.status==true) {
    
      dispatch(handleCoinChange(res.data.credits))
    } else {
      // toast.error(res.data.message)
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  } catch (error) {

  }
};

const deductTokenG    = async() => {
  try {
    const res = await axios.post("/deduct-token",{transactionType:"templateGeneration"},{
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              })
    if (res.data.status==true) {
      
      dispatch(handleCoinChange(res.data.credits))
    } else {
      // toast.error(res.data.message)
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  } catch (error) {

  }
};



  // check credits
  const checkcredits = async() => {
    try {
      const res = await axios.post("/check-credits",{transactionType:"creativeDownload"},{
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                })
                if(res?.data?.data>0){
                  return true
                }else{
                
                  // toast.error(res.data.message)
                  toast.error(res?.data?.message, {
                    className: 'custom-toast-error',
                    hideProgressBar: true
                  });
                }

      if (res.data.status==true) {
        // toast.error(res.data.data.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
    // return true
      }
    } catch (error) {


    }
  };


  return (
    <div className="generated-selection-section ">
      <div className="generated-selection-heading">
        <h2>Generated Creatives</h2>

        <div className="right-side">
          <div className="option-box-alignment" onClick={() => handleEditAll()}>
            <EditIcon />
            <p>Edit All </p>
          </div>

          <div className="option-box-alignment" onClick={() => ConvertAll()}>
            <ConvertIcon />
            <p>Convert All</p>
          </div>

          <div
            className="option-box-alignment"
            onClick={() => handleNewProject()}
          >
            <PlusIcon />
            <p>Create A New Project</p>
          </div>

          <div className="option-box-alignment" onClick={downloadAll}>
            {!imgLoader ? (
              <>
                {" "}
                <DownloadIcon />
                <p>Download All</p>{" "}
              </>
            ) : (
              <p class="spinner-border " role="status">
                <span class="visually-hidden">Loading...</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="generated-selection-details-alignment">
        <div className="generated-grid">
          {imageData?.map((image, index) => (
            <div className="generated-grid-item" key={index}>
              <div className="top-details-alignment">
                <div className="top-heding">
                  <div className="top-heding-left">
                    <RoundedIcon />
                    <p>Conversion Score 99/100</p>
                  </div>
                  <BGStar />
                </div>

                <div className="profile-img-alignment">
                  <div className="profile-img">
                    {loading[index] ? (
                      <div className="d-flex" style={{display:"flex", marginTop:"40px", justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                 <div className="spinner-border"  style={{margin:"auto"}} role="status">
                 <span className="visually-hidden">Loading...</span>
               </div>
               </div>
                    ) : (
                      <img
                        src={`data:image/png;base64,${image?.imagePath}`}
                        alt={`Image ${index}`}
                        style={{ objectFit: "contain" }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="generate-bottom-details-alignment">
                {/*  <div className='checkbox-alignment'>
                      <input type='checkbox' />
                    </div>
                    */}

                <div
                  className="edit-alignment"
                  onClick={() => handleEditOneCreative(index)}
                >
                  <EditIcon2 />
                </div>

                <div
                  className="second-download-alignment"
                  onClick={() => handleDownloadClick(index)}
                >
                  <DownloadIcon />
                  <p>Download</p>
                  {downloadButtonDropdown[index] && (
                    <div className="second-download-box-alignment">
                      <div className="dropdown-button-alignment">
                        <button
                          onClick={() =>
                            downloadImage(
                              image.imagePath,
                              `creative${image?.originalWidth}X${image?.originalHeight}`,
                              image?.originalWidth,
                              image?.originalHeight
                            )
                          }
                        >
                          Download current post
                          <DownloadIcon />
                        </button>
                      </div>
                      <div className="dropdown-button-alignment">
                        <button onClick={() => differentSizePayload(index)}>
                          Download in other size
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {imgLoader && (
        <div className="loading">
          <div className="generated-selection-details-alignment">
            <div className="generated-grid">
              {[0, 1, 2, 3].map((image, index) => (
                <div
                  className="generated-grid-item"
                  style={{ backgroundColor: "white" }}
                  key={index}
                >
                  <div className="top-details-alignment">
                    <div className="top-heding">
                      <div className="top-heding-left">
                        <RoundedIcon />
                        <p>Conversion Score 99/100</p>
                      </div>
                      <BGStar />
                    </div>

                    <div className="profile-img-alignment">
                      <div className="profile-img">
                        {/* <img src={`data:image/png;base64,${image?.imagePath}`} alt="Generating"  /> */}
                      </div>
                    </div>
                  </div>

                  <div className="generate-bottom-details-alignment">
                    {/*  <div className='checkbox-alignment'>
                      <input type='checkbox' />
                    </div>
                    */}

                    <div
                      className="edit-alignment second-download-alignment1"
                      onClick={() => handleEditOneCreative()}
                      style={{ cursor: "not-allowed" }}
                    >
                      <EditIcon2 />
                    </div>

                    <div
                      className="second-download-alignment second-download-alignment1"
                      style={{ cursor: "not-allowed" }}
                    >
                      <DownloadIcon />
                      <p>Download</p>
                      {downloadButtonDropdown[index] && (
                        <div className="second-download-box-alignment">
                          <div className="dropdown-button-alignment">
                            <button>  
                              Download current post
                              <DownloadIcon />
                            </button>
                          </div>
                          <div className="dropdown-button-alignment">
                            <button>Download in other size</button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="shimmer-div"> </div>
        </div>
      )}
      {/* {loader && <GenerateLoader />} */}

      {downloadModal &&
        (downloadModalLoader ? (
          <DownloadModalLoader />
        ) : (
          <DownloadModal
            downloadImage={downloadImage}
            difSizeImageLIst={difSizeImageLIst}
            selectedDownload={selectedDownload}
            setSelectedDownload={setSelectedDownload}
            setDownloadModal={setDownloadModal}
          />
        ))}
    </div>
  );
}
