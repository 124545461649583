import React, { useContext, useEffect, useState } from "react";
import "./GoogleContent.css";
import Laptop from "./Images/Laptop.png";
import Mobile from "./Images/Phone.png";
import { MobileSvg, desktopIcon, dotsIcon1 } from "../SVG";
import { AiIcon, BlackIcon, BlueDot } from "./SVG";
import { CommonBtn } from "../../../../../UI-Components/ReusableComponents";
import { ToggleContext } from "../ContextFile";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import InputMain from "./AiGenerationModal/InputMain";
import Cookies from "js-cookie";
import AiSuggestionMain from "./AiGenerationModal/AiSuggestionMain";
import axios from "../../../../../axios/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  addBingSuggestion,
  addWebsiteDeatil,
  addWebsiteUrl,
  addWebsiteDetailForBing,
} from "../../../../../../Redux/AiSuggestion";
import { wondSvg } from "../../FacebookCampaign/SideNavContainer/SVG";
import { addBingDeatils } from "../../../../../../Redux/CampaignDetailSlice";
import { Link } from "react-router-dom";

const GoogleContent = () => {
  const [aiApiDescription, setAiApiDescription] = useState();
  const [aiApiHeading, setAiApiHeading] = useState();

  const { handleToggleVisibleBtn, HandleActiveStep } = useContext(
    ToggleContext
  );
  const token = Cookies.get("token");
  const LocalwebsiteDetail = sessionStorage.getItem("websiteDetail");
  const LocalwebsiteUrl = sessionStorage.getItem("websiteUrl");
  const LocalCompanyName = sessionStorage.getItem("websiteTitle");

  const { Bing, websiteDetailsForBing } = useSelector(
    (state) => state.AiSuggestion
  );

  const { BingDescription, BingHeadline } = useSelector(
    (state) => state.CampaignDetail
  );
  const dispatch = useDispatch();

  const [aiText, setAiText] = useState("");
  const [aiText2, setAiText2] = useState("Website Main Headline");

  useEffect(() => {
    HandleActiveStep(1);
    handleToggleVisibleBtn(true);

    if (BingDescription?.length > 0 || BingHeadline?.length > 0) {
      setAiText2(BingHeadline);
      setAiText(BingDescription);
    }
    // AiSuggestionData();
    if (
      websiteDetailsForBing &&
      LocalwebsiteDetail &&
      websiteDetailsForBing == LocalwebsiteDetail
    ) {
      if (Bing?.descriptions) {
        setAiApiDescription(Bing?.descriptions);
        setAiApiHeading(Bing?.headlines);
      } else {
        if (LocalwebsiteDetail) {
          AiSuggestionData();
        }
      }
    } else if (
      websiteDetailsForBing &&
      LocalwebsiteDetail &&
      websiteDetailsForBing !== LocalwebsiteDetail
    ) {

      if (LocalwebsiteDetail) {
        AiSuggestionData();
      }
    } else if (LocalwebsiteDetail) {
      AiSuggestionData();
    }
    return () => {
      handleToggleVisibleBtn(false);
    };
  }, []);

  useEffect(() => {
    if (aiText?.length > 0 || aiText2?.length > 0) {
      dispatch(
        addBingDeatils({
          headline: aiText2,
          descriptions: aiText,
        })
      );
    }
  }, [aiText, aiText2]);

  // google
  const AiSuggestionData = () => {
    setAiText("");
    setAiText2("Website Main Headline");

    dispatch(
      addBingDeatils({
        headline: "",
        descriptions: "",
      })
    );
    try {
      const url = "/fetch-ai/data";

      axios
        .post(
          url,
          { platform: "google", websiteDetail: LocalwebsiteDetail },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // if (response.data.data == "No Data Found") {
          //   setAiApiDescription(["No data Found"]);
          //   setAiApiHeading(["No data Found"]);
          // }
          // if (response.data) {
          //   dispatch(addGoogleSuggestion(response.data));
          //   setAiApiDescription(response.data.data.descriptions.descriptions);
          //   if (response.data.data.headlines?.descriptions) {
          //     setAiApiHeading(response.data.data.headlines.descriptions);
          //   } else if (response.data.data.headlines.Headlines) {
          //     setAiApiHeading(response.data.data.headlines.Headlines);
          //   }
          //   dispatch(addWebsiteDetailForGoogle(LocalwebsiteDetail));
          //   dispatch(addWebsiteUrl(LocalwebsiteUrl));
          // } else {
          //   setAiApiHeading("No data Found");
          // }

          if (response.data?.Description && response.data?.Headline) {
            dispatch(
              addBingSuggestion({
                descriptions: response?.data?.Description,
                headlines: response?.data?.Headline,
                keyword: response?.data?.keyword,
              })
            );
            setAiApiDescription(response?.data?.Description);
            setAiApiHeading(response?.data?.Headline);
            dispatch(addWebsiteDetailForBing(LocalwebsiteDetail));
            dispatch(addWebsiteUrl(LocalwebsiteUrl));
          } else {
            setAiApiDescription(["no data Found"]);
            setAiApiHeading(["no data Found"]);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  // const AiSuggestionData = () => {
  //   try {
  //     const url = "/fetch-ai/data";

  //     axios
  //       .post(
  //         url,
  //         { platform: "bing", websiteDetail: LocalwebsiteDetail },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         if (response.data.data == "No Data Found") {
  //           setAiApiDescription(["No data Found"]);
  //           setAiApiHeading(["No data Found"]);
  //         } else {
  //           dispatch(addBingSuggestion(response.data.data));
  //           setAiApiDescription(response.data.data.descriptions.descriptions);
  //           if (response.data.data.headlines.descriptions) {
  //             setAiApiHeading(response.data.data.headlines.descriptions);
  //           } else if (response.data.data.headlines.Headlines) {
  //             setAiApiHeading(response.data.data.headlines.Headlines);
  //           } else {
  //             setAiApiHeading("No data Found");
  //           }
  //           dispatch(addWebsiteDetailForBing(LocalwebsiteDetail));
  //           dispatch(addWebsiteUrl(LocalwebsiteUrl));
  //         }
  //       });
  //   } catch (error) {
  //   }
  // };

  const { toggle } = useContext(ToggleContext);
  return (
    <div className="FacebookAiSuggestionScroller">
      {toggle ? (
        <MobileView
          aiApiDescription={aiApiDescription}
          aiApiHeading={aiApiHeading}
          aiText={aiText}
          aiText2={aiText2}
          setAiText={setAiText}
          setAiText2={setAiText2}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      ) : (
        <LaptopView
          aiApiDescription={aiApiDescription}
          aiApiHeading={aiApiHeading}
          aiText={aiText}
          aiText2={aiText2}
          setAiText={setAiText}
          setAiText2={setAiText2}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      )}

      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/platfromselection"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        <Link to={"/main/campaign/bingcampaign/bing-add-1/targeting"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const LaptopView = ({
  aiApiDescription,
  aiApiHeading,
  aiText,
  aiText2,
  setAiText,
  setAiText2,
  LocalwebsiteUrl,
  LocalCompanyName,
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [messageEdit, setMessageEdit] = useState(false);
  const [messageEdit2, setMessageEdit2] = useState(false);

  const HandelAiItems = (item) => {
    setAiText(item);
    setAiModalToggle(!aimodalToggle);
  };

  const HandelAiItemsMessage = (item) => {
    setAiText2(item);
    setAiModalToggle2(!aimodalToggle2);
  };

  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
    setAiModalToggle2(false);
  };
  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
    setAiModalToggle(false);
  };
  return (
    <div
      className="google-content-main"
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        setMessageEdit(false);
        setAiModalToggle2(false);
        setMessageEdit2(false);
      }}
    >
      <div className="google-laptopimg-main-box">
        <div className="google-content-mainbox" style={{ flexWrap: "wrap" }}>
          <div className="google-content-mainbox-header">
            <p>Sponsored</p>
            <div className="google-content-mainbox-header-div">
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{BlackIcon}</span>
                <div>
                  <h6>
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6>
                  <p>{`${
                    LocalwebsiteUrl ? LocalwebsiteUrl : "www.yourwebsite.com"
                  }`}</p>
                </div>
              </div>
              {dotsIcon1}
            </div>
          </div>

          <div className="google-content-mainbox-div2">
            {/* <div style={{ display: "flex", gap: "5px", width: "90%" }}>
              <p>Website Main Headline</p>
              <span>|</span>
              <p> Main Headline</p>
              <span>|</span>
              <p> Main Headline</p>
            </div> */}

            {!messageEdit2 ? (
              <div style={{ display: "flex", gap: "5px", width: "90%" }}>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMessageEdit2(!messageEdit2);
                  }}
                >
                  {aiText2}
                </p>
                {/* <span>|</span>
                <p>websiteDetail</p>
                <span>|</span>
                <p>websiteDetail</p> */}
              </div>
            ) : (
              <input
                style={{ width: "90%", outline: "none", fontSize: "16px" }}
                value={aiText2}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setAiText2(e.target.value);
                }}
              />
            )}
            <div
              className="google-content-greendot"
              onClick={(e) => HandleGreenDot2(e)}
              style={{ marginTop: "-50px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Headline
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>

          <div className="google-content-mainbox-div2">
            <div
              className="google-content-mainbox-div3"
              style={{ width: "90%" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMessageEdit(!messageEdit);
              }}
            >
              {!messageEdit ? (
                aiText.length > 0 ? (
                  aiText
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    {
                      <svg
                        width="80%"
                        height="11"
                        viewBox="0 0 612 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="612"
                          height="11"
                          rx="5.5"
                          fill="#249FE4"
                          fill-opacity="0.6"
                        />
                      </svg>
                    }
                    {
                      <svg
                        width="40%"
                        height="9"
                        viewBox="0 0 333 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="333"
                          height="11"
                          rx="5.5"
                          fill="#249FE4"
                          fill-opacity="0.6"
                        />
                      </svg>
                    }
                  </div>
                )
              ) : (
                <input
                  style={{ width: "100%", outline: "none" }}
                  value={aiText}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiText(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              className="google-content-greendot"
              onClick={(e) => HandleGreenDot(e)}
              style={{ marginTop: "-50px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Headline
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        {aimodalToggle2 && (
          <div
            className="google-content-modal laptopModalMarginAiMessage "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Bing Ads > Headlines`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>

            <div className="google-content-modal-div2">
              {aiApiHeading ? ( //aiApiDescription
                aiApiHeading.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItemsMessage(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
              >
                Generate More
              </CommonBtn>
            </div> */}
          </div>
        )}

        {aimodalToggle && (
          <div
            className="google-content-modal googlelaptopModalMarginAiHeading"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Bing Ads > Descriptions`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>

            <div className="google-content-modal-div2">
              {aiApiDescription ? ( //aiApiDescription
                aiApiDescription.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItems(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
              >
                Generate More
              </CommonBtn>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

const MobileView = ({
  aiApiDescription,
  aiApiHeading,
  aiText,
  aiText2,
  setAiText,
  setAiText2,
  LocalwebsiteUrl,
  LocalCompanyName,
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [messageEdit, setMessageEdit] = useState(false);
  const [messageEdit2, setMessageEdit2] = useState(false);

  const [lgShow, setLgShow] = useState(false);

  const HandelAiItemsMessage = (item) => {
    setAiText2(item);
    setAiModalToggle2(!aimodalToggle2);
  };

  const HandelAiItems = (item) => {
    setAiText(item);
    setAiModalToggle(!aimodalToggle);
  };

  const HandelAiGenerat = () => {
    setLgShow(true);
    setAiModalToggle(false);
  };

  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
    setAiModalToggle2(false);
  };
  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
    setAiModalToggle(false);
  };
  return (
    <div
      className="main-google-content-main  google-mobileview-box "
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        setAiModalToggle2(false);
        setMessageEdit(false);
        setMessageEdit2(false);
      }}
    >
      <div className="facebook-mobileview-box-subdiv">
        <div className="google-content-mainbox" style={{ flexWrap: "wrap" }}>
          <div className="google-content-mainbox-header">
            <p>Sponsored</p>
            <div className="google-content-mainbox-header-div">
              <div style={{ display: "flex", gap: "10px" }}>
                <span>{BlackIcon}</span>
                <div>
                  <h6>
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6>
                  <p>{`${
                    LocalwebsiteUrl ? LocalwebsiteUrl : "www.yourwebsite.com"
                  }`}</p>
                </div>
              </div>
              {dotsIcon1}
            </div>
          </div>

          <div className="google-content-mainbox-div2">
            {!messageEdit2 ? (
              <div style={{ display: "flex", gap: "5px", width: "90%" }}>
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setMessageEdit2(!messageEdit2);
                  }}
                >
                  {aiText2}
                </p>
              </div>
            ) : (
              <input
                style={{ width: "90%", outline: "none", fontSize: "16px" }}
                value={aiText2}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setAiText2(e.target.value);
                }}
              />
            )}
            <div
              className="google-content-greendot"
              style={{ marginTop: "-50px" }}
              onClick={HandleGreenDot2}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Headline
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="google-content-mainbox-div2">
            <div
              className="google-content-mainbox-div3"
              style={{ width: "90%", fontSize: "12px" }}
              onClick={(e) => {
                setMessageEdit(!messageEdit);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {!messageEdit ? (
                aiText.length > 0 ? (
                  aiText
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    {
                      <svg
                        width="80%"
                        height="11"
                        viewBox="0 0 612 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="612"
                          height="11"
                          rx="5.5"
                          fill="#249FE4"
                          fill-opacity="0.6"
                        />
                      </svg>
                    }
                    {
                      <svg
                        width="40%"
                        height="9"
                        viewBox="0 0 333 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="333"
                          height="11"
                          rx="5.5"
                          fill="#249FE4"
                          fill-opacity="0.6"
                        />
                      </svg>
                    }
                  </div>
                )
              ) : (
                <input
                  style={{ width: "100%", outline: "none" }}
                  value={aiText}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiText(e.target.value);
                  }}
                />
              )}
            </div>
            <div
              className="google-content-greendot"
              style={{ marginTop: "-40px" }}
              onClick={HandleGreenDot}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Description
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        {aimodalToggle2 && (
          <div
            style={{ top: "-25px" }}
            className="google-content-modal  mobile-message-suggestionbing mobileMessageWidthPropgoogle"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Bing Ads > Headlines`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>
            <div className="google-content-modal-div2">
              {aiApiHeading ? ( //aiApiDescription
                aiApiHeading.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItemsMessage(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
          </div>
        )}

        {aimodalToggle && (
          <div
            style={{ top: "-25px" }}
            className="google-content-modal  mobile-headline-suggestiongoogle mobileHeadlinesWidthPropgoogle"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Bing Ads > Descriptions`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>
            <div className="google-content-modal-div2">
              {aiApiDescription ? ( //aiApiDescription
                aiApiDescription.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItems(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ToggleBtn = ({ toggle, handleToggleBtn }) => {
  return (
    <div>
      <button
        className={`desktop-toggle-btn-main ${toggle && "active"}`}
        onClick={handleToggleBtn}
      >
        <p
          className="desktop-toggle-text"
          style={toggle ? { display: "none" } : {}}
        >
          Desktop
        </p>
        <div className="desktop-icon-main">
          {toggle ? MobileSvg : desktopIcon}
        </div>
        <p
          className="desktop-toggle-text"
          style={!toggle ? { display: "none" } : {}}
        >
          Mobile
        </p>
      </button>
    </div>
  );
};

const AiGenerationModal = ({ lgShow, hide }) => {
  return (
    <Modal
      size="lg"
      centered
      show={lgShow}
      onHide={hide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Container fluid>
        <Row style={{ gap: "10px" }}>
          <Col style={{ padding: "0px" }}>
            <div className="generate-modal-main-col1-header ">
              <span>Input</span>
              <ul>
                <NavLink to={"input/brand"}>
                  <li>Brand</li>
                </NavLink>
                <NavLink to={"input/campaign"}>
                  <li>Campaign</li>
                </NavLink>
                <NavLink to={"input/googlead"}>
                  <li>Google Ad</li>
                </NavLink>
              </ul>
            </div>
          </Col>
          <Col style={{ padding: "0px" }}>
            <div className="generate-modal-main-col1-header">
              <span>AI Suggestions</span>
              <ul>
                <NavLink to={"aisuggestion/headline"}>
                  <li>Headline</li>
                </NavLink>
                <NavLink to={"aisuggestion/description"}>
                  <li>Descriptions</li>
                </NavLink>
                <NavLink to={"aisuggestion/keyword"}>
                  <li>Keywords</li>
                </NavLink>
              </ul>
            </div>
            <div></div>
          </Col>
        </Row>

        <Row style={{ display: "flex ", justifyContent: "center" }}>
          <Col
            style={{
              height: "380px",
            }}
            lg={8}
          >
            <InputMain />
          </Col>
        </Row>
        <Row>
          <div className="ai-generate-footer">
            <button>Done</button>
            <button>Generate</button>
          </div>
        </Row>
      </Container>
    </Modal>
  );
};

export default GoogleContent;
