import "./Login.css";
import LoginImg from "./Images/Login.png";
import Exoad from "./Images/EXOAD.png";
import vector from "./Images/Vector 3.png";
import google from "./Images/google.png";
import faceBook from "./Images/Facebook.png";
import apple from "./Images/apple.png";
import password from "./Images/password.png";
import eyeOpen from "./Images/openEye.png";
import axios from "../../axios/auth";
import { useForm } from "react-hook-form";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MailContext } from "./mailContext";
import { useDispatch } from "react-redux";


export const submitemail = async (email, handleMail, navigate,setIsSubmitting) => {


  try {
    // const [isSubmitting, setIsSubmitting] = useState(false);
   
    
    const res = await axios.post("/forgetpassword", { email: email });
    if(res?.data?.status ==true){
      toast.success(`OTP sent successfully to ${email}`, {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
  
      // Store the email in sessionStorage
      sessionStorage.setItem("email", email);
  
      const userEmail = sessionStorage.getItem("email");
  
      setTimeout(() => {
        if (userEmail) {
          navigate("/OtpCheck");
        }
      }, 2000);

    }
    else{
      setIsSubmitting(false)
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }

   

  } catch (e) {
    // Display an error toast
    setIsSubmitting(false)
    toast.error(e.response?.data?.message, {
      className: 'custom-toast-error',
      hideProgressBar: true
    });

  }
};



const ForgetPassword = () => {
  const [toggle, setToggle] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { handleMail } = useContext(MailContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate('/landingpage/home');
  };

  const onSubmit = (data) => {
    setIsSubmitting(true)
    submitemail(data.email, SaveEmaiToContext, navigate,setIsSubmitting);
  };
  const SaveEmaiToContext = (email) => {
    dispatch(email);
  };



  return (
    <Container fluid className="layout">
      <Row className="align-items-center">
        <Col
          className=" liginImg   d-flex justify-content-end align-items-center "
          lg={6}
          md={12}
          sm={12}
        >
          {/* <img
            src={LoginImg}
            style={{
              width: "70%",
            }}
            alt="login-img"
          /> */}
        </Col>
        <Col style={{ textAlign: "center" }} lg={6} md={12} sm={12}>
          <div
            className="right d-flex justify-content-center align-items-center "
            style={{
              padding: "3rem 2rem 1rem 2rem",
              height: "70vh",
              gap: "20px",
              border:"3px solid white",
              // maxHeight:"950px"
            }}
            // style={{ padding: "3rem 2rem 1rem 2rem",marginTop:"40px",border:"3px solid white",maxHeight:"650px"}
          >
            <div className=" ">
              <img src={Exoad} alt="Exoad" style={{ cursor: "pointer" }}    onClick={handleClick} />

              <div style={{ width: "100%" }}>
                <h1 className="welcome">Forgot Password</h1>
                <p className="p" style={{ width: "100%" }}>
                  Enter email address associated to the email address, We will
                  send an OTP to the associated email
                </p>
              </div>

              {/* <SignIn toggleEye={toggleEye} setToggleEye={setToggleEye} /> */}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="input-div" style={{ marginBottom: 10 }}>
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Email
                    </label>
                    <input
                      className="input"
                      type="email"
                      maxLength={50}
                      required
                      placeholder="Enter your email"
                      {...register("email", { required: true })}
                    />
                  </div>
                </div>

                {/* <button
                  type="submit"
                  className=" button-container login-button"
                >
                  Send
                </button> */}
                <button
        type="submit"
        className="button-container login-button"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Send"}
      </button>
              </form>


            </div>
          </div>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default ForgetPassword;
