import React, { useContext, useEffect, useState } from "react";
import "./GoogleTargeting.css";
import { plusIcon, SearchSvg, arr1, RemoveSVG, arr2, arr3, arr4, arr5 } from "./SVG";
import { useDispatch, useSelector } from "react-redux";
import { OnlyCountry, Countries_Data } from "../../CampaignData";
import { minusIcon } from "../../GoogleCampaign/SideNavContainer/SVG";
import { ToggleContext } from "../ContextFile";
import { Link, useNavigate } from "react-router-dom";
import { nodeUrl } from "../../../../../axios/auth";
import axios from "axios";
import { AddTargetingDetails } from "../../../../../../Redux/GoogleSearchSlice";
import Cookie from "js-cookie"
import { AddTargetingDetailsFacebook } from "../../../../../../Redux/FacebbokAdsSlice";
import { toast } from "react-toastify";
const GoogleTargeting = () => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [ageInput, setAgeInput] = useState(false);

  const { keyword } = useSelector((state) => state.AiSuggestion.Facebook);
  const { HandleActiveStep } = useContext(ToggleContext);
  const [city, setCity] = useState("");
  const [cityArray, setCityArray] = useState([]);
  const [country, setCountry] = useState("");
  const [min,setMin] = useState()
  const [max,setMax] = useState()
  const [gender,setGender] = useState()
  // const [keywords,setKeywords] = useState([])
  // const [Cities,setCities] = useState([])
  // const [CountryCode,setCountryCode] = useState()
  const dispatch = useDispatch();
  const { keywords, Cities, CountryCode } = useSelector(
    (state) => state.FacebookAdDetails
  );

  // useEffect(()=>{
  //   let data1 = sessionStorage.getItem("fbData");
  //   let parsedData = JSON.parse(data1);
  //  if(parsedData){
  //   const keywordsObj = parsedData.find(item => item.hasOwnProperty('keywords'));
  //   const cityObj = parsedData.find(item => item.hasOwnProperty('cities'));
  //   const countryObj = parsedData.find(item => item.hasOwnProperty('country'));
  //   const countryCodeObj = parsedData.find(item => item.hasOwnProperty('countryCode'));
  //   setKeywords(keywordsObj.keywords)
  //   setCities(cityObj.cities)
  //   setCountry(countryObj.country)
  //   setCountryCode(countryCodeObj.countryCode)
  //  }
   
  // },[])

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [data7, setData7] = useState([]);

  useEffect(()=>{
    let fbDataArray = sessionStorage.getItem('fbData');
            
    // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
    fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
    
    // Find the item with the "description" key in the array
    let itemToUpdate = fbDataArray.find(obj => obj?.minAge);
    
    if (itemToUpdate) {
        // Update the description if it's different
       
    } else {
        // If the item does not exist, add the new item directly
        fbDataArray.push({ minAge:18 });
    }

    // Convert the updated array back into a JSON string
    sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));
  },[])


 useEffect(()=>{
  let data = sessionStorage.getItem("fbData");
  let parsedData = JSON.parse(data);
  const minAgeObj = parsedData?.find(item => item.hasOwnProperty('minAge'));
  const maxAgeObj = parsedData?.find(item => item.hasOwnProperty('maxAge'));
  const genderObj = parsedData?.find(item => item.hasOwnProperty('gender'));
  if (minAgeObj) {
    setMin(minAgeObj.minAge||18)
  } 
  if (maxAgeObj) {
    setMax(maxAgeObj.maxAge)
  } 
  if (genderObj) {
    setGender(genderObj.gender)
    setData7([genderObj.gender])
  } 
  
 },[])

const navigate = useNavigate()

 useEffect(() => {
  let data1 = sessionStorage.getItem("fbData");

    if (!data1) {
    
    }
else{
  let parsedData = JSON.parse(data1);
    const minAgeObj = parsedData.find(item => item.hasOwnProperty('minAge'));
    const maxAgeObj = parsedData.find(item => item.hasOwnProperty('maxAge'));
    const genderObj = parsedData.find(item => item.hasOwnProperty('gender'));
    
    const headLineObj = parsedData.find(item => item.hasOwnProperty('headline'));
    const descriptionObj = parsedData.find(item => item.hasOwnProperty('description'));
    const hashObj = parsedData.find(item => item.hasOwnProperty('hash'));
    const keywordsObj = parsedData.find(item => item.hasOwnProperty('keywords'));
    const cityObj = parsedData.find(item => item.hasOwnProperty('cities'));
    const countryCodeObj = parsedData.find(item => item.hasOwnProperty('countryCode'));
    const countryObj = parsedData.find(item => item.hasOwnProperty('countryCode'));
    const imagePathObj = parsedData.find(item => item.hasOwnProperty('country'));
  
    setMin(minAgeObj?.minAge||18)
    setMax(maxAgeObj?.maxAge)
    setGender(genderObj?.gender)
    setCountry(countryCodeObj?.countryCode)
    setData1(countryObj)
    setData4(cityObj?.cities)
    setData2(keywordsObj?.keywords?keywordsObj?.keywords:[])
  
}
   

  }, []);

  useEffect(() => {
    // OR || beacuse we want to show even if there is only on parameter in the state
    if (keywords?.length > 0 || Cities?.length > 0 || CountryCode?.length > 0) {
      if (CountryCode) {
        let Country = Countries_Data?.find(
          (country) => country?.country_code === CountryCode
        );

        setData1([Country?.country_name]);
        setCountry(CountryCode);
      }

      if (keywords?.length > 0) {
        setData2(keywords);
      }

      if (Cities?.length > 0) {
        setData4(Cities);
      }
    } 
    // else if (sessionStorage.getItem("fbData")) {
    //   let data = JSON.parse(
    //     sessionStorage.getItem("fbData")
    //   );
     

    //   if (data?.CountryCode) {
    //     let Country = Countries_Data.find(
    //       (country) => country?.country_code === data?.CountryCode
    //     );
    //     setData1([Country?.country_name]);
    //     setCountry(data?.CountryCode);
    //   }

    //   setData4(data?.Cities);

    //   setData2(data?.keywords);
    // }
  }, [keywords, Cities, CountryCode]);

  useEffect(() => {
    if (data1?.length > 0) {
      let country = Countries_Data.find(
        (country) => country.country_name === data1[0]
      );
      setCountry(country.country_code);
    }

    if (country?.length > 0 || data4?.length > 0 || data2?.length > 0) {
      let data = [
        { country: country },
        { cityList: data4 },
        { keywords: data2 },
        
      ];
      dispatch(AddTargetingDetailsFacebook(data));
    }
  }, [data1, data4, data2, country]);
  const addIntoArray1 = (item) => {
  setData4([])
  setCityArray([])
    if (!data1?.includes(item)) {
        // Retrieve the current array from sessionStorage
        let fbDataArray = sessionStorage.getItem('fbData');
            
        // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
        fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
        
        // Find the item with the "description" key in the array
        let itemToUpdate = fbDataArray.find(obj => obj.country);
        let itemToUpdate1 = fbDataArray.find(obj => obj.countryCode);
        
        if (itemToUpdate) {
            // Update the description if it's different
            if (itemToUpdate.country !== item ) {
              
                itemToUpdate.country = item
                let country = Countries_Data.find(
                  (country) => country.country_name === item
                );
                itemToUpdate1.countryCode =country.country_code
                setCountry(country.country_code);
      //  dispatch(AddTargetingDetailsFacebook({CountryCode:country.country_code}))

            }
        } else {
            // If the item does not exist, add the new item directly
            let country = Countries_Data.find(
              (country) => country.country_name === item
            );
             fbDataArray.push({ countryCode:country.country_code })
      //  dispatch(AddTargetingDetailsFacebook({CountryCode:country.country_code}))
      setCountry(country.country_code);
            fbDataArray.push({ country:item });
        }
        
        // Convert the updated array back into a JSON string
        sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

      setData1([item]);
    }
    setActive1(false);
  };
  const addIntoArray2 = (item) => {
    if (!data2?.includes(item)) {

 // Retrieve the current array from sessionStorage
 let fbDataArray = sessionStorage.getItem('fbData');

 // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
 fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
 
 // Define the item to be added or updated
 
 // Find the item with the "cities" key in the array
 let itemToUpdate = fbDataArray.find(obj => obj.hasOwnProperty('keywords'));
 // If the item is found
 if (itemToUpdate) {
     // Update the cities array if the item is different
     if (!itemToUpdate.keywords?.includes(item)) {
         itemToUpdate.keywords.push(item);
     }
 } else {
     // If the item does not exist, add the new item directly
     fbDataArray.push({ keywords: [item] });
 }
 
 // Convert the updated array back into a JSON string
 sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));


      setData2([...data2, item]);
    }
  };

  const addIntoArray3 = (item) => {
    if (!data3?.includes(item)) {
      setData3([item]);
    }
    setActive3(false);
  };
  const handleNavigate = () => {
    const minAge = parseInt(min, 10);
    const maxAge = parseInt(max, 10);
 
  
    if (minAge >= 18) {
      if (minAge > maxAge) {
        toast.error("Please select a max age greater than the min age", {
          className: 'custom-toast-error',
          hideProgressBar: true,
        });
      } else if (minAge > 18 || maxAge > 65) {
        toast.error("Please select a valid age (18-65)", {
          className: 'custom-toast-error',
          hideProgressBar: true,
        });
      } else {
        navigate("/main/campaign/facebookcampaign/facebook-add-1/funding");
      }
    } else {
      toast.error("Please select a min age greater than or equal to 18", {
        className: 'custom-toast-error',
        hideProgressBar: true,
      });
    }
  };
  
  

  

  const addIntoArray4 = (item) => {
    if (!data4?.includes(item)) {
      setData4([...data4, item]);


 // Retrieve the current array from sessionStorage
 let fbDataArray = sessionStorage.getItem('fbData');

 // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
 fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
 
 // Define the item to be added or updated
 
 // Find the item with the "cities" key in the array
 let itemToUpdate = fbDataArray.find(obj => obj.hasOwnProperty('cities'));
 // If the item is found
 if (itemToUpdate) {
     // Update the cities array if the item is different
     if (!itemToUpdate?.cities?.includes(item)) {
         itemToUpdate?.cities?.push(item);
     }
 } else {
     // If the item does not exist, add the new item directly
     fbDataArray.push({ cities: [item] });
 }
 
 // Convert the updated array back into a JSON string
 sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

    }
    setCity("");
  };
  const addIntoArray5 = (item) => {
    if (!data5?.includes(item)) {
      setData5([item]);
    }
    setActive5(false);
  };

  const addIntoArray6 = (item) => {
    if (!data6?.includes(item)) {
      setData6([item]);
    }
    setActive6(false);
  };

  const addIntoArray7 = (item) => {
    if (!data7?.includes(item)) {


        // Retrieve the current array from sessionStorage
        let fbDataArray = sessionStorage.getItem('fbData');
            
        // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
        fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
        
        // Find the item with the "description" key in the array
        let itemToUpdate = fbDataArray.find(obj => obj.gender);
        
        if (itemToUpdate) {
            // Update the description if it's different
            if (itemToUpdate.gender !== item ) {
                itemToUpdate.gender = item
            }
        } else {
            // If the item does not exist, add the new item directly
            fbDataArray.push({ gender:item });
        }
 
        // Convert the updated array back into a JSON string
        sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

      setData7([item]);
    }

    setGender(item)
    setActive7(false);
  };

  const DeleteItemFromListArry1 = (array, i) => {
    let fbDataArray = sessionStorage.getItem('fbData');

// Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];


// Remove the item with the "country" key
fbDataArray = fbDataArray.filter(obj => !obj.hasOwnProperty('country'));

// Remove the item with the "countryCode" key
fbDataArray = fbDataArray.filter(obj => !obj.hasOwnProperty('countryCode'));
sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

    const updatedData1 = [...data1]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData1(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry2 = (array, i) => {

    
// Retrieve the current array from sessionStorage
let fbDataArray = sessionStorage.getItem('fbData');

// Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];



// Find the item with the "cities" key in the array
let itemToUpdate = fbDataArray.find(obj => obj.hasOwnProperty('keywords'));

// If the item is found and the index is valid
if (itemToUpdate) {
  // Check if the index 'i' is valid
  if (i > -1 && i < itemToUpdate.keywords.length) {
      // Remove the keyword at the specific index
      itemToUpdate.keywords.splice(i, 1);
  }

  // If the keywords array is now empty, remove the entire object from the array
  if (itemToUpdate.keywords.length === 0) {
    fbDataArray = fbDataArray.filter(obj => !obj.hasOwnProperty('keywords'));

  }
}

// Convert the updated array back into a JSON string
sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

    const updatedData1 = [...data2]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData2(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry3 = (array, i) => {
    const updatedData1 = [...data3]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData3(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry4 = (array, i) => {

// Retrieve the current array from sessionStorage
let fbDataArray = sessionStorage.getItem('fbData');

// Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];



// Find the item with the "cities" key in the array
let itemToUpdate = fbDataArray.find(obj => obj.hasOwnProperty('cities'));

// If the item is found and the index is valid
if (itemToUpdate && i > -1 && i < itemToUpdate.cities.length) {
  // Remove the item at the specific index
  itemToUpdate.cities.splice(i, 1);

  // If the cities array is now empty, remove the entire object from the array
  if (itemToUpdate.cities.length === 0) {
      // Find the index of the object to remove it from fbDataArray
      const indexToRemove = fbDataArray.indexOf(itemToUpdate);
      fbDataArray = fbDataArray.filter(obj => !obj.hasOwnProperty('cities'));
  }
}

// Convert the updated array back into a JSON string
sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

// Logging the result for verification

    const updatedData1 = [...data4]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData4(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry5 = (array, i) => {
    const updatedData1 = [...data5]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData4(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry6 = (array, i) => {
    const updatedData1 = [...data6]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData4(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry7 = (array, i) => {
    let fbDataArray = sessionStorage.getItem('fbData');

// Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];


// Remove the item with the "country" key
fbDataArray = fbDataArray.filter(obj => !obj.hasOwnProperty('gender'));
sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));
    const updatedData1 = [...data7]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData7(updatedData1); // Update the state with the new array
  };


  
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (city?.trim() !== "") {


          axios
          .get(
            `${nodeUrl}/get-city-facebook?country=${country}&q=${city}`,
          {
            headers:{
              "Authorization": `Bearer ${Cookie.get("token")}`
            }
          }
          )

          

          // for direct python
        // axios
        //   .get(
        //     `http://3.6.128.153:5000/v1/google/geo-targets/locations?country=${country}&q=${city}`
        //   )
          .then((response) => {
            setCityArray(() => response?.data?.data?.data);

          })
          .catch((error) => {
            // console.error("Error fetching data:", error);
          });
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [city]);

  const handleAccordian1 = (active) => {
    setActive1(active);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
  };

  const handleAccordian2 = (active) => {
    setActive1(false);
    setActive2(active);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);

  };

  const handleAccordian3 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(active);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);

  };

  const handleAccordian4 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(active);
    // setActive5(false);
    // setActive6(false);
    // setActive7(false);

  };

  const handleAccordian5 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(active);
    setActive6(false);
    setActive7(false);

  };

  const handleAccordian6 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(active);
    setActive7(false);

  };

  const handleAccordian7 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(active);

  };

  const setMinAge = (e) => {
    const value = e.target.value;

    // Retrieve the current array from sessionStorage
    let fbDataArray = sessionStorage.getItem('fbData');
        
    // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
    fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
    
    // Find the item with the "minAge" key in the array
    let itemToUpdate = fbDataArray.find(obj => obj?.minAge);

    if (value == "") {
        // If the minAge is less than 0, remove the object from the array
        fbDataArray = fbDataArray.filter(obj => !obj.minAge);
    } else {
        if (itemToUpdate) {
            // Update the minAge if it's different
            if (itemToUpdate.minAge !== value) {
                itemToUpdate.minAge = value;
            }
        } else {
            // If the item does not exist, add the new item directly
            fbDataArray.push({ minAge: value });
        }
    }

    // Convert the updated array back into a JSON string
    sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

    // Update the state
    setMin(value);
};



const setMaxAge = (e) => {
  const value = e.target.value;

  // Retrieve the current array from sessionStorage
  let fbDataArray = sessionStorage.getItem('fbData');
      
  // Parse the JSON string into a JavaScript array, or create a new array if it doesn't exist
  fbDataArray = fbDataArray ? JSON.parse(fbDataArray) : [];
  
  // Find the item with the "maxAge" key in the array
  let itemToUpdate = fbDataArray.find(obj => obj?.maxAge);

  if (value == "") {
      // If the maxAge is less than 0, remove the object from the array
      fbDataArray = fbDataArray.filter(obj => !obj.maxAge);
  } else {
      if (itemToUpdate) {
          // Update the maxAge if it's different
          if (itemToUpdate.maxAge !== value) {
              itemToUpdate.maxAge = value;
          }
      } else {
          // If the item does not exist, add the new item directly
          fbDataArray.push({ maxAge: value });
      }
  }

  // Convert the updated array back into a JSON string
  sessionStorage.setItem('fbData', JSON.stringify(fbDataArray));

  // Update the state
  setMax(value);
};


  useEffect(() => {
    HandleActiveStep(2);
  }, []);
  return (
    <div
      className="targerting-main-box Fb-funding-scroller"
      style={{}}
    // onClick={() => {
    //   setActive1(false);
    //   setActive2(false);
    //   setActive3(false);
    // }}
    >
      <div style={{ alignSelf: "center", maxWidth: "700px", width: "100%" }}>
        <Accordion
          title={"Select Location"}
          placeholder={"The Entire World"}
          active={active1}
          setActive={handleAccordian1}
          dataArr={OnlyCountry}
          addIntoArray={addIntoArray1}
          DeleteItemFromList={DeleteItemFromListArry1}
          data={data1 && data1}
          scrollHeight={0}
          searchInput
          morePlus
        />

        {data1?.length > 0 && (
          <Accordion2
            title={"Select City/State"}
            placeholder={"Select City/State"}
            active={active4}
            setActive={handleAccordian4}
            dataArr={cityArray}
            addIntoArray={addIntoArray4}
            DeleteItemFromList={DeleteItemFromListArry4}
            data={data4}
            setCity={setCity}
            city={city}
            scrollHeight={100}
          />
        )}

        <Accordion3
          title={"Select Keyword"}
          dataArr={keyword ? keyword : ["Please select headline first"]}
          placeholder={"Keyword"}
          active={active2}
          setActive={handleAccordian2}
          addIntoArray={addIntoArray2}
          DeleteItemFromList={DeleteItemFromListArry2}
          data={data2}
          scrollHeight={200}
          searchInput
          morePlus
        />
        

        <div className="demographic-details-alignment">
          <label>Demographic</label>
          <div className="ageAlignment">
            <div style={{ width: "125px" }} >
              {/* <Accordion
                placeholder={"Age"}
                setActive={handleAccordian4}
                dataArr={arr4}
                addIntoArray={addIntoArray4}
                DeleteItemFromList={DeleteItemFromListArry4}
                data={data4}
                scrollHeight={100}
                smallDropdown
              /> */}
              <div className="age-design-alignment" onClick={() => setAgeInput(!ageInput)}>
                <div className="tagert-accordion-main">
                  <div className="tagert-accordion">
                    <p className="targer-accordion-text">
                      Age
                    </p>
                    <span style={{ width: "20px" }}>
                      {ageInput ? minusIcon : plusIcon}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {ageInput && (

              <>
                <div style={{ width: "125px" }}>
                  <div className="age-design-alignment">
                    <div className="tagert-accordion-main">
                      <div className="tagert-accordion">
                        <div className="targer-accordion-text">
                        <input
  type="number"
  placeholder="Min"
  className="age-input-alignment"
  onChange={(e) => setMinAge(e)}
  defaultValue={min || 18}
  min={18}
  max={65}
/>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "125px" }}>
                  <div className="age-design-alignment">
                    <div className="tagert-accordion-main">
                      <div className="tagert-accordion">
                        <div className="targer-accordion-text">
                        <input type="number" placeholder="Max" className="age-input-alignment" onChange={(e)=>setMaxAge(e)} defaultValue={max}   min={min + 1}
        max={65}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="ageAlignment">

            <div className="option-details-flex-alignment">
              <div style={{ width: "125px" }}>

                <Accordion
                  placeholder={"Gender"}
                  active={active7}
                  setActive={handleAccordian7}
                  dataArr={arr5}
                  addIntoArray={addIntoArray7}
                  DeleteItemFromList={DeleteItemFromListArry7}
                  data={data7}
                  scrollHeight={100}
                  smallDropdown
                />
              </div>
            </div>
          </div>
        </div>
        <div className="language-details-alignment">
          <label htmlFor="">Language</label>

          <div className="language-input-alignment">
            <span>English</span>
          </div>
        </div>
      </div>
      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/facebookcampaign/facebook-add-1/content"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        <div onClick={handleNavigate}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div >
  );
};

const Accordion3 = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  scrollHeight,
  searchInput,
  smallDropdown,
  morePlus
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [manualInput, setManualInput] = useState("");

  const HandleScroll = () => {
    const mainBox = document.querySelector(".targerting-main-box");
    setTimeout(() => {
      mainBox.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 100);
  };

  const handleManualAdd = () => {
    if (manualInput?.trim() !== "") {
      addIntoArray(manualInput.trim());
      setManualInput("");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <button
        className="targerting-box"
        style={{}}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(!active);
          HandleScroll();
        }}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main">
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data?.length > 0
                ? data.map((item, i) => (
                  <div className="item-in-data" key={i}>
                    <p st className="item-in-data-text">{item}</p>
                    <span
                      style={{}}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        DeleteItemFromList(data, i);
                      }}
                    >
                      {RemoveSVG}
                    </span>
                  </div>
                ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>
      {active && (
        <div className={smallDropdown ? "search-body small-dropdown" : "search-body  "} style={{}}>
          {/* {searchInput && (

            <div className="search-main">
              <div>
                {SearchSvg}
                <input
                  type="text"
                  style={{
                    border: "transparent",
                    background: "inherit",
                    outline: "none",
                  }}
                  placeholder="Search Keyword"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
          )} */}

           <div className="manual-input-section" style={{width:"100%"}}>
            <input
            className="manual-input"
              type="text"
              placeholder="Type your own keyword"
              maxLength={20}
              value={manualInput}
              onChange={(e) => setManualInput(e.target.value)}
            />
            <button  className="manual-btn" onClick={handleManualAdd}><span style={{marginRight:"-124px"}}>{plusIcon}</span></button>
          </div>

          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr?.length > 0
              ? dataArr
                ?.filter((item) =>
                  item.toLowerCase()?.includes(searchValue.toLowerCase())
                )
                .map((item, i) => (
                  <div
                    className="item-main"
                    onClick={() => addIntoArray(item)}
                  >
                    <p style={{cursor:"pointer"}} className="item-text">{item}</p>
                    {morePlus && (

                      <span>{plusIcon}</span>
                    )}
                  </div>
                ))
              : "No data"}
          </div>
        </div>
      )}



    </div>
  );
};


const Accordion = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  scrollHeight,
  searchInput,
  smallDropdown,
  morePlus
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [manualInput, setManualInput] = useState("");
  const HandleScroll = () => {
    const mainBox = document.querySelector(".targerting-main-box");
    setTimeout(() => {
      mainBox.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 100);
  };
  const handleManualAdd = () => {
    if (manualInput?.trim() !== "") {
      addIntoArray(manualInput.trim());
      setManualInput("");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <button
        className="targerting-box"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(!active);
          HandleScroll();
        }}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main">
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data?.length > 0
                ? data.map((item, i) => (
                    <div className="item-in-data" key={i}>
                      <p className="item-in-data-text">{item}</p>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          DeleteItemFromList(data, i);
                        }}
                      >
                        {RemoveSVG}
                      </span>
                    </div>
                  ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>
      {active && (

        
        <div className={smallDropdown ? "search-body small-dropdown" : "search-body"}>

          {/* Manual Input Section */}
          {/* <div className="manual-input-section">
            <input
            className="manual-input"
              type="text"
              placeholder="Type your own keyword"
              maxLength={20}
              value={manualInput}
              onChange={(e) => setManualInput(e.target.value)}
            />
            <button className="manual-btn" onClick={handleManualAdd}><span>{plusIcon}</span></button>
          </div> */}

          {searchInput && (
            <div className="search-main">
              <div>
                {SearchSvg}
                <input
                  type="text"
                  style={{
                    border: "transparent",
                    background: "inherit",
                    outline: "none",
                  }}
                  placeholder="Search Country"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
          )}

          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr?.length > 0 ? (
              dataArr
                .filter((item) =>
                  item.toLowerCase()?.includes(searchValue.toLowerCase())
                )
                .map((item, i) => (
                  <div className="item-main" key={i} onClick={() => addIntoArray(item)}>
                    <p className="item-text" style={{cursor:'pointer'}}>{item}</p>
                    {morePlus && (
                      <span>{plusIcon}</span>
                    )}
                  </div>
                ))
            ) : (
              <p>No data</p>
            )}
          </div>
          
          
          
        </div>
      )}
    </div>
  );
};
const Accordion2 = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  city,
  setCity,
}) => {
  return (
    <div>
      <button
        className="targerting-box"
        style={{}}
        onClick={() => setActive(!active)}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main">
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data?.length > 0
                ? data?.map((item, i) => (
                  <div className="item-in-data" key={item.id}>
                    <p className="item-in-data-text">{item}</p>

                    <span
                      style={{}}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        DeleteItemFromList(data, i);
                      }}
                    >
                      {RemoveSVG}
                    </span>
                  </div>
                ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>

      {active && (
        <div className="search-body" style={{}}>
          <div className="search-main">
            <div>
              {SearchSvg}
              <input
                autoFocus
                type="text"
                style={{
                  border: "transparent",
                  background: "inherit",
                  outline: "none",
                }}
                placeholder="Search city/state"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr?.length > 0 &&
              dataArr
                .filter((item) =>
                  item.name.toLowerCase()?.includes(city?.toLowerCase())
                )
                .map((item, i) => (
                  <div
                    className="item-main"
                    style={{ cursor: "pointer" }}
                    onClick={() => addIntoArray(item.name)}
                  >
                    <p className="item-text">
                      {item.name} ,{item.canonical_name}
                    </p>
                    <p className="item-text"></p>

                    <span>{plusIcon}</span>
                  </div>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default GoogleTargeting;
