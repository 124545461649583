import React, { useContext, useState } from "react";
import "./GoogleCampaign.css";
import { Container, Row } from "react-bootstrap";
import { leftArrow, googleIcon, dotsIcon1, World, MobileSvg } from "./SVG";
import { Link, NavLink, Outlet } from "react-router-dom";
import { ToggleBtn } from "./SideNavContainer/FacebookContent";
import { ToggleContext, ToggleProvider } from "./ContextFile";
import YourComponent from "./Steps/Steps";

const FacebookCampaign = () => {
  // useEffect(() => {
  //   if (active === "first") {
  //     navigate("/campaign/googlecompaign/content");
  //   }
  // }, []);

  return (
    <ToggleProvider>
      <Container
        fluid
        className="goggle-main-body"
        style={{ padding: "0px", height: "100%", position: "relative" }}
      >
        <div style={{ position: "absolute", right: 0, top: "120px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="104"
            viewBox="0 0 72 134"
            fill="none"
          >
            <g filter="url(#filter0_diii_392_7899)">
              <circle cx="56" cy="56" r="36" fill="#47BEB9" />
            </g>
            <defs>
              <filter
                id="filter0_diii_392_7899"
                x="0.615384"
                y="0.615384"
                width="132.923"
                height="132.923"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="11.0769" dy="11.0769" />
                <feGaussianBlur stdDeviation="15.2308" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_392_7899"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_392_7899"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="5.53846" dy="-11.0769" />
                <feGaussianBlur stdDeviation="15.2308" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.652583 0 0 0 0 0.955 0 0 0 0 0.941559 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect2_innerShadow_392_7899"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="11.0769" dy="13.8462" />
                <feGaussianBlur stdDeviation="12.4615" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_innerShadow_392_7899"
                  result="effect3_innerShadow_392_7899"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="14.4" />
                <feGaussianBlur stdDeviation="16.344" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.28 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_innerShadow_392_7899"
                  result="effect4_innerShadow_392_7899"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div style={{ position: "absolute", right: 0, top: "170px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="144"
            height="186"
            viewBox="0 0 194 256"
            fill="none"
          >
            <g filter="url(#filter0_diii_370_10473)">
              <circle cx="107" cy="107" r="69" fill="#47BEB9" />
            </g>
            <defs>
              <filter
                id="filter0_diii_370_10473"
                x="0.846155"
                y="0.846155"
                width="254.769"
                height="254.769"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="21.2308" dy="21.2308" />
                <feGaussianBlur stdDeviation="29.1923" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_370_10473"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_370_10473"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="10.6154" dy="-21.2308" />
                <feGaussianBlur stdDeviation="29.1923" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.652583 0 0 0 0 0.955 0 0 0 0 0.941559 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect2_innerShadow_370_10473"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="21.2308" dy="26.5385" />
                <feGaussianBlur stdDeviation="23.8846" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_innerShadow_370_10473"
                  result="effect3_innerShadow_370_10473"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="27.6" />
                <feGaussianBlur stdDeviation="31.326" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.28 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_innerShadow_370_10473"
                  result="effect4_innerShadow_370_10473"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <Row className="google-header">
          <Header title={"FaceBook Campaign"} display={1} />
          <GoogleNavigation
            icon1={googleIcon}
            title1={"Facebook Ad 01"}
            // title2={"Facebook Ad 02"}
            title3={"Your Website"}
            NavTo1={"facebook-add-1"}
            // NavTo2={"facebook-add-2"}
            NavTo3={"facebook-add-3"}
            dotsIcon={dotsIcon1}
          />
        </Row>

        <Row className="circle-Nav-And-LaptopImg-div">
          <Outlet />
        </Row>
      </Container>
    </ToggleProvider>
  );
};

export const GoogleNavigation = ({
  icon1,
  title1,
  title2,
  title3,
  NavTo1,
  NavTo2,
  NavTo3,
  dotsIcon,
}) => {
  return (
    <div className="google-Nav-main">
      <NavTab text={title1} icon={icon1} NavTo={NavTo1} dotsIcon={dotsIcon} />
      <div style={{ width: "1px", height: "33px", background: "#B2BBC6" }} />
      {/* <NavTab text={title2} icon={icon1} NavTo={NavTo2} dotsIcon={dotsIcon} />
      <div style={{ width: "1px", height: "33px", background: "#B2BBC6" }} /> */}
      <NavTab text={title3} icon={World} NavTo={NavTo3} dotsIcon={dotsIcon} />
      <YourComponent />
    </div>
  );
};
const NavTab = ({ text, icon, NavTo, dotsIcon }) => {
  return (
    <NavLink to={NavTo} className="google-Nav-Tab">
      {icon ? icon : ""}
      <p className="google-Nav-tab-text">{text}</p>
      <span className="hide-google-dots">{dotsIcon ? dotsIcon : ""}</span>
    </NavLink>
  );
};

export const Header = ({ title, display, HandleNewAdBtn }) => {
  const { toggle, handleToggleBtn, toggleVisible } = useContext(ToggleContext);

  return (
    <div className="google-header-div">
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Link to={"/main/campaign/platfromselection"}>{leftArrow}</Link>
        <p className="google-header-text">{title}</p>
      </div>

      {display === 1 ? (
        toggleVisible ? (
          <ToggleBtn toggle={toggle} handleToggleBtn={handleToggleBtn} />
        ) : null
      ) : (
        <Btn HandleNewAdBtn={HandleNewAdBtn} title={"New Ad"} />
      )}
    </div>
  );
};

export const Btn = ({ HandleNewAdBtn, title }) => {
  return (
    <div>
      <button className="new-ad-btn" onClick={HandleNewAdBtn}>
        <span className="">{title}</span>
      </button>
    </div>
  );
};
export default FacebookCampaign;
