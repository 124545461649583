import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
const MailContext = createContext();
const MailProvider = ({ children }) => {
  const {brandData} =useSelector((state)=>state?.brandSetupData)
  const [mail, setMail] = useState();
  const [coin, setCoin] = useState();
  const [imageToggle, setImageToggle] = useState(true);
  const [aiSuggestionLoader, setAiSuggestionLoader] = useState(false);
  const [fonts, setFonts] = useState(brandData?.selectedFont?? "Poppins");
const [performanceCampaignData ,setPerformanceCampaignData]=useState(null)

const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleMail = (mail) => {
    setMail(mail);
  };
  const handleCoin = (res) => {
    setCoin(res);
  };
  const HandleAiSuggestionLoader = (res) => {
    setAiSuggestionLoader(res);
  };

  const HandleimageToggle = (res) => {
    setImageToggle(res);
  };

  const HandleFonts = (res) => {
    setFonts(res);
  };

  const HandlePerformanceCampaign=(data)=>{
    setPerformanceCampaignData(data)
  }

  useEffect(() => {
      const cookiesId = Cookies.get('session_id');
      const sessionId =sessionStorage.getItem("session_id")

      
      setIsAuthenticated(!!sessionId);
  }, []);

  return (
    <MailContext.Provider
      value={{
        mail,
        handleMail,
        coin,
        handleCoin,
        HandleAiSuggestionLoader,
        aiSuggestionLoader,
        HandleimageToggle,
        imageToggle,
        HandleFonts,
        fonts,
        HandlePerformanceCampaign,performanceCampaignData,
      isAuthenticated
      }}
    >
      {children}
    </MailContext.Provider>
  );
};

export { MailProvider, MailContext };
