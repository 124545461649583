import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import Avatar from "./Header Images/avatar.png";
import "../../Main Pages/Images/userDetailBg.png";

import {
  BellIcon,
  // Coins,
  DropDownArrow,
  NotificationBell,
  NotificationSvg,
} from "./svg";
import axios, { nodeUrl } from "../../../axios/auth";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MailContext } from "../../Auth Pages/mailContext";
import { Button, Col, Dropdown, Modal, Navbar, Row } from "react-bootstrap";
import { inputIcone } from "./svg";
import SideBar from "../SideBar";
import { useForm } from "react-hook-form";
import { adduserdata, clearRedux } from "../../../../Redux/login/loginSlice";
import { ToastContainer, toast } from "react-toastify";
import eyeClose from "../../Auth Pages/Images/password.png";
import eyeOpen from "../../Auth Pages/Images/openEye.png";
import Coins from "../../../account/Main Pages/Images/coins.png";
import { handleCoinChange } from "../../../../Redux/addCreateSlice";
import { handleFullName, handleprofileImage } from "../../../../Redux/addCreateSlice";
import CampaignsLoader from "../Dashboard/CampaignsLoader";
import { addGoogleAnalytics } from "../Redux/AdsAnalyticsDetailsSlice";
import { clearToken } from "../Redux/login/loginSlice";
import CloseIcon from "../../../../assets/icons/close.png";
import MoreIcon from ".././../../../assets/icons/menu-icon.png";
import MobileLogo from "../../../../assets/logo/logo.png";



const Header = ({ modalShow, setModalShow, notification }) => {
  const token = Cookies.get("token");
  const [modal, setModal] = useState(false);
  const [userDeatilModal, setUserDetailModal] = useState(false);
  const [coinsExpire, setCoinsExpire] = useState(false);
  const [ToggleOnNav, setToggleOnNav] = useState(false);
  const [GoogleAdSuccess, setgoogleAdSuccess] = useState();
  const [GoogleAdsResult, setgoogleAdsResult] = useState();
  const [mobileSidebar, setMobileSidebar] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(null);








  

const [coin ,setTicket]= useState()
  const flag = useRef(true);



  

  const user = useSelector((state) => state.loginuser?.user);

  useEffect(() => {
    getCoins();

    if (user?.credits<= 5) {
      setCoinsExpire(true);
      setInterval(() => {
      flag.current = false;
      setCoinsExpire(false);

      }, [3000]);

    }
  }, []);

  // let coin = useSelector((state) => state.addcreative.coinManagement);
  let {coinMangment} = useSelector((state) => state.addcreative);
  let {profileImage} = useSelector((state) => state.addcreative);
    let {profileName} = useSelector((state) => state.addcreative);
  const currentDate = new Date();
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCoins = async () => {

    if(token){
    try {
      const res = await axios.get(
        "/blanceHistory",

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
     
      dispatch(handleCoinChange(res?.data?.data?.credits))
    if(res){
      setTicket(coinMangment)
    }
    
    } catch (error) {
      // console.log(error);
    }
  }
  else{
    navigate("/login")
  }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown); // Toggle the dropdown
  };


useEffect(()=>{

},[coinMangment])

useEffect(() => {
  getCoins();

  if (user?.credits<= 5) {
    setCoinsExpire(true);
    setInterval(() => {
    flag.current = false;
    setCoinsExpire(false);

    }, [3000]);

  }
}, [coinMangment]);


 

  const HandleToggleNav = () => {
    // setToggleOnNav(true);
    RemoveShowClass();
  };
  const RemoveShowClass = () => {
    // Get a reference to the element with id "basic-navbar-nav"
    const navbarNav = document.getElementById("basic-navbar-nav");
    const navbarNavMain = document.getElementById("basic-navbar-nav-main");

    // Check if the "show" class is present
    if (navbarNav.classList.contains("show")) {
      // If it's present, remove the "show" class
      navbarNav.classList.remove("show");
      navbarNavMain.classList.add("collapsed");
      setToggleOnNav(false);
    }
  };

  const RemoveCollapsedClass = () => {
    const navbarNavMain = document.getElementById("basic-navbar-nav-main");
    if (navbarNavMain.classList.contains("collapsed")) {
      navbarNavMain.classList.remove("collapsed");

      setToggleOnNav(false);
    }
  };

  const logout = async () => {
   
   dispatch(addGoogleAnalytics(null)) 
    try {

      Cookies.remove("token");
      Cookies.remove("_TWk_Id");
      dispatch(clearRedux());
      // dispatch(clearToke(""))
   
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  

  const handleGoogleAdSuccess = () => {
    const urlWithoutStatus = window.location.href.split("?")[0]; // Get the current URL without query parameters
    window.history.replaceState({}, document.title, urlWithoutStatus); // Replace the current URL without modifying the page

    let adType = sessionStorage.getItem("adType");

    if (adType == "search") {
      sessionStorage.removeItem("googleSearchAdsCompleteData");
      sessionStorage.removeItem("adType");
    } else if (adType == "Display") {
      sessionStorage.removeItem("googleDisplayAdsCompleteData");
      sessionStorage.removeItem("adType");
      sessionStorage.removeItem("GoogleDisplayImages");
    }

    setgoogleAdSuccess(false);
  };

  useEffect(() => {
    // Parsing the URL
    let urlParams = new URLSearchParams(window.location.search);
    // Getting the value of the 'google' parameter
    let googleParam = urlParams.get("status");

    if (googleParam === "true") {
      setgoogleAdSuccess(true);
      setgoogleAdsResult(true);
    } else if (googleParam === "false") {
      setgoogleAdSuccess(true);
      setgoogleAdsResult(false);
    }
  }, []);

  return (
    <Navbar expand="lg" className="header-main-container  ">
      <Navbar.Brand className="Welcome">
        <p>
          Welcome,&nbsp;
          <span>
            {profileName? profileName:user?.fullName}
          </span>
          <br />
          <p className="date">{formattedDate}</p>
        </p>
      </Navbar.Brand>
      {/* <Navbar.Toggle
        id="basic-navbar-nav-main"
        aria-controls="basic-navbar-nav"
        onClick={RemoveCollapsedClass}
      /> */}
       <div
        id="basic-navbar-nav"
        className={mobileSidebar ? "right-item mobile-view-open-sidebar" : "right-item mobile-view-close-sidebar"}
        ref={dropdownRef}
      >

      {/* <Navbar.Collapse
        id="basic-navbar-nav"
        className={mobileSidebar ? "right-item mobile-view-open-sidebar" : "right-item mobile-view-close-sidebar"}
        // onClick={ToggleOnNav ? RemoveShowClass : null}
      > */}

         <div className="close-alignment" onClick={() => setMobileSidebar(false)}>
          <img src={CloseIcon} alt="CloseIcon" />
        </div>
        {user && (
          <div
            className="date"
            style={{
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {/* {Coins} */}
            <img src={Coins} alt="Coins" />
            <span>{coin}</span>
          </div>
        )}

<NotificationDropDown 
        notification={notification} 
        isOpen={openDropdown === 'notification'} 
        onToggle={() => handleDropdownToggle('notification')} 
      />
      
      <DropDown
        setModalShow={setModalShow}
        modalShow={modalShow}
        user={user}
        profileImage={profileImage}
        profileName={profileName}
        logout={logout}
        setModal={setModal}
        setUserDetailModal={setUserDetailModal}
        isOpen={openDropdown === 'account'}
        onToggle={() => handleDropdownToggle('account')}
      />

        {/* <NotificationDropDown notification={notification}
         
        />
        

        <DropDown
          setModalShow={setModalShow}
          modalShow={modalShow}
          user={user}
          profileImage={profileImage}
          profileName={profileName}
          logout={logout}
          setModal={setModal}
          setUserDetailModal={setUserDetailModal}
        /> */}
          {/* <DropDown
        setModalShow={setModalShow}
        modalShow={modalShow}
        user={user}
        profileImage={profileImage}
        profileName={profileName}
        logout={logout}
        setModal={setModal}
        setUserDetailModal={setUserDetailModal}
        isOpen={openDropdown === 'dropdown'}
        onClick={handleDropDownClick}
      /> */}


        <div className="d-sm-none">

          <SideBar
            className="mobileSidebarShow"
            HandleToggleNav={HandleToggleNav}
            setMobileSidebar={setMobileSidebar}
          />
        </div>
        <CustomModal
          show={modal}
          setModal={setModal}
          onHide={() => setModal(false)}
        />
        <AccountUpdate
          show={userDeatilModal}
          setModal={setUserDetailModal}
          onHide={() => setUserDetailModal(false)}
        />
        <CoinsExpireModal
          show={coinsExpire}
          setModal={setCoinsExpire}
          onHide={() => setCoinsExpire(false)}
          buttonTitle={"Close"}
          coin={coin}
        />
        </div>

      {/* </Navbar.Collapse> */}
      <AdRunSuccessModal
        show={GoogleAdSuccess}
        buttonTitle={"Close"}
        handleCloseModal={handleGoogleAdSuccess}
        onHide={() => setgoogleAdSuccess(false)}
        GoogleAdsResult={GoogleAdsResult}
      />
      {/* <ToastContainer /> */}
      <div className="mobile-view-option-alignment" onClick={() => setMobileSidebar(true)}>
        <img src={MoreIcon} alt="more-icon" />
      </div>
    </Navbar>
  );
};

const DropDown = ({ setModal, user,profileImage,profileName, logout, setUserDetailModal, isOpen, onToggle}) => {

  const token = Cookies.get("token");
  const [fullName, setFullName] = useState("");

  const dispatch = useDispatch();

  const openAcoountModal =async()=>{

    try {
      const res = await axios.get("/getUser", {
        headers: {
          Authorization: `Bearer ${token}`, // Correct spelling
        },
      });
      if(res?.data?.data[0]){
      dispatch(adduserdata(res?.data?.data[0]));
      dispatch(handleprofileImage(res?.data?.data[0]?.imagePath))
      dispatch(handleFullName(res?.data?.data[0]?.fullName))
      setFullName(res?.data?.data[0]?.fullName)
      setUserDetailModal(true)
      }
      else{
        setUserDetailModal(true)
      }

    } catch (e) {
      // console.log(e);
      // throw e;
    }
  


  
}
  return (

    <Dropdown
    show={isOpen}
      onClick={(e) => (e.stopPropagation(), e.preventDefault())}
      style={{ position: "relative" }}
    >
      <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-btn" onClick={onToggle}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div>
          <img
              className="avatar"
              src={
                profileImage ? `${nodeUrl}/${profileImage}` : 
                user?.imagePath ? `${nodeUrl}/${user.imagePath}` : Avatar
              }
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop in case of repeated errors
                e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
              }}
              alt="avatar"
            />
          </div>

          <div className="logout-container">
            <p className="logout-text">
            { profileName ? profileName :
              user?.fullName}
              &nbsp;
            </p>

            <div
              className="logout-div"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                logout();
              }}
            >
              <h6 className="logout-label">Logout</h6>
              <div style={{ alignItems: "flex-start", display: "flex" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10.667 11.3337L14.0003 8.00033L10.667 4.66699"
                    stroke="#B2BBC6"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 8H6"
                    stroke="#B2BBC6"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.99996 13.9999H3.33332C2.9797 13.9999 2.64057 13.8594 2.39052 13.6094C2.14047 13.3593 2 13.0202 2 12.6666V3.33332C2 2.9797 2.14047 2.64057 2.39052 2.39052C2.64057 2.14047 2.9797 2 3.33332 2H5.99996"
                    stroke="#B2BBC6"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="drop-down-menu-main">
        <span className="dropdown-arrow ">{DropDownArrow}</span>
        <div style={{}}>
          <div className="dropdown-item1" style={{ display: "flex" }}>
          <img
              className="avatar"
              src={
                profileImage ? `${nodeUrl}/${profileImage}` : 
                user?.imagePath ? `${nodeUrl}/${user.imagePath}` : Avatar
              }
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop in case of repeated errors
                e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
              }}
              alt="avatar"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label>
              { profileName ? profileName: user?.fullName}
                &nbsp;
              </label>
              <p>{user?.email}</p>
            </div>
          </div>
        </div>
        <div
          style={{
            border: "5px solid #edf9f8",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          {/* <div
            style={{}}
            onClick={() => setModal(true)}
            className="drop-down-item2"
          >
            <p className="drop-down-p">Brand Settings</p>
          </div> */}

          <div
            className="drop-down-item2"
            onClick={() => setUserDetailModal(true)}
          >
            <p className="drop-down-p">Account Settings</p>
          </div>

          <div className="drop-down-item3 " onClick={logout}>
            <p>Logout</p>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Row className="header-modal">
        <Row className="header-modal-heading">Brand Settings</Row>
        <Row className="header-modal-rowcontainer">
          <label>Brand Name</label>
          <input placeholder="User Name" />
        </Row>
        <Row className="header-modal-rowcontainer">
          <label>Google Ad Account</label>
          <input placeholder="No ad account selected ... " />
        </Row>
        <Row className="header-modal-rowcontainer">
          <label>Facebook Ad Account</label>
          <input placeholder="No ad account selected ... " />
        </Row>
        <Row style={{ width: "35%" }}>
          <button
            onClick={() => props.setModal(false)}
            className="header-modal-button"
          >
            Done
          </button>
        </Row>
      </Row>
    </Modal>
  );
};

const AccountUpdate = (props) => {
  const user = useSelector((state) => state.loginuser.user);
  const [toggle, setToggle] = useState(true);
  const [oldPasswordToggle, setOldPasswordToggle] = useState(false);
  const [newPasswordToggle, setNewPasswordToggle] = useState(false);
  const [currentPasswordToggle, setCurrentPasswordToggle] = useState(false);
  const [defaultName, setDefaultName] = useState(user?.fullName);


  let {profileImage} = useSelector((state) => state.addcreative);
    let {profileName} = useSelector((state) => state.addcreative);
  const [profilepic, setProfilepic] = useState("");
  const [privewImg, setPrivewImg] = useState();
  const [userImage, setUserImage] = useState(profileImage || user?.imagePath);


  
  const {
    register,
    handleSubmit,
    reset,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const oldPassword = watch("currentpassword", ""); 
  const password = watch("newpassword", ""); // Watch the "password" field
  const navigate = useNavigate();
  const confirmPassword = watch("confirmPassword", ""); // Watch the "confirmPassword" field
  const name = watch("name", "");

  const fetchdata = async () => {
    try {
      const res = await axios.get("/getUser", {
        headers: {
          Authorization: `Bearer ${token}`, // Correct spelling
        },
      });

      dispatch(adduserdata(res?.data?.data[0]));
      dispatch(handleprofileImage(res?.data?.data[0]?.imagePath))
      dispatch(handleFullName(res?.data?.data[0]?.fullName))
    } catch (e) {
      // console.log(e);
      // throw e;
    }
  };

  const logout = async () => {
    try {
      const res = await axios.get("/logout", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // toast.info("Re-Login with your New-password");
      toast.success("Re-Login with your New-password", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
      Cookies.remove("token");
      setTimeout(() => {
        dispatch(clearRedux());
        navigate("/login");
      }, [3000]);
    } catch (error) {}
  };
  const handleImageChange = (e) => {

    setProfilepic(e.target.files[0]);

    const imgUrl =  URL.createObjectURL(e.target.files[0]);
    setPrivewImg(imgUrl)
};

  const Submit = async (data) => {
    
    if (
      (defaultName ||profileName|| data.companyname || profilepic) &&
      !data.currentpassword
    ) {

      
      if(defaultName.length ==0){



      }
      else{
    
      try {
        const res = await axios.post(
          "/updateuser",
          {
            fullName: defaultName ||profileName,
            companyName: data.companyname,
            image: profilepic,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fetchdata();
        if (res?.data?.status === true) {
          // toast.success("User Details updated Successfully.");
          toast.success("User Details updated Successfully.", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
          props.setModal(false);
        }
      } catch (error) {
        // toast.error("Error While updating user details");
        toast.error("Error While updating user details", {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
        }
        // throw error; // Re-throw the error to handle it in the onSubmit catch block
      }
    } else if (
      data.newpassword &&
      data.currentpassword &&
      data.confirmPassword &&
      data.newpassword === data.confirmPassword
    ) {
      try {
        const res = await axios.post(
          "/updateuser",
          {
            password: data.currentpassword,
            newPassword: data.newpassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        fetchdata();
        resetField("currentpassword");
        if (res?.data?.status === true) {
          toast.success("Password Updated Successfully.", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
          logout();

          props.setModal(false);
        }
      } catch (error) {
        toast.error(error.response?.data?.message, {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 
        props.setModal(false);
      }
    }
    reset();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <form onSubmit={handleSubmit(Submit)}> */}
      <Row className="header-modal" style={{ width: "100%" }}>
        {toggle ? (
          <form
            onSubmit={handleSubmit(Submit)}
            method="post"
            enctype="multipart/form-data"
          >
            <Row className="header-modal-heading">Edit User Details</Row>

            <Row className="header-modal-rowcontainer">
              <label>Name</label>
              <input
    type="text"
    defaultValue= {profileName? profileName:user?.fullName}
    onChange={(e) => setDefaultName(e.target.value )}
    placeholder="User Name"
/>

              {name && name.length < 3 && (
                <span
                  className="text-danger fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Username must be have 3 characters
                </span>
              )}
            </Row>
            <Row className="header-modal-rowcontainer-file">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  padding: "0px",
                }}
              >
                <div className="upload-text-modal">

                


{privewImg ? (
 
<img
className="avatar"
src={privewImg}
onError={(e) => {
e.target.onerror = null; // Prevent infinite loop in case of repeated errors
e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
}}
alt="avatar"
/>
) : (
userImage ? (

  profileImage ?(
<img
className="avatar"
src={`${nodeUrl}/${profileImage}`}
onError={(e) => {
e.target.onerror = null; // Prevent infinite loop in case of repeated errors
e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
}}
alt="avatar"
/>
  ):(
    <img
className="avatar"
src={`${nodeUrl}/${userImage}`}
onError={(e) => {
e.target.onerror = null; // Prevent infinite loop in case of repeated errors
e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
}}
alt="avatar"
/>
  )

 
) : (
  profileImage ?(
    <img
    className="avatar"
    src={`${nodeUrl}/${profileImage}`}
    onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop in case of repeated errors
    e.target.src = Avatar; // Set a default avatar image if there's an error loading the user's image
    }}
    alt="avatar"
    />
      ):(
  <>
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="18"
   height="17"
   viewBox="0 0 18 17"
   fill="none"
 >
   <path
     d="M8.57895 14.4737H2.68421C2.23753 14.4737 1.80914 14.2962 1.49329 13.9804C1.17744 13.6645 1 13.2362 1 12.7895V5.21053C1 4.76385 1.17744 4.33546 1.49329 4.01961C1.80914 3.70376 2.23753 3.52632 2.68421 3.52632H3.52632C3.973 3.52632 4.40138 3.34887 4.71723 3.03302C5.03308 2.71717 5.21053 2.28879 5.21053 1.84211C5.21053 1.61877 5.29925 1.40457 5.45717 1.24665C5.6151 1.08872 5.82929 1 6.05263 1H11.1053C11.3286 1 11.5428 1.08872 11.7007 1.24665C11.8586 1.40457 11.9474 1.61877 11.9474 1.84211C11.9474 2.28879 12.1248 2.71717 12.4407 3.03302C12.7565 3.34887 13.1849 3.52632 13.6316 3.52632H14.4737C14.9204 3.52632 15.3487 3.70376 15.6646 4.01961C15.9805 4.33546 16.1579 4.76385 16.1579 5.21053V8.15789M11.9474 13.6316H17M14.4737 11.1053V16.1579"
     stroke="black"
     stroke-width="1.68421"
     stroke-linecap="round"
     stroke-linejoin="round"
   />
   <path
     d="M6.05225 8.57856C6.05225 9.24858 6.31841 9.89116 6.79219 10.3649C7.26596 10.8387 7.90854 11.1049 8.57856 11.1049C9.24858 11.1049 9.89116 10.8387 10.3649 10.3649C10.8387 9.89116 11.1049 9.24858 11.1049 8.57856C11.1049 7.90854 10.8387 7.26596 10.3649 6.79219C9.89116 6.31841 9.24858 6.05225 8.57856 6.05225C7.90854 6.05225 7.26596 6.31841 6.79219 6.79219C6.31841 7.26596 6.05225 7.90854 6.05225 8.57856Z"
     stroke="black"
     stroke-width="1.68421"
     stroke-linecap="round"
     stroke-linejoin="round"
   />
 </svg>

 <p>Update Photo </p> 
 </>
      )
)
)
}


</div>

<input
                  id="file"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  // onChange={(e) => setProfilepic(e.target.files[0])}
                />
                <label
                  style={{
                    color: "#f2f2f2",
                    width: "150px",
                    textAlign: "center",
                    padding: "8px 16px",
                    borderRadius: "4px",
                  }}
                  for="file"
                  class="btn-2"
                >
                  Upload
                </label>
              </div>
            </Row>
            {/* <Row className="header-modal-rowcontainer">
              <label>Company Name</label>
              <input
                defaultValue={user?.companyName}
                // placeholder="ItSpark.inc "
                {...register("companyname", {
                  // required: "Company name is required",
                })}
              />
            </Row> */}
            <Row style={{}}>
              <span
                style={{
                  textAlign: "end",
                  padding: "14px",
                  fontSize: "14px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() => setToggle(!toggle)}
              >
                {toggle ? "Update Password ?" : "Update User Details ?"}
              </span>
              <button
                type="submit"
                style={{
                  background: "transparent",
                  alignSelf: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="148"
                  height="67"
                  viewBox="0 0 218 67"
                  fill="none"
                >
                  <g filter="url(#filter0_i_9_793)">
                    <path
                      d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
                      fill="#2E908F"
                    />
                    <text
                      x="39%"
                      y="60%"
                      font-family="Arial"
                      font-size="20"
                      fill="white"
                      className="newCampaignBtn"
                      style={{ fontSize: "22px" }}
                    >
                      Save
                    </text>
                  </g>

                  <defs>
                    <filter
                      id="filter0_i_9_793"
                      x="0.516418"
                      y="0"
                      width="231.15"
                      height="78.2004"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_9_793"
                      />
                    </filter>
                  </defs>
                </svg>

              </button>
            </Row>
          </form>
        ) : (
          <form onSubmit={handleSubmit(Submit)}>
            <Row className="header-modal-heading">Update Password</Row>
            <Row className="header-modal-rowcontainer">
              <label>Current Password</label>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#f5f7f9",
                  borderRadius: "10px",
                  alignItems: "center",
                }}
              >
                <input
                  type={oldPasswordToggle ? "password" : "text"}
                  placeholder="********"
                  required
                  {...register("currentpassword", {
                    required: "Current Password is required",
                  })}
                />

                {!oldPasswordToggle ? (
                  <img
                    className="header-password-icon"
                    src={eyeClose}
                    alt=""
                    onClick={() => setOldPasswordToggle(!oldPasswordToggle)}
                  />
                ) : (
                  <img
                    className="header-password-icon"
                    src={eyeOpen}
                    alt=""
                    onClick={() => setOldPasswordToggle(!oldPasswordToggle)}
                  />
                )}
              </div>
              {/* {oldPassword(
                <span
                  className="text-danger fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Password field is required
                </span>
              )} */}
            </Row>

            <Row className="header-modal-rowcontainer">
              <label>New Password</label>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#f5f7f9",
                  borderRadius: "10px",
                  alignItems: "center",
                }}
              >
                <input
                  type={newPasswordToggle ? "password" : "text"}
                  placeholder="********"
                  {...register("newpassword", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "",
                    },
                  })}
                />
                {!newPasswordToggle ? (
                  <img
                    className="header-password-icon"
                    src={eyeClose}
                    alt=""
                    onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                  />
                ) : (
                  <img
                    className="header-password-icon"
                    src={eyeOpen}
                    alt=""
                    onClick={() => setNewPasswordToggle(!newPasswordToggle)}
                  />
                )}
              </div>
              {password && password.length < 8 && (
                <span
                  className="text-danger fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Password at least 8 char long
                </span>
              )}
            </Row>

            <Row className="header-modal-rowcontainer">
              <label>Confrim Password</label>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#f5f7f9",
                  borderRadius: "10px",
                  alignItems: "center",
                }}
              >
                <input
                  type={currentPasswordToggle ? "password" : "text"}
                  placeholder="********"
                  {...register("confirmPassword", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "",
                    },
                  })}
                />

                {!currentPasswordToggle ? (
                  <img
                    className="header-password-icon"
                    src={eyeClose}
                    alt=""
                    onClick={() =>
                      setCurrentPasswordToggle(!currentPasswordToggle)
                    }
                  />
                ) : (
                  <img
                    className="header-password-icon"
                    src={eyeOpen}
                    alt=""
                    onClick={() =>
                      setCurrentPasswordToggle(!currentPasswordToggle)
                    }
                  />
                )}
              </div>
              {confirmPassword && password !== confirmPassword && (
                <span
                  className="text-danger fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Passwords do not match
                </span>
              )}
            </Row>

            <Row style={{}}>
              <span
                style={{
                  textAlign: "end",
                  padding: "4px",
                  fontSize: "14px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() => setToggle(!toggle)}
              >
                {toggle ? "Update Password ?" : "Update User Details ?"}
              </span>
              <button
                type="submit"
                style={{
                  background: "transparent",
                  alignSelf: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="148"
                  height="67"
                  viewBox="0 0 218 67"
                  fill="none"
                >
                  <g filter="url(#filter0_i_9_793)">
                    <path
                      d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
                      fill="#2E908F"
                    />
                    <text
                      x="39%"
                      y="60%"
                      font-family="Arial"
                      font-size="20"
                      fill="white"
                      className="newCampaignBtn"
                      style={{ fontSize: "22px" }}
                    >
                       Save 
                    </text>
                  </g>

                  <defs>
                    <filter
                      id="filter0_i_9_793"
                      x="0.516418"
                      y="0"
                      width="231.15"
                      height="78.2004"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_9_793"
                      />
                    </filter>
                  </defs>
                </svg>
              </button>
            </Row>
          </form>
        )}
      </Row>
      {/* </form> */}
    </Modal>
  );
};

const CoinsExpireModal = (props) => {
  const user = useSelector((state) => state.loginuser?.user);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="confirmpass-body-modal" style={{ gap: "16px" }}>
        <h5 className="confirmpass-label-modal ">
          Current Balance {user?.credits}
        </h5>
        <p>Please Recharge To continue Our Services</p>

        <Button className="confirmpass-btn-modal " 
        onClick={props.onHide}
        >
          {props.buttonTitle}
        </Button>
      </div>
    </Modal>
  );
};

const NotificationDropDown = ({ notification, isOpen, onToggle}) => {
  const data = [
    "15,000 reach achieved",
    "Target not achieved",
    "15,000 reach achieved",
    "Target not achieved",
    "15,000 reach achieved",
    "Target not achieved",
    "15,000 reach achieved",
    "Target not achieved",
    "15,000 reach achieved",
    "Target not achieved",
  ];
  return (
    <Dropdown
    show={isOpen}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

      }}
      style={{ position: "relative" }}
    >
      <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown-btn "  onClick={onToggle} >
        <div className="bell-icon">{BellIcon}</div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="drop-down-menu-main-notification"
        // style={{
        //   background: "transparent",
        //   border: "none",
        //   padding: "0px",
        // }}
      >
        {/* <span className="dropdown-arrow ">{DropDownArrow}</span> */}
        <div>
          <div className="header-notification-main" style={{ display: "flex" }}>
            <Row className="header-notification-row">
              <Col>
                <Row className="">
                  <div className="header-notification-row1">
                    <h5>Notifications</h5>
                    {/* <p>Mark all as read</p> */}
                  </div>
                </Row>
                {/* <Row>
                  <div className="header-notification-row2  ">
                    <span style={{ marginTop: "-8px" }}>
                      {NotificationBell}
                    </span>
                    <div>
                      <h6>Push Notification</h6>
                      <p>Automatically send new notifications</p>
                    </div>
                    <MonthlyToggle />
                  </div>
                </Row> */}
                <Row
                  style={{
                    gap: "8px",
                    maxHeight: "400px",
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  {notification?.map((item) => (
                    <NotificationItem item={item} />
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};



const NotificationItem = ({ item }) => {
  // Date string to compare
  const dateString = item.createdAt;
  const dateToCompare = new Date(dateString);

  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate.getTime() - dateToCompare.getTime();

  // Convert milliseconds to days
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return (
    <div className="notification-item-box">
      <div style={{ display: "flex", gap: "5px" }}>
        <div
          className="notification-svg-box"
          style={
            item === "Target not achieved" ? { background: "#110535" } : {}
          }
        >
          {NotificationSvg}
        </div>
        <div className="notification-content-box">
        <h5 class="capitalize">{item.notificationDescription}</h5>
          <p style={item === "Target not achieved" ? { color: "red" } : {}}>
            {daysPassed == 0 ? "Today" : `${daysPassed} Days`}
          </p>
        </div>
      </div>
      {/* <Link to={item.redirectUrl} className="notification-view">
        view
      </Link> */}
    </div>
  );
};



const MonthlyToggle = ({}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div
      className="header-toggle-main"
      style={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      <button
        onClick={() => setToggle(!toggle)}
        className={
          toggle ? "header-toggle-button-active" : "header-toggle-button"
        }
      >
        <span className="header-toggle-button-inside"></span>
      </button>
    </div>
  );
};

const AdRunSuccessModal = (props) => {
  return (

    props.GoogleAdsResult == true
      ? <CampaignsLoader />:
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="confirmpass-body-modal">
        <h5 className="confirmpass-label-modal ">
          {props.GoogleAdsResult == true
            ? "Google Ad Running!"
            : "Ad API Failed"}
        </h5>

        <Button
          className="confirmpass-btn-modal "
          onClick={props.handleCloseModal}
          style={
            props.GoogleAdsResult == true
              ? { background: "#358f8b", border: "#358f8b" }
              : { background: "#b30000", border: "#b30000" }
          }
        >
          {props.buttonTitle}
        </Button>
      </div>
    </Modal>
  );
};


export default Header;
