import "./SideBar.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CupImage from "./sidebar-img/cup.png";
import Rocket from "./sidebar-img/Rocket.png";
import Cookies from "js-cookie";
import Exoid from "../../../assets/logo/logo.png";
import MenuIcon from "../Main Pages/Images/menu.png";
import AddRun from "../Main Pages/Images/Add-run.png";
import Brand from "../Main Pages/Images/brands.png";
import Generate from "../Main Pages/Images/generate.png";
import Integration from "../Main Pages/Images/integration.png";
import Payment from "../Main Pages/Images/payments.png";
import Setting from "../Main Pages/Images/setting.png";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTempData } from "../../../Redux/templateSlice";
import {
  ActiveAddRun,
  ActiveBrand,
  ActiveGenerate,
  ActiveIntegration,
  ActiveMenuIcon,
  ActivePayment,  
  ActiveSetting,
  Logout,
} from "./.PagesSVG";
import axios from "../../axios/auth";
import { Coins } from "./Header/svg";
import { addOnlyCreativeSize, addOnlyCreativeState } from "../../../Redux/onlyCreativeData";
import { addBrandData, removeBrandData } from "../../../Redux/brandSetupData";
import { logoColorPikedFun } from "../../../Redux/addCreateSlice";
import { addBrandArray } from "../../../Redux/generatedBrandList";

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

const Footer = styled.div`
  min-width: 180px;

  background-color: #edf9f8;
  / gap: 10px; /
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding: 2px;
  margin: 5px;
`;

const SideBar = ({ HandleToggleNav, setMobileSidebar }) => {
  const [subscriptionMode, setSubscriptionMode] = useState();
  const [daysLeft, setDaysLeft] = useState();
  const [planName, setPlanname] = useState();

  const [avaiBrand, setAvaiBrand] = useState(false)
  const [coins, setCoins] = useState();
  const userToken = Cookies.get("token");

  const { user } = useSelector((state) => state?.loginuser);
  let { coinMangment } = useSelector((state) => state.addcreative);
  const { brand } = useSelector((state) => state?.brandList)


  const fetchBrand = async () => {
    if (userToken) {
      try {
        const res = await axios.get("/fetch-brand", {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        });

        // dispatch(addBrandData(res.data.data))
        dispatch(addBrandArray(res.data?.data))
        if (res.data?.data.length > 0) {
          setAvaiBrand(true)
        }
        // Handle the response as needed
      } catch (e) {
        console.error("error fetchBrand sideBar se:", e);
        // Handle the error as needed
      }
    }
    else { }
  };


  useEffect(() => {
    fetchBrand()
  }, [])

  useEffect(() => {
    if (brand?.length > 0) {
      setAvaiBrand(true)
    }
  }, [brand])

  useEffect(() => {
    let length = user?.plan.length;

    if (user?.plan && length > 0) {
      setSubscriptionMode(user?.plan[length - 1]?.subscriptionMode);
    }
  }, [subscriptionMode, user]);

  const dispatch = useDispatch();

  // get coins Apis start
  const token = Cookies.get("token");

  const getCoins = async () => {
    if (token) {
      try {
        const res = await axios.get(
          "/blanceHistory",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );


        setCoins(res.data.data);
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    getCoins();
  }, []);

  useEffect(() => {
    let EndDate = user?.plan[user?.plan.length - 1]?.subscriptionExpiryDate;
    let dayLeft = calculateDaysLeft(EndDate);
    setPlanname(user?.plan[user?.plan.length - 1]?.name);
    setDaysLeft(dayLeft);
  }, [user]);

  function calculatePercentage(part, whole) {
    return (part / whole) * 100;
  }
  // Example usage:
  const part = 5000;
  const whole = 10000;
  let percentage = calculatePercentage(part, whole);
  percentage = percentage.toFixed(1);

  function calculateDaysLeft(targetDateString) {
    const targetDate = new Date(targetDateString);
    // Get the current date
    const currentDate = new Date();
    // Calculate the difference in milliseconds
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    // Convert the difference to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  }

  
  const emptyData = () => {
    dispatch(updateTempData({}));
    dispatch(addOnlyCreativeState("onlyCreativeSelected"))
    dispatch(removeBrandData({}))
    dispatch(logoColorPikedFun([]))
    dispatch(addOnlyCreativeSize(""))
    dispatch(addBrandData({ createProject: "createProject", onlyCreativeSelected: "onlyCreativeSelected" }))
  };

  const handleBrand = () => {
    dispatch(addOnlyCreativeState(""))
    dispatch(addOnlyCreativeSize(""))
    dispatch(removeBrandData({}))
    dispatch(logoColorPikedFun([]))
    dispatch(addBrandData({ onlyCreativeSelected: "" }))
  }

  const handleItemClick = (path) => {
   
      if (typeof setMobileSidebar === 'function') {
        setMobileSidebar(false);
      }
    handleBrand();
  };

  const handleItemClickSec = (path) => {
    if (typeof setMobileSidebar === 'function') {
      setMobileSidebar(false);
    }
    emptyData();

  };


  return (
    <div className="header-container">
      <div>
        <Link
          to={"/main/dashboard"}
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="image" src={Exoid} alt="Exoid" />

        </Link>
      </div>

      <nav className="header-main">
        <NavList style={{ width: "90%", gap: "10px", display: "flex" }}>
          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >

            <NavLink
              className={`${subscriptionMode === "aiCreative"
                ? "StyledNavLink-disable"
                : "StyledNavLink"
                }`}
              style={{ margin: "0px" }}
              to={`${subscriptionMode === "aiCreative" ? "#" : "/main/dashboard"
                }`}
                onClick={() => {
                  if (typeof setMobileSidebar === 'function') {
                    setMobileSidebar(false);
                  }
                }}
            >
              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveAddRun}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Dashboard
              </span>
            </NavLink>
          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
            <NavLink
              style={{ margin: "0px" }}
              className={`${subscriptionMode === "aiCreative"
                ? "StyledNavLink-disable"
                : "StyledNavLink"
                }`}
              to={`${subscriptionMode === "aiCreative" ? "#" : "/main/campaign"
                }`}
                onClick={() => {
                  if (typeof setMobileSidebar === 'function') {
                    setMobileSidebar(false);
                  }
                }}
            >
              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveMenuIcon}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Ad Creation
              </span>
            </NavLink>
          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
            <NavLink
              className={"StyledNavLink"}
              to={"/main/add"}
              style={{ margin: "0px" }}
              onClick={() => {
                if (typeof setMobileSidebar === 'function') {
                  setMobileSidebar(false);
                }
              }}
            >
              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveAddRun}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Add Run
              </span>
            </NavLink>
          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
            <NavLink
              style={{ margin: "0px" }}
              className={`${subscriptionMode === "aiCreative"
                ? "StyledNavLink-disable"
                : "StyledNavLink"
                }`}
              to={`${subscriptionMode === "aiCreative" ? "#" : "/main/Integrations"
                }`}
                onClick={() => {
                  if (typeof setMobileSidebar === 'function') {
                    setMobileSidebar(false);
                  }
                }}
            >
              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveIntegration}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Integrations
              </span>
            </NavLink>
          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >

            <NavLink
              className={"StyledNavLink"}
              to={avaiBrand ? "/main/brand-list" : "/main/brand"}
              style={{ margin: "0px" }}
              // onClick={handleBrand}
              // onClick={() => setMobileSidebar(false)}
              onClick={() => handleItemClick('/path1')}
            >

              <div style={{ position: "absolute", top: "4px", left: 4 }}>
                {ActiveBrand}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Brand
              </span>
            </NavLink>
          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
            <NavLink
              className={"StyledNavLink"}
              // to={"/main/onlyCreative"}
              to={"/main/brand-setup"}
              style={{ margin: "0px" }}
              onClick={() => handleItemClickSec('/path1')}
            >
              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveGenerate}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Ad Creative
              </span>
            </NavLink>
          </li>
          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
           <NavLink
  style={{ margin: "0px" }}
  className={`${subscriptionMode === "aiCreative"
    ? "StyledNavLink-disable"
    : "StyledNavLink"
    }`}
  to={`${subscriptionMode === "aiCreative" ? "#" : "/main/Payments"}`}
  onClick={() => {
    if (typeof setMobileSidebar === 'function') {
      setMobileSidebar(false);
    }
  }}
>
  <div style={{ position: "absolute", top: "4px", left: 4 }}>
    {ActivePayment}
  </div>
  <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
    Payments
  </span>
</NavLink>

          </li>

          <li
            className="main-2"
            onClick={HandleToggleNav}
            style={{ position: "relative" }}
          >
            <NavLink
              className={"StyledNavLink"}
              to={"/main/setting"}
              style={{ margin: "0px" }}
              onClick={() => {
                if (typeof setMobileSidebar === 'function') {
                  setMobileSidebar(false);
                }
              }}
            >

              <div style={{ position: "absolute", top: "4px" }}>
                {ActiveSetting}
              </div>
              <span className="sidebar-text" style={{ paddingLeft: "50px" }}>
                Settings
              </span>
            </NavLink>
          </li>


          {typeof setMobileSidebar === 'function' && (
  <li
    // className="main-2"
    className="Logout"
    onClick={HandleToggleNav}
    // style={{ position: "relative" }}
  >
    <NavLink
      // className={"StyledNavLink"}
      // className={"StyleLogout"}
      to={"/login"}
      style={{ margin: "0px" }}
      onClick={() => {
        setMobileSidebar(false);
      }}
    >
      <div>
        
      </div>
      <button className="logout-btn">
        Logout
      </button>
    </NavLink>
  </li>
)}
        </NavList>
      </nav>

      <div className="footer-container">
       
        <Footer>
          <div
            style={{
              margin: "10px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <p
              className="footer-p"
              style={{ fontWeight: "bold", padding: "0px 2px" }}
            >
              {planName ? planName : "Silver"}  🔥
            </p>

            <p className="footer-span" style={{ padding: "3px" }}>
              Trails ends in {daysLeft ? daysLeft : "2"} Days <br /> Continue
              Enjoying ..
            </p>

            <div className="footer-creditMain">
              <div className="footer-creditMain-text" style={{}}>
                <h5>Credits</h5>
                <p>
                  {coinMangment}/{coins?.totalCredits}
                </p>
              </div>
              <div
                style={{
                  width: "90%",
                  background: "#D9D9D9",
                  height: "6px",
                  borderRadius: "20px",

                  minWidth: "100px",
                  position: "relative",
                }}
              >
                <p
                  style={{
                    position: "absolute",
                    background: "#47beb9 ",
                    height: "6px",
                    borderRadius: "20px",
                    minWidth: `${percentage && percentage}%`,
                  }}
                ></p>
              </div>
            </div>

            <Link
              to={"/plans"}
              className="footer-button-main"
              style={{ marginTop: "10px" }}
            >
              <img className="footer-cup" src={CupImage} alt="cup" />
              <span className="footer-button">Upgrade</span>
            </Link>
          </div>
        </Footer>
      </div>
    </div>
  );
};

const MainContent = ({ active, text, icon, ActiveIcon }) => {
  return (
    <div
      className={`main-2 ${active ? "active" : ""} `}
      style={{ width: "90%", display: "flex", alignSelf: "end" }}
    >
      {active ? (
        <img
          src={ActiveIcon}
          className={`item-icon ${active ? "active-icon" : ""}`}
          alt="menu-icon"
        />
      ) : (
        <img
          src={icon}
          className={`item-icon ${active ? "active-icon" : ""}`}
          alt="menu-icon"
        />
      )}
      <span className={`sidebar-text  ${active ? "active-text" : ""}`}>
        {text}
      </span>
    </div>
  );
};

export default SideBar;
