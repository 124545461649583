import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CommonBtn } from "../../../UI-Components/ReusableComponents";
import { PlusSvg } from "../../../UI-Components/ReUsableSVG";
import style from "./BrandList.module.css";
import Brandlist from "./Images/Brandlist.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTempData } from "../../../../Redux/templateSlice";
import { EditedBrand, addUpdateBrandLIst } from "../../../../Redux/addCreateSlice";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";


const BrandList = () => {
  const userToken = Cookies.get("token");
  // const { brandList } = useSelector((state) => state.addcreative);
//   const dispatch=useDispatch()
//   useEffect(()=>{
// dispatch(addUpdateBrandLIst(brandList))
//   },[brandList])

const [brandList,setBrandList]=useState([])
  useEffect(()=>{
    getBrandListApi()
  },[])

  const getBrandListApi= async()=>{
    try {
      const res = await axios.get("/fetch-brand", {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setBrandList(res?.data?.data)
    } catch (error) {
      
    }
  
  }
const navigate=useNavigate()
const dispatch=useDispatch()
const generateNewBrand=()=>{ 
dispatch(EditedBrand(""))
navigate("/main/Generate")
}

  return (
    <Container
      fluid
      style={{ background: "" }}
      className={`adContainer ${style.brandListMain}`}
    >
      <Row>
        <div className="brand-header">
          <div>
            <h5 className={style.BrandHeading}>
              <b>Brands List</b>
            </h5>
            <p className={style.brandListSubLeading}>
              See your projects and create new ones under the selected brand.
            </p>
          </div>
          <div className="brand-header-button-div">
            <CommonBtn
              borderRadius={24}
              icon={PlusSvg}
              handleSubmit={generateNewBrand}
            >
                Create A New Brand
            </CommonBtn>
          </div>
        </div>
      </Row>
      <Row className={style.BrandListingBody}>
      {brandList.map((ele,index)=>{
        return   <SingleBrandList
        title={ele.brandname}
       logoImg={ele.logo}
       brandData={ele}
       indexNumber={index}
        />
      })
    }
      </Row>
    </Container>
  );
};

const SingleBrandList = ({ title, subtitle,logoImg,brandData,indexNumber  }) => {
  const { brandList } = useSelector((state) => state.addcreative);
  const navigate=useNavigate()
  const dispatch=useDispatch()
 const handleNavigate=()=>{
  dispatch(updateTempData(brandData))
  dispatch(EditedBrand(brandData.id))
navigate("/main/brand/brandsetup")
 }
  return (
    <Col lg={3} className={style.BrandListingSingle} onClick={handleNavigate}>
      <div className={`d-flex flex-column ${style.BrandListingSingleHeading}`}>
        <h4 >{title}</h4>
        <p>{subtitle}</p>
      </div>
      <img src={logoImg} alt="Brandlist"  height={20} width={20}/>
    </Col>
  );
};
export default BrandList;
